import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";

import Searchicon from "../../assets/images/dashboardimages/search.png";

import Editicon from "../../assets/images/dashboardimages/pencil.png";
import addicon from "../../assets/images/dashboardimages/product.png";

// import DatePicker from 'react-date-picker';

class ProductVPM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,
      view: false,
      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      ProductData: [],
      VariantData: [],

      Vertical: null,
      SearchField: null,

      PVPMData: [],
      Status: "Yes",
      CoatingName: null,
      BasePrice: null,

      AdditonalCost: null,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      CoatingPriceID: null,
      Sheet: null,
      VariantName: null,
      NoofUps: null,
      DieCost: null,
      PastingCost: null,
      SampleCost: null,
      MVPId: null,
      SheetData: [],
      ProductName: null,
      ProductData: [],
      ProductItemName: "",
      PrintingBasePrice: null,
      PrintingNoOfSheets: null,
      PrintingAdditionalCostPerSheet: null,
      PunchingBasePrice: null,
      PunchingNoOfSheets: null,
      PunchingAdditionalCostPerSheet: null,
    };
  }

  openNav = () => {
    document.getElementById("mySidenav").style.width = "90%";
  };

  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  onSaveData() {
    // console.log(this.state.VariantName)
    if (this.state.VariantName != null) {
      if (this.state.Sheet != null) {
        if (this.state.NoofUps != null) {
          if (this.state.GSM != null) {
            if (this.state.DieCost != null) {
              if (this.state.PastingCost != null) {
                if (this.state.SampleCost != null) {
                  this.onPost();
                } else {
                  Notiflix.Notify.Failure("Please enter sample cost");
                }
              } else {
                Notiflix.Notify.Failure("Please enter pasting cost");
              }
            } else {
              Notiflix.Notify.Failure("Please enter die cost");
            }
          } else {
            Notiflix.Notify.Failure("Please enter GSM");
          }
        } else {
          Notiflix.Notify.Failure("Please enter the no of Ups");
        }
      } else {
        Notiflix.Notify.Failure("Please enter the sheet size");
      }
    } else {
      Notiflix.Notify.Failure("Please select variant name");
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        feedingid: this.state.MVPId,
        variantid: this.state.VariantName,
        sheetsizeid: this.state.Sheet,
        status: this.state.Status == "Yes" ? "Active" : "Inactive",
        noofups: this.state.NoofUps,
        gsm: this.state.GSM,
        diecost: this.state.DieCost,
        boxsize: null,
        pastingcost: this.state.PastingCost,
        samplecost: this.state.SampleCost,
        productItemName: this.state.ProductItemName,
        printingbaseprice: this.state.PrintingBasePrice,
        printingnoofsheets: this.state.PrintingNoOfSheets,
        printingadditionalcostpersheet:
          this.state.PrintingAdditionalCostPerSheet,
        punchingbaseprice: this.state.PunchingBasePrice,
        punchingnoofsheets: this.state.PunchingNoOfSheets,
        punchingadditionalcostpersheet:
          this.state.PunchingAdditionalCostPerSheet,
      },
      "ProductVariantPriceMapping"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Coating Price successfully added.");

          this.closeNav();
          this.getProductVariant();
          //window.location.href = "/price_calculation_header";
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          ProductData: obj.data,
        });
      })
    );

    this.getSheetSize();

    this.getProductVariant();
  }

  getSheetSize = () => {
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "fld_sheet_size as label,fld_sheet_id as value",
      },
      "GetPrintingSheet"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            SheetData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  getProductVariant = () => {
    Notiflix.Loading.Dots("Please wait...");

    localStorage.removeItem("PSMDetails");

    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetProductVariantPriceMapping"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            PVPMData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  render() {
    const { text, suggestions } = this.state;

    // =========VariantList=============
    const PVPMData = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Item Name",
          field: "itemName",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Variant Name",
          field: "VN",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Sheet Size",
          field: "sheetsize",
          sort: "disabled",
          width: 150,
        },
        {
          label: "GSM",
          field: "gsm",
          sort: "disabled",
          width: 150,
        },
        // {
        //     label: "Die Cost",
        //     field: "Diecost",
        //     sort: "disabled",
        //     width: 400,
        // },
        // {
        //     label: "Pasting Cost",
        //     field: "PastingCost",
        //     sort: "disabled",
        //     width: 400,
        // },

        // {
        //     label: "Sample Cost",
        //     field: "samplecost",
        //     sort: "disabled",
        //     width: 400,
        // },

        {
          label: "Costing",
          field: "costing",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ], // end for variant
      rows: this.state.PVPMData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput != "" &&
          (data.itemName != null ? data.itemName.toLowerCase() : "").includes(
            this.state.searchInput.toLowerCase()
          )
        ) {
          return data;
        }
        if (
          this.state.searchInput != "" &&
          data.variantName
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          itemName: data.itemName,
          VN: data.variantName,
          sheetsize: data.SheetSize,
          gsm: data.fld_gsm,
          costing: (

            <table className="pricetable">
              <tr>
                <td ><b>Die Cost</b></td>:<td>{data.fld_die_cost}</td>
              </tr>


              <tr>
                <td><b>Pasting Cost</b></td>  <td>:</td>
                <td>{data.fld_pasting_cost == null ? "0.00" : data.fld_pasting_cost}</td>
              </tr>



              <tr>
                <td><b>Sample Cost</b></td>  <td>:</td>
                <td>{data.fld_sample_cost == null ? "-" : data.fld_sample_cost}</td>
              </tr>


              <tr>
                <td><b>Printing Base Price</b></td>  <td>:</td>
                <td> {data.fld_printingbaseprice == null ? "-" : data.fld_printingbaseprice}</td>
              </tr>

              <tr>
                <td><b>Printing No Of Sheets</b></td>  <td>:</td>
                <td> {data.fld_printingnoofsheets == null ? "-" : data.fld_printingnoofsheets}</td>
              </tr>

              <tr>
                <td><b>Printing Additional Cost Per Sheet</b></td>  <td>:</td>
                <td> {data.fld_printingadditionalcostpersheet == null ? "-" : data.fld_printingadditionalcostpersheet}</td>
              </tr>

              <tr>
                <td><b>Punching Base Price</b></td>  <td>:</td>
                <td>  {data.fld_punchingbaseprice == null ? "-" : data.fld_punchingbaseprice}</td>
              </tr>

              <tr>
                <td><b>Punching No Of Sheets</b></td>  <td>:</td>
                <td>   {data.fld_punchingnoofsheets == null ? "-" : data.fld_punchingnoofsheets}</td>
              </tr>

              <tr>
                <td><b>Punching Additional Cost Per Sheet</b></td>  <td>:</td>
                <td>   {data.fld_punchingadditionalcostpersheet == null ? "-" : data.fld_punchingadditionalcostpersheet}</td>
              </tr>

            </table>

          ),
          //   Diecost: data.fld_die_cost,
          //   PastingCost:
          //     data.fld_pasting_cost == null ? "0.00" : data.fld_pasting_cost,
          //   samplecost:
          //     data.fld_sample_cost == null ? "-" : data.fld_sample_cost,
          action: (
            <span>
              <img
                src={Editicon}
                alt="View user"
                className="btnicons"
                onClick={() => {
                  this.setState(
                    {
                      Sheet: data.fld_sheet_size_id,
                      VariantName: data.fld_variant_id,
                      NoofUps: data.fld_no_of_ups,
                      DieCost: data.fld_die_cost,
                      PastingCost: data.fld_pasting_cost,
                      SampleCost:
                        data.fld_sample_cost == null ? 0 : data.fld_sample_cost,
                      MVPId: data.fld_feeding_id,
                      GSM: data.fld_gsm,
                      Status: data.fld_status == "Active" ? "Yes" : "No",
                      ProductName: data.productid,
                      PrintingBasePrice: data.fld_printingbaseprice,
                      PrintingNoOfSheets: data.fld_printingnoofsheets,
                      PrintingAdditionalCostPerSheet: data.fld_printingadditionalcostpersheet,
                      PunchingBasePrice: data.fld_punchingbaseprice,
                      PunchingNoOfSheets: data.fld_punchingnoofsheets,
                      PunchingAdditionalCostPerSheet: data.fld_punchingadditionalcostpersheet,
                    },
                    () => {
                      PostApiCall.postRequest(
                        {
                          whereClause: `where fld_productid=${this.state.ProductName}`,
                          recordCount:
                            "fld_variantid as value,fld_variantname as label",
                        },
                        "GetVariantData"
                      ).then((results) =>
                        results.json().then((obj) => {
                          if (results.status == 200 || results.status == 201) {
                            // console.log(obj.data);
                            this.setState(
                              {
                                VariantData: obj.data,
                              },
                              () => {

                                this.openNav();

                              }
                            );
                          }
                        })
                      );
                    }
                  );
                }}
              ></img>
            </span>
          ),
        };
      }),
    };

    return (
      <React.Fragment>
        <div id="mySidenav" class="sidenav ">
          <div className="mb-5">
            <a
              href="javascript:void(0)"
              class="closebtn"
              onClick={() => {
                this.closeNav();
              }}
            >
              &times;
            </a>
          </div>
          <Row>
            <Col xs="12">
              <div className=" content-draw">
                <div className="row">
                  <div className="form-group col col-4">
                    <label
                      className="my-1 mr-2"
                      for="inlineFormCustomSelectPref"
                    >
                      Item Name
                      <span className="mandatory">*</span>
                    </label>
                    <select
                      value={this.state.ProductName}
                      onChange={(text) => {
                        this.setState(
                          {
                            ProductName: text.target.value,
                          },
                          () => {
                            Notiflix.Loading.Dots("Please wait...");

                            PostApiCall.postRequest(
                              {
                                whereClause: `where fld_productid=${this.state.ProductName}`,
                                recordCount:
                                  "fld_variantid as value,fld_variantname as label",
                              },
                              "GetVariantData"
                            ).then((results) =>
                              results.json().then((obj) => {
                                if (
                                  results.status == 200 ||
                                  results.status == 201
                                ) {
                                  // console.log(obj.data);
                                  this.setState({
                                    VariantData: obj.data,
                                    VariantName: obj.data[0].value,
                                    ProductItemName: obj.data[0].label,
                                  });
                                  Notiflix.Loading.Remove();
                                }
                              })
                            );
                          }
                        );
                      }}
                      className="custom-select my-1 mr-sm-2"
                    >
                      <option value="">Select Item Name</option>
                      {this.state.ProductData.map((product) => (
                        <option
                          key={product.value}
                          value={product.value}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            this.setState({
                              ProductItemName: e.target.value,
                            });
                          }}
                        >
                          {product.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label for="SheetSize">
                        Variant Name
                        <span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={this.state.VariantName}
                        onChange={(text) => {
                          this.setState({
                            VariantName: text.target.value,
                          });
                        }}
                      >
                        <option value="">Select Variant Name</option>
                        {this.state.VariantData.map((data) => (
                          <option
                            key={data.value}
                            value={data.value}

                          >
                            {data.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label for="SheetSize">
                        Sheet Size
                        <span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={this.state.Sheet}
                        onChange={(text) => {
                          this.setState({
                            Sheet: text.target.value,
                          });
                        }}
                      >
                        {this.state.SheetData.map((data) => (
                          <option
                            key={data.value}
                            value={data.value}
                          >
                            {data.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-group">
                      <label for="BasePrice">
                        No. of Ups
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="BasePrice"
                        className="form-control"
                        value={this.state.NoofUps}
                        onChange={(text) => {
                          if (this.state.DecimalRegex.test(text.target.value)) {
                            this.setState({
                              NoofUps: text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label for="Sheet">
                        GSM
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="Sheet"
                        className="form-control"
                        value={this.state.GSM}
                        onChange={(text) => {
                          this.setState({
                            GSM: text.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Die Cost
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.DieCost}
                        onChange={(text) => {
                          if (this.state.DecimalRegex.test(text.target.value)) {
                            this.setState({
                              DieCost: text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Pasting Cost
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.PastingCost}
                        onChange={(text) => {
                          if (this.state.DecimalRegex.test(text.target.value)) {
                            this.setState({
                              PastingCost: text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Sample Cost
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.SampleCost}
                        onChange={(text) => {
                          if (this.state.DecimalRegex.test(text.target.value)) {
                            this.setState({
                              SampleCost: text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="Sheet">
                        Printing
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="Sheet"
                        disabled="true"
                        className="form-control"
                        value="Printing"
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Printing Base Price
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.PrintingBasePrice}
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PrintingBasePrice:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Printing No Of Sheets
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.PrintingNoOfSheets}
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PrintingNoOfSheets:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Printing Additional Cost Per Sheet
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={
                          this.state
                            .PrintingAdditionalCostPerSheet
                        }
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PrintingAdditionalCostPerSheet:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="Sheet">
                        Punching
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="Sheet"
                        disabled="true"
                        className="form-control"
                        value="Punching"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Punching Base Price
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.PunchingBasePrice}
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PunchingBasePrice:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Punching No Of Sheets
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={this.state.PunchingNoOfSheets}
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PunchingNoOfSheets:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label for="AdditonalCost">
                        Punching Additional Cost Per Sheet
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="AdditonalCost"
                        className="form-control"
                        value={
                          this.state
                            .PunchingAdditionalCostPerSheet
                        }
                        onChange={(text) => {
                          if (
                            this.state.DecimalRegex.test(
                              text.target.value
                            )
                          ) {
                            this.setState({
                              PunchingAdditionalCostPerSheet:
                                text.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>



                  <div className="col-12">
                    <div className="form-group">
                      <label for="BoardName">
                        Status
                        <span className="mandatory">*</span>
                      </label>

                      <label class="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          //  disabled={!this.state.IsVisible}
                          checked={this.state.Status == "Yes" ? true : false}
                          onChange={() => {
                            this.setState({
                              Status: "Yes",
                            });
                          }}
                        />
                        Yes
                      </label>
                      <label
                        class="radio-inline"
                        style={{ marginLeft: "10px" }}
                      >
                        <input
                          type="radio"
                          name="optradio"
                          //  disabled={!this.state.IsVisible}
                          checked={this.state.Status == "No" ? true : false}
                          onChange={() => {
                            this.setState({
                              Status: "No",
                            });
                          }}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div
                className="col-12
                            "
              >
                <button
                  style={{ float: "right" }}
                  className="btn align-items-center Bechofy-btn mb-4"
                  onClick={this.onSaveData.bind(this)}
                >
                  {this.state.MVPId == null ? (
                    <span>Save Variant Price</span>
                  ) : (
                    <span>Update Variant Price</span>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            <Breadcrumbs
              breadcrumbItem={"Product Variant Price Mapping Master"}
              urlPath={"/product_variant_price_mapping"}
            />

            <Row>
              <Col xl="12">
                <Card>
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1">
                      <div className="col-5">
                        <div className="form-group">
                          <h4
                            className="FilterCard"
                            style={{ marginTop: "10px", marginLeft: "4%" }}
                          >
                            Product Variant Price Mapping List
                          </h4>
                        </div>
                      </div>

                      <div className="col-7">
                        <div className="position-relative">
                          <button
                            onClick={() => {
                              window.location.href =
                                "/add_product_variant_price/create";
                            }}
                            style={{ marginRight: "2%" }}
                            className="btn align-items-center btn Bechofy-btn AddNewBtn"
                          >
                            Map New Variant Price
                            <span className="">
                              <img
                                src={addicon}
                                alt="block user"
                                className="btnicons"
                                style={{ marginLeft: "5px" }}
                              ></img>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CardBody className="pt-0">
                    <Row>
                      <MDBCol
                        md="12"
                        style={{ marginBottom: "15px", marginTop: "15px" }}
                      >
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <span className="">
                                <img
                                  src={Searchicon}
                                  alt="block user"
                                  className="btnicons"
                                ></img>
                              </span>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type the search phrase you wish to search within the grid"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={(e) => this.seachBarHandler(e)}
                            value={this.state.searchInput}
                          />
                        </div>
                      </MDBCol>

                      <Col md="12">
                        {this.state.PVPMData.length !== 0 ? (
                          <MDBDataTable
                            disableRetreatAfterSorting={true}
                            hover
                            // scrollY
                            striped
                            bordered
                            data={PVPMData}
                            seachTop={false}
                            entriesOptions={[25, 50, 100]}
                          >
                            <MDBTableHead columns={PVPMData.columns} />
                            <MDBTableBody rows={PVPMData.rows} />
                          </MDBDataTable>
                        ) : (
                          <p>No Data Found</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* </Card>

							</Col>
						</Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ProductVPM;
