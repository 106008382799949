import React, { Component } from "react";
import {
    Row, Col, Card, CardBody,
    Media,

} from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";


class SocialSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RefundListData: []

        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        Notiflix.Loading.Custom("");

        PostApiCall.postRequest(
            {
                WhereClause: ''
            },
            "Get_DashboardRefundList"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // //console.log(obj.data)
                    this.setState({
                        RefundListData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))

    }

    render() {
        return (
            <React.Fragment>

                <Card className="mini-stats-wid" style={{ height: '290px' }}>
                    <CardBody>
                        <Media>
                            <Media body>
                                <Row>
                                    <Col xs="6">
                                        <h4 className="Bechofy-text" style={{ color: '#495057' }}>
                                            Latest Return & Refund Request
                                        </h4>

                                    </Col>
                                    <Col xs="6">
                                        <div className="Bechofy-text">
                                            <Link
                                                // to="/return"
                                                style={{
                                                    float: "right",

                                                    background: 'black',
                                                    color: 'white',
                                                    marginBottom: '5px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                View All Refund / Return Orders{" "}

                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <div class="table-wrapper-scroll-y my-custom-scrollbar">

                                    {this.state.RefundListData.length == 0 ?

                                        <div className="noDataFound">
                                            <p>No Data found !</p>
                                        </div>

                                        :

                                        <table class="table table-bordered table-striped mb-0">

                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Order ID</th>
                                                    <th>Customer Name</th>
                                                    <th>Request Date & Time</th>
                                                    <th>Total Items</th>
                                                    <th>Order Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.RefundListData.map((data, i) => (
                                                    <tr>
                                                        <td className="centered">{i + 1}</td>
                                                        <td className="leftAlign">{data.fld_order_id}</td>
                                                        <td className="centered">{data.customer_name}</td>
                                                        <td className="centered">{data.fld_order_date}</td>
                                                        <td className="centered">{data.items_count}</td>
                                                        <td className="rightAlign">{data.fld_order_amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>

                                    }
                                </div>
                            </Media>
                        </Media>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SocialSource;
