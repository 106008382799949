import imageCompression from "browser-image-compression";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";



const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);

class AddMaterial extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        ////console.log("param " + action);
        this.state = {
            imagePreviewUrl:
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
            ImageData: [],
            VerticalData: [],
            MaterialName: "",
            MaterialId: null,
            Action: action,
            MaterialDescription: "",
            Verticalname: "",
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,

            ImageName: null,
            MaterialImage: null,
            Cost: null,
            GSM: null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });




        var det = localStorage.getItem("MaterialDetails");
        var MaterialData = JSON.parse(det);

        if (MaterialData != null) {
            if (this.state.Action == "update") {
                this.setState({
                    MaterialName: MaterialData.fld_Material_name,
                    MaterialId: MaterialData.fld_Material_id,
                    Status: MaterialData.fld_status == 'Active' ? 'Yes' : 'No',
                    Cost: MaterialData.fld_cost,
                    // GSM: MaterialData.fld_gsm,

                });
            }

            if (MaterialData.fld_image != null) {
                this.setState({
                    imagePreviewUrl: MaterialData.fld_image,
                    MaterialImage: MaterialData.fld_image,
                });
            } else {
                this.setState({
                    imagePreviewUrl:
                        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
                });
            }

            if (this.state.Action == "update") {
                new Promise((resolve, reject) => {
                    setTimeout(resolve, 1000);
                }).then(() => {
                    this.setState({
                        MaterialDescription: MaterialData.fld_description,
                        // TermsCondition:OfferData.fld_termscondition,
                    });
                });
            }
        }
    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };



    onChangeDescription(text) {
        this.setState({
            MaterialDescription: text.editor.getData(),
        });
    }

    onSaveData() {
        if (this.state.MaterialName != "") {
            if (
                this.state.imagePreviewUrl !=
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
            ) {
                if (this.state.Cost != null) {
                    this.onPost();

                }
                else {
                    Notiflix.Notify.Failure("Please enter the Material cost");
                }
            } else {
                Notiflix.Notify.Failure("Please upload Material image");
            }
        } else {
            Notiflix.Notify.Failure("Please enter the Material name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        if (this.state.MaterialId == null) {

            if (this.state.MaterialImage != this.state.imagePreviewUrl) {

                if (this.state.outputFileName != undefined) {

                    PostApiCall.postRequest(
                        {
                            id: this.state.MaterialId,
                            materialname: this.state.MaterialName,
                            description: this.state.MaterialDescription,
                            status: this.state.Status == "Yes" ? "Active" : "Inactive",
                            materialprice: this.state.Cost,
                            image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/MaterialImages/" +
                                this.state.MaterialName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,

                        },
                        "AddMaterialMaster"
                    ).then((resultcategory) =>
                        resultcategory.json().then((obj) => {
                            if (resultcategory.status == 200 || resultcategory.status == 201) {
                                Notiflix.Notify.Success("Material successfully added. Image upload in process");

                                this.onUploadImage(obj);

                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        })
                    );

                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure("Please Upload Material Image");
                }
            }
            else {
                this.onUpdateMaterial()
            }
        }
        else {

            this.onUpdateMaterial()
        }

    }



    onUpdateMaterial = () => {

        PostApiCall.postRequest(
            {
                id: this.state.MaterialId,
                materialname: this.state.MaterialName,
                description: this.state.MaterialDescription,
                status: this.state.Status == "Yes" ? "Active" : "Inactive",
                materialprice: this.state.Cost,
                image: this.state.imagePreviewUrl,

            },
            "AddMaterialMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Notify.Success("Coating successfully added. Image upload in process");

                    this.onUploadImage(obj);
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );

    }



    async onUploadImage(obj) {
        Notiflix.Loading.Dots("Uploading Images...");

        let response;

        if (this.state.outputFileName != undefined) {

            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {



                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "MaterialImages");
                    form.append(
                        "filename",
                        this.state.MaterialName.trim().replace(/\s/g, "-") +
                        "-" +
                        compressedFile.name

                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then(response => response.json())
                        .then(data => {

                            Notiflix.Loading.Remove()
                            Notiflix.Notify.Success("Image successfully uploaded.")
                            window.location.href = "/materialmaster";
                        }

                        )
                    // //console.log(response);


                })
        }
        else {
            Notiflix.Loading.Remove()
            Notiflix.Notify.Success("Image successfully uploaded.")
            window.location.href = "/materialmaster";
        }




    }

    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Material Master"} breadcrumbItem1={this.state.MaterialId == null ? "Add New Material" : "Update Material"} urlPath={"/materialmaster"} urlPath1={this.state.MaterialId == null ? "/add_Material/create" : "/add_Material/update"} />
                        <Row>
                            <Col xl="12">


                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    {this.state.MaterialId == null ?
                                                        <h5 className="Bechofy-text">
                                                            Add New Material
                                                        </h5>
                                                        :
                                                        <h5 className="Bechofy-text">
                                                            Update Material
                                                        </h5>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row  ">
                                                        <div className="col col-12">
                                                            <div className="row">

                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Material Name
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="MaterialName"
                                                                            className="form-control"
                                                                            value={this.state.MaterialName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    MaterialName: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Material GSM
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="GSM"
                                                                            className="form-control"
                                                                            value={this.state.GSM}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    GSM: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Material Price
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="MaterialName"
                                                                            className="form-control"
                                                                            value={this.state.Cost}
                                                                            onChange={(text) => {
                                                                                if (
                                                                                    this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        Cost: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Show on Website
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <br />
                                                                        <label class="radio-inline">
                                                                            <input
                                                                                type="radio"
                                                                                name="optradio"
                                                                                //  disabled={!this.state.IsVisible}
                                                                                checked={
                                                                                    this.state.Status == "Yes"
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={() => {
                                                                                    this.setState({
                                                                                        Status: "Yes",
                                                                                    });
                                                                                }}
                                                                            />{" "}
                                                                            Yes
                                                                        </label>
                                                                        <label
                                                                            class="radio-inline"
                                                                            style={{ marginLeft: "10px" }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                name="optradio"
                                                                                //  disabled={!this.state.IsVisible}
                                                                                checked={
                                                                                    this.state.Status == "No"
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={() => {
                                                                                    this.setState({
                                                                                        Status: "No",
                                                                                    });
                                                                                }}
                                                                            />{" "}
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div class="form-group">
                                                                        <label for="sw-arrows-first-name">
                                                                            Description(maximum 500 Characters)
                                                                        </label>

                                                                        <div class="niceeditors">
                                                                            <CKEditor
                                                                                config={{
                                                                                    extraPlugins:
                                                                                        "justify,font,colorbutton",
                                                                                }}
                                                                                data={this.state.MaterialDescription}
                                                                                onChange={this.onChangeDescription.bind(
                                                                                    this
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-1"></div>
                                                            <div class="col-md-11">
                                                                <label for="sw-arrows-first-name">
                                                                    Upload Material Image (1024*1024 px)<span className="mandatory">*</span>
                                                                </label>
                                                                <div class="div1">
                                                                    <ImgUpload
                                                                        onChange={this.photoUpload}
                                                                        src={this.state.imagePreviewUrl}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            {this.state.MaterialId == null ? <span>Save Material</span> : <span>Update Material</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {/*
                </Card>




                            </Col>
                               </Row> */}
                                    {/* </Card> */}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddMaterial;