import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { ToWords } from "to-words";
import moment from "moment";
import DatePicker from "react-datepicker";
import Parser from "html-react-parser";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PostApiCall from "../../Api";
const inr = require("inr-words");

var vend = [];
var selPro = [];

class Dispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderData: [],
      SelectedProduct: [],
      ShippingDate: null,
      TrackingNumber: null,
      DispatchDate: null,
      DispatchId: null,
      StateData: [],
      StockState: "",
      WarehouseData: [],
      Warehouse: "",
      OrderId: null,
      AmountInWords: null,
      Amount: null,
      Payment_Mode: null,
      Email: null,
      OrderNumber: null,
      ClientName: "",
      ClientLogo: "",
      ClientAddress: "",
      ClientCity: "",
      ClientPhoneNumber: "",
      ClientCountry: "",
      ClinetPincode: "",
      ClientState: "",
      ClientWebsite: "",
      ClientEmail: "",
      ShippingCharge: "",
      ClientGST: "",
      ShippingChargeGst: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    if (localStorage.getItem("ClientDetails") == null) {
      Notiflix.Notify.Failure(
        "Session timed out. Please access the page from main business domain again."
      );
    } else {
      var clientDetails = localStorage.getItem("ClientDetails");
      var clientInformation = JSON.parse(clientDetails);
      this.setState({
        ClientName: clientInformation[0].fld_companyname,
        ClientLogo: clientInformation[0].fld_storelogo,
        ClientPhoneNumber: clientInformation[0].fld_mobilenumber,
        ClientAddress: clientInformation[0].fld_address,
        ClientCity: clientInformation[0].fld_city,
        ClientCountry: clientInformation[0].fld_country,
        ClientEmail: clientInformation[0].fld_email,
        ClinetPincode: clientInformation[0].fld_pincode,
        ClientState: clientInformation[0].fld_state,
        ClientWebsite: clientInformation[0].fld_website,
        ClientGST: clientInformation[0].fld_gst,
      });
    }

    const toWords = new ToWords({
      localeCode: "en-IN",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
      },
    });
    //   this.props.setclearbrand()
    var customer = localStorage.getItem("Dispatchdetails");
    var CustomerDetails = JSON.parse(customer);
    this.setState({
      OrderId: CustomerDetails,
    });
    // console.log(CustomerDetails)

    Notiflix.Loading.Dots("Please wait...");

    PostApiCall.postRequest(
      {
        orderid: CustomerDetails,
      },
      "Get_Dispatch_item_details"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data)
          this.setState({
            OrderData: obj.data,
            Amount: obj.data[0].Total_Inclusive_Tax,
            Payment_Mode: obj.data[0].Payment_Mode,
            Email: obj.data[0].fld_email,
            OrderNumber: obj.data[0].fld_orderNumber,
            ShippingCharge:
              obj.data[0].Shipping_Charg == null
                ? 0.0
                : obj.data[0].Shipping_Charg,
            ShippingChargeGst:
              obj.data[0].Shipping_Charg_gst == null
                ? 0.0
                : obj.data[0].Shipping_Charg_gst,
          });
          this.state.OrderData.map((data, i) => {
            if (i === 0) {
              let words = inr(
                Math.round(data.fld_total_to_bepaid) +
                  Math.round(data.Shipping_Charg) +
                  Math.round(data.Shipping_Charg_gst)
              );
              this.setState({
                AmountInWords: words,
              });
            }
          });
          Notiflix.Loading.Remove();
        }
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: `where fld_status='Active' and 
              exists(select 1 from WarehousePincodeMapping wpm inner join Order_Header ca on ca.fld_shipping_pincode = wpm.fld_pincode
              where wpm.fld_warehouseid = udv_warehouse_master.fld_warehouseid and ca.fld_order_id = ${CustomerDetails}
                  )`,
        recordCount: "fld_name as label,fld_warehouseid as value",
      },
      "GetWarehouseByState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            // console.log(obj.data)
            this.setState({
              WarehouseData: obj.data,
            });
          }
        }
      })
    );
    Notiflix.Loading.Remove();
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    var item = "";
    for (var i = 0; i < this.state.SelectedProduct.length; i++) {
      if (i == 0) {
        item = this.state.SelectedProduct[i].fld_order_dtl_id;
      } else {
        item = item + ", " + this.state.SelectedProduct[i].fld_order_dtl_id;
      }
    }

    let details = JSON.parse(localStorage.getItem("LoginDetail"));

    PostApiCall.postRequest(
      {
        dispatchid: this.state.DispatchId,
        orderid: JSON.parse(localStorage.getItem("Dispatchdetails")),
        disptachdate: null,
        dispatchmode: this.state.DispatchData,
        status: "Dispatched",
        trackingno: this.state.TrackingNumber,
        shippingdate: null,
        deliveryagent: null,
        itemlist: item,
        warehouseid: parseInt(this.state.Warehouse),
        createdBy: details[0].fld_userid,
        updatedby: details[0].fld_userid,
      },
      "DispatchOrder"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          // this.sendMailer()
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Item has been dispatched Successfully.");
          window.location.reload();
        } else {
          Notiflix.Loading.Remove();

          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  sendMailer = () => {
    var item = "";
    for (var i = 0; i < this.state.SelectedProduct.length; i++) {
      if (i == 0) {
        item = this.state.SelectedProduct[i].fld_order_dtl_id;
      } else {
        item = item + ", " + this.state.SelectedProduct[i].fld_order_dtl_id;
      }
    }

    PostApiCall.postRequest(
      {
        orderid: this.state.OrderNumber,
        disptachdate: moment(this.state.DispatchDate).format("DD/MM/YYYY"),
        trackingno: this.state.TrackingNumber,
        shippingdate: moment(this.state.ShippingDate).format("DD/MM/YYYY"),
        total_amount: this.state.Amount,
        payment_mode:
          this.state.Payment_Mode == "COD"
            ? "Cash On Delivery"
            : this.state.Payment_Mode,
        email: this.state.Email,
      },
      "Mailer_Dispatch"
    ).then((result) => {
      Notiflix.Loading.Remove();
      Notiflix.Notify.Success(
        "Mail has been successfully sent to the customer with tracking details."
      );
      window.location.reload();
    });
  };

  getGstRate = (orderDate) => {
    const date = new Date(orderDate);
    return date >= new Date("09/09/2024") ? 12 : 18;
  };

  
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={"Dispatch Form"}
              urlPath={"/view_dispatch"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="globalTrendzEcomm-bg-soft-pink">
                    <Row>
                      <Col xs="6">
                        <div className="globalTrendzEcomm-text  p-3">
                          <h5 className="globalTrendzEcomm-text">Dispatch</h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="form-group my-1 mr-sm-2">
                          {/* <label>Dispatch</label> */}
                          <select
                            className="form-control"
                            value={this.state.Warehouse}
                            onChange={(text) => {
                              this.setState({
                                Warehouse: text.target.value,
                              });
                              PostApiCall.postRequest(
                                {
                                  orderid: this.state.OrderId,
                                  warehouseId: text.target.value,
                                },
                                "Get_Dispatch_item_details"
                              ).then((results) =>
                                // const objs = JSON.parse(result._bodyText)
                                results.json().then((obj) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    // console.log(obj.data)

                                    this.setState({
                                      OrderData: obj.data,
                                    });
                                    this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        const toWords = new ToWords();
                                        let words = toWords.convert(
                                          data.Total_Inclusive_Tax
                                        );
                                        this.setState({
                                          AmountInWords: words,
                                        });
                                      }
                                    });
                                    Notiflix.Loading.Remove();
                                  }
                                })
                              );
                            }}
                          >
                            <option value="">
                              Select Warehouse to Dispatch
                            </option>
                            {this.state.WarehouseData.map((data) => (
                              <option key={data.value} value={data.value}>
                                {data.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <table
                    style={{
                      width: "1000px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      bottom: "0px",
                      borderRightColor: "#000",
                      marginTop: "30px",
                      borderCollapse: "collapse",
                    }}
                    border="1"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          colspan="2"
                          style={{ width: "20%", verticalAlign: "middle" }}
                        >
                          <img
                            src={`data:image/jpeg;base64,${this.state.ClientLogo}`}
                            style={{
                              width: "65%",
                              marginRight: "auto",
                              marginLeft: "auto",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                        </td>
                        <td colspan="8" style={{ width: "80%" }}>
                          {" "}
                          <h2
                            style={{
                              textAlign: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.ClientName}
                          </h2>
                          <p style={{ textAlign: "center" }}>
                            {this.state.ClientAddress}
                            <br />
                            {this.state.ClientCity}, {this.state.ClientState} -{" "}
                            {this.state.ClinetPincode},{" "}
                            {this.state.ClientCountry}
                            <br />
                            Phone: {this.state.ClientPhoneNumber}
                            <br />
                            GST: {this.state.ClientGST}
                          </p>
                          <tr
                            rowspan="8"
                            class="success"
                            style={{
                              display: "table",
                              width: "100%",
                              backgroundColor: "#f7f7f7",
                              borderBottom: "none",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            <td
                              colspan="8"
                              style={{
                                textAlign: "right",
                                paddingRight: "1%",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Customer Order Form
                            </td>
                          </tr>
                        </td>
                      </tr>

                      {this.state.OrderData.map((data, i) => {
                        if (i === 0) {
                          return (
                            <tr>
                              <td
                                colspan="6"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1%",
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  borderRight: "none",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Order Date{" "}
                                </span>
                                <span> &nbsp;&nbsp;{data.fld_order_date}</span>
                              </td>

                              <td
                                colspan="6"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "1%",
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                  borderLeft: "none",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Customer Order No.
                                </span>
                                <span> &nbsp;&nbsp;{data.fld_orderNumber}</span>
                              </td>
                            </tr>
                          );
                        }
                      })}

                      <tr class="Success">
                        <td
                          colspan="4"
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Billing Address
                        </td>
                        <td
                          colspan="4"
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Shipping Address
                        </td>
                      </tr>
                      {this.state.OrderData.map((data, i) => {
                        if (i === 0) {
                          return (
                            <tr>
                              <td
                                colspan="4"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1%",
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                }}
                              >
                                {Parser(
                                  (
                                    "<p>" +
                                    data.fld_billing_address_all +
                                    "</p>"
                                  )
                                    .replace(/font-family/g, "")
                                    .replace(/<br\/?>/gi, " ")
                                )}
                              </td>

                              <td
                                colspan="4"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1%",
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                }}
                              >
                                {Parser(
                                  (
                                    "<p>" +
                                    data.fld_shipping_address_all +
                                    "</p>"
                                  )
                                    .replace(/font-family/g, "")
                                    .replace(/<br\/?>/gi, " ")
                                )}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                  <table
                    style={{
                      width: "1000px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                    }}
                    border="1"
                    cellspacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr class="Success">
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>S.No.</span>
                        </td>

                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>HSN Code</span>
                        </td>
                        <td
                          colSpan="3"
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Product</span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Quantity</span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Rate Per Unit
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Gross Price(A)
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "9%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Discount(B)
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "7%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            GST Rate(%)
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "8%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            GST Amount(C)
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "8%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Total Amount
                            <br />
                            (A-B+C)
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "8%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Available Stock
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            textAlign: "center",
                            width: "10%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Product For Dispatch
                          </span>
                        </td>
                      </tr>
                      {this.state.OrderData.map((data, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{data.fld_hsncode}</td>
                          <td
                            colSpan={3}
                            style={{
                              textAlign: "left",
                              paddingLeft: "1%",
                              paddingTop: "1%",
                              paddingBottom: "1%",
                            }}
                          >
                            {data.variant_name} - {data.fld_weight} <br />
                            <span>
                              <b>{data.item_name}</b>
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {data.fld_quantity}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "1%" }}
                          >
                            {parseFloat(
                              data.fld_website_discount_price
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "1%" }}
                          >
                            {parseFloat(
                              data.fld_quantity *
                                data.fld_website_discount_price
                            ).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {parseFloat(data.fld_discount_amount).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {data.fld_GSTRate}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {parseFloat(data.fld_GSTamount).toFixed(2)}
                          </td>

                          <td
                            style={{ textAlign: "right", paddingRight: "1%" }}
                          >
                            {parseFloat(data.fld_item_value).toFixed(2)}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "1%" }}
                          >
                            {data.stock_available}
                          </td>
                          <td
                            style={{ textAlign: "center", paddingRight: "1%" }}
                          >
                            {data.fld_line_status == "InCart" ? (
                              data.stock_available != "0" ? (
                                <input
                                  type="checkbox"
                                  checked={this.state.SelectedProduct.find(
                                    function (value) {
                                      return (
                                        value.fld_order_itemid ==
                                        data.fld_order_itemid
                                      );
                                    }
                                  )}
                                  onChange={() => {
                                    var cd = [...this.state.SelectedProduct];
                                    var t = 0;

                                    for (var i = 0; i < cd.length; i++) {
                                      if (
                                        cd[i].fld_order_itemid ==
                                        data.fld_order_itemid
                                      ) {
                                        t = 1;
                                      }
                                    }
                                    if (t == 0) {
                                      selPro.push(data);
                                      this.setState({
                                        SelectedProduct: selPro,
                                      });
                                    } else {
                                      for (var i = 0; i < cd.length; i++) {
                                        if (
                                          cd[i].fld_order_itemid ==
                                          data.fld_order_itemid
                                        ) {
                                          selPro.splice(i, 1);
                                          this.setState({
                                            SelectedProduct: selPro,
                                          });
                                        }
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                "No Stock Available"
                              )
                            ) : (
                              data.fld_line_status
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "1000px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                    }}
                    border="1"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr style={{ width: "100%" }}>
                        <td
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            width: "65%",
                          }}
                        >
                          <table
                            style={{
                              width: "620px",
                              borderRightColor: "#000",
                              marginRight: " -60px",
                              borderCollapse: "collapse",
                            }}
                            border="1"
                          >
                            {this.state.OrderData.map((data, i) => {
                              if (i === 0) {
                                const gstRate = this.getGstRate(
                                  data.fld_order_date
                                );
                                return (
                                  <tbody>
                                    <tr>
                                      <th
                                        colspan="10"
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "1%",
                                          paddingBottom: "1%",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Total Products to be Delivered :{" "}
                                          {this.state.OrderData.length} Products
                                          ({data.Total_Item_Quantity} Items)
                                        </span>
                                      </th>
                                    </tr>

                                    <tr class="Success">
                                      <th style={{ textAlign: "center" }}>
                                        GST Rate(%)
                                      </th>
                                      <th style={{ textAlign: "center" }}>
                                        Item
                                      </th>
                                      <th style={{ textAlign: "center" }}>
                                        Amount
                                      </th>
                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: "center" }}>
                                        5%
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_In_GstRate_5}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_Amnt_In_GstRate_5}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td style={{ textAlign: "center" }}>
                                        GST({gstRate}%)
                                        {/* 12% */}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_In_GstRate_12}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_Amnt_In_GstRate_12}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td style={{ textAlign: "center" }}>
                                        GST({gstRate}%)
                                        {/* 12% */}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_In_GstRate_18}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_Amnt_In_GstRate_18}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td style={{ textAlign: "center" }}>
                                        28%
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_In_GstRate_28}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        {data.Item_Amnt_In_GstRate_28}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              }
                            })}
                          </table>
                        </td>
                        {this.state.OrderData.map((data, i) => {
                          if (i === 0) {
                            return (
                              <td>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Value Before Discount
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(data.fld_sub_total_mrp).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>

                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Discount ({data.fld_coupon_code})
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.fld_coupon_discount == null
                                        ? 0
                                        : data.fld_coupon_discount
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Taxable Value
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.fld_sub_total_mrp -
                                        data.fld_coupon_discount
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      GST
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {data.Item_Amnt_In_GstRate_5 != null
                                      ? data.Item_Amnt_In_GstRate_5
                                      : data.Item_Amnt_In_GstRate_12 != null
                                      ? data.Item_Amnt_In_GstRate_12
                                      : data.Item_Amnt_In_GstRate_18 != null
                                      ? data.Item_Amnt_In_GstRate_18
                                      : data.Item_Amnt_In_GstRate_28 != null
                                      ? data.Item_Amnt_In_GstRate_28
                                      : "0.00"}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Net Invoice Value Before Shipping(A)
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.fld_total_to_bepaid
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Shipping(B){" "}
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.Shipping_Charg == null
                                        ? 0.0
                                        : data.Shipping_Charg
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      GST on Shipping(C){" "}
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.Shipping_Charg_gst == null
                                        ? 0.0
                                        : data.Shipping_Charg_gst
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%", display: "table" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      padding: "1%",
                                    }}
                                  >
                                    <span style={{ fontWeight: "bold" }}>
                                      Total Billed Value(A+B+C){" "}
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "1%",
                                      width: "35%",
                                    }}
                                  >
                                    {" "}
                                    &#8377;
                                    {parseFloat(
                                      data.fld_total_to_bepaid +
                                        data.Shipping_Charg +
                                        data.Shipping_Charg_gst
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </td>
                            );
                          }
                        })}
                      </tr>

                      <tr>
                        <td
                          colspan="10"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Amount in Words:
                          </span>{" "}
                          Rupees {this.state.AmountInWords}
                        </td>
                      </tr>
                      {this.state.OrderData.map((data, i) => {
                        if (i === 0) {
                          return (
                            <tr>
                              <td
                                colspan="10"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1%",
                                  paddingTop: "1%",
                                  paddingBottom: "1%",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Payment Mode:{"  "}
                                </span>{" "}
                                {data.Payment_Mode}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                  <table
                    style={{
                      width: "1000px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRightColor: "#000",
                      borderTop: "hidden",
                      fontFamily: "Lato, sans-serif",
                      borderCollapse: "collapse",
                      marginBottom: "30px",
                    }}
                    border="1"
                    cellspacing="1"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td colspan="4"></td>
                        <td
                          colspan="6"
                          style={{
                            textAlign: "center",
                            background: "#f7f7f7",
                          }}
                        >
                          Have a Question? Call us on{" "}
                          {this.state.ClientPhoneNumber} or Email us at{" "}
                          {this.state.ClientEmail}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
                <Card>
                  <CardBody className="py-1  my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-6"></div>
                          <div className="col-6">
                            <button
                              style={{
                                float: "right",
                                backgroundColor: "#777f80",
                                color: "#fff",
                              }}
                              className="btn align-items-center btn globalTrendzEcomm-btn"
                              onClick={() => {
                                var item = "";
                                for (
                                  var i = 0;
                                  i < this.state.SelectedProduct.length;
                                  i++
                                ) {
                                  if (i == 0) {
                                    item =
                                      this.state.SelectedProduct[i]
                                        .fld_order_itemid;
                                  } else {
                                    item =
                                      item +
                                      ", " +
                                      this.state.SelectedProduct[i]
                                        .fld_order_itemid;
                                  }
                                }
                                //console.log(item)
                                if (this.state.SelectedProduct.length > 0) {
                                  this.onPost();
                                } else {
                                  Notiflix.Notify.Failure(
                                    "Please select item for dispatch."
                                  );
                                }
                              }}
                            >
                              Dispatch
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Dispatch;
