import React, { Component } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button, Label, Container } from "reactstrap";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import "../../assets/scss/Notification.scss";

import logox from "../../assets/images/bechofylogo.png";

class UiNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",

      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      Notificationimage: 'http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg'
    };

  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>

            <Breadcrumbs title="Notification Management" breadcrumbItem="View All Notifications" />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ background: "#777f80" }}
                  >
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text text-white">
                            View Notifications
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <div class="card notification-card notification-invitation">
                            <div class="card-body notfication-card">
                              <table>
                                <tr>
                                  <td style={{ width: '1%' }}><img src={this.state.Notificationimage} className="Notificatin-image" ></img></td>
                                  <td style={{ width: '92%' }}>
                                    <h4 class="Notification-head">Shivani Rawat</h4>
                                    <p class="Notification-head"> Imagine a group of architects designing a three-story house, laboring over the blueprints for months. It’s impressive! It’s beautiful! But just as they get close to finishing the diagram, one of them exclaims, “ Wait! How do people get from the first to the third floor? “ They forgot about the staircase!</p>
                                  </td>
                                  <td style={{ width: '20%' }}>
                                    <p>2 min ago</p>
                                  </td>
                                  {/* <td style={{width:'70%'}}><div class="card-title">Jane invited you to join '<b>Familia</b>' group</div></td> */}
                                  {/* <td >
            <a href="#" class="btn btn-primary">View</a>
            <a href="#" class="btn btn-danger dismiss-notification">Dismiss</a>
          </td> */}
                                </tr>
                              </table>
                            </div>
                          </div>

                          <div class="card notification-card notification-invitation">
                            <div class="card-body notfication-card">
                              <table>
                                <tr>
                                  <td style={{ width: '1%' }}><img src={this.state.Notificationimage} className="Notificatin-image" ></img></td>
                                  <td style={{ width: '92%' }}>
                                    <h4 class="Notification-head">Shivani Rawat</h4>
                                    <p class="Notification-head"> Imagine a group of architects designing a three-story house, laboring over the blueprints for months. It’s impressive! It’s beautiful! But just as they get close to finishing the diagram, one of them exclaims, “ Wait! How do people get from the first to the third floor? “ They forgot about the staircase!</p>
                                  </td>
                                  <td style={{ width: '20%' }}>
                                    <p>2 min ago</p>
                                  </td>
                                  {/* <td style={{width:'70%'}}><div class="card-title">Jane invited you to join '<b>Familia</b>' group</div></td> */}
                                  {/* <td >
            <a href="#" class="btn btn-primary">View</a>
            <a href="#" class="btn btn-danger dismiss-notification">Dismiss</a>
          </td> */}
                                </tr>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>

                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default UiNotifications;
