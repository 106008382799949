import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class RiderList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,

			UserData: [],
			Id: "",
			searchInput: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetRiderList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data);

				this.setState({
					UserData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}

	// fld_gender
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "disabled",
					width: 400,
				},

				{
					label: "Gender",
					field: "gender",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Mobile Number",
					field: "mobileno",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Email",
					field: "email",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Rider Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.UserData.filter((data) => {
				let name = [
					...data.fld_name
						.toLowerCase()
						.matchAll(this.state.searchInput.toLowerCase()),
				];
				let gender = [
					...data.fld_gender
						.toLocaleLowerCase()
						.matchAll(this.state.searchInput.toLocaleLowerCase()),
				];
				//  let designaition = [...data.fld_designation.toLocaleLowerCase().matchAll(this.state.searchInput.toLocaleLowerCase())]

				if (this.state.searchInput.length > 0 && name[0]) {
					return data;
				}

				// if (this.state.searchInput.length > 0  && designaition[0]) {
				//   return data
				// }
				if (this.state.searchInput.length > 0 && gender[0]) {
					return data;
				}

				if (
					data.fld_status.toLowerCase() ===
					this.state.searchInput.toLowerCase() &&
					this.state.searchInput.toLowerCase() !== ""
				) {
					return data;
				}

				if (this.state.searchInput.length == 0) {
					return data;
				}
			}).map((data, i) => {
				//  //console.log(data)
				return {
					serial: <td>{i + 1}</td>,
					name: <td>{data.fld_name}</td>,
					gender: <td>{data.fld_gender}</td>,
					mobileno: <td>{data.fld_mobile}</td>,
					email: <td>{data.fld_email}</td>,
					// usertype:<td>{data.fld_usertype}</td>,
					status: <td>{data.fld_status}</td>,

					action: (
						<td className="text-center actionuser">
							{/* <i className="fas fa-eye btn" style={{fontSize:'15px',marginTop:'-11px'}}></i> */}
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									localStorage.setItem("riderdetails", JSON.stringify(data));
									window.location.href = "/edit_rider";
								}}
							></i>
							<span className="btn" style={{ marginTop: "-9px" }}>
								<i
									className="fa fa-trash py-auto "
									aria-hidden="true"
									style={{ fontSize: "15px" }}
									onClick={() => {
										confirmAlert({
											title: "Confirm to Delete",
											message: "Are you sure you want to delete user.",
											buttons: [
												{
													label: "Yes",
													onClick: () => {
														Notiflix.Loading.Dots("");

														PostApiCall.postRequest(
															{
																id: data.fld_userid,
																status: "Disabled",
															},
															"DeleteRider"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (
																	results.status == 200 ||
																	results.status == 201
																) {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Success(
																		"User successfully deleted."
																	);
																	window.location.reload();
																} else {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Failure(
																		"Something went wrong, try again later."
																	);
																}
															})
														);
													},
												},
												{
													label: "No",
													// onClick: () => alert('Click No')
												},
											],
										});
									}}
								/>
							</span>
						</td>
					),
				};
			}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Rider Management"}
							breadcrumbItem={"Rider List"}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Rider Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Card className="overflow-hidden">
										<Row>
											<Col xl="12">
												<Card className="overflow-hidden">
													<div className="Bechofy-bg-soft-pink">
														<Row>
															<Col xs="6">
																<div className="Bechofy-text p-3">
																	<h5 className="Bechofy-text">
																		Rider List
																	</h5>
																</div>
															</Col>
															<Col xs="6">
																<div className="Bechofy-text p-3">
																	<Link
																		to="/add_rider"
																		style={{
																			float: "right",
																			marginTop: "-5px",
																		}}
																		className="btn align-items-center btn Bechofy-btn "
																	>
																		Add New Rider{" "}
																		<i
																			className="fa fa-plus"
																			aria-hidden="true"
																		></i>
																	</Link>
																</div>
															</Col>
														</Row>
													</div>
													<CardBody className="pt-0">
														<Row></Row>
													</CardBody>
													<CardBody className="pt-0">
														<Row>
															<MDBCol md="3" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) => this.seachBarHandler(e)}
																	value={this.state.searchInput}
																/>
															</MDBCol>
															<Col md="12">
																<MDBDataTableV5
																	striped
																	bordered
																	small
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTableV5>
															</Col>
														</Row>
													</CardBody>
												</Card>

												{/* <WelcomeComp /> */}
											</Col>
										</Row>
									</Card>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RiderList;
