import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import SideBar from "./SideBar";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from 'moment';
import Navbar from "../CompanyMaster/NavBar/Navbar"

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
            OldPassword2: '',
            LoginDetail: [],
            isOldPasswordVisible: false,
            isNewPasswordVisible: false,
            isConPasswordVisible: false
        }
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: '#777f80'

        });

        var Oldpassword = localStorage.getItem('OldPassword');
        var passdetails = JSON.parse(Oldpassword)

        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        //console.log(details)
        if (details != null) {
            this.setState({
                OldPassword2: passdetails,
                LoginDetail: details[0]
            })

        }
    }

    onClickReset() {

        if (this.state.OldPassword != '') {
            if (this.state.OldPassword == this.state.OldPassword2) {
                if (this.state.NewPassword != '') {

                    if (this.state.ConfirmPassword != '') {
                        if (this.state.NewPassword == this.state.ConfirmPassword) {

                            Notiflix.Loading.Dots('');

                            PostApiCall.postRequest({

                                userid: this.state.LoginDetail.fld_userid,
                                password: this.state.ConfirmPassword,
                                salt: this.state.LoginDetail.fld_salt,
                                updatedon: moment().format('lll'),
                                updatedby: this.state.LoginDetail.fld_userid

                            }, "ChangePassword").then((results) =>

                                // const objs = JSON.parse(result._bodyText)
                                results.json().then(obj => {


                                    if (results.status == 200 || results.status == 201) {
                                        Notiflix.Loading.Remove()
                                        Notiflix.Notify.Success('Password Successfully updated')
                                        localStorage.removeItem('LoginDetail')
                                        window.location.href = '/'

                                    }
                                    else {
                                        Notiflix.Loading.Remove()
                                        Notiflix.Notify.Failure(obj.data)
                                    }
                                }
                                )
                            )
                        }

                        else {
                            Notiflix.Notify.Failure('New Password and Confirm Password do not match')
                        }

                    }
                    else {
                        Notiflix.Notify.Failure('Confirm Password cannot be empty ')
                    }
                }

                else {
                    Notiflix.Notify.Failure('New Password cannot be empty');
                }
            }

            else {
                Notiflix.Notify.Failure('Current Password is incorrect');
            }
        }
        else {
            Notiflix.Notify.Failure('Please enter all the fields');
        }



    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <div className="col-12">
                            <div className="row">

                                <div className="col-lg-12 col-md-12">
                                    <form>
                                        <CardBody className="justify-content-center">
                                            <CardTitle
                                                className="usertitle sidebar-heading justify-content-center"
                                                style={{
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    textAlign: 'center'
                                                }}>
                                                Change Password
                                            </CardTitle>
                                            <Row>
                                                <Col lg="12" xs="12" md="12">
                                                    <div className="form my-4">
                                                        <div class="row justify-content-center">
                                                            <div class="col-sm-8 col-md-6">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <form>
                                                                            <div class="form-group required">
                                                                                <label class="form-control-label" for="currentPassword">
                                                                                    Current Password
                                                                                </label>
                                                                                <input type="password" class="form-control hf-validated hf-invalid" id="currentPassword" name="dwfrm_profile_login_currentpassword" required="" aria-required="true" value="" maxlength="255" minlength="8" aria-invalid="true"
                                                                                    value={this.state.OldPassword} type={this.state.isOldPasswordVisible ? 'text' : "password"}
                                                                                    onChange={(pswd) => {
                                                                                        this.setState({
                                                                                            OldPassword: pswd.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <span class="login-icon-change-pass">
                                                                                    <i style={{ color: this.state.isOldPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                isOldPasswordVisible: !this.state.isOldPasswordVisible
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div class="form-group required">
                                                                                <label class="form-control-label" for="newPassword">
                                                                                    New Password
                                                                                </label>
                                                                                <input type="password" class="form-control hf-validated hf-invalid" id="newPassword" name="dwfrm_profile_login_newpasswords_newpassword" required="" aria-required="true" value="" maxlength="255" minlength="8" aria-invalid="true"
                                                                                    value={this.state.NewPassword} type={this.state.isNewPasswordVisible ? 'text' : "password"}
                                                                                    onChange={(newpwd) => {
                                                                                        this.setState({
                                                                                            NewPassword: newpwd.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <span class="login-icon-change-pass">
                                                                                    <i style={{ color: this.state.isNewPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                isNewPasswordVisible: !this.state.isNewPasswordVisible
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div class="form-group required">
                                                                                <label class="form-control-label" for="newPasswordConfirm">
                                                                                    Confirm New Password
                                                                                </label>
                                                                                <input type="password" class="form-control hf-validated hf-invalid" id="newPasswordConfirm" name="dwfrm_profile_login_newpasswords_newpasswordconfirm" required="" aria-required="true" value="" maxlength="255" minlength="8" aria-invalid="true"
                                                                                    value={this.state.ConfirmPassword} type={this.state.isConPasswordVisible ? 'text' : "password"}
                                                                                    onChange={(confirmpwd) => {
                                                                                        this.setState({
                                                                                            ConfirmPassword: confirmpwd.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <span class="login-icon-change-pass">
                                                                                    <i style={{ color: this.state.isConPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                isConPasswordVisible: !this.state.isConPasswordVisible
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="form-group col-12"
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    marginLeft: 'auto',
                                                                                    marginRight: 'auto',
                                                                                    justifyContent: 'center',
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    type="button"
                                                                                    style={{ marginTop: "5px" }}
                                                                                    className="btn align-items-center Bechofy-btn "
                                                                                    onClick={this.onClickReset.bind(this)}
                                                                                >
                                                                                    Save New Password
                                                                                </button>
                                                                            </div>

                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}

export default ChangePassword