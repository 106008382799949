
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

// users
import user1 from '../../../assets/images/helpcenter.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            Name: '',
            Details: [],
            Userimage: ''
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    componentDidMount() {
        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        // //console.log(details)

        this.setState({
            Details: details[0],
            Name: details[0].fld_name,
            Userimage: details[0].fld_photo
        })


    }

    render() {

        return (
            <React.Fragment>


                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={this.state.Userimage} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.Name}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" onClick={() => {
                            localStorage.setItem("authUser", JSON.stringify({
                                username: this.state.details.fld_name
                            }));
                        }} href="/profile">
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                            {this.props.t('Profile')}
                        </DropdownItem>

                        <DropdownItem tag="a" href="change_password">
                            <i class="fa fa-key font-size-16 align-middle mr-1" aria-hidden="true"></i> {this.props.t('Change Password')}
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <a onClick={() => {
                            localStorage.removeItem('LoginDetail')
                            localStorage.clear();
                            window.location.href = '/'
                        }} className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{this.props.t('Logout')}</span>
                        </a>
                    </DropdownMenu>
                </Dropdown>

            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(ProfileMenu));
