import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Select from "react-select";

class AddRiderArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Status: "Active",

			CategoryData: [],
			CategorySelect: [],
			groupArray: [],
			PinCode: [],
			AttributeData: [],
			Attribute: "",
			subcat: [],
			StateData: [],
			State: "",
			CityData: [],
			City: "",
			RiderData: [],
			Rider: "",
			PinCodeData: [],
			Pincodes: [],
			PincodesData: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		// GetApiCall.getRequest('Get_RiderName').then((resultdes) =>
		//   resultdes.json().then((obj) => {
		//     //console.log(obj.data)
		//     this.setState({
		//       CategoryData: obj.data,
		//     })
		//     Notiflix.Loading.Remove()
		//   }),
		// )

		GetApiCall.getRequest("Get_RiderName").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					RiderData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		//       fld_categoryname: "Stationery "
		// fld_subcategoryname: "Pens & Pencils "
	}

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({
			...this.state,
			imageValue: file,
			isImageValid: isvalid,
		});
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription(text) {
		this.setState({
			JobDescription: text.editor.getData(),
		});
	}

	SaveRider() {
		// //console.log(this.state.Pincodes.length)
		if (this.state.Rider != "") {
			if (this.state.State != "") {
				if (this.state.City != "") {
					if (this.state.Pincodes.length > 0) {
						this.onPost();
					} else {
						Notiflix.Notify.Failure("Please select Pincode");
					}
				} else {
					Notiflix.Notify.Failure("Please select city");
				}
			} else {
				Notiflix.Notify.Failure("Please select state");
			}
		} else {
			Notiflix.Notify.Failure("Please select rider");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var addpincode = "";

		for (var i = 0; i < this.state.Pincodes.length; i++) {
			if (i == 0) {
				addpincode = this.state.Pincodes[i].value;
			} else {
				addpincode = addpincode + ", " + this.state.Pincodes[i].value;
			}
		}
		PostApiCall.postRequest(
			{
				riderid: this.state.Rider,
				stateid: this.state.State,
				cityid: this.state.City,
				pincode: addpincode,
				active: this.state.Status,
				CreatedOn: moment().format("YYYY-MM-DD"),
				CreatedBy: details[0].fld_userid,
				UpdatedOn: moment().format("YYYY-MM-DD"),
				UpdatedBy: details[0].fld_userid,
			},
			"Add_RiderArea"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					// //console.log('success')
					Notiflix.Notify.Success("Rider Area successfully added.");
					window.location.href = "/rider_area";
				} else {
					Notiflix.Notify.Failure("Obj");
				}
			})
		);
	};

	onChangeRider(rider) {
		this.setState(
			{
				Rider: rider.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.Rider,
					},
					"Get_Riderstate"
				).then((results2) =>
					results2.json().then((obj2) => {
						if (results2.status == 200 || results2.status == 201) {
							this.setState({
								StateData: obj2.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	onChangeState(state) {
		this.setState(
			{
				State: state.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						stateid: this.state.State,
					},
					"GetCity"
				).then((results3) =>
					results3.json().then((obj3) => {
						if (results3.status == 200 || results3.status == 201) {
							this.setState({
								CityData: obj3.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	onChangeCity(text) {
		this.setState(
			{
				City: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.City,
					},
					"Get_Rider_Pincode"
				).then((results4) =>
					results4.json().then((obj4) => {
						if (results4.status == 200 || results4.status == 201) {
							this.setState({
								PinCodeData: obj4.data,
							});

							let arr = [];
							if (obj4.data) {
								obj4.data.map((item) => {
									arr.push({
										label: item.label,
										value: item.value,
									});
								});
							}

							this.setState({
								PincodesData: arr,
							});

							Notiflix.Loading.Remove();
						}
					})
				);

				PostApiCall.postRequest(
					{
						id: this.state.City,
					},
					"GetPincodeById"
				).then((results5) =>
					results5.json().then((obj5) => {
						if (results5.status == 200 || results5.status == 201) {
							// //console.log(obj5.data)

							let arr = [];
							if (obj5.data) {
								obj5.data.map((item) => {
									arr.push({
										label: item.fld_pincode,
										value: item.fld_pincode,
									});
								});
								//    //console.log(arr)
							}

							this.setState({
								Pincodes: arr,
							});
							// var sp = []

							// var sp = []
							// if(obj5.data){
							//    obj5.data.map(item=>{
							//        //console.log(item.fld_pincode)
							//     if(item.fld_pincode != null){
							//         sp.push({

							//         })
							//         for(var i = 0; i < item.fld_pincode.length ; i++){
							//            //console.log(item.fld_pincode[i])

							//             sp.push({label : item[i].fld_pincode,value : item[i].fld_pincode})
							//         }
							//         // //console.log(sp)
							//         this.setState({
							//             PincodesData : sp
							//         })

							//     }
							//    })

							// }
							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	render() {
		// //console.log(this.state.subcat.fld_subcategoryname)

		let temp = [];

		this.state.PinCodeData.map((data, i) => {
			temp.push(data.Name);
		});
		temp = [...new Set(temp)];

		let groupTemp = this.groupBy(this.state.PinCodeData, "Name");
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Rider Management"}
							breadcrumbItem={"Rider Area Assignment"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Rider Area Assignment
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Assign Area
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-4">
																		<div className="form-group">
																			<label for="CategoryName">
																				Rider Name
																			</label>
																			<select
																				value={this.state.Rider}
																				onChange={this.onChangeRider.bind(this)}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">Select Rider </option>
																				{this.state.RiderData.map((attri) => (
																					<option
																						key={attri.value}
																						value={attri.value}
																					>
																						{attri.label}
																					</option>
																				))}
																			</select>
																		</div>
																	</div>

																	<div className="col col-4">
																		<div className="form-group">
																			<label for="CategoryName">State</label>
																			<select
																				value={this.state.State}
																				onChange={this.onChangeState.bind(this)}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">Select State </option>
																				{this.state.StateData.map((attri) => (
																					<option
																						key={attri.value}
																						value={attri.value}
																					>
																						{attri.label}
																					</option>
																				))}
																			</select>
																		</div>
																	</div>

																	<div className="col col-4">
																		<div className="form-group">
																			<label for="CategoryName">City</label>
																			<select
																				value={this.state.City}
																				onChange={this.onChangeCity.bind(this)}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">Select City </option>
																				{this.state.CityData.map((attri) => (
																					<option
																						key={attri.value}
																						value={attri.value}
																					>
																						{attri.label}
																					</option>
																				))}
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Assign Pincode to Rider
																</h5>
															</div>
														</Col>
													</Row>
													<Row></Row>
												</div>

												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																{temp.map((info, index) => (
																	<div
																		class="col-md-12"
																		style={{
																			marginTop: "1%",
																			marginBottom: "1%",
																		}}
																	>
																		<label class="checkbox-inline">
																			{info}
																		</label>

																		<Select
																			options={this.state.PincodesData}
																			value={this.state.Pincodes}
																			onChange={(as) => {
																				this.setState({ Pincodes: as });
																			}}
																			isMulti
																		/>
																	</div>
																))}
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "7px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveRider.bind(this)}
																	>
																		Save & Assign Area
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddRiderArea;
