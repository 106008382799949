import { MDBCol, MDBDataTableV5 } from "mdbreact";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import GetApiCall from "../../GETAPI";
// import { Link } from "react-router-dom";
import PostApiCall from "../../Api";

import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import "react-responsive-modal/styles.css";

import Notiflix from "notiflix";


// const ImgUpload = ({ onChange, src }) => (
// 	<label htmlFor="photo-upload" className="custom-file-upload fas">
// 		<div className="img-wrap img-upload">
// 			<img
// 				for="photo-upload"
// 				src={src}
// 				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
// 			/>
// 		</div>
// 		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
// 	</label>
// );

class verticleMater extends Component {
	state = {
		data: [],
		searchInput: "",
		VerticleData: [],
		ViewClick: "true",
		open: false,
		Status: "Active",
		Name: "",
		openedit: false,
		Id: "",
	};

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetVerticleMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data);

				this.setState({
					VerticleData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}

	SaveAttribute() {
		if (this.state.Name !== "") {
			this.onPost();
		} else {
			Notiflix.Notify.Failure("Please enter verticle name");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				verticlename: this.state.Name,
				status: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
			},
			"AddVerticleMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status === 200 || resultcategory.status === 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("New Verticle successfully added.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};

	UpdateAttribute() {
		if (this.state.Name !== "") {
			this.onPost2();
		} else {
			Notiflix.Notify.Failure("Please enter verticlename");
		}
	}

	onPost2 = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				id: this.state.Id,
				verticlename: this.state.Name,
				status: this.state.Status,
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
			},
			"UpdateVerticleMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status === 200 || resultcategory.status === 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Vertical successfully updated.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};

	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 120,
				},
				{
					label: "Name",
					field: "name",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Email",
					field: "email",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Mobile No.",
					field: "mobile",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},
				// {
				// 	label: "Action",
				// 	field: "action",
				// 	sort: "disabled",
				// 	width: 100,
				// },
			],

			rows: this.state.VerticleData.map((data, item) => {
				return {
					serial: item + 1,
					name: <td></td>,
					email: <td></td>,
					mobile: <td></td>
					// action: (
					// 	<span className="text-center">
					// 		<span className="btn" style={{ marginTop: "-9px" }}>
					// 			<i
					// 				className="fa fa-trash py-auto "
					// 				aria-hidden="true"
					// 				style={{ fontSize: "15px" }}
					// 				onClick={() => {
					// 					confirmAlert({
					// 						title: "Confirm to Delete",
					// 						message: "Are you sure you want to delete vertical data.",
					// 						buttons: [
					// 							{
					// 								label: "Yes",
					// 								onClick: () => {
					// 									Notiflix.Loading.Dots("");

					// 									PostApiCall.postRequest(
					// 										{
					// 											id: data.fld_verticleid,
					// 											status: "Disabled",
					// 										},
					// 										"DeleteVerticleMaster"
					// 									).then((results) =>
					// 										// const objs = JSON.parse(result._bodyText)
					// 										results.json().then((obj) => {
					// 											if (
					// 												results.status === 200 ||
					// 												results.status === 201
					// 											) {
					// 												Notiflix.Loading.Remove();
					// 												Notiflix.Notify.Success(
					// 													"Vertical successfully deleted."
					// 												);
					// 												window.location.reload();
					// 											} else {
					// 												Notiflix.Loading.Remove();
					// 												Notiflix.Notify.Failure(
					// 													"Something went wrong, try again later."
					// 												);
					// 											}
					// 										})
					// 									);
					// 								},
					// 							},
					// 							{
					// 								label: "No",
					// 								// onClick: () => alert('Click No')
					// 							},
					// 						],
					// 					});
					// 				}}
					// 			></i>
					// 		</span>
					// 		<i
					// 			className="fas fa-edit btn"
					// 			style={{ fontSize: "15px", marginTop: "-11px" }}
					// 			onClick={() => {
					// 				this.setState({
					// 					Status: data.fld_status,
					// 					openedit: true,
					// 					Id: data.fld_verticleid,
					// 					Name: data.fld_verticlename,
					// 				});

					// 				//    this.props.setfootweartype(data.fld_type)
					// 			}}
					// 		/>
					// 	</span>
					// ),
				};
			}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>


						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">
								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>Master Management</li>
										<li>Self Delivery Master</li>

									</ul>
								</div>

							</div>
							<div className="col-md-6">
								{/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}
							</div>
						</div>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">

									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Self-Delivery Logistics List
																</h5>
															</div>
														</Col>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<a href="/add_self_delivery"
																	style={{
																		float: "right",
																		marginTop: "-5px",
																		background: 'white',
																		color: 'grey'
																	}}
																	className="btn align-items-center btn Bechofy-btn "
																>
																	Add New Self Delivery Logistics{" "}
																	<i
																		className="fa fa-plus"
																		aria-hidden="true"
																	></i>
																</a>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row></Row>
												</CardBody>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<MDBCol md="3" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) =>
																		this.setState({
																			searchInput: e.target.value,
																		})
																	}
																	value={this.state.searchInput}
																/>
															</MDBCol>
															<MDBDataTableV5
																striped
																bordered
																small
																data={data}
																searchBottom={false}
																seachTop={true}
																className="tablecol"
															/>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default verticleMater;
