import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class UpdateCareerManagment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			JobTitle: "",
			JobLocation: "",
			JobType: "",
			SalaryRange: "",
			JobType: "",
			JobData: [
				{ value: "Part Time", label: "Part Time" },
				{ value: "Full Time", label: "Full Time" },
				{ value: "Contract", label: "Contract" },
				{ value: "Freelancer", label: "Freelancer" },
			],
			CityData: [],
			JobDescription: "",
			Status: "Active",
			userDetail: JSON.parse(localStorage.getItem("careerdetails")),
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetCityonly").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					CityData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		if (this.state.userDetail != "[]") {
			this.setState({
				JobTitle: this.state.userDetail.fld_jobtitle,
				JobLocation: this.state.userDetail.fld_location,
				JobType: this.state.userDetail.fld_jobtype,
				SalaryRange: this.state.userDetail.fld_salaryrange,
				Status: this.state.userDetail.fld_status,
				JobDescription: this.state.userDetail.fld_description,
				data: this.state.userDetail.description,
			});
		}
	}
	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription = (e) => {
		this.setState({ JobDescription: e.editor.getData() });
	};

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				id: this.state.userDetail && this.state.userDetail.fld_careerid,
				jobtitle: this.state.JobTitle,
				location: this.state.JobLocation,
				jobtype: this.state.JobType,
				description: this.state.JobDescription,
				salaryrange: this.state.SalaryRange,
				status: this.state.Status,
				created_on: moment().format("lll"),
				updated_on: moment().format("lll"),
				updated_by: details[0].fld_userid,
			},
			"UpdateCareer"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				//console.log(obj);

				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("New Job successfully updated.");
					window.location.href = "/career-management";
				} else {
					Notiflix.Notify.Failure("Error Occured");
				}
			})
		);
	};

	SaveCareer = () => {
		if (this.state.JobTitle != "") {
			if (this.state.JobLocation != "") {
				if (this.state.JobType != "") {
					this.onPost();
				} else {
					Notiflix.Notify.Failure("Please select job type");
				}
			} else {
				Notiflix.Notify.Failure("Please select job location");
			}
		} else {
			Notiflix.Notify.Failure("Please enter job title");
		}
	};

	render() {
		//console.log(this.state);

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy /"}
							breadcrumbItem={"Edit New Job"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Career Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Edit New Job
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Job Title
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.JobTitle}
																						onChange={(job) => {
																							this.setState({
																								JobTitle: job.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-3">
																				<div className="form-group">
																					<label
																						class="my-1 mr-2"
																						for="inlineFormCustomSelectPref"
																					>
																						Job Location
																					</label>
																					<select
																						class="custom-select my-1 mr-sm-2"
																						id="inlineFormCustomSelectPref"
																						name="JobLocation"
																						value={this.state.JobLocation}
																						onChange={(location) => {
																							this.setState({
																								JobLocation:
																									location.target.value,
																							});
																						}}
																					>
																						<option selected>
																							Select Job Location
																						</option>
																						{this.state.CityData.map((city) => (
																							<option
																								key={city.value}
																								value={city.value}
																							>
																								{city.label}
																							</option>
																						))}
																					</select>
																				</div>
																			</div>

																			<div className="col-3">
																				<div className="form-group">
																					<label
																						class="my-1 mr-2"
																						for="inlineFormCustomSelectPref"
																					>
																						Job Type
																					</label>
																					<select
																						class="custom-select my-1 mr-sm-2"
																						id="inlineFormCustomSelectPref"
																						name="JobType"
																						value={this.state.JobType}
																						onChange={(text) => {
																							this.setState({
																								JobType: text.target.value,
																							});
																						}}
																					>
																						<option selected>
																							Select Job Type
																						</option>
																						{this.state.JobData.map((type) => (
																							<option
																								key={type.value}
																								value={type.value}
																							>
																								{type.label}
																							</option>
																						))}
																					</select>
																				</div>
																			</div>

																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Salary Range
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SalaryRange}
																						onChange={(text) => {
																							this.setState({
																								SalaryRange: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Job Description(maximum 1000
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={
																								!this.state.JobDescription
																									? this.state.userDetail
																										.fld_description
																									: this.state.JobDescription
																							}
																							onChange={(e) =>
																								this.onChangeDescription(e)
																							}
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveCareer}
																	>
																		Save New Job
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default UpdateCareerManagment;
