import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import imageCompression from "browser-image-compression";



const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);

class AddPSM extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        this.state = {
            imagePreviewUrl:
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
           SheetSize:null,
           SheetLength:null,
           SheetBreadth:null,
           SheetSizelb:null,
           CostPerKg:null,
            SheetId:null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            Action: action
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });




        var det = localStorage.getItem("PSMDetails");
        var PSMData = JSON.parse(det);

        if (PSMData != null) {
            if (this.state.Action == "update") {
                
                this.setState({
                    SheetSize: PSMData.fld_sheet_size,
                    SheetLength: PSMData.fld_sheet_length,
                    SheetBreadth: PSMData.fld_sheet_breadth,
                    SheetSizelb: PSMData.fld_sheet_size_lb,
                    CostPerKg: PSMData.fld_cost_per_kg,
                    SheetId: PSMData.fld_sheet_id,

                });
            }

        }
    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };



    onChangeDescription(text) {
        this.setState({
            BoardDescription: text.editor.getData(),
        });
    }

    onSaveData() {
        if (this.state.SheetSize != null) {
            if (this.state.SheetLength!=null) {
                if (this.state.SheetBreadth != null) {
                    if (this.state.SheetSizelb != null) {
                        this.onPost();
                       
                    }
                    else {
                        Notiflix.Notify.Failure("Please enter the sheet size in lb");
                    }
                }
                else {
                    Notiflix.Notify.Failure("Please enter the sheet breadth");
                }
            } else {
                Notiflix.Notify.Failure("Please enter the sheet length");
            }
        } else {
            Notiflix.Notify.Failure("Please enter the sheet size");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
                    PostApiCall.postRequest(
                        {
                         sheetid:this.state.SheetId,
                         sheetsize :this.state.SheetSize,
                         sheetlength :this.state.SheetLength,
                         sheetbreadth:this.state.SheetBreadth,
                         sheetsizelb: this.state.SheetSizelb,
                         costperkg :this.state.CostPerKg
                        },
                        "PrintingSheetMaster"
                    ).then((resultcategory) =>
                        resultcategory.json().then((obj) => {
                            if (resultcategory.status == 200 || resultcategory.status == 201) {
                                Notiflix.Loading.Remove()
                                Notiflix.Notify.Success("Printing Sheet successfully added.")
                                window.location.href = "/printing_sheet_master";

                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        })
                    );

               

    }



 



  

    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Printing Sheet Master"} breadcrumbItem1={this.state.SheetId == null ? "Add New Printing Sheet" : "Update Printing Sheet"} urlPath={"/printing_sheet_master"} urlPath1={this.state.SheetId == null ? "/add_printing_sheet/create" : "/add_printing_sheet/update"} />
                        <Row>
                            <Col xl="12">


                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    {this.state.SheetId == null ?
                                                        <h5 className="Bechofy-text">
                                                            Add New Printing Sheet
                                                        </h5>
                                                        :
                                                        <h5 className="Bechofy-text">
                                                            Update Printing Sheet
                                                        </h5>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row  ">
                                                        <div className="col col-12">
                                                            <div className="row">

                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="SheetSize">
                                                                           Sheet Size
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="SheetSize"
                                                                            className="form-control"
                                                                            value={this.state.SheetSize}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    SheetSize: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="SheetLength">
                                                                            Sheet Length
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="SheetLength"
                                                                            className="form-control"
                                                                            value={this.state.SheetLength}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                               this.setState({
                                                                                    SheetLength: text.target.value,
                                                                                });
                                                                            }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="SheetBreadth">
                                                                            Sheet Breadth
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="SheetBreadth"
                                                                            className="form-control"
                                                                            value={this.state.SheetBreadth}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        SheetBreadth: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="SheetSizelb">
                                                                            Sheet Size lb
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="SheetSizelb"
                                                                            className="form-control"
                                                                            value={this.state.SheetSizelb}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        SheetSizelb: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="CostPerKg">
                                                                           Cost Per Kg
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="CostPerKg"
                                                                            className="form-control"
                                                                            value={this.state.CostPerKg}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        CostPerKg: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                       
                                                                    </div>
                                                                </div> */}

                                                              
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            {this.state.SheetId == null ? <span>Save Printing Sheet</span> : <span>Update Printing Sheet</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
                                    {/* </Card> */}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddPSM;