import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Parser from "html-react-parser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import selecticon from '../../assets/images/dashboardimages/select.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import editicon from '../../assets/images/dashboardimages/edit.png'
import addicon from '../../assets/images/dashboardimages/add.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Breadcrumbs from "../../components/Common/Breadcrumb";

class SubCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SubCategoryData: [],
			CategoryData: [],
			selectedCategory: "",
			searchInput: ''
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		// GetApiCall.getRequest("Get_categorydropdown").then((resultdes) =>
		// 	resultdes.json().then((obj) => {
		// 		// //console.log(obj.data)
		// 		this.setState({
		// 			CategoryData: obj.data,
		// 		});
		// 		Notiflix.Loading.Remove();
		// 	})
		// );
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});


		PostApiCall.postRequest(
			{
				whereClause: `where fld_showOnWebsite ='Active' OR fld_showOnWebsite = 'True'`,

			},
			"Get_categorydropdown"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						CategoryData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("Get_Subcategory").then((resultdes1) =>
			resultdes1.json().then((obj1) => {
				this.setState({
					SubCategoryData: obj1.data,
				});
				// //console.log(obj1.data);
				Notiflix.Loading.Remove();
			})
		);
	}


	OnSelectHandler = (e) => {
		this.setState({ ...this.state, selectedCategory: e.target.value });
	};

	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};

	render() {

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs breadcrumbItem={"SubCategory Management"} urlPath={"/sub-category-management"} />
						<div className="overflow-hidden pagebackground">
							<div className="Bechofy-bg-soft-pink">
								<Row>
									<Col xs="3">
										<div className="Bechofy-text p-3">
											<h5 className="Bechofy-text">
												Filter
											</h5>
										</div>
									</Col>
									<Col xs="6">
										{/* <div className="row">
														<div className="col col-4">
															<label
																className="my-1 mr-2"
																for=""
																style={{ paddingTop: "15px", float: "right" }}
															>
																Filter By Category
															</label>
														</div>
														<div
															className="col col-8"
															style={{ marginTop: "5px" }}
														>
															<select
																onChange={(e) => {
																	this.OnSelectHandler(e);
																}}
																className="custom-select my-1 mr-sm-2"
															>
																<option value="">Select Category</option>
																{this.state.CategoryData.map((data, i) => {
																	return (
																		<option value={data.value}>
																			{data.label}
																		</option>
																	);
																})}
															</select>
														</div>
													</div> */}
									</Col>

								</Row>
							</div>

							<Card style={{ padding: ' 0 20px' }}>
								<div className="row my-1 ">

									<div className="col-10">
										<div className="form-group">
											{/* <label for="inputType" className="FilterCard">City</label> */}
											<div class="input-group">
												<div class="input-group-prepend gridinput">
													<span class="input-group-text" id="basic-addon1">
														<span className=""><img src={selecticon} alt="block user" className="btnicons"></img></span></span>
												</div>
												<select
													onChange={(e) => {
														this.OnSelectHandler(e);
													}}
													className="form-control gridinput"
												>
													<option value="">Select Category</option>
													{this.state.CategoryData.map((data, i) => {
														return (
															<option value={data.value}>
																{data.label}
															</option>
														);
													})}
												</select>
											</div>
										</div>
									</div>



									<div className="col-2">
										<div className="position-relative">
											<a style={{

												marginTop: "15px",
												// background: 'white',
												color: 'white'
											}}
												className="btn align-items-center btn Bechofy-btn "
											>
												Filter & Display
											</a>
										</div>
									</div>

								</div>
							</Card>

							<Card>

								<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
									<div className="row my-1">
										<div className="col-2">
											<div className="form-group">
												<h4 className="FilterCard" style={{ marginTop: '10px' }}>Sub Category List</h4>
											</div>
										</div>

										<div className="col-10">
											<div className="Bechofy-text p-3">
												<Link
													to="/add-sub-category/create"
													style={{
														float: "right", marginTop: "-8px", background: 'white',
														color: 'grey'
													}}
													className="btn align-items-center btn Bechofy-btn"
												>
													Add Sub Category{" "}
													<span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>


												</Link>
											</div>
										</div>




									</div>
								</div>

								<CardBody className="pt-0">
									<Row>
										<div class="input-group mb-3" style={{ marginTop: '10px' }}>


											<div class="input-group-prepend">
												<span class="input-group-text" id="basic-addon1">
													<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
											</div>
											<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
												onChange={(e) => this.seachBarHandler(e)}
												value={this.state.searchInput}

											/>
										</div>

										{this.state.selectedCategory === ""
											? this.state.SubCategoryData.filter((data) => {


												let name = [
													...data.fld_categoryname
														.toLowerCase()
														.matchAll(this.state.searchInput.toLowerCase()),
												];

												let status = [
													...data.fld_showOnWebsite
														.toLowerCase()
														.matchAll(this.state.searchInput.toLowerCase()),
												];
												let subcategory = [
													...data.fld_subcategoryname
														.toLowerCase()
														.matchAll(this.state.searchInput.toLowerCase()),
												];

												if (this.state.searchInput.length > 0 && name[0]) {
													return data;
												};

												if (this.state.searchInput.length > 0 && status[0]) {
													return data;
												};

												if (this.state.searchInput.length > 0 && subcategory[0]) {
													return data;
												}

												if (this.state.searchInput.length == 0) {
													return data;
												}
											}).map((data, i) => {
												return (
													<Col xs="4">
														<CardBody className="categoryCard">
															<CardTitle className="mb-4">
																{data.fld_categoryname}

																<p className="subcategoryname">
																	{data.fld_subcategoryname}
																</p>
															</CardTitle>

															<Row className="card-row">
																<Col sm="4">
																	<div className="imagecard">
																		<img
																			src={data.fld_image}
																			className="categoryimage"
																		></img>
																	</div>
																	{/* <hr style={{border:'1px solid #fff'}}/> */}
																</Col>
																<Col sm="8">
																	<div className="">
																		<p className="categorydesc">
																			{" "}
																			{data.fld_description == null
																				? ""
																				: Parser(
																					data.fld_description
																						.replace(/font-family/g, "")
																						.replace(/<p>/g, "")
																				)}
																		</p>
																	</div>
																</Col>
																<hr />
															</Row>
															<Row>
																<Col sm="4">
																	<p className="categorystatus">
																		<b>Status</b> -{" "}
																		<span
																			style={{
																				color:
																					data.fld_showOnWebsite == "Active"
																						? "Green"
																						: "Red",
																			}}
																		>
																			{data.fld_showOnWebsite}
																		</span>
																	</p>
																</Col>
																<Col sm="">
																	<span className="">
																		<img src={deleteicon} alt="block user" className="btnicons deleteicons" style={{ cursor: "pointer" }}
																			onClick={() => {
																				confirmAlert({
																					title: "Confirm to Inatcive",
																					message:
																						"Are you sure you want to Inactive the sub-category.",
																					buttons: [
																						{
																							label: "Yes",
																							onClick: () => {
																								Notiflix.Loading.Dots("");

																								PostApiCall.postRequest(
																									{
																										id:
																											data.fld_subcategoryid,
																										showOnWebsite:
																											"Inactive",
																									},
																									"DeleteSubcategory"
																								).then((results) =>
																									// const objs = JSON.parse(result._bodyText)
																									results
																										.json()
																										.then((obj) => {
																											if (
																												results.status ==
																												200 ||
																												results.status ==
																												201
																											) {
																												Notiflix.Loading.Remove();
																												Notiflix.Notify.Success(
																													"Sub Category successfully deleted."
																												);
																												window.location.reload();
																											} else {
																												Notiflix.Loading.Remove();
																												Notiflix.Notify.Failure(
																													"Something went wrong, try again later."
																												);
																											}
																										})
																								);
																							},
																						},
																						{
																							label: "No",
																							// onClick: () => alert('Click No')
																						},
																					],
																				});
																			}}
																		></img>
																	</span>
																	<a
																		onClick={() => {
																			localStorage.setItem(
																				"SubCategoryDetails",
																				JSON.stringify(data)
																			);
																			window.location.href =
																				"/add-sub-category/update";
																		}}
																		className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																		style={{
																			marginTop: "10px",
																			color: "white",
																		}}
																	>
																		<span className=""><img src={editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
																		Edit Sub Category
																	</a>
																</Col>
															</Row>
														</CardBody>
													</Col>
												);
											})
											: this.state.SubCategoryData.filter((data) => {
												if (
													data.fld_categoryid == this.state.selectedCategory
												) {
													return data;
												}
											}).map((data, index) => (
												<Col xs="4">
													<CardBody className="categoryCard">
														<CardTitle className="mb-4">
															{data.fld_categoryname}

															<p className="subcategoryname">
																{data.fld_subcategoryname}
															</p>
														</CardTitle>

														<Row className="card-row">
															<Col sm="4">
																<div className="imagecard">
																	<img
																		src={data.fld_image}
																		className="categoryimage"
																	></img>
																</div>
																{/* <hr style={{border:'1px solid #fff'}}/> */}
															</Col>
															<Col sm="8">
																<div className="">
																	<p className="categorydesc">
																		{" "}
																		{data.fld_description == null
																			? ""
																			: Parser(
																				data.fld_description
																					.replace(/font-family/g, "")
																					.replace(/<p>/g, "")
																			)}
																	</p>
																</div>
															</Col>
															<hr />
														</Row>
														<Row>
															<Col sm="4">
																<p className="categorystatus">
																	<b>Status</b> -{" "}
																	<span
																		style={{
																			color:
																				data.fld_showOnWebsite == "Active"
																					? "Green"
																					: "Red",
																		}}
																	>
																		{data.fld_showOnWebsite}
																	</span>
																</p>
															</Col>
															<Col sm="8">
																<span className="">
																	<img src={deleteicon} alt="block user" className="btnicons deleteicons"

																		onClick={() => {
																			confirmAlert({
																				title: "Confirm to Delete",
																				message:
																					"Are you sure you want to delete category.",
																				buttons: [
																					{
																						label: "Yes",
																						onClick: () => {
																							Notiflix.Loading.Dots("");

																							PostApiCall.postRequest(
																								{
																									id:
																										data.fld_subcategoryid,
																									showOnWebsite: "Disabled",
																								},
																								"DeleteSubcategory"
																							).then((results) =>
																								// const objs = JSON.parse(result._bodyText)
																								results
																									.json()
																									.then((obj) => {
																										if (
																											results.status ==
																											200 ||
																											results.status == 201
																										) {
																											Notiflix.Loading.Remove();
																											Notiflix.Notify.Success(
																												"Sub Category successfully deleted."
																											);
																											window.location.reload();
																										} else {
																											Notiflix.Loading.Remove();
																											Notiflix.Notify.Failure(
																												"Something went wrong, try again later."
																											);
																										}
																									})
																							);
																						},
																					},
																					{
																						label: "No",
																						// onClick: () => alert('Click No')
																					},
																				],
																			});
																		}}
																	></img> </span>

																<a
																	onClick={() => {
																		localStorage.setItem(
																			"SubCategoryDetails",
																			JSON.stringify(data)
																		);
																		window.location.href =
																			"/editsubcategory";
																	}}
																	className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																	style={{
																		marginTop: "10px",
																		color: "white",
																	}}
																>
																	<span className=""><img src={editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
																	Edit Sub Category
																</a>
															</Col>
														</Row>
													</CardBody>
												</Col>
											))}
									</Row>
								</CardBody>
							</Card>
							{/* </Row> */}
						</div>

					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default SubCategory;
