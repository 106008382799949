import React, { Component } from 'react';

import PostApiCall from "../../Api";

import Notiflix from "notiflix";

import Select from 'react-select';
import moment from 'moment'
import Downloadicon from '../../assets/images/dashboardimages/upload-file.png'


class CsvUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StateData: [],
      UploadCSVURL: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "uploadcsv",
      CSVFile: [],
      PriceState: [],
      StateId: [],
      WarehouseData: [],

      WareHouseId: [],
    };
  }


  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            // this.props.setstate()

            this.setState({

              StateData: objstate.data,


            }

            );
          }
          Notiflix.Loading.Dots("Please wait...");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_status='Active' AND fld_state=${objstate.data[0].value}`,
              recordCount: 'fld_name as label,fld_warehouseid as value'

            },
            "GetWarehouseByState"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {

                  //console.log(obj.data)
                  this.setState({
                    WarehouseData: obj.data,


                  });
                }
              }
            }))
          Notiflix.Loading.Remove();

        }
      }))




  }


  uploadFile = () => {
    if (JSON.stringify(this.state.StateId) != "[]") {
      if (JSON.stringify(this.state.WareHouseId) != "[]") {

        if (JSON.stringify(this.state.CSVFile) != "[]") {

          Notiflix.Loading.Dots("Please wait...");

          const form = new FormData();

          form.append("file", this.state.CSVFile);
          form.append("foldername", "StockCSV");
          form.append(
            "filename", moment().format('mmsshh') + this.state.CSVFile.name

          );

          fetch(this.state.UploadCSVURL, {
            method: "POST",
            body: form,
          }).then((image) => {
            image
              .json()
              .then((data) => ({
                data: data,
                status: image.status,
              }))
              .then((res) => {

                PostApiCall.postRequest(
                  {
                    id: null,
                    filename: res.data.Message.split(",")[2].split("=")[1].trim(),
                    filelocation: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/CSV/StockCSV/`,
                    state: this.state.StateId.value,
                    warehouse: this.state.WareHouseId.value,
                    filestatus: 'NEW',
                    localpath: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/CSV/StockCSV/`

                  },
                  "addVariantStock"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      // //console.log(obj1)
                      Notiflix.Loading.Remove();
                      Notiflix.Notify.Success("Variant Stock successfully added.");
                      window.location.reload();
                    }
                    else {
                      Notiflix.Loading.Remove();
                      Notiflix.Notify.Failure(obj1.data);
                    }
                  })
                );


              })
          })
        }
        else {
          Notiflix.Notify.Failure('Please upload document')

        }
      }
      else {
        Notiflix.Notify.Failure('Please select state')
      }
    }
    else {
      Notiflix.Notify.Failure('Please select state')
    }

  }




  onChangeState(state) {
    this.setState(
      {
        //    StateId: state.target.value,
        StateId: state,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {

            whereClause: `where fld_status='Active' AND fld_state=${this.state.StateId.value}`,
            recordCount: 'fld_name as label,fld_warehouseid as value'

          },
          "GetWarehouseByState"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              if (obj.data.length != 0) {


                this.setState({
                  WarehouseData: obj.data,
                });
              }
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }

  onChangeWareHouse(warehouse) {
    this.setState({
      WareHouseId: warehouse,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center" style={{ height: '370px', marginBottom: '25px' }}>
          <div class="mt-1 price-card py-5 col-10">
            <div style={{ marginTop: '-25px' }}>
              <h4 className="stepper-heading d-flex justify-content-center align-items-center">To update stock, upload the CSV file with the latest stock of products.</h4><br />
            </div>
            <div className="col-12">
              <label className="mt-1">Select State</label>
              <Select



                options={this.state.StateData}
                value={this.state.StateId}
                onChange={this.onChangeState.bind(this)}




                isSearchable={true}
                isClearable={true} />

              <label className="mt-2">Select Warehouse</label>

              <Select
                options={this.state.WarehouseData}
                value={this.state.WareHouseId}
                onChange={this.onChangeWareHouse.bind(this)}
                isSearchable={true}
                isClearable={true} />





            </div>
            <div className="col-12" style={{ marginTop: '3%' }}>
              <div className="form-group">
                <label for="CategoryName">
                  Upload Stock List (.csv)
                </label>
                <input
                  type="file"
                  id="CategoryName"
                  className="form-control"
                  style={{ display: 'block' }}
                  accept="application/csv"

                  onChange={(e) => {
                    if (
                      e.target.files[0].size <
                      9000000
                    ) {
                      const reader = new FileReader();
                      const file =
                        e.target.files[0];
                      reader.onloadend = () => {
                        this.setState({
                          CSVFile: file,
                        });
                      };
                      reader.readAsDataURL(file);
                    } else {
                      Notiflix.Notify.Failure(
                        "File too large, upload file less than 9 Mb."
                      );
                    }
                  }}



                />
              </div>
            </div>
            <div>
              <button className="btn downloadcsv-button mt-1 mb-1" style={{ float: 'right' }}
                onClick={this.uploadFile}
              >
                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                Upload Stock</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default CsvUpload;