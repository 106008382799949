const intialiseState={
    Name:'',
    Mobile:'',
    Email:'',
    DOB:'',
    AnniversaryDate:'',
    Designation:'',
    Department:'',
    UserType:'Admin',
    Address:'',
    Country:'',
    State:'',
    City:'',
    Pincode:'',
    AadharCard:'',
    PANCard:'',
    DrivingLicenses:'',
    BankName:'',
    Branch:'',
    AccountNumber:'',
    IFSCCode:'',
    Salary:'',
    Password:'',
    ConfirmPassword:'',


}

export default (state = intialiseState, action) =>{
    switch(action.type){
        
        case "SET_NAME":
            return{
                ...state,
                Name:action.payload
            };
        case "SET_MOBILE":
            return{
                ...state,
                Mobile:action.payload
            };
        case "SET_EMAIL":
            return{
                ...state,
                Email:action.payload
            };
        case "SET_DOB":
            return{
                ...state,
                DOB:action.payload
            };
        case "SET_ANNIVERSARY_DATE":
            return{
                ...state,
                AnniversaryDate:action.payload
            };
      
        case "SET_DESIGNATION":
            return{
                ...state,
                Designation:action.payload
            };
        case "SET_DEPARTMENT":
            return{
                ...state,
                Department:action.payload
            };
        case "SET_USER_TYPE":
            return{
                ...state,
                UserType:action.payload
            };
     
        case "SET_ADDRESS":
            return{
                ...state,
                Address:action.payload
            };
        case "SET_COUNTRY":
            return{
                ...state,
                Country:action.payload
            };
        case "SET_STATE":
            return{
                ...state,
                State:action.payload
            };
        case "SET_CITY":
            return{
                ...state,
                City:action.payload
            };
        case "SET_PINCODE":
            return{
                ...state,
                Pincode:action.payload
            };


           

            case "SET_AADHAR_CARD":
            return{
                ...state,
                AadharCard:action.payload
            };
            case "SET_PAN_CARD":
                return{
                    ...state,
                    PANCard:action.payload
                };
                case "SET_DRIVING_LICENSES":
                    return{
                        ...state,
                        DrivingLicenses:action.payload
                    };
                    case "SET_BankName":
                        return{
                            ...state,
                            BankName:action.payload
                        };
                        case "SET_Branch":
                            return{
                                ...state,
                                Branch:action.payload
                            };
                            case "SET_Accountno":
                                return{
                                    ...state,
                                    Accountno:action.payload
                                };
                                case "SET_Ifsccode":
                                    return{
                                        ...state,
                                        Ifsccode:action.payload
                                    };
                                    case "SET_Salary":
                                        return{
                                            ...state,
                                            Salary:action.payload
                                        };
                        case "SET_PASSWORD":
                            return{
                                ...state,
                                Password:action.payload
                            };
                            case "SET_CONFIRM_PASSWORD":
                                return{
                                    ...state,
                                    ConfirmPassword:action.payload
                                };
             
         case "CLEAR_User":
             return{
                ...state,
                Name:'',
    Mobile:'',
    Email:'',
    DOB:'',
    AnniversaryDate:'',
    Designation:'',
    Department:'',
    UserType:'Admin',
    Address:'',
    Country:'',
    State:'',
    City:'',
    Pincode:'',
    AadharCard:'',
    PANCard:'',
    DrivingLicenses:'',
    BankName:'',
    Branch:'',
    AccountNumber:'',
    IFSCCode:'',
    Salary:'',
    Password:'',
    ConfirmPassword:'',
            
          };

             default:
                return state;  
        }
}
