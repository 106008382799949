import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'

import Select from 'react-select';
import { CSVLink } from "react-csv";

class SelectState extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef()

    this.state = {
      StateData: [],
      PriceState: [],
      fileData: [],
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest("GetPriceState").then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            // this.props.setstate()
            this.setState({
              StateData: objstate.data,
            });
          }
          Notiflix.Loading.Remove();
        }
      }))


  }


  DownloadCSV = () => {

    if (JSON.stringify(this.state.PriceState) != "[]") {
      Notiflix.Loading.Dots("Please wait...");
      PostApiCall.postRequest(
        {
          stateid: this.state.PriceState.value,
          variantid: null
        },
        "downloadcsv"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            if (obj.data.length != 0) {
              // this.props.setstate()

              this.setState({

                fileData: obj.data,

              }, () => {
                setTimeout(() => {
                  this.csvLink.current.link.click();
                });
              });
            }
            Notiflix.Loading.Remove();
          }
        }))
    }
    else {
      Notiflix.Notify.Failure('Please select State')
    }
  }



  render() {

    return (
      <React.Fragment>
        <div className="d-flex justify-content-center" style={{ height: '300px' }}>
          <div class="mt-3 price-card py-5 col-10">
            <div >
              <h4 className="stepper-heading d-flex justify-content-center align-items-center">Select the State for which you would want to change the Product Prices</h4><br />
            </div>
            <div className="col-12">
              <Select
                // className="form-control"
                options={this.state.StateData}


                value={this.state.PriceState}
                onChange={(value) => {

                  this.setState({
                    PriceState: value
                  })

                }}




                isSearchable={true}
                isClearable={true} />
            </div>
            <div>
              <button className="btn downloadcsv-button mt-4 mb-4" style={{ float: 'right' }}

                onClick={this.DownloadCSV}
              >
                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                Download CSV</button>

              <CSVLink
                data={this.state.fileData}
                filename="sample.csv"
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default SelectState