import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
class RiderAreaAssignment extends Component {
	render() {
		const data = {
			columns: [
				{
					label: "S.No",
					field: "sn",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Rider Name",
					field: "name",
					sort: "disabled",
					width: 270,
				},
				{
					label: "Area assignment",
					field: "area",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: [
				{
					sn: <td>1</td>,
					name: <td>test</td>,
					area: <td>Delhi</td>,
					action: (
						<td className="text-center">
							{" "}
							<span className="btn" style={{ marginTop: "-9px" }}>
								<i
									className="fa fa-trash py-auto "
									aria-hidden="true"
									style={{ fontSize: "15px" }}
								></i>
							</span>
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
							></i>
						</td>
					),
				},
			],
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Product Management"}
							breadcrumbItem={"Rider Area Assignment"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Rider Area Assignment List
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<Link
														to="/add_riderarea"
														style={{ float: "right", marginTop: "-5px" }}
														className="btn align-items-center btn Bechofy-btn "
													>
														Add New Rider Area{" "}
														<i className="fa fa-plus" aria-hidden="true"></i>
													</Link>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row></Row>
									</CardBody>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<MDBDataTableV5
													striped
													bordered
													small
													data={data}
													searchBottom={false}
													seachTop={true}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RiderAreaAssignment;
