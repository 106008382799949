import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";

import PostApiCall from "../../Api";
import moment from "moment";
import { data } from "jquery";
import "./Invoice.css"

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderData: []
    };
  }



  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    //   this.props.setclearbrand()
    var customer = localStorage.getItem('InvoiveDetails');
    var CustomerDetails = JSON.parse(customer)
    //console.log(CustomerDetails)


    Notiflix.Loading.Dots("Please wait...");

    PostApiCall.postRequest(
      {
        orderid: CustomerDetails
      },
      "Get_Dispatch_item_details"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //console.log(obj.data)
          this.setState({
            OrderData: obj.data,

          });
          Notiflix.Loading.Remove();
        }
      }))


  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Bechofy/Order Management"}
              breadcrumbItem={"Invoice List"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="6" md="12">
                        <div className="Bechofy-text  p-3">
                          <h5 className="Bechofy-text">View Invoice</h5>
                        </div>
                      </Col>

                    </Row>
                  </div>
                  <section>
                    <div class="container">
                      <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="card invoice" id="invoice">
                            <div class="card-header p-4 d-flex justify-content-between align-items-center">
                              <h1>Bechofy</h1>
                              {/* <p style={{ textAlign: 'center' }}>D 200, 3rd Floor, Mansarover Garden,New Delhi - 110 015.
                                INDIA<br />Phone: +91 11 4703 7722</p> */}
                              <div class="float-right">
                                <h3 class="mb-0">TAX INVOICE</h3>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row mb-4">
                                <div class="col-sm-6 ">
                                  <table class="table table-sm info-en">
                                    <thead>
                                      <tr>
                                        <th scope="col" colspan="2">YOUR DETAILS</th>
                                      </tr>
                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody>
                                            <tr>
                                              <th scope="row">NAME</th>
                                              <td>{data.fld_contactperson}</td>
                                            </tr>
                                            <tr>
                                              <th>EMAIL</th>
                                              <td>{data.fld_email}</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERY ADDRESS</th>
                                              <td>{data.fld_address}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>CARD NUMBER & CARD TYPE</th>
                                              <td>MASTER**********1234</td>
                                            </tr>
                                            <tr>
                                              <th>PAYMENT METHOD</th>
                                              <td>{data.Payment_Mode}</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERY METHOD</th>
                                              <td>Standard Delivery</td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    })}
                                  </table>
                                </div>
                                <div class="col-sm-6 ">
                                  <table class="table table-sm info-en">
                                    <thead>
                                      <tr>
                                        <th scope="col" colspan="2">ORDER DETAILS</th>
                                      </tr>
                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody class="inv-info">
                                            <tr>
                                              <th scope="row">INVOICE DATE</th>
                                              <td>{data.fld_invoice_date}</td>
                                            </tr>
                                            <tr>
                                              <th>INVOICE NUMBER</th>
                                              <td>{data.fld_invoice_number}</td>
                                            </tr>
                                            <tr>
                                              <th>ORDER NUMBER</th>
                                              <td>{data.fld_orderNumber}</td>
                                            </tr>
                                            <tr>
                                              <th>ORDER DATE</th>
                                              <td>{data.fld_order_date}</td>
                                            </tr>

                                          </tbody>
                                        )
                                      }

                                    })}
                                    <thead id="ship">

                                      <th colspan="2">SHIPED BY</th>

                                    </thead>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tbody>
                                            <tr>
                                              <td>SIGN</td>
                                              {/* <td>Global Trendz<br />
                                                # 621, 3rd Floor, Anna Salai,<br />
                                                Chennai - 600 006. INDIA.<br />
                                                Phone: +91 44 4309 9119<br />
                                              </td> */}
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    })}
                                  </table>
                                </div>
                              </div>
                              <div class="table-responsive-sm">
                                <table class="table table-sm info-en items">
                                  <thead>
                                    <tr>
                                      <th scope="col" colspan="11">ORDER SUMMARY</th>
                                    </tr>
                                  </thead>



                                  <tbody>

                                    <tr>
                                      <th>ORDER NO.</th>
                                      <th>HSN CODE</th>
                                      <th>ITEM NAME</th>
                                      <th>QTY</th>
                                      <th>UNIT PRICE</th>
                                      <th>DISCOUNT</th>
                                      <th>IGST</th>
                                      <th>IGST AMOUNT</th>
                                      <th>TOTAL PRICE</th>
                                    </tr>
                                    {this.state.OrderData.map((data, i) => {
                                      if (i === 0) {
                                        return (
                                          <tr>
                                            <td>{data.fld_orderNumber}</td>
                                            <td>{data.fld_hsncode} </td>
                                            <td>{data.variant_name}</td>
                                            <td>{data.fld_quantity}</td>
                                            <td>Rs. {data.fld_total}</td>
                                            <td>{data.fld_discount}</td>
                                            <td>5.00%</td>
                                            <td class="center">45.24</td>
                                            <td>Rs. {data.fld_total}</td>
                                          </tr>
                                        )
                                      }
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-sm-5">
                                </div>
                                <div class="col-lg-4 col-sm-5 ml-auto">
                                  {this.state.OrderData.map((data, i) => {
                                    if (i === 0) {
                                      return (
                                        <table class="table table-sm info-in sub-total" id="info">
                                          <tbody class="inv-info">
                                            <tr>
                                              <th scope="row">PRODUCTS TOTAL:</th>
                                              <td>Rs. 6997.00</td>
                                            </tr>
                                            <tr>
                                              <th>DISCOUNTS:</th>
                                              <td>-Rs. 1299.00</td>
                                            </tr>
                                            <tr>
                                              <th>DELIVERRY</th>
                                              <td>FREE</td>
                                            </tr>
                                          </tbody>
                                          <tbody class="total">
                                            <tr>
                                              <th>TOTAL</th>
                                              <td class="right"><strong>{data.fld_total}</strong></td>
                                            </tr>
                                            <tr>
                                              <td class="right" colspan="2">
                                                Two Thousand Eight Hundred Seventy Eight Rupees
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Excluding GST</td>
                                              <td class="right">Rs. 2740.95</td>
                                            </tr>
                                            <tr>
                                              <td>IGST:5.00%</td>
                                              <td class="right">Rs. 137.05</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                              {/* <div class="row">
                                            <div class="col-lg-5 col-sm-5 qr-code">
                                              <h3>Scan to Return</h3>
                                              <img src='https://chart.googleapis.com/chart?cht=qr&chl=http%3A%2F%2Fglobaltrendz.com%2F&chs=90x90&choe=UTF-8&chld=L|2' alt='qr code'/>
                                                <p>Or visit customer service return page on Global Trebdz</p>
                                                <img src="https://barcode.tec-it.com/barcode.ashx?data=ABC-abc-1234&code=Code128&translate-esc=on" class="img-fluid" width="150px"/>
                                            </div>
                                                <div class="col-lg-4 col-sm-5 ml-auto">
                                                  <div class="d-flex justify-content-center align-items-center p-4">
                                                    <img src='https://chart.googleapis.com/chart?cht=qr&chl=http%3A%2F%2Fglobaltrendz.com%2F&chs=90x90&choe=UTF-8&chld=L|2' alt='qr code' class=""/>
                                                </div>


                                                  </div>
                                                </div> */}
                            </div>
                            {/* <div class="card-footer bg-white">
                              <p class="mb-0">www.globaltrendz.com, D 200, 3rd Floor, Mansarover Garden, New Delhi - 110 015. INDIA.</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </section>
                  {/* <WelcomeComp /> */}
                </Card>
              </Col>
            </Row>

          </Container>

        </div>
      </React.Fragment>
    );
  }
}
export default Invoice;

