import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import Notiflix from "notiflix";
import Notiflix from "notiflix";
import PostApiCall from "../../../Api";

import moment from "moment";



class categoryManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            PurchaseApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
            Status: "Yes",


            Details: [],
            StockData: [],
            Quantity: null,
            CP: null,
            TotalAmount: null,
            PON: null,
            PODate: null,
            VendorName: null,
            PurchasePDf: [],
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            NumRegex: /^[0-9]*$/,
            SourceData: [{
                label: 'Return', value: 'Return',
            },
            {
                label: 'Miscellaneous', value: 'Miscellaneous'
            }],
            Source: null,
            Reason: null
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        var VariantID = JSON.parse(localStorage.getItem("variantid"));


        var WarehouseId = JSON.parse(localStorage.getItem("warehouseId"))

        PostApiCall.postRequest(
            {
                Recordcount: "Top 1 *",
                whereClause: `where fld_variant_id = ${VariantID} AND fld_warehouseid=${WarehouseId}`
            },
            "Get_Stock_List_By_Id"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        StockData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))






    }

    photoUpload = (e) => {
        e.preventDefault();
        if (e.target.files[0].size < 100000) {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    ImageData: file,
                });
            };
            reader.readAsDataURL(file);
        } else {
            Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
        }
    };






    onPost = () => {

        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        var VariantID = JSON.parse(localStorage.getItem("variantid"));
        var WarehouseId = JSON.parse(localStorage.getItem("warehouseId"))


        PostApiCall.postRequest(
            {
                stockid: 0,
                varientid: VariantID,
                quantity: this.state.Quantity,
                costprice: null,
                totalamt: null,
                purchse_order_no: null,
                purchse_order_date: null,
                stockstatus: this.state.Reason,
                purchasefile: null,
                userid: details[0].fld_userid,
                action: 'OUT STOCK',
                batchno: null,
                vendorid: null,
                expirydate: null,
                mnfcturedate: null,
                status: 'NEW',
                warehouseid: WarehouseId,
                source: this.state.Source

            },
            "Add_Stocks"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    // //console.log(obj1)
                    Notiflix.Loading.Remove();
                    localStorage.removeItem("variantid");
                    localStorage.removeItem("warehouseId");
                    Notiflix.Notify.Success("Stock successfully reduce.");

                    window.location.href = "/stock_management";
                }
                else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );


    }

    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={
                                "Bechofy / Stock Management / Stock List "
                            }
                            breadcrumbItem={"Add New Stock"}
                        />

                        <Row>
                            <Col xl="12">
                                {/* <Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Category Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div> */}
                                {/* <Card className="overflow-hidden">

                        <Row>
                            <Col xl="12"> */}

                                <Card className="overflow-hidden pagebackground">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        Add New Stock
                                                    </h5>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Row style={{ marginTop: '15px' }}>
                                        <Col xl="4" >
                                            <Card className="" style={{ height: '225px' }}>
                                                <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}
                                                >
                                                    <Row>
                                                        {this.state.StockData.map((data, i) => {
                                                            return (
                                                                <Col xs="7">
                                                                    <div className="Bechofy-text p-3">
                                                                        <h5 className="Bechofy-text">{data.fld_variantname}</h5>
                                                                        <p><b>SKU</b> {data.fld_sku}</p>

                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}

                                                    </Row>
                                                </div>


                                                <CardBody className="pt-4">

                                                    <Row>
                                                        <Col xs="6">
                                                            <p>Stock in Hand</p>
                                                            <p>Last Purchase Price</p>
                                                            <p> Last Purchase Order Date</p>


                                                        </Col>
                                                        {this.state.StockData.map((data, i) => (


                                                            <Col xs="6">
                                                                <p>{data.fld_stock_in_hand}</p>
                                                                <p>₹ {data.fld_total_amt}</p>
                                                                <p>{data.fld_purchase_order_date == null ? null : moment(data.fld_purchase_order_date).format('DD/MM/YYYY')}</p>



                                                            </Col>
                                                        ))}

                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        </Col>

                                        <Col md="8">
                                            <Card className="mini-stats-wid">
                                                <div className="Bechofy-bg-soft-pink">
                                                    <Row>
                                                        <Col xs="3">
                                                            <div className="Bechofy-text p-3">
                                                                <h5 className="Bechofy-text">
                                                                    Add Stock
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <CardBody>
                                                    <Row>

                                                        <Col xs="12">
                                                            <div className="col col-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">Source<span className="mandatory">*</span></label>
                                                                            <select id="CategoryName" className="form-control" name="Name"
                                                                                value={this.state.Source}
                                                                                onChange={(text) => {
                                                                                    this.setState({
                                                                                        Source: text.target.value
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <option value=''>Select Source</option>
                                                                                {this.state.SourceData.map((data =>
                                                                                    <option key={data.value} value={data.value}>{data.label}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Quantity

                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id="CategoryName"
                                                                                className="form-control "
                                                                                value={this.state.Quantity}
                                                                                onChange={(text) => {
                                                                                    if (this.state.NumRegex.test(
                                                                                        text.target.value
                                                                                    )) {
                                                                                        this.setState({
                                                                                            Quantity: text.target.value
                                                                                        })
                                                                                    }
                                                                                }}


                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">Reason For Reduce Stock<span className="mandatory">*</span></label>
                                                                            <input id="CategoryName" className="form-control" name="Name"
                                                                                type="text"
                                                                                value={this.state.Reason}
                                                                                onChange={(text) => {
                                                                                    this.setState({
                                                                                        Reason: text.target.value
                                                                                    })
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    </div>




                                                                </div>

                                                            </div>










                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Card>



                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={() => {
                                                                if (this.state.Quantity != null) {
                                                                    if (this.state.Source != null) {
                                                                        if (this.state.Reason != null) {
                                                                            if (this.state.Reason.split(' ').length < 100) {

                                                                                this.onPost()
                                                                            }
                                                                            else {
                                                                                Notiflix.Notify.Failure('Reason cannot be greater then 100 characters ')
                                                                            }

                                                                        }
                                                                        else {
                                                                            Notiflix.Notify.Failure('Please enter reason for reducing stock ')
                                                                        }
                                                                    }
                                                                    else {
                                                                        Notiflix.Notify.Failure('Please select source')
                                                                    }

                                                                }
                                                                else {
                                                                    Notiflix.Notify.Failure('Please select quantity')
                                                                }
                                                            }}
                                                        >
                                                            Reduce Stock
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                </Card>


                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default categoryManagement;
