
// Pages Component
import Chat from "../pages/Chat/Chat";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

//Ecommerce Pages
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProductDetail";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoices

import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Inner Authentication
import LockScreen from "../pages/Authentication/auth-lock-screen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Crypto
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ToastUIChart from "../pages/Charts/ToastUIChart";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiImageCropper from "../pages/Ui/ui-image-cropper";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";

//Pages

import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesMaintenance from "../pages/Utility/pages-maintenance";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Contacts
import ChangePasswordPage from "../pages/Authentication/ChangePassword";
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";

// Cutomers
// import CutomerList from "../pages/CustomerMangement/CutomerList";
import LoyaltyPointReport from "../pages/CustomerMangement/LoyaltyPointReport";

// Product Management
import categoryManagement from "../pages/ProductMangement/AddCategory";
import PricemMangement from "../pages/ProductMangement/PriceMangement";
// import sub-category-management from "../pages/ProductMangement/sub-category-management";
// Order Management

import DispatchMangement from "../pages/OrderMangement/DispatchMangement";
import Orderlist from "../pages/OrderMangement/OrderList";
import RefundManagement from "../pages/OrderMangement/RefundManagement";

import BrandMaster from "../pages/Masters/AddBrandMaster";
import CompanyMaster from "../pages/Masters/CompanyMaster";
import ProductMaster from "../pages/Masters/ProductMaster";
import ChildMenuMaster from "../pages/MenuManagement/ChildMenuMaster";
import MainMenuMaster from "../pages/MenuManagement/MainMenuMaster";
import SubMenuMaster from "../pages/MenuManagement/SubMenuMaster";
import RiderResigtrationForm from "../pages/pages/RiderResigtration/RiderResigtrationForm";
import Categorymanagementlist from "../pages/ProductMangement/CategoryManagementlist";
import CategoryMappingMaster from "../pages/ProductMangement/CategoryMappingMaster";
import AddRider from "../pages/RiderMangement/AddRider";
import RiderAreaAssignment from "../pages/RiderMangement/RiderAreaAssignment";
import RiderList from "../pages/RiderMangement/RiderList";

import SubCategoryManagement from "../pages/ProductMangement/SubCategoryManagement";

import Addsubcategory from "../pages/ProductMangement/AddSubCategory";
import ItemManagementList from "../pages/ProductMangement/ItemManagement";

import AddItemMAnagement from "../pages/ProductMangement/AddNewItem";

import AddUser from "../pages/UserManagement/AddUser";

import AddRawMaterial from "../pages/ProductMangement/AddRawMaterial";
import RawmaterialList from "../pages/ProductMangement/RawmaterialList";
import UserList from "../pages/UserManagement/UserList";

import Add_Career from "../pages/UserManagement/AddCareer";
import CareerList from "../pages/UserManagement/Careerlist";
// import editcategory from "../pages/ProductMangement/AddCategory";
import UpdateRawMaterial from "../pages/ProductMangement/UpdateRawMaterial";

import customerList from "../pages/CustomerMangement/CustomerList";
import EditCareer from "../pages/UserManagement/Editcareer";
import Edituser from "../pages/UserManagement/Edituser";

import AddAttributeMaster from "../pages/Masters/AddAttributeMaster";

import AddAttributeDetail from "../pages/Masters/AddAttributeDetail";

import AddAttributeList from "../pages/Masters/AttributemasterList";

import AddAttributeValueList from "../pages/Masters/AttributeValueList";

import AddCustomer from "../pages/CustomerMangement/AddCustomer";
import EditCustomer from "../pages/CustomerMangement/EditCustomer";
import Addattributemaster from "../pages/Masters/AddAttribute";
import AttributeMasterList from "../pages/Masters/AttributeList";
import UpdateAttributeMapping from "../pages/Masters/UpdateAttributeMapping";

import VerticalMapping from "../pages/Masters/VerticalMapping";
import Verticalmaster from "../pages/Masters/Verticalmaster";

import ReviewList from "../pages/ReviewManagemet/ReviewList";
import UpdateRider from "../pages/RiderMangement/UpdateRider";

import AddReview from "../pages/ReviewManagemet/AddReview";
import EditReview from "../pages/ReviewManagemet/EditReview";

import PickupList from "../pages/PickupManagement/PickupList";

import AddOffer from "../pages/OfferManagement/AddOffer";
import EditOffer from "../pages/OfferManagement/EditOffer";
import OfferList from "../pages/OfferManagement/OfferList";

import AddVerticalMapping from "../pages/Masters/AddVerticalMapping";
import ProductPinCode from "../pages/Masters/ProductPinCode";
import Edititem from "../pages/ProductMangement/UpdateNewItem";
import AddRiderArea from "../pages/RiderMangement/AddRiderArea";

import VariantManagement from "../pages/ProductMangement/VariantManagement";

// ===========08/06/2021=========
import ViewCustomer from "../pages/CustomerMangement/ViewCustomer";
import ViewOrder from "../pages/OrderMangement/OrderForm";
import BrandManagementList from "../pages/ProductMangement/BrandManagementList";


import AddStock from "../pages/ProductMangement/StockManagement/AddStock";
import StockManagement from "../pages/ProductMangement/StockManagement/StockManagement";
//=================Banner Management================////
import AddNewBanner from "../pages/BannerManagement/AddNewBanner";
import BannerList from "../pages/BannerManagement/BannerList";
import Home from "../pages/BannerManagement/Home";
import NewPage from "../pages/BannerManagement/NewPage";

import NotificationList from "../pages/Notification/NotificationList";
import InvoiceForm from "../pages/OrderMangement/InvoiceForm";


import BannerMaster from "../pages/Masters/BannerMaster";
import SelfDeliveryMaster from "../pages/Masters/SelfDeliveryMaster";

import LogisticsMaster from "../pages/Masters/LogisticsMaster";

import DispatchForm from "../pages/OrderMangement/DispatchForm";
import DownloadOrderForm from "../pages/OrderMangement/DownloadOrderForm";
//==========================SideBar=====================
import AddAddress from "../pages/CustomerProfile/AddAddress";
import AddressBookForm from "../pages/CustomerProfile/AddressBookForm";
import ChangePassword from "../pages/CustomerProfile/ChangePassword";
import OrderHistory from "../pages/CustomerProfile/OrderHistory";
import Profile from "../pages/CustomerProfile/Profile";
import SideBar from "../pages/CustomerProfile/SideBar";

//===========Company Master=======================
import HelpCenter from "../pages/CompanyMaster/HelpCenter";
import MenuBar from "../pages/CompanyMaster/MenuBar";
import Routes from "../pages/CompanyMaster/NavBar/Routes";
import SubmitRequest from "../pages/CompanyMaster/SubmitRequest";



import AddNewVariant from "../pages/ProductMangement/AddVariant";

import invoiceList from "../pages/OrderMangement/invoiceList";

// =====Master management========
import GSTMaster from "../pages/Masters/GSTMaster";
import Unitofmeasurement from "../pages/Masters/Unitofmeasurement";

import PurchaseList from "../pages/ProductMangement/StockManagement/PurchaseList";

import AddVendor from "../pages/Masters/AddVendor";


//===========Price Management start=============
import PriceList from "../pages/PriceManagement/PriceList";
import StockList from "../pages/StockManagement/StockList";

import ReduceStock from "../pages/ProductMangement/StockManagement/ReduceStock";

import AddPrice from '../pages/PriceManagement/SinglePriceManagement/AddPrice';
import SinglePriceList from '../pages/PriceManagement/SinglePriceManagement/SinglePriceManagementList';



// =======Company And Master========

import AddCompany from '../pages/CompanyMaster/AddCompany';
import CompanyList from '../pages/CompanyMaster/CompanyList';
import WarehouseMaster from '../pages/WarehouseMaster/AddWarehouse';
import WarehouseList from '../pages/WarehouseMaster/WarehouseList';
import WarehousePincodeMapping from '../pages/WarehouseMaster/WarehousePincodeMapping';


import AddImages from '../pages/ProductMangement/AddImages';

import AddSEOData from "../pages/SEOManagement/AddSEOData";

import SeoList from "../pages/SEOManagement/SeoList";

// pio master
import BoxMaster from "../pages/Masters/BoxMaster";

import Boardmaster from "../pages/Masters/BoardMaster";
import CoatingList from "../pages/Masters/CoatingList";

import AddCoating from "../pages/Masters/AddCoating";

import AddDesign from "../pages/Masters/AddDesign";
import DesignList from "../pages/Masters/DesignList";

import AddBoard from "../pages/Masters/AddBoard";

import AddCoatingPrice from "../pages/Masters/AddCoatingPrice";
import AddDesignImage from "../pages/Masters/AddDesignImage";
import AddPrintSheet from "../pages/Masters/AddPrintSheet";
import DesignImage from "../pages/Masters/DesignImage";
import MapVariantPrice from "../pages/Masters/MapVariantPrice";
import PriceCalculation from "../pages/Masters/PriceCalculation";
import PriceCalculationHeader from "../pages/Masters/PriceCalculationHeader";
import PrintingSheetMaster from "../pages/Masters/PrintingSheetMaster";
import ProductVariantPrice from "../pages/Masters/ProductVariantPrice";


//==Delivery Management==
import DeliveryManagement from "../pages/DeliveryManagement/DeliveryManagement";
import ViewDeliveryChalan from "../pages/DeliveryManagement/ViewDeliveryChalan";
import ViewInvoice from "../pages/OrderMangement/ViewInvoice";

import UploadKeyline from "../pages/Masters/UploadKeyline";
import AllReviews from "../pages/ReviewManagement/VIewAllReviews";

import addStaticPages from "../pages/StaticPages/staticPage";
import StaticPages from "../pages/StaticPages/StaticPageList";


//==sticker management
import AddMaterial from "../pages/Stickers/AddMaterial";
import AddStickerCalcHeader from "../pages/Stickers/AddStickerCalcHeader";
import MaterialMaster from "../pages/Stickers/MaterialMaster";
import StickerCalculationHeader from "../pages/Stickers/StickerCalHeader";

import StickerMaster from "../pages/Stickers/StickerMaster";
import StickerPriceCalculation from "../pages/Stickers/StickerPriceCalculation";
import StickerShapeMaster from "../pages/Stickers/StickerShapeMaster";
import StickerSizeMaster from "../pages/Stickers/StickerSizeMaster";

import StickerDiscountMaster from "../pages/Stickers/StickerDiscountMaster";
import StickerShippingMaster from "../pages/Stickers/StickerShippingMaster";

// Printing Sticker Master
import StickerPrintingMaster from "../pages/Stickers/StickerShippingPrintingMaster";


const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },
	//Crypto
	//profile
	{ path: "/profile", component: UserProfile },
	//chat
	{ path: "/chat", component: Chat },
	//calendar
	{ path: "/calendar", component: Calendar },
	//Ecommerce
	{ path: "/ecommerce-products", component: EcommerceProducts },
	{ path: "/ecommerce-product-detail", component: EcommerceProductDetail },
	{ path: "/ecommerce-orders", component: EcommerceOrders },
	{ path: "/ecommerce-customers", component: EcommerceCustomers },
	{ path: "/ecommerce-cart", component: EcommerceCart },
	{ path: "/ecommerce-checkout", component: EcommerceCheckout },
	{ path: "/ecommerce-shops", component: EcommerceShops },
	{ path: "/ecommerce-add-product", component: EcommerceAddProduct },

	//Email

	{ path: "/email-inbox", component: EmailInbox },
	{ path: "/email-read", component: EmailRead },


	{ path: "/invoices-detail", component: InvoiceDetail },

	// Contacts
	{ path: "/contacts-grid", component: ContactsGrid },
	{ path: "/contacts-list", component: ContactsList },
	{ path: "/contacts-profile", component: ContactsProfile },

	//Charts
	{ path: "/apex-charts", component: ChartApex },
	{ path: "/chartist-charts", component: ChartistChart },
	{ path: "/chartjs-charts", component: ChartjsChart },
	{ path: "/e-charts", component: EChart },
	{ path: "/sparkline-charts", component: SparklineChart },
	{ path: "/tui-charts", component: ToastUIChart },
	{ path: "/charts-knob", component: ChartsKnob },

	// Icons
	{ path: "/icons-boxicons", component: IconBoxicons },
	{ path: "/icons-dripicons", component: IconDripicons },
	{ path: "/icons-materialdesign", component: IconMaterialdesign },
	{ path: "/icons-fontawesome", component: IconFontawesome },

	// Maps
	{ path: "/maps-google", component: MapsGoogle },
	{ path: "/maps-vector", component: MapsVector },
	{ path: "/maps-leaflet", component: MapsLeaflet },

	// Forms
	{ path: "/form-elements", component: FormElements },
	{ path: "/form-advanced", component: FormAdvanced },
	{ path: "/form-editors", component: FormEditors },
	{ path: "/form-mask", component: FormMask },
	{ path: "/form-repeater", component: FormRepeater },
	{ path: "/form-uploads", component: FormUpload },
	{ path: "/form-wizard", component: FormWizard },
	{ path: "/form-validation", component: FormValidations },
	{ path: "/form-xeditable", component: FormXeditable },

	// Ui
	{ path: "/ui-alerts", component: UiAlert },
	{ path: "/ui-buttons", component: UiButtons },
	{ path: "/ui-cards", component: UiCards },
	{ path: "/ui-carousel", component: UiCarousel },
	{ path: "/ui-colors", component: UiColors },
	{ path: "/ui-dropdowns", component: UiDropdown },
	{ path: "/ui-general", component: UiGeneral },
	{ path: "/ui-grid", component: UiGrid },
	{ path: "/ui-images", component: UiImages },
	{ path: "/ui-lightbox", component: UiLightbox },
	{ path: "/ui-modals", component: UiModal },
	{ path: "/ui-progressbars", component: UiProgressbar },
	{ path: "/ui-sweet-alert", component: UiSweetAlert },
	{ path: "/ui-tabs-accordions", component: UiTabsAccordions },
	{ path: "/ui-typography", component: UiTypography },
	{ path: "/ui-video", component: UiVideo },
	{ path: "/ui-session-timeout", component: UiSessionTimeout },
	{ path: "/ui-rating", component: UiRating },
	{ path: "/ui-rangeslider", component: UiRangeSlider },
	{ path: "/ui-notifications", component: UiNotifications },
	{ path: "/ui-image-cropper", component: UiImageCropper },
	// Customer Management
	// { path: "/customer-managment", component: CutomerList },
	{ path: "/loyalty-PointReport", component: LoyaltyPointReport },
	// Order management
	{ path: "/dispatch_management", component: DispatchMangement },
	{ path: "/order_list", component: Orderlist },
	{ path: "/refund-management", component: RefundManagement },
	// RIder management
	{ path: "/add-rider", component: AddRider },
	{ path: "/rider-area-assignment", component: RiderAreaAssignment },
	{ path: "/rider-list", component: RiderList },
	// RIder Authentication
	{ path: "/rider-resigtration-form", component: RiderResigtrationForm },

	// MenuMaster
	{ path: "/main-menu-master", component: MainMenuMaster },
	{ path: "/sub-menu-master", component: SubMenuMaster },
	{ path: "/child-menu-master", component: ChildMenuMaster },
	// Campany Master
	{ path: "/company-master", component: CompanyMaster },
	{ path: "/brand-master/:action", component: BrandMaster },
	{ path: "/product-master", component: ProductMaster },

	// Product management
	{ path: "/category-management", component: Categorymanagementlist },
	{ path: "/add-category/:action", component: categoryManagement },
	{ path: "/price-mangement", component: PricemMangement },
	{ path: "/sub-category-management", component: SubCategoryManagement },
	{ path: "/category-type-master", component: categoryManagement },
	{ path: "/category-mapping-master", component: CategoryMappingMaster },
	{ path: "/add-sub-category/:action", component: Addsubcategory },
	{ path: "/item-management", component: ItemManagementList },
	{ path: "/add_item", component: AddItemMAnagement },
	{ path: "/edit-raw-material", component: UpdateRawMaterial },
	{ path: "/variant-management", component: VariantManagement },

	//  =====Rider Management=========
	{ path: "/rider_management", component: RiderList },
	{ path: "/add_rider", component: AddRider },
	{ path: "/rider_area", component: RiderAreaAssignment },
	{ path: "/add_riderarea", component: AddRiderArea },
	{ path: "/edit_rider", component: UpdateRider },

	// add_riderarea

	// =====User Management========
	{ path: "/user-management", component: UserList },
	{ path: "/add-user", component: AddUser },

	// ====Raw Material=========
	{ path: "/raw-material", component: RawmaterialList },
	{ path: "/add-raw-material", component: AddRawMaterial },

	{ path: "/career-management", component: CareerList },
	{ path: "/edit-career", component: EditCareer },

	{ path: "/add-career", component: Add_Career },
	{ path: "/edit-user", component: Edituser },

	{ path: "/add-attribute-value", component: AddAttributeDetail },

	{ path: "/attribute", component: AddAttributeList },

	{ path: "/attribute-value", component: AddAttributeValueList },
	{ path: "/add-attribute", component: AddAttributeMaster },

	{ path: "/attribute-master", component: AttributeMasterList },
	{ path: "/add-attribute-master", component: Addattributemaster },

	{ path: "/edit-attribute-mapping", component: UpdateAttributeMapping },

	{ path: "/vertical-master", component: Verticalmaster },
	{ path: "/vertical-mapping", component: VerticalMapping },
	{ path: "/add-vertical-mapping", component: AddVerticalMapping },

	// this route should be at the end of all other routes
	// =====	Customer Managment ====
	{ path: "/add-customer", component: AddCustomer },
	{ path: "/edit-customer", component: EditCustomer },
	{ path: "/customer-management", component: customerList },

	// ========Review==========

	// =====User Management========
	{ path: "/review-list", component: ReviewList },
	{ path: "/add-review", component: AddReview },
	{ path: "/edit-review", component: EditReview },
	{ path: "/pickup-management", component: PickupList },

	{ path: "/offer-list", component: OfferList },
	{ path: "/add-offer", component: AddOffer },
	{ path: "/edit-offer", component: EditOffer },
	{ path: "/edit-item", component: Edititem },

	{ path: "/product-pincode-mapping", component: ProductPinCode },


	// ============08/06/2021========
	{ path: "/brand_list", component: BrandManagementList },
	{ path: "/view_order", component: ViewOrder },
	{ path: "/customer_profile", component: ViewCustomer },


	{ path: "/stock_management", component: StockManagement },
	{ path: "/stocks", component: AddStock },

	//=================Banner Management================////
	{ path: "/banner_list", component: BannerList },
	{ path: "/add_banner", component: AddNewBanner },
	{ path: "/home", component: Home },
	{ path: "/newpage", component: NewPage },

	// ========
	{ path: "/view_notification", component: NotificationList },
	{ path: "/view_invoice", component: InvoiceForm },

	{ path: "/banner_master", component: BannerMaster },


	{ path: "/logistics_master", component: LogisticsMaster },

	{ path: "/self_delivery_master", component: SelfDeliveryMaster },

	{ path: "/view_dispatch", component: DispatchForm },
	{ path: "/download_order_form/:orderId", component: DownloadOrderForm },
	//==========================SideBar=====================
	{ path: "/sidebar", component: SideBar },
	{ path: "/account_profile", component: Profile },
	{ path: "/add_address", component: AddAddress },
	{ path: "/addressbook_form", component: AddressBookForm },
	{ path: "/order_history", component: OrderHistory },
	{ path: "/change_password", component: ChangePassword },

	//===========Company Master=======================
	{ path: "/help_center", component: HelpCenter },
	{ path: "/submit_request", component: SubmitRequest },
	{ path: "/menubar", component: MenuBar },
	// { path: "/navbar", component: Navbar},
	{ path: "/routes", component: Routes },

	{ path: "/addnewvariant/:productid", component: AddNewVariant },
	{ path: "/addnewvariant", component: AddNewVariant },

	{ path: "/invoices-list", component: invoiceList },


	// =============Master============
	{ path: "/gst_master", component: GSTMaster },
	{ path: "/unit_measurement_master", component: Unitofmeasurement },



	{ path: "/addpurchaseorder", component: PurchaseList },

	{ path: "/addvendor", component: AddVendor },


	//=====Price Management=============
	{ path: "/uploadprice", component: PriceList },
	{ path: "/uploadstock", component: StockList },
	{ path: "/reducestock", component: ReduceStock },

	{ path: "/price_management", component: SinglePriceList },

	{ path: "/addPrice", component: AddPrice },



	// =====Master=======
	{ path: "/add_company", component: AddCompany },
	{ path: "/vendor_master", component: CompanyList },
	//==========Warehouse Master==========
	{ path: "/add_warehouse", component: WarehouseMaster },
	{ path: "/warehouse_master", component: WarehouseList },
	{ path: "/warehouse_pincode", component: WarehousePincodeMapping },
	{ path: "/addimages", component: AddImages },
	{ path: "/seo_information/:action/:id", component: AddSEOData },
	{ path: "/seo_management", component: SeoList },



	//piomaster
	{ path: "/board_master", component: Boardmaster },
	{ path: "/boxsize_master", component: BoxMaster },
	{ path: "/coating_master", component: CoatingList },
	{ path: "/add_coating/:action", component: AddCoating },
	{ path: "/design_master", component: DesignList },
	{ path: "/add_design/:action", component: AddDesign },
	{ path: "/add_board/:action", component: AddBoard },
	{ path: "/printing_sheet_master", component: PrintingSheetMaster },
	{ path: "/add_printing_sheet/:action", component: AddPrintSheet },
	{ path: "/costing_price_master", component: PriceCalculationHeader },
	{ path: "/add_coating_price/:action", component: AddCoatingPrice },
	{ path: "/product_variant_price_mapping", component: ProductVariantPrice },
	{ path: "/add_product_variant_price/:action", component: MapVariantPrice },
	{ path: "/design_image", component: DesignImage },
	{ path: "/add_designimage/:action", component: AddDesignImage },
	{ path: "/price_calculation", component: PriceCalculation },



	{ path: "/delivery-management", component: DeliveryManagement },
	{ path: "/view-delivery-chalan/:dispatchid", component: ViewDeliveryChalan },
	{ path: "/view-invoice/:orderid", component: ViewInvoice },
	{ path: "/upload_keyline", component: UploadKeyline },
	{ path: "/viewallreviews", component: AllReviews },


	{ path: "/static_pages", component: StaticPages },
	{ path: "/add_static_pages", component: addStaticPages },

	//==stickers
	{ path: "/stickercalcheader", component: StickerCalculationHeader },
	{ path: "/add_sticker_calc_header/:action", component: AddStickerCalcHeader },
	{ path: "/materialmaster", component: MaterialMaster },
	{ path: "/addmaterial/:action", component: AddMaterial },
	{ path: "/stickershapemaster", component: StickerShapeMaster },
	{ path: "/stickersizemaster/:shapeIdProps", component: StickerSizeMaster },
	{ path: "/stickerpricecalculation", component: StickerPriceCalculation },
	{ path: "/stickerdiscountmaster", component: StickerDiscountMaster },
	{ path: "/stickershippingmaster", component: StickerShippingMaster },
	{ path: "/stickermaster/:shapeIdProps", component: StickerMaster },
	{ path: "/downloadorderform/:orderId", component: DownloadOrderForm }, 

	// Sticker Printing
	{ path: "/shippingprintingmaster", component: StickerPrintingMaster },






];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/change-password-screen", component: ChangePasswordPage },
	{ path: "/register", component: Register },

	{ path: "/pages-maintenance", component: PagesMaintenance },
	{ path: "/pages-comingsoon", component: PagesComingsoon },
	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },
	{ path: "/crypto-ico-landing", component: CryptoIcoLanding },

	// Authentication Inner
	{ path: "/pages-login", component: Login }, //Login1
	{ path: "/pages-register", component: Register }, //Register1
	{ path: "/pages-forgot-pwd", component: ForgetPwd }, //Forgetpwd1
	{ path: "/auth-lock-screen", component: LockScreen }, //LockScreen
];

export { authProtectedRoutes, publicRoutes };
