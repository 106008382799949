import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
// layouts

import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Login from "./pages/Authentication/Login";
// Import scss
import "./assets/scss/theme.scss";

// import "./assets/scss/Notificatio.scss";
import fakeBackend from './helpers/AuthType/fakeBackend';
import PrivateRoute from './routes/PrivateRoute'
import ManageCustomerAPI from "../src/ManageCustomerAPI";
import queryString from 'query-string';



// Activating fake backend
fakeBackend();



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getLayout = this.getLayout.bind(this);
    }


    /**
     * Returns the layout
     */
    getLayout = () => {
        let layoutCls = HorizontalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = HorizontalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <Router>
                    <Switch>

                        <AppRoute
                            path="/"
                            exact={true}
                            layout={NonAuthLayout}
                            component={Login}
                        />
                        {publicRoutes.map((item) => {
                            return (
                                <AppRoute
                                    path={item.path}
                                    layout={NonAuthLayout}
                                    component={item.component}
                                />
                            )
                        })}

                        {
                            authProtectedRoutes.map((item) => {
                                return (
                                    <PrivateRoute
                                        path={item.path}
                                        layout={Layout}
                                        component={item.component}
                                    />
                                )
                            })}

                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
