import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
	Card,
	CardBody,
	CardTitle, Col, Container,
	Row
} from "reactstrap";
// import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
// import {XSquare} from 'react-feather';
import photoicon from '../../assets/images/dashboardimages/photo.png';
import addicon from '../../assets/images/dashboardimages/product.png';

import Select from 'react-select';


import "react-confirm-alert/src/react-confirm-alert.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Editicon from '../../assets/images/dashboardimages/pencil.png';


class AddVariant extends Component {
	constructor(props) {
		super(props);
		const { productid } = props.match.params;
		if (productid === "undefined" || productid === undefined) {
			localStorage.removeItem("ProductID")
		}
		this.state = {
			enabled: false,
			startDate: new Date(),
			endDate: new Date(),
			imageValue: "",
			isImageValid: null,
			Photos: [],



			CategoryName: "",
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: "",
			AttributData: [],
			ItemName: [],
			HSNCode: "",
			GSTRate: "",
			GSTData: [
				{ value: "5", label: "5" },
				{ value: "10", label: "10" },
				{ value: "12", label: "12" },
				{ value: "15", label: "15" },
				{ value: "18", label: "18" },
			],



			UOM: "",
			Status: "Yes",
			SKU: "",
			Barcode: "",
			VariantName: "",
			ShortDescription: "",
			LongDescription: "",
			AttributeValue: [],
			StockInHand: 0,
			CostPrice: 0,
			MRP: 0,
			SellingPrice: 0,
			Websitediscountprice: 0,
			ItemStatus: "Active",
			ProductID: productid,
			PRoductData: [],
			SaveVariantData: [],
			FormStatus: "",
			fields: "",
			finaldata: "",
			name: "",
			groupArray: [],
			AttributeMappingId: null,
			VariantimageId: null,
			VariantPriceId: null,
			VariantID: null,
			Variantsdata: "",
			ItemData: [],
			VariantIdForImage: null,
			VariantDataForimage: [],
			ShippingCharge: 0,
			ImageUrl: ""

		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		localStorage.removeItem("VariantImageID")
		localStorage.removeItem("VariantIdForImage")

		Notiflix.Loading.Dots("Please wait...");



		// //console.log(localStorage.getItem('VariantDetails'))

		GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					ItemData: obj.data,
				});
			})
		);


		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{

				whereClause: `where fld_productid=${this.state.ProductID}`,
				recordCount: '*'
			},
			"GetVariantData"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {

				if (results.status == 200 || results.status == 201) {
					// console.log(obj.data)

					this.setState({
						VariantData: obj.data,
						ViewVariantList: true,
						ViewProductList: false

					});
				}


				Notiflix.Loading.Remove();
			}
			))


		PostApiCall.postRequest(
			{
				whereClause: `where fld_variantid=${this.state.ProductID}`
			},
			"Get_VariantImage"
		).then((results8) =>
			results8.json().then((obj8) => {
				if (
					results8.status == 200 ||
					results8.status == 201
				) {
					// console.log(obj8.data)

					if (obj8.data != null) {
						this.setState({
							Photos: obj8.data,


						});
					}


				}
			}))

		if (this.state.ProductID != null) {
			Notiflix.Loading.Dots('Please wait...');
			PostApiCall.postRequest(
				{

					whereClause: `where fld_productid=${this.state.ProductID}`,
					recordCount: '*'
				},
				"GetVariantData"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						//console.log(obj.data[0].fld_variantid)
						this.setState({
							SaveVariantData: obj.data

						}
						);
						Notiflix.Loading.Remove();
					}
				}))


		}
	}


	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.SubCategory,
					},
					"Get_Category_Attribute_value"
				).then((results2) =>
					// const objs = JSON.parse(result._bodyText)
					results2.json().then((obj2) => {
						// console.log(obj2.data);
						if (results2.status == 200 || results2.status == 201) {
							this.setState({
								AttributData: obj2.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}










	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};



	onSaveVariant() {
		// //console.log("value"+ProductID)
		if (this.state.ItemName.length != 0) {
			if (this.state.VariantName != "") {
				if (this.state.ShortDescription != "") {
					if (this.state.ShortDescription.split(" ").length <= 300) {



						if (this.state.imageCount != 0) {

							this.onPostVariantData();


						} else {
							Notiflix.Notify.Failure(
								"Please upload atleast one product image."
							);
						}
					} else {
						Notiflix.Notify.Failure(
							"Please enter short description with less than 300 characters."
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter ShortDescription");
				}
			} else {
				Notiflix.Notify.Failure("Please enter variant name");
			}
		}
		else {
			Notiflix.Notify.Failure("Please select item name");
		}
	}


	onPostVariantData = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				productid: this.state.ProductID,
				varientid: this.state.VariantID,
				sku: this.state.SKU,
				barcode: this.state.Barcode,
				longdescripiton: this.state.LongDescription,
				variantname: this.state.VariantName,
				shortdescripiton: this.state.ShortDescription,
				shipping: this.state.ShippingCharge,
				stockInHand: this.state.StockInHand,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVarient"
		).then((resultcategory1) =>
			resultcategory1.json().then((obj1) => {
				if (resultcategory1.status == 200 || resultcategory1.status == 201) {
					if (this.state.VariantID == null) {
						localStorage.setItem("VariantID", JSON.stringify(obj1.data))
						localStorage.setItem("ProductID", JSON.stringify(this.state.ProductID))
						this.onPostPrice(obj1);
					}
					else {
						this.onPostPrice(obj1);
					}

				} else {
					Notiflix.Loading.Remove();

					Notiflix.Notify.Failure(obj1.data);
				}
			})
		);
	};

	onPostPrice(obj1) {
		// //console.log(JSON.parse(JSON.stringify(obj1.data[0])).VariantId);
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				variantpriceid: this.state.VariantPriceId,
				variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
				mrp: this.state.MRP,
				costPrice: this.state.CostPrice,
				sellingPrice: this.state.SellingPrice,
				websiteDiscountPrice: this.state.Websitediscountprice,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVariantPrice"
		).then((resultcategory2) =>
			resultcategory2.json().then((obj2) => {
				if (resultcategory2.status == 200 || resultcategory2.status == 201) {

					this.onPostAttribute(obj1)

				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj2.data);
				}
			})
		);
	}
	onPostAttribute(obj1) {
		//console.log('attribute')

		Notiflix.Loading.Dots('Please wait...');

		var login = localStorage.getItem('LoginDetail');
		var details = JSON.parse(login)
		var addattribute = ''
		for (var i = 0; i < this.state.AttributeValue.length; i++) {

			if (i == 0) {
				addattribute = this.state.AttributeValue[i].value
			} else {
				addattribute = addattribute + ', ' + this.state.AttributeValue[i].value
			}

		}

		PostApiCall.postRequest({
			attributemappingid: this.state.AttributeMappingId,
			productid: this.state.ProductID,
			variantid: (JSON.parse(JSON.stringify(obj1.data[0]))).VariantId,
			attributeValue: addattribute,
			createdOn: moment().format("YYYY-MM-DD"),
			updatedOn: moment().format("YYYY-MM-DD"),
			updatedBy: details[0].fld_userid

		}, "AddAttributeMasterMapping").then((resultcategory3 =>
			resultcategory3.json().then(obj3 => {
				if (resultcategory3.status == 200 || resultcategory3.status == 201) {
					// console.log("obj3 " + obj3)
					//this.onPostUploadImage(obj1);
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Product Details Successfully Added");
					window.location.reload();
				} else {
					//this.onPostUploadImage(obj1);
				}
			})
		))

	}


	// onPostUploadImage(obj1) {


	// 	var login = localStorage.getItem("LoginDetail");
	// 	var details = JSON.parse(login);

	// 	var empty = 0;

	// 	var count = 0;

	// 	for (var i = 0; i < this.state.Photos.length; i++) {


	// 		if (this.state.Photos[i].value != "" && this.state.Photos[i].data != "") {
	// 			const form1 = new FormData();



	// 			form1.append("file", this.state.Photos[i].data);
	// 			form1.append("foldername", "ProductImages");
	// 			form1.append(
	// 				"filename", moment().format('sshhmm') + i +
	// 			this.state.Photos[i].data.name
	// 			);
	// 			fetch(this.state.ImageApiUrl, {
	// 				method: "POST",
	// 				body: form1,
	// 				// eslint-disable-next-line no-loop-func
	// 			}).then((image1) => {
	// 				image1
	// 					.json()
	// 					.then((data1) => ({
	// 						data: data1,
	// 						status: image1.status,
	// 					}))
	// 					.then((res1) => {
	// 						PostApiCall.postRequest(
	// 							{
	// 								variantimageid: this.varientImageId,
	// 								variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
	// 								imageurl:
	// 									JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
	// 									res1.data.Message.split(",")[2].split("=")[1].trim(),
	// 								createdOn: moment().format("YYYY-MM-DD"),
	// 								updatedOn: moment().format("YYYY-MM-DD"),
	// 								updatedBy: details[0].fld_userid,
	// 							},
	// 							"AddVariantImage"
	// 						).then((results2) =>
	// 							// const objs = JSON.parse(result._bodyText)
	// 							results2.json().then((obj2) => {
	// 								if (results2.status == 200 || results2.status == 201) {
	// 									count = count + 1;

	// 									if (count == this.state.Photos.length) {
	// 										//Notiflix.Loading.Remove();
	// 										Notiflix.Notify.Success("Product Details Saved");
	// 										window.location.reload();
	// 									}
	// 								}
	// 							})
	// 						);
	// 					});
	// 			});
	// 		} else if (
	// 			this.state.Photos[i].value != "" &&
	// 			this.state.Photos[i].data == ""
	// 		) {

	// 			this.varientImageId1 = this.state.Photos[i].imageId;
	// 			PostApiCall.postRequest(
	// 				{
	// 					variantimageid: this.varientImageId1,
	// 					variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
	// 					imageurl: this.state.Photos[i].value,
	// 					createdOn: moment().format("YYYY-MM-DD"),
	// 					updatedOn: moment().format("YYYY-MM-DD"),
	// 					updatedBy: details[0].fld_userid,
	// 				},
	// 				"AddVariantImage"
	// 			).then((results2) =>
	// 				// const objs = JSON.parse(result._bodyText)
	// 				results2.json().then((obj2) => {
	// 					if (results2.status == 200 || results2.status == 201) {
	// 						count = count + 1;
	// 						empty = empty + 1;
	// 						if (count == this.state.Photos.length) {
	// 							Notiflix.Loading.Remove();

	// 							Notiflix.Notify.Success('Product variant added successfully.')

	// 							window.location.reload();
	// 						}
	// 					}
	// 				})
	// 			);
	// 		} else {
	// 			//console.log("id");
	// 			count = count + 1;

	// 			if (count == this.state.Photos.length) {
	// 				Notiflix.Loading.Remove();

	// 				Notiflix.Notify.Success('Product variant updated successfully.')
	// 				window.location.reload();
	// 			}
	// 		}
	// 	}
	// }

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onPostGetAttribute = () => {
		// //console.log(ProductID)
		// var Productid = ''
		// for (var i = 0; i < this.state.ItemName.length; i++) {
		// 	if (i == 0) {
		// 		Productid = this.state.ItemName[i].value
		// 	}

		// }


		PostApiCall.postRequest(
			{
				id: this.state.ProductID,
				sfor: 'ProductID'
			},
			"GetCategoryAttributeValue"
		).then((results2) =>
			// const objs = JSON.parse(result._bodyText)
			results2.json().then((obj2) => {
				if (results2.status == 200 || results2.status == 201) {
					//console.log(obj2.data)
					this.setState({
						AttributData: obj2.data,
					});
				}
			})
		);








	}







	render() {




		const settings = {
			dots: false,
			infinite: true,
			arrows: false,
			speed: 800,
			autoplay: true,
			slidesToShow: 4,
			slidesToScroll: 1,
		};

		const variantImages = {
			dots: false,
			infinite: true,
			arrows: false,
			speed: 800,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		let temp = [];

		this.state.AttributData.map((data, i) => {
			temp.push(data.fld_attributename);
		});
		temp = [...new Set(temp)];
		let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");

		let KeysArray = Object.keys(groupTemp);
		let ValuesArray = Object.values(groupTemp);


		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Variant List</a></li>
										<li>Add New Variant</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								{/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

							</div>
						</div>



						<Row>
							<Col xl="12">
								<Card className="overflow-hidden" >
									<div
										className="Bechofy-bg-soft-pink"

									>
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Variant List{" "}
													</h5>
												</div>
											</Col>
											<Col xs="9">
												<button
													onClick={() => {
														window.location.href = "/add_item";
													}}

													className="btn align-items-center btn Bechofy-btn AddNewBtn"
												>
													Add New Item{" "}
													<span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

												</button>


											</Col>
										</Row>
									</div>

									<Row>
										{this.state.SaveVariantData.length !== 0 ? (
											this.state.SaveVariantData.map((data, index) => (
												<Col xs="4">
													<CardBody className="categoryCard">
														<CardTitle className="mb-4">
															<h4>{data.fld_itemname} - {data.fld_variantname}</h4>
														</CardTitle>
														<Row className="card-row">
															<Col sm="6">
																<div>
																	<Slider {...variantImages}>
																		{data.VariantImage?.split('#').map((img, i) => (
																			<div class="best-products">
																				<div class="img-container" width="50%">
																					<img src={img} alt="product image"
																						onClick={() => {
																							localStorage.setItem("VariantImageID", JSON.stringify(data.fld_variantimageid));
																							window.location.href = '/addimages'
																						}}
																					></img>


																				</div>
																			</div>
																		)

																		)}







																	</Slider>

																</div>

															</Col>
															<hr />
														</Row>
														<Row>
															<Col sm="6">
																<p className="categorystatus">
																	<b>Status</b> -{" "}
																	<span
																		style={{
																			color:
																				data.fld_status == "Active"
																					? "green"
																					: "red",
																		}}
																	>
																		{data.fld_status}
																	</span>
																</p>
															</Col>
															<Col sm="6">


																<span className=""><img src={Editicon} alt="block user" className="btnicons waves-effect waves-light" style={{ marginRight: '5px', marginTop: '15px', width: '30px', float: 'right' }}
																	onClick={() => {
																		var data = [
																			...this.state.SaveVariantData,
																		];

																		this.setState({
																			SKU: data[index].fld_sku,
																			Barcode: data[index].fld_barcode,
																			VariantName:
																				data[index].fld_variantname,
																			StockInHand:
																				data[index].fld_stock_in_hand,
																			Websitediscountprice:
																				data[index]
																					.fld_website_discount_price,
																			SellingPrice:
																				data[index].fld_selling_price,
																			CostPrice:
																				data[index].fld_cost_price,
																			MRP: data[index].fld_mrp,
																			VariantID:
																				data[index].fld_variantid,
																			AttributeMappingId:
																				data[index]
																					.fld_attributemappingid,
																			VariantimageId:
																				data[index].fld_variantimageid,
																			VariantPriceId:
																				data[index].fld_variantpriceid,

																		});

																		new Promise((resolve, reject) => {
																			setTimeout(resolve, 1000);
																		}).then(() => {
																			this.setState({
																				LongDescription:
																					data[index]
																						.fld_long_description,
																				ShortDescription:
																					data[index]
																						.fld_short_description,
																			});

																		}

																		);
																		// var GetProductID = []
																		// fld_productid
																		var sp = []

																		sp.push({
																			label: data[index].fld_itemname,
																			value: data[index].fld_productid
																		})


																		this.setState({
																			ItemName: sp
																		})
																		//console.log(data[index].fld_productid)
																		PostApiCall.postRequest(
																			{
																				id: data[index].fld_productid,
																				sfor: null
																			},
																			"GetCategoryAttributeValue"
																		).then((results2) =>
																			// const objs = JSON.parse(result._bodyText)
																			results2.json().then((obj2) => {
																				if (results2.status == 200 || results2.status == 201) {
																					// //console.log(obj2.data)
																					this.setState({
																						AttributData: obj2.data,
																					});
																				}
																			})
																		);





																	}}
																></img> </span>

																<span className=""><img src={photoicon} alt="block user" className="btnicons waves-effect waves-light" style={{ marginRight: '5px', marginTop: '15px', width: '30px', float: 'right' }}
																	onClick={() => {
																		localStorage.setItem('VariantIdForImage', JSON.stringify(data))
																		window.location.href = '/addimages'
																	}}></img> </span>
															</Col>
														</Row>
													</CardBody>
												</Col>
											))
										) : (
											<p></p>
										)}
									</Row>
								</Card>

								<Card className="overflow-hidden" id="AddNewItem">
									<div
										className="Bechofy-bg-soft-pink"

									>
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Variant Information
													</h5>
												</div>
											</Col>

										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">

												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Name
																						<span className="mandatory">*</span>
																					</label>
																					<Select
																						options={this.state.ItemData}


																						value={this.state.ItemName}
																						onChange={(value) => {
																							Notiflix.Loading.Dots('Please wait...');
																							PostApiCall.postRequest(
																								{

																									whereClause: `where fld_productid=${value.value}`,
																									recordCount: '*'
																								},
																								"GetVariantData"
																							).then((results) =>
																								// const objs = JSON.parse(result._bodyText)
																								results.json().then((obj) => {
																									if (results.status == 200 || results.status == 201) {
																										//console.log(obj.data[0].fld_variantid)
																										this.setState({
																											SaveVariantData: obj.data

																										}
																										);
																										Notiflix.Loading.Remove();
																									}
																								}))

																							this.setState({
																								ItemName: value,
																								ProductID: value.value
																							})
																						}}





																						isSearchable={true}
																						isClearable={true} />
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Variant Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.VariantName}
																						onChange={(text) => {
																							this.setState({
																								VariantName: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Short Description(maximum 300
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={this.state.ShortDescription}
																							onChange={(text) => {
																								this.setState({
																									ShortDescription:
																										text.editor.getData(),
																								});
																							}}
																						//
																						/>
																					</div>
																				</div>
																			</div>


																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>







											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-4">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-8">

																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveVariant.bind(this)}
																	>
																		Save & Add More
																	</button>


																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																			marginRight: '5px',
																			display: this.state.VariantDataForimage.length == 0 ? 'none' : 'block'
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={() => {
																			localStorage.setItem('VariantIdForImage', JSON.stringify(this.state.VariantDataForimage))
																			window.location.href = '/addimages'
																		}}
																	>
																		<img src={photoicon} alt="block user" className="btnicons" style={{ marginLeft: '5px', width: '20px', float: 'right' }}
																		></img>
																		Add Image
																	</button>


																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>




											{/* <WelcomeComp /> */}
										</Col>
									</Row>
									{/* -row */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddVariant;
