import React, {Component, useState} from 'react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import {MenuList} from "./MenuList"


const Navbar = () => {
    const [clicked, setClicked] = useState(false);
    const menuList = MenuList.map(({ url, title }, index) =>{
        return(
            <li key={index}>
                <NavLink exact to={url} activeClassName="active">{title}</NavLink>
            </li>
        )
    })
    const handleClick = () =>{
        setClicked(!clicked);
    };

    return(
        <nav>
            <div className="logo">
                Bechofy
            </div>
            <div className="menu-icon" onClick={handleClick}>
                <i className={ clicked ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
            <ul className={clicked ? "menu-list" : "menu-list-close"}>{menuList}</ul>
        </nav>
    )
}

export default Navbar;