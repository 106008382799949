import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import profileImg from "../../assets/images/profile-img.png";
import Notiflix from "notiflix";
import NotificationIcon from '../../assets/images/dashboardimages/notification.png'
import ChngPswdIcon from '../../assets/images/dashboardimages/change-password.png'
import LogoutIcon from '../../assets/images/dashboardimages/logout.png'
import UserIcon from '../../assets/images/dashboardimages/profile.png'
import { Modal } from "react-responsive-modal";
import PostApiCall from "../../Api";
import moment from "moment"

class WelcomeComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Details: [],
            Logintime: '',
            open: false,
            OpenChangePasswordModal: false,
            Email: "",
            Password: "",
            ConfirmNewPassword: "",
            NewPassword: ""
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Custom("");


        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        // //console.log(details)


        var lastlogin = localStorage.getItem('lastlogin');
        var LastDateTime = JSON.parse(lastlogin)

        // //console.log(LastDateTime)
        this.setState({
            Details: details[0],
            Logintime: LastDateTime,
            Email: details[0].fld_email
        })

    }



    render() {
        // //console.log(this.state.Details.fld_name)
        return (
            <React.Fragment>
                <Modal
                    open={this.state.open}
                    onClose={() => {
                        this.setState({ open: false });
                    }}
                    center
                >
                    <div class="modal-content modelcontent2">
                        <div
                            class="modal-header Bechofy-bg-soft-pink"
                            style={{ background: "#777f80" }}
                        >
                            <h4 class="modal-title text-white">
                                Change Password <p style={{ fontSize: "12px", fontWeight: "300" }}>( Verify yourself to change the password )</p>
                            </h4>

                        </div>
                        <div class="modal-body">

                            <div className="col-12">
                                <div className="form-group">
                                    <label for="CategoryName">Email<span className="mandatory">
                                        *
                                    </span></label>
                                    <input type="text" id="CategoryName" className="form-control"
                                        disabled
                                        style={{ backgroundColor: "#e9ecef" }}
                                        value={this.state.Email}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="CategoryName">Password<span className="mandatory">
                                        *
                                    </span></label>
                                    <input type="password" id="CategoryName" className="form-control"
                                        value={this.state.Password}
                                        onChange={(password) => {
                                            this.setState({
                                                Password: password.target.value
                                            })

                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button
                                className="btn align-items-center Bechofy-btn"
                                type="submit"
                                style={{ float: "right" }}
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                        Status: "Active",
                                    });
                                    window.location.reload();
                                }}
                            >
                                Close
                            </button>

                            <button
                                style={{ float: "right" }}
                                className="btn align-items-center Bechofy-btn "
                                onClick={() => {

                                    if (this.state.Email != "") {
                                        if (this.state.Password != "") {

                                            PostApiCall.postRequest(
                                                {
                                                    email: this.state.Email,
                                                    password: this.state.Password,
                                                    action: "Login",
                                                    actiondate: moment().format("lll"),

                                                },
                                                "AuthenticateUser"
                                            ).then((results) =>
                                                // const objs = JSON.parse(result._bodyText)
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        this.setState({
                                                            open: false,
                                                            OpenChangePasswordModal: true
                                                        })
                                                    } else {
                                                        Notiflix.Notify.Failure(obj.data);
                                                    }
                                                })
                                            );

                                        } else {
                                            Notiflix.Notify.Failure("Please enter the password")
                                        }

                                    } else {
                                        Notiflix.Notify.Failure("Email can not be empty")
                                    }
                                }}
                            >
                                Verify
                            </button>
                            <span></span>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={this.state.OpenChangePasswordModal}
                    onClose={() => {
                        this.setState({ OpenChangePasswordModal: false });
                    }}
                    center
                >
                    <div class="modal-content modelcontent2">
                        <div
                            class="modal-header Bechofy-bg-soft-pink"
                            style={{ background: "#777f80" }}
                        >
                            <h4 class="modal-title text-white">
                                Change Password
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="CategoryName">New Password<span className="mandatory">
                                        *
                                    </span></label>
                                    <input type="password" id="CategoryName" className="form-control"
                                        value={this.state.NewPassword}
                                        onChange={(password) => {
                                            this.setState({
                                                NewPassword: password.target.value
                                            })

                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="CategoryName">Confirm New Password<span className="mandatory">
                                        *
                                    </span></label>
                                    <input type="password" id="CategoryName" className="form-control"
                                        value={this.state.ConfirmNewPassword}
                                        onChange={(password) => {
                                            this.setState({
                                                ConfirmNewPassword: password.target.value
                                            })

                                        }}
                                    />
                                </div>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button
                                className="btn align-items-center Bechofy-btn"
                                type="submit"
                                style={{ float: "right" }}
                                onClick={() => {
                                    this.setState({
                                        OpenChangePasswordModal: false,

                                    });

                                }}
                            >
                                Close
                            </button>

                            <button
                                style={{ float: "right" }}
                                className="btn align-items-center Bechofy-btn "
                                onClick={() => {
                                    if (this.state.NewPassword != "") {
                                        if (this.state.ConfirmNewPassword != "") {
                                            if (this.state.NewPassword == this.state.ConfirmNewPassword) {


                                                Notiflix.Loading.Dots('');
                                                var login = localStorage.getItem('LoginDetail');
                                                var details = JSON.parse(login)

                                                PostApiCall.postRequest({

                                                    userid: details[0].fld_userid,
                                                    password: this.state.ConfirmNewPassword,
                                                    salt: details[0].fld_salt,
                                                    updatedon: moment().format('lll'),
                                                    updatedby: details[0].fld_userid

                                                }, "ChangePassword").then((results) =>

                                                    // const objs = JSON.parse(result._bodyText)
                                                    results.json().then(obj => {


                                                        if (results.status == 200 || results.status == 201) {
                                                            Notiflix.Loading.Remove()
                                                            Notiflix.Notify.Success('Password Successfully updated')
                                                            localStorage.removeItem('LoginDetail')
                                                            window.location.href = '/'

                                                        }
                                                        else {
                                                            Notiflix.Loading.Remove()
                                                            Notiflix.Notify.Failure('something went wrong, try again later')
                                                        }
                                                    }
                                                    )
                                                )

                                            } else {
                                                Notiflix.Notify.Failure("New Password and Confirm New Password does not match")
                                            }

                                        } else {
                                            Notiflix.Notify.Failure("Confirm New Password can not be empty")
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("New Password can not be empty")
                                    }
                                }}
                            >
                                Change Password
                            </button>
                            <span></span>
                        </div>
                    </div>
                </Modal>

                <Card className="overflow-hidden" style={{ height: '340px' }}>
                    <div className="Bechofy-bg-soft-pink">
                        <Row>
                            <Col xs="7">
                                <div className="Bechofy-text p-3">
                                    <h5 className="Bechofy-text">Welcome {this.state.Details.fld_name} !</h5>
                                    <p>{this.state.Details.fld_designation}</p>

                                </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                                <img src={profileImg} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                    <CardBody className="pt-0">
                        <Row>
                            <Col sm="6">
                                <div className="avatar-md profile-user-wid mb-4 user_profile" >
                                    <img src={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_storelogo} alt="" className="img-thumbnail rounded-circle" style={{ height: '100px' }} />
                                </div>
                                {/* <h5 className="font-size-15 text-truncate">{this.state.Details.fld_name}</h5> */}
                            </Col>

                            <Col sm="6">
                                <div className="pt-4">
                                    <Row>
                                        <Col xs="12">
                                            <h5 className="font-size-15">Last Login</h5>
                                            <p className="text-muted mb-0"> {this.state.Logintime}</p>
                                        </Col>

                                    </Row>
                                    {/* <div className="mt-4">
                                        <Link to="" className="btn Bechofy-btn waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ml-1"></i></Link>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardBody className="pt-0">

                        <Row>
                            <Col xs="3" className="ProfilesIcon">
                                <img src={UserIcon} data-toggle="tooltip" data-placement="top" title="Profile" alt="profile" ></img>
                                <p>Profile</p>
                            </Col>
                            <Col xs="3" className="ProfilesIcon">

                                <img src={NotificationIcon} data-toggle="tooltip" data-placement="top" title="Notifications" alt="notification" ></img>
                                <p>Notifications</p>
                            </Col>
                            <Col xs="4" className="ProfilesIcon">
                                <a onClick={() => {
                                    this.setState({
                                        open: true
                                    })
                                }} style={{ color: "#49507D" }}>
                                    <img src={ChngPswdIcon} data-toggle="tooltip" data-placement="top" title="Chanhge Password" alt="change password" ></img>
                                    <p>Change Password</p>
                                </a>
                            </Col>
                            <Col xs="2" className="ProfilesIcon" style={{ cursor: "pointer" }}>
                                <img src={LogoutIcon} data-toggle="tooltip" data-placement="top" title="Logout" alt="logout"
                                    onClick={() => {
                                        localStorage.removeItem('LoginDetail')
                                        localStorage.clear();
                                        window.location.href = '/'
                                    }}></img>
                                <p>Logout</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default WelcomeComp;
