import React, { Component } from "react";
import { Container } from "reactstrap";
import HelpCenterImage from "../../assets/images/helpcenter.png"
import { FaTruckMoving, FaLaptop, FaRegCreditCard, FaUserCircle, FaHandshake } from "react-icons/fa";
import { GiReceiveMoney, GiPublicSpeaker } from "react-icons/gi";
import Navbar from "../CompanyMaster/NavBar/Navbar"

class HelpCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Navbar />
                        <section class="position-relative bg-dark bg-size-cover bg-position-center-x position-relative py-5 mb-5 no-repeat fixed top" style={{ backgroundImage: `url(${HelpCenterImage})` }}>
                            <span class="position-absolute top-0 start-0 w-100 h-100 bg-darker" style={{ opacity: ".65" }}></span>
                            <div class="container position-relative zindex-5 py-4 my-3" style={{ background: "rgb(61 68 40 / 32%)" }}>
                                <div class="row justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="text-light text-center">How can we help?</h1>
                                        <p class="pb-3 text-light text-center">Ask Questions. Browse Topics. Find Answers.</p>
                                        <div class="input-group input-group-lg mb-3">
                                            <i class="ci-search position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                                            <input class="form-control rounded-start" type="search" placeholder="Ask your question..." />
                                        </div>
                                        <div class="fs-sm">
                                            <span class="text-light opacity-50 me-1">Suggestions:</span>
                                            <a class="nav-link-style nav-link-light me-1 pb-1 border-bottom border-light" href="#"> Dashboard, {" "}</a>
                                            <a class="nav-link-style nav-link-light me-1 pb-1 border-bottom border-light" href="#">Payment,{" "}</a>
                                            <a class="nav-link-style nav-link-light me-1 pb-1 border-bottom border-light" href="#"> Refunds,{" "}</a>
                                            <a class="nav-link-style nav-link-light me-1 pb-1 border-bottom border-light" href="#"> Delivery</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="container py-3">
                            <h2 class="h3 text-center">Select a topic</h2>
                            <div class="row pt-4">
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <FaUserCircle className="h2 mt-2 mb-4" size="38px" />
                                            <h6>Managing account</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <FaLaptop className="h2 mt-2 mb-4" size="38px" />
                                            <h6>Working with dashboard</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <FaRegCreditCard className="h2 mt-2 mb-4" size="38px" />
                                            <h6>Payment options</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <FaTruckMoving className="h2 mt-2 mb-4" size="40px" />
                                            <h6>Delivery information</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <GiReceiveMoney className="h2 mt-2 mb-4" size="40px" />
                                            <h6>Refund policy</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 mb-grid-gutter">
                                    <div class="card border-0 shadow">
                                        <div class="card-body text-center">
                                            <GiPublicSpeaker className="h2 mt-2 mb-4" size="40px" />
                                            <h6>Affiliate program</h6>
                                            <p class="fs-sm text-muted pb-2">Proin nec turpis vel tortor venenatis tempus. Sed mollis vel arcu in tempor. Mauris enim purus, laoreet nec elit non euismod tempur accusantium.</p>
                                            <a class="btn btn-outline-primary btn-sm stretched-link mb-2" href="#">Learn more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="container text-center pt-1 pb-5 mb-2">
                            <h2 class="h4 pb-3">Haven't found the answer? We can help.</h2>
                            <FaHandshake className="h3 d-block mb-4 handshake-icon" size="40px" />
                            <a class="btn Bechofy-btn" href="/submit_request">Submit a request</a>
                            <p class="fs-sm pt-4">Contact us and we’ll get back to you as soon as possible.</p>
                        </section>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default HelpCenter;