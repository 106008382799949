import React, { Component } from 'react';

class Breadcrumbs extends Component {


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <ul class="breadcrumbs">
                                <li style={{ fontWeight: "900" }}><a href={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_website}>{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_companyname}</a></li>
                                <li><a href={this.props.urlPath}>{this.props.breadcrumbItem}</a></li>
                                <li><a href={this.props.urlPath1 ? this.props.urlPath1 : ""}>{this.props.breadcrumbItem1 ? this.props.breadcrumbItem1 : ""}</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Breadcrumbs;