import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { confirmAlert } from "react-confirm-alert";
import Calendericon from '../../assets/images/dashboardimages/calendar.png';
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png';
import Filtericon from '../../assets/images/dashboardimages/filter.png';
import Refreshicon from '../../assets/images/dashboardimages/refresh.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';

// import Editicon from '../../assets/images/dashboardimages/pencil.png'
// import Eyeicon from '../../assets/images/dashboardimages/eye.png'
// ../../assets/images/dashboardimages/eye.png'

class OrderList extends Component {
	constructor(props) {
		super(props);
		this.csvLink = React.createRef()
		this.state = {
			open: false,
			UserData: [],
			Id: "",
			searchInput: "",
			FromDate: null,
			ToDate: null,
			OrderData: [],
			CustomerData: [],
			SearchField: null,
			fileData: [],
			UploadPdfUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
			InvoiceFile: [],
			InvoiceId: null,
			KeyLineId: null,
			KeyLineFile: []
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{

				WhereClause: 'Order By fld_order_number DESC',
				RecordCount: 'Top 20 *'
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						OrderData: obj.data.sort((a, b) => (a.fld_order_id < b.fld_order_id) ? 1 : -1),

					});
					Notiflix.Loading.Remove();
				}
			}))


	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				RecordCount: '*',
				WhereClause: `where convert(datetime,fld_order_date,103) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)
    `,
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						OrderData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
	}


	onPostSearch() {
		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{

				RecordCount: '*',
				WhereClause: `where upper(SearchFiled) LIKE isnull('%${this.state.SearchField == null ? "" : this.state.SearchField}%',upper(SearchFiled))
				`,
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						OrderData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
	}

	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};

	DownloadCSV = () => {

		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{

				WhereClause: '',
				RecordCount: '*'
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length != 0) {
						// this.props.setstate()

						this.setState({

							fileData: obj.data,

						}, () => {
							setTimeout(() => {
								this.csvLink.current.link.click();
							});
						});
					}
					Notiflix.Loading.Remove();
				}
			}))

	}

	onuploadInvoice(data) {
		Notiflix.Loading.Dots("Please wait...");
		if (this.state.InvoiceFile.length != 0) {
			PostApiCall.postRequest(
				{
					id: this.state.InvoiceId,
					orderid: data.fld_order_id,
					invoice: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/Invoice/" +
						data.fld_order_number.trim().replace(/\//g, "-") + "-" + this.state.InvoiceFile.name,


				},
				"UploadInvoice"
			).then((results) =>
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						Notiflix.Notify.Success("Invoice successfully added. File upload in process");

						this.uploadInvoiceFile(data)
					}
				}))
		} else {
			Notiflix.Loading.Remove()
			Notiflix.Notify.Success("Please upload invoice file.")
			window.location.reload()
		}
	}


	async uploadInvoiceFile(data) {
		Notiflix.Loading.Dots("Uploading Images...");

		let response;



		const form = new FormData();

		form.append("file", this.state.InvoiceFile);
		form.append("foldername", "Invoice");
		form.append(
			"filename",
			data.fld_order_number.trim().replace(/\//, "-") +
			"-" +
			this.state.InvoiceFile.name

		);
		response = fetch(this.state.UploadPdfUrl, {
			method: "POST",
			body: form,
		}).then(response => response.json())
			.then(dataFile => {
				// console.log(dataFile)
				Notiflix.Loading.Remove()
				Notiflix.Notify.Success("File successfully uploaded.")
				window.location.reload()
			}

			)



	}



	render() {

		const data = {
			columns: [
				{
					label: "S.No.",
					field: "sn",
					sort: "asc",
					width: 150,
				},
				{
					label: "Order Number",
					field: "orderno",
					sort: "asc",
					width: 270,
				},
				{
					label: "Order Date",
					field: "order",
					sort: "asc",
					width: 200,
				},
				{
					label: "Customer Name",
					field: "name",
					sort: "asc",
					width: 100,
				},
				{
					label: "Number of Items",
					field: "items",
					sort: "asc",
					width: 150,
				},
				{
					label: "Order Value",
					field: "value",
					sort: "asc",
					width: 100,
				},
				{
					label: "Payment Status",
					field: "paymentstatus",
					sort: "asc",
					width: 100,
				},
				{
					label: "Payment Mode",
					field: "paymentmode",
					sort: "asc",
					width: 100,
				},
				{
					label: "Order Status",
					field: "orderstatus",
					sort: "asc",
					width: 100,
				},
				{
					label: "Upload Invoice",
					field: "uploadinvoice",
					sort: "asc",
					width: 100,
				},


				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.OrderData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.customer_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_order_number
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_payment_mode != null
							? data.fld_payment_mode.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_order_status != null
							? data.fld_order_status.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.customer_name != null
							? data.customer_name.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					sn: (i + 1),
					orderno: <div>
						{data.fld_order_number}
						{data.payment_paid === 'OnlinePayment' && data.fld_payment_status === 'success' ? <p style={{
							marginBottom: 0,
							padding: "5px 10px",
							backgroundColor: "green",
							color: "white",
							borderRadius: "5px",
							marginTop: "5px"
						}}>Fully Paid</p> : data.payment_paid === 'Partially Paid' && data.fld_payment_status === 'success' ?
							<div>

								<p style={{
									marginBottom: 0,
									padding: "5px 10px",
									backgroundColor: "yellow",
									color: "black",
									borderRadius: "5px",
									marginTop: "5px"
								}}>Partially Paid</p>
							</div> : data.payment_paid === 'PayLater' ? <div>

								<p style={{
									marginBottom: 0,
									padding: "5px 10px",
									backgroundColor: "red",
									color: "white",
									borderRadius: "5px",
									marginTop: "5px"
								}}>Pay Later</p>
							</div> : ""
						}
					</div>,
					order: (data.fld_order_date),
					name: (data.customer_name),
					items: (data.total_items),
					value: (parseFloat(data.Total_Inclusive_Tax).toFixed(2)),
					paymentstatus: (data.fld_payment_mode == "COD" ? "Pending" : data.fld_payment_status),
					paymentmode: (data.fld_payment_mode),
					orderstatus: (data.fld_order_status),
					uploadinvoice: <span className="" style={{ cursor: "pointer" }}>
						<span>
							<input
								type="file"
								id="CategoryName"
								className="form-control"
								style={{ display: "block" }}
								accept="application/pdf"
								onChange={(e) => {
									if (
										e.target.files[0].size <
										5000000
									) {
										const reader = new FileReader();
										const file =
											e.target.files[0];
										reader.onloadend = () => {

											this.setState({
												InvoiceFile: file,
												InvoiceId: data.invoiceid
											}, () => {
												this.onuploadInvoice(data)
											});
										};
										reader.readAsDataURL(file);
									} else {
										Notiflix.Notify.Failure(
											"File too large, upload file less than 5 Mb."
										);
									}
								}}
							/>
						</span>

						{data.fld_invoice_file != null ? <span>
							<a href={data.fld_invoice_file} target="_blank" download className="btn align-items-center btn Bechofy-btn mr-3 mt-3"> View Invoice</a>
						</span> : ''}




					</span>,
					uploadkeyline: <span className="" style={{ cursor: "pointer" }}>
						<input
							type="file"
							id="CategoryName"
							className="form-control"
							style={{ display: "block" }}
							accept="application/pdf"
							onChange={(e) => {
								if (
									e.target.files[0].size <
									5000000
								) {
									const reader = new FileReader();
									const file =
										e.target.files[0];
									reader.onloadend = () => {

										this.setState({
											KeyLineFile: file,
											KeyLineId: data.invoiceid
										}, () => {
											this.onuploadKeyLine(data)
										});
									};
									reader.readAsDataURL(file);
								} else {
									Notiflix.Notify.Failure(
										"File too large, upload file less than 5 Mb."
									);
								}
							}}
						/>
						{/* <button className="btn align-items-center btn Bechofy-btn mr-3"

							onClick={() => {
								Notiflix.Loading.Dots("Please wait...");
								PostApiCall.postRequest(
									{
										 id : null,
										 orderid: data.fld_order_id,
										invoice: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/Invoice/" +
											data.fld_order_number.trim().replace(/\s/g, "-") + "-" + this.state.uploadInvoice,


									},
									"UploadInvoice"
								).then((results) =>
									results.json().then((obj) => {
										if (results.status == 200 || results.status == 201) {

										}
									}))


							}}

							>Upload Invoice</button> */}

					</span>,
					action: <p>
						<span className="" style={{ cursor: "pointer" }}>
							<button className="btn align-items-center btn Bechofy-btn mr-3" onClick={() => {
								localStorage.setItem("Orderdetails", JSON.stringify(data.fld_order_id));
								window.open(`https://manage.piopackaging.com/download_order_form/${data.fld_order_id.toString()}`, '_blank');
							}}>View Order</button>
							<button className="btn align-items-center btn Bechofy-btn mr-3" onClick={() => {
								Notiflix.Loading.Dots("Please wait...");
								PostApiCall.postRequest(
									{
										txnid: data.fld_payment_trx_num,
										orderid: data.fld_order_id,

									},
									"VerifyPayment"
								).then((results) =>
									results.json().then((obj) => {
										if (results.status == 200 || results.status == 201) {
											Notiflix.Loading.Remove();
											confirmAlert({
												customUI: ({ onClose }) => {
													return (
														<div className='custom-ui'>
															<h1>Payment details</h1>
															<table className="pricetable">
																<tr>
																	<td><b>Payment Id</b></td>  <td>:</td>
																	<td>{obj.data.mihpayid == null ? "-" : obj.data.mihpayid}</td>
																</tr>
																<tr>
																	<td><b>Status</b></td>  <td>:</td>
																	<td>{obj.data.status == null ? "-" : obj.data.status}</td>
																</tr>
																<tr>
																	<td><b>Card Holer Name</b></td>  <td>:</td>
																	<td>{obj.data.firstname == null ? "-" : obj.data.firstname}</td>
																</tr>
																<tr>
																	<td><b>Amount Charged</b></td>  <td>:</td>
																	<td>{obj.data.transaction_amount == null ? "-" : obj.data.transaction_amount}</td>
																</tr>
																<tr>
																	<td ><b>Bank ref Number</b></td>:<td>{obj.data.bank_ref_num}</td>
																</tr>
																<tr>
																	<td ><b>Transaction Id</b></td>:<td>{obj.data.txnid}</td>
																</tr>

																<tr>
																	<td><b>Bank Code</b></td>  <td>:</td>
																	<td>{obj.data.bankcode == null ? "0.00" : obj.data.bankcode}</td>
																</tr>
																<tr>
																	<td><b>Card Number</b></td>  <td>:</td>
																	<td>{obj.data.card_no == null ? "-" : obj.data.card_no}</td>
																</tr>
																<tr>
																	<td><b>Error</b></td>  <td>:</td>
																	<td>{obj.data.error_Message == null ? "-" : obj.data.error_Message}</td>
																</tr>
																<tr>
																	<td><b>Message</b></td>  <td>:</td>
																	<td>{obj.data.field9 == null ? "-" : obj.data.field9}</td>
																</tr>
															</table>
															<button style={{

																marginTop: "15px",
																// background: 'white',
																color: 'white',
																marginLeft: '0px',
																marginRight: '4px'
															}}
																className="btn align-items-center btn Bechofy-btn " onClick={onClose}>No</button>
															<button style={{

																marginTop: "15px",
																// background: 'white',
																color: 'white',
																marginLeft: '0px',
																marginRight: '4px'
															}}
																className="btn align-items-center btn Bechofy-btn "
																onClick={() => {
																	Notiflix.Loading.Dots("Saving Data...");
																	PostApiCall.postRequest(
																		{

																			txnid: obj.data.txnid,
																			paymentstaus: obj.data.status,
																			orderid: data.fld_order_id,
																		},
																		"UpdatePaymentStatusAfterVerifying"
																	).then((results) =>
																		results.json().then((obj) => {
																			if (results.status == 200 || results.status == 201) {

																				Notiflix.Loading.Remove();
																				onClose();
																			}
																		}))

																}}
															>
																Update Payment Status!
															</button>
														</div>
													);
												}
											});
										}
									}))


							}}>Verify Payment</button>

						</span>



					</p>

				}
			})
		}

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={"Order Management"} urlPath={"/order_list"} />

						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
												</div>
											</div>


										</div>
									</div>
									<Card style={{ padding: ' 0 20px' }}>
										<div className="row my-1 ">
											<div className="col-4">
												<div className="form-group ">

													<div class="input-group">
														<div class="input-group-prepend gridinput">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text"
															value={this.state.SearchField}
															onChange={(text) => {
																this.setState({
																	SearchField: text.target.value
																})
															}}
															class="form-control gridinput" placeholder="Search either Customer Name / Order Status" aria-label="Search" aria-describedby="basic-addon1" />
													</div>
												</div>
											</div>
											<div className="col-1">
												<button
													onClick={() => {
														if (this.state.SearchField != null) {
															this.onPostSearch()
														}
														else {
															Notiflix.Notify.failure('Please enter the name of Customer')

														}

													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Search
												</button>
											</div>
											<div className="col-2">

												<div className="form-group ">
													<DatePicker
														placeholderText="From Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.FromDate}
														onChange={(date) => {
															this.setState({
																FromDate: date
															})
														}}
														// isClearable
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span class="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>

												</div>
											</div>
											<div className="col-2">

												<div className="form-group">

													<DatePicker
														placeholderText="To Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.ToDate}
														onChange={(date) => {
															this.setState({
																ToDate: date
															})
														}}
														// isClearable
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span class="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>
												</div>
											</div>

											<div className="col-3">
												<button
													onClick={() => {
														if (this.state.FromDate != null || this.state.ToDate != null) {
															this.onPost()
														}
														else {
															Notiflix.Notify.failure('Please select from and to date.')

														}

													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Filter & Display
												</button>

												<button
													onClick={() => {

														Notiflix.Loading.Dots("Please wait...");

														PostApiCall.postRequest(
															{

																WhereClause: '',
																RecordCount: '*'
															},
															"GetCustomerOrderDetails"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	// //console.log(obj.data)
																	this.setState({
																		OrderData: obj.data,

																	});
																	Notiflix.Loading.Remove();
																}
															}))
													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													List All Orders
												</button>
												<button className="btn align-items-center btn Bechofy-btn "
													style={{

														marginTop: "15px",
														// background: 'white',


													}}
													onClick={() => {
														window.location.reload();
													}}
												>
													<span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

												</button>

											</div>


										</div>
									</Card>

									<Card >
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-2">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Order List</h4>
													</div>
												</div>

												<div className="col-10">
													<div className="position-relative">

														<button
															onClick={this.DownloadCSV.bind(this)}
															style={{
																float: "right",
																marginTop: "10px",
																background: 'white',
																color: 'grey',
																marginRight: '20px'
															}}
															className="btn align-items-center btn "
														>
															<span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
															Download List (.csv)
														</button>
													</div>

													<CSVLink
														data={this.state.fileData}
														filename="OrderList.csv"
														className="hidden"
														ref={this.csvLink}
														target="_blank"
													/>

												</div>


											</div>
										</div>
										<CardBody className="pt-0">
											<Row>


												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div class="input-group mb-3">


														<div class="input-group-prepend">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}

														/>
													</div>
												</MDBCol>



												<Col md="12">
													{/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
													{this.state.OrderData.length != 0 ?
														<MDBDataTable
															hover
															// scrollY
															striped
															bordered
															data={data}
															seachTop={false}
															entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
														>

															<MDBTableHead columns={data.columns} />
															<MDBTableBody rows={data.rows} />
														</MDBDataTable>
														:
														<p>No Data Found</p>}
												</Col>

											</Row>
										</CardBody>
									</Card>


								</Card>

							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default OrderList;
