import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";

import Searchicon from "../../assets/images/dashboardimages/search.png";

import Refreshicon from "../../assets/images/dashboardimages/refresh.png";

import deleteicon from "../../assets/images/dashboardimages/delete.png";
import Saveicon from "../../assets/images/dashboardimages/filesave.png";
import Editicon from "../../assets/images/dashboardimages/pencil.png";

import Backicon from "../../assets/images/dashboardimages/undo.png";

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "5px" }}
  >
    <div className="img-wrap brandimages img-upload">
      <img
        for="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

// import DatePicker from 'react-date-picker';

// Generate random number
var randomNumber = Math.floor(100000 + Math.random() * 900000);

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,
      view: false,
      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      ProductData: [],
      VariantData: [],
      Category: null,
      SubCategory: null,
      imagePreviewUrl:
        "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
      Status: "Yes",
      ImageApiUrl:
        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress +
        "AddImage",
      Brand: null,
      Vertical: null,
      SearchField: null,
      ViewProductList: true,
      ViewVariantList: false,
      suggestions: [],
      BrandData: [],
      VerticalData: [],
      CategoryData: [],
      SubCatgeoryData: [],
      //   ItemName: null,
      ItemName: "",
      originalImage: null,
      originalLink: "",
      clicked: false,
      uploadImage: false,
      ItemId: null,
      ItemLogo: null,
      uploadImage: false,
      IsOpen: false,
      GST: null,
      GSTData: [
        { value: "5", label: "5" },
        { value: "10", label: "10" },
        { value: "12", label: "12" },
        { value: "15", label: "15" },
        { value: "18", label: "18" },
      ],
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    localStorage.removeItem("VariantImageID");
    localStorage.removeItem("VariantIdForImage");

    localStorage.removeItem("itemDetails");
    localStorage.removeItem("VariantDetails");
    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          BrandData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );

    GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticalData: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );

    // ======Get Item List==========
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetProductMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ProductData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name,
      uploadImage: true,
    });
  };

  onSaveData() {
    // //console.log(this.state.imageCount);

    // if (this.state.ItemName != null) {
    if (this.state.ItemName != "") {
      if (this.state.Status != null) {
        if (this.state.GST != null) {
          this.onPostItem();
        } else {
          Notiflix.Notify.Failure("Please select GST");
        }
      } else {
        Notiflix.Notify.Failure("Please select status");
      }
    } else {
      Notiflix.Notify.Failure("Please enter item name");
    }
    // 	} else {
    // 		Notiflix.Notify.Failure("Please select sub category name");
    // 	}
    // } else {
    // 	Notiflix.Notify.Failure("Please select  category name");
    // }
  }

  // onAddImage() {
  //   const form1 = new FormData();
  //   // console.log(this.state.originalImage);
  //   form1.append("file", this.state.originalImage);
  //   form1.append("foldername", "VerticalImages");
  //   form1.append(
  //     "filename",
  //     this.state.ItemName.trim().replace(/\s/g, "-") +
  //       "-" +
  //       randomNumber +
  //       "-" +
  //       this.state.outputFileName
  //   );
  //   fetch(this.state.ImageApiUrl, {
  //     method: "POST",
  //     body: form1,
  //   });
  // }

  onPostItem = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        productid: this.state.ProductID,
        itemname: this.state.ItemName,
        itemimage:
          this.state.outputFileName != "NoChange"
            ? JSON.parse(localStorage.getItem("ClientDetails"))[0]
                .fld_image_url_path +
              "/VerticalImages/" +
              this.state.ItemName.trim().replace(/\s/g, "-") +
              "-" +
              randomNumber +
              "-" +
              this.state.outputFileName
            : this.state.imagePreviewUrl,
        Gstrate: this.state.GST,
        status: this.state.Status == "Yes" ? "Active" : "InActive",
      },
      "AddProductMaster"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Item is Successfully Added");

          if (this.state.originalImage !== null) {
            this.onAddImage(() => {
              this.fetchProductData();
            });
          } else {
            this.fetchProductData();
          }
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  onAddImage(callback) {
    const form1 = new FormData();
    form1.append("file", this.state.originalImage);
    form1.append("foldername", "VerticalImages");
    form1.append(
      "filename",
      this.state.ItemName.trim().replace(/\s/g, "-") +
        "-" +
        randomNumber +
        "-" +
        this.state.outputFileName
    );
    fetch(this.state.ImageApiUrl, {
      method: "POST",
      body: form1,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload image.");
        }
        // If the response is successful and a callback function is provided, execute it
        if (callback && typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  }

  // onAddImage() {
  //   const form1 = new FormData();
  //   // console.log(this.state.originalImage);
  //   form1.append("file", this.state.originalImage);
  //   form1.append("foldername", "VerticalImages");
  //   form1.append(
  //     "filename",
  //     this.state.ItemName.trim().replace(/\s/g, "-") +
  //       "-" +
  //       randomNumber +
  //       "-" +
  //       this.state.outputFileName
  //   );
  //   fetch(this.state.ImageApiUrl, {
  //     method: "POST",
  //     body: form1,
  //   }).then((response) => {
  //     if (!response.ok) {
  //       throw new Error("Failed to upload image.");
  //     }
  //   });
  // }

  // Get all Data

  fetchProductData = () => {
    Notiflix.Loading.Dots("Fetching Product Data...");
    return PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetProductMaster"
    )
      .then((results) => {
        if (results.status == 200 || results.status == 201) {
          return results.json();
        } else {
          throw new Error("Failed to fetch product data.");
        }
      })
      .then((obj) => {
        this.setState({
          ProductData: obj.data,
          ItemName: "",
          GST: "",
          status: "Yes",
          imagePreviewUrl:
            "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
        });
        Notiflix.Loading.Remove();
      })
      .catch((error) => {
        console.error("Error:", error);
        Notiflix.Notify.Failure(
          "An error occurred while fetching product data."
        );
      });
  };

  onChangeCategory(text) {
    this.setState(
      {
        Category: text.target.value,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
          {
            categoryid: this.state.Category,
          },
          "Get_subcategoryDropdown"
        ).then((results1) =>
          results1.json().then((obj1) => {
            if (results1.status == 200 || results1.status == 201) {
              this.setState({
                SubCatgeoryData: obj1.data,
              });

              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  render() {
    const { text, suggestions } = this.state;

    const itemdata = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Item Name",
          field: "itemname",
          sort: "disabled",
          width: 400,
        },

        {
          label: "GST (%)",
          field: "gst",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Action",
          field: "actions",
          sort: "disabled",
          width: 100,
        },
      ], // end for variant
      rows: this.state.ProductData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_itemname
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          itemname: (
            <div>
              <span className="ClassificationtextLeft">
                <b>Item Name</b>
              </span>
              <span className="ClassificationtextRight">
                {data.fld_itemname}
              </span>
              <br />
              <span className="ClassificationtextLeft">
                <b>Variant</b>
              </span>
              <span className="ClassificationtextRight">
                <a
                  onClick={() => {
                    Notiflix.Loading.Dots("Please wait...");
                    PostApiCall.postRequest(
                      {
                        whereClause: `where fld_productid=${data.fld_productid}`,
                        recordCount: "*",
                      },
                      "GetVariantData"
                    ).then((results) =>
                      // const objs = JSON.parse(result._bodyText)
                      results.json().then((obj) => {
                        if (results.status == 200 || results.status == 201) {
                          // console.log(obj.data);
                          this.setState({
                            VariantData: obj.data,
                            ViewVariantList: true,
                            ViewProductList: false,
                          });
                          Notiflix.Loading.Remove();
                        }
                      })
                    );
                  }}
                  style={{ color: "green" }}
                >
                  ({data.VariantCount} Variant)
                </a>
              </span>
            </div>
          ),

          gst: data.fld_Gstrate,
          actions: (
            <span>
              <img
                src={Editicon}
                alt="Edit user"
                style={{ marginLeft: "2px" }}
                className="btnicons"
                onClick={() => {
                  localStorage.setItem(
                    "itemDetails",
                    JSON.stringify(data.fld_productid)
                  );
                  window.location.href = "/add_item";
                }}
              ></img>
            </span>
          ),
        };
      }),
    };

    // =========VariantList=============
    const variantdata = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Variant Image",
          field: "itemimage",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Variant Name",
          field: "itemname",
          sort: "disabled",
          width: 400,
        },

        {
          label: "GST (%)",
          field: "gst",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ], // end for variant
      rows: this.state.VariantData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          data.fld_variantname
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          itemimage: (
            <img
              src={data.VariantImage}
              alt="Variantimage"
              style={{ width: "50px" }}
            ></img>
          ),
          itemname: data.fld_variantname,

          gst: data.fld_Gstrate,

          status: data.fld_status,
          action: (
            <span>
              <img
                src={Editicon}
                alt="View user"
                className="btnicons"
                onClick={() => {
                  localStorage.setItem(
                    "VariantDetails",
                    JSON.stringify(data.fld_variantid)
                  );
                  window.location.href = "/addnewvariant";
                }}
              ></img>
              <img
                src={Refreshicon}
                alt="Edit user"
                style={{ marginLeft: "2px" }}
                className="btnicons"
                onClick={() => {
                  localStorage.setItem(
                    "itemDetails",
                    JSON.stringify(data.fld_productid)
                  );
                  var customer = localStorage.getItem("ClientDetails");
                  var CustomerDetails = JSON.parse(customer);
                  var url = CustomerDetails[0].fld_product_share_url
                    .replace(":verticalid", data.fld_verticleid)
                    .replace(":variantid", data.fld_variantid);
                  window.open(url, "_blank");
                }}
              ></img>
            </span>
          ),
        };
      }),
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            <div className="row">
              <div className="col-md-6">
                <div>
                  <ul class="breadcrumbs">
                    <li>
                      <a href="https://store.bechofy.in/">store.bechofy.in</a>
                    </li>
                    <li>Product Management</li>
                    <li>Product List</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}
              </div>
            </div>
            <Row>
              <Col xl="12">
                <Card className="pagebackground" id="upload-card">
                  <Card>
                    <div
                      className="Bechofy-bg-soft-pink"
                      style={{ height: "60px" }}
                    >
                      <div className="row my-1">
                        <div className="col-4">
                          <div
                            className="form-group"
                            style={{
                              display:
                                this.state.ViewProductList == true
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              Item List
                            </h4>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              display:
                                this.state.ViewVariantList == true
                                  ? ""
                                  : "none",
                            }}
                          >
                            <h4
                              className="FilterCard"
                              style={{ marginTop: "10px" }}
                            >
                              Variant List
                            </h4>
                          </div>
                        </div>

                        <div
                          className="col-md-8"
                          style={{
                            display:
                              this.state.ViewVariantList == true ? "" : "none",
                          }}
                        >
                          <button
                            className="btn align-items-center btn Bechofy-btn AddNewBtn"
                            onClick={() => {
                              Notiflix.Loading.Dots("Please wait...");
                              PostApiCall.postRequest(
                                {
                                  whereClause: "",
                                  recordCount: "Top 20 *",
                                },
                                "GetProductMaster"
                              ).then((results) =>
                                // const objs = JSON.parse(result._bodyText)
                                results.json().then((obj) => {
                                  if (
                                    results.status == 200 ||
                                    results.status == 201
                                  ) {
                                    // //console.log(obj.data)
                                    this.setState({
                                      ProductData: obj.data,
                                      ViewVariantList: false,
                                      ViewProductList: true,
                                    });
                                    Notiflix.Loading.Remove();
                                  }
                                })
                              );
                            }}
                          >
                            Back to Item list
                            <span className="">
                              <img
                                src={Backicon}
                                alt="block user"
                                className="btnicons"
                                style={{ marginLeft: "5px" }}
                              ></img>{" "}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <MDBCol
                          md="12"
                          style={{ marginBottom: "15px", marginTop: "15px" }}
                        >
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                <span className="">
                                  <img
                                    src={Searchicon}
                                    alt="block user"
                                    className="btnicons"
                                  ></img>
                                </span>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Type the search phrase you wish to search within the grid"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}
                            />
                          </div>
                        </MDBCol>

                        <Col md="12">
                          {this.state.ProductData.length !== 0 &&
                          this.state.ViewProductList == true ? (
                            <Card>
                              <CardBody className="pt-0">
                                <Row>
                                  <Col xs="4">
                                    <CardBody className="categoryCard">
                                      <Row className="card-row">
                                        <Col sm="6">
                                          <div className="imagecard">
                                            <div className="form-group ">
                                              <label for="sw-arrows-first-name">
                                                Upload Item Image
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <div class="div1">
                                                <ImgUpload
                                                  onChange={this.photoUpload}
                                                  src={
                                                    this.state.imagePreviewUrl
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {/* <hr style={{border:'1px solid #fff'}}/> */}
                                        </Col>
                                        <Col sm="6">
                                          <div className="row">
                                            <h5>Add New Item</h5>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">
                                                Item Name *
                                              </label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Item Name"
                                                value={this.state.ItemName}
                                                onChange={(text) => {
                                                  this.setState({
                                                    ItemName: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">
                                                GST Amount *
                                              </label>
                                              <select
                                                value={this.state.GST}
                                                onChange={(text) => {
                                                  this.setState({
                                                    GST: text.target.value,
                                                  });
                                                }}
                                                disabled={
                                                  this.state.FormStatus ==
                                                  "Completed"
                                                    ? true
                                                    : false
                                                }
                                                className="custom-select my-1 mr-sm-2"
                                              >
                                                <option value="">
                                                  Select GST
                                                </option>
                                                {this.state.GSTData.map(
                                                  (gst) => (
                                                    <option
                                                      key={gst.value}
                                                      value={gst.value}
                                                    >
                                                      {gst.label}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>

                                            <div
                                              className="col-12"
                                              style={{ marginTop: "10px" }}
                                            >
                                              <div className="form-group">
                                                <label for="CategoryName">
                                                  Status
                                                  <span className="mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <br />
                                                <label class="radio-inline">
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "Yes"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "Yes",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Active
                                                </label>
                                                <label
                                                  class="radio-inline"
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "No"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "No",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Inactive
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <hr />
                                      </Row>
                                      <Row>
                                        <Col sm="6"></Col>
                                        <Col sm="6">
                                          <a
                                            onClick={this.onSaveData.bind(this)}
                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                            style={{
                                              marginTop: "5px",
                                              color: "white",
                                            }}
                                          >
                                            <span className="">
                                              <img
                                                src={Saveicon}
                                                alt="block user"
                                                className="btnicons"
                                                style={{ marginRight: "5px" }}
                                              ></img>{" "}
                                            </span>
                                            Save Item
                                          </a>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Col>

                                  {/* ======Brand List */}
                                  {this.state.ProductData.filter((data) => {
                                    if (this.state.searchInput == "") {
                                      return data;
                                    }
                                    if (
                                      this.state.searchInput !== "" &&
                                      (data.fld_itemname
                                        .toLowerCase()
                                        .includes(
                                          this.state.searchInput.toLowerCase()
                                        ) ||
                                        `${
                                          data.fld_status
                                            ? data.fld_status.toLowerCase()
                                            : ""
                                        }`.includes(
                                          this.state.searchInput.toLowerCase()
                                        ))
                                    ) {
                                      return data;
                                    }
                                  })
                                    .filter(
                                      (deletedata) =>
                                        deletedata.fld_status != "Deleted"
                                    )
                                    .map((data, i) => (
                                      <Col xs="4">
                                        <CardBody className="categoryCard">
                                          <Row className="card-row">
                                            <Col
                                              sm="5"
                                              style={{ marginTop: "15px" }}
                                            >
                                              <div className="imagecard">
                                                <div className="form-group custom-file-upload fas">
                                                  <div class="div1 img-wrap brandimages">
                                                    <img
                                                      src={data.fld_itemimage}
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "5%",
                                                      }}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              {/* <hr style={{border:'1px solid #fff'}}/> */}
                                            </Col>
                                            <Col sm="7">
                                              <div className="row">
                                                <div
                                                  className="col-12"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <p>
                                                    <b>Item Name</b> -{" "}
                                                    <span>
                                                      {data.fld_itemname}
                                                    </span>
                                                  </p>
                                                </div>

                                                <div className="col-12">
                                                  <p>
                                                    <b>Total Variants</b> -{" "}
                                                    <span>
                                                      {data.VariantCount}
                                                    </span>
                                                  </p>
                                                </div>

                                                <div className="col-12">
                                                  <p>
                                                    <b>GST</b> -{" "}
                                                    <span>
                                                      {data.fld_Gstrate}
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </Col>
                                            <hr />
                                          </Row>
                                          <Row>
                                            <Col sm="4" className="BrandStatus">
                                              <p>
                                                <b>Status</b> -{" "}
                                                <span
                                                  style={{
                                                    color:
                                                      data.fld_status ==
                                                      "Active"
                                                        ? "green"
                                                        : "red",
                                                  }}
                                                >
                                                  {data.fld_status}
                                                </span>
                                              </p>
                                            </Col>
                                            <Col sm="8">
                                              <span>
                                                <a
                                                  onClick={() => {
                                                    this.setState({
                                                      ItemName:
                                                        data.fld_itemname,
                                                      ProductID:
                                                        data.fld_productid,
                                                      Status:
                                                        data.fld_status ==
                                                        "Active"
                                                          ? "Yes"
                                                          : "No",
                                                      GST: data.fld_Gstrate,
                                                    });
                                                    if (
                                                      data.fld_itemimage != null
                                                    ) {
                                                      this.setState({
                                                        imagePreviewUrl:
                                                          data.fld_itemimage,
                                                        outputFileName:
                                                          "NoChange",
                                                        ItemLogo:
                                                          data.fld_itemimage,
                                                      });
                                                    } else {
                                                      this.setState({
                                                        imagePreviewUrl:
                                                          "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                                      });
                                                    }
                                                    window.location.href =
                                                      "#upload-card";
                                                  }}
                                                  // onClick={this.onSaveData.bind(this)}
                                                  className="btn Bechofy-btn waves-effect waves-light btn-sm float-left"
                                                  style={{
                                                    marginTop: "5px",
                                                    color: "white",
                                                  }}
                                                >
                                                  <span className="">
                                                    <img
                                                      src={Editicon}
                                                      alt="block user"
                                                      className="btnicons"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    ></img>{" "}
                                                  </span>
                                                  Edit
                                                </a>
                                              </span>
                                              <span>
                                                <a
                                                  onClick={() => {
                                                    localStorage.removeItem(
                                                      "VariantDetails"
                                                    );
                                                    localStorage.setItem(
                                                      "ItemName",
                                                      data.fld_itemname
                                                    );
                                                    window.location.href =
                                                      "/addnewvariant/" +
                                                      data.fld_productid;
                                                  }}
                                                  // onClick={this.onSaveData.bind(this)}
                                                  className="btn Bechofy-btn waves-effect waves-light btn-sm float-left"
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "4px",
                                                    color: "white",
                                                  }}
                                                >
                                                  <span className="">
                                                    <img
                                                      src={Editicon}
                                                      alt="block user"
                                                      className="btnicons"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    ></img>{" "}
                                                  </span>
                                                  Variants
                                                </a>
                                              </span>
                                              <span>
                                                <img
                                                  src={deleteicon}
                                                  alt="block user"
                                                  className="btnicons float-right mt-2"
                                                  onClick={() => {
                                                    confirmAlert({
                                                      title:
                                                        "Confirm to Delete",
                                                      message:
                                                        "Are you sure you want to delete Item.",
                                                      buttons: [
                                                        {
                                                          label: "Yes",
                                                          onClick: () => {
                                                            Notiflix.Loading.Dots(
                                                              ""
                                                            );
                                                            var login =
                                                              localStorage.getItem(
                                                                "LoginDetail"
                                                              );
                                                            var details =
                                                              JSON.parse(login);
                                                            PostApiCall.postRequest(
                                                              {
                                                                productid:
                                                                  data.fld_productid,
                                                                itemname:
                                                                  data.fld_itemname,
                                                                itemimage:
                                                                  data.fld_itemimage,
                                                                // status: "InActive"
                                                                status:
                                                                  "Deleted",
                                                              },
                                                              "AddProductMaster"
                                                            ).then(
                                                              (
                                                                resultcategory
                                                              ) =>
                                                                resultcategory
                                                                  .json()
                                                                  .then(
                                                                    (obj) => {
                                                                      if (
                                                                        resultcategory.status ==
                                                                          200 ||
                                                                        resultcategory.status ==
                                                                          201
                                                                      ) {
                                                                        Notiflix.Loading.Remove();
                                                                        Notiflix.Notify.Success(
                                                                          "Item is Successfully Deleted"
                                                                        );
                                                                        window.location.reload();
                                                                      } else {
                                                                        Notiflix.Loading.Remove();
                                                                        Notiflix.Notify.Failure(
                                                                          obj.data
                                                                        );
                                                                      }
                                                                    }
                                                                  )
                                                            );
                                                          },
                                                        },
                                                        {
                                                          label: "No",
                                                          // onClick: () => alert('Click No')
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                ></img>
                                              </span>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Col>
                                    ))}
                                </Row>
                              </CardBody>
                            </Card>
                          ) : (
                            <p
                              style={{
                                display:
                                  this.state.ViewProductList == false
                                    ? "none"
                                    : "block",
                              }}
                            >
                              No Data Found
                            </p>
                          )}
                          {this.state.VariantData.length !== 0 &&
                          this.state.ViewVariantList == true ? (
                            <MDBDataTable
                              hover
                              // scrollY
                              striped
                              bordered
                              data={variantdata}
                              seachTop={false}
                              entriesOptions={[25, 50, 100]}
                            >
                              <MDBTableHead columns={variantdata.columns} />
                              <MDBTableBody rows={variantdata.rows} />
                            </MDBDataTable>
                          ) : (
                            <p
                              style={{
                                display:
                                  this.state.ViewVariantList == false
                                    ? "none"
                                    : "block",
                              }}
                            >
                              No Data Found
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Card>

                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* </Card>

							</Col>
						</Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ProductList;
