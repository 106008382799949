import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-responsive-modal/styles.css";
import moment from "moment";
import Searchicon from '../../assets/images/dashboardimages/search.png'
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import Saveicon from '../../assets/images/dashboardimages/filesave.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas" style={{ marginBottom: '-5px' }}>
        <div className="img-wrap brandimages img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);


class KeyLineMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {

            imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',

            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
            ImageData: [],
            BrandName: "",
            BrandId: null,

            CompanyId: null,
            BrandData: [],
            searchInput: '',
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,
            BrandLogo: null,
            ImageName: null,
            VariantData: [],
            UploadPdfUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            KeyLineFile: [],
            KeylineData: [],
            KeyLineid: null,
            VariantName: null,
            ProductData: []

        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");


        PostApiCall.postRequest(
            {

                whereClause: '',
                recordCount: 'fld_productid as value,fld_itemname as label'
            },
            "GetProductMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        ProductData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


        PostApiCall.postRequest({
            whereClause: ``,
            recordCount: 'fld_variantid as value,fld_variantname as label'
        }, "GetVariantData").then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        VariantData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))

        this.getKeyLine()


    }


    getKeyLine() {
        PostApiCall.postRequest({
            whereClause: `Order by fld_id DESC`,
            recordCount: '*'
        }, "GetKeyline").then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        KeylineData: obj.data,
                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }



    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };


    onSaveData = () => {
        if (this.state.VariantName != null) {
            this.onuploadKeyLine();

        } else {
            Notiflix.Notify.Failure("Please select variant name");
        }
    }






    onuploadKeyLine(data) {
        Notiflix.Loading.Dots("Please wait...");
        if (this.state.KeyLineFile.length != 0) {
            PostApiCall.postRequest(
                {
                    id: this.state.KeyLineid,
                    variantid: this.state.VariantName,
                    keyline: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/Keyline/" +
                        this.state.VariantName.trim().replace(/\//g, "-") + "-" + this.state.KeyLineFile.name,


                },
                "UploadKeyline"
            ).then((results) =>
                results.json().then((obj) => {
                    if (results.status == 200 || results.status == 201) {
                        Notiflix.Notify.Success("Keyline successfully added. File upload in process");

                        this.uploadKeylineFile()
                    }
                }))
        } else {
            Notiflix.Loading.Remove()
            Notiflix.Notify.Failure("Please upload Keyline file.")

        }
    }


    async uploadKeylineFile() {
        Notiflix.Loading.Dots("Uploading Images...");

        let response;



        const form = new FormData();

        form.append("file", this.state.KeyLineFile);
        form.append("foldername", "Keyline");
        form.append(
            "filename",
            this.state.VariantName.trim().replace(/\//, "-") +
            "-" +
            this.state.KeyLineFile.name

        );
        response = fetch(this.state.UploadPdfUrl, {
            method: "POST",
            body: form,
        }).then(response => response.json())
            .then(dataFile => {
                // console.log(dataFile)
                Notiflix.Loading.Remove()
                Notiflix.Notify.Success("File successfully uploaded.")
                window.location.reload()
            }

            )



    }


    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>




                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Keyline Management"} urlPath={"/upload_keyline"} />


                        {/* ==========Vertical======= */}



                        <Row>

                            <Col xl="12">
                                <Card className="overflow-hidden pagebackground">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text" id="upload-card">
                                                        Keyline List
                                                    </h5>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the variant name to search the keyline" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card >

                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col xs="4">
                                                    <CardBody className="categoryCard">

                                                        <Row className="card-row">

                                                            <Col sm="12" style={{ marginBottom: '-10px' }}>
                                                                <div className="row">
                                                                    {this.state.KeyLineid == null ?
                                                                        <h5 >Add New Keyline</h5>
                                                                        :
                                                                        <h5 >Edit Keyline </h5>
                                                                    }
                                                                    <div className="col-12">
                                                                        <label class="my-1 mr-2">Item Name</label>

                                                                        <select
                                                                            value={this.state.Product}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Product: text.target.value,
                                                                                }, () => {
                                                                                    PostApiCall.postRequest(
                                                                                        {

                                                                                            whereClause: `where fld_productid=${this.state.Product}`,
                                                                                            recordCount: 'fld_variantid as value,fld_variantname as label'
                                                                                        },
                                                                                        "GetVariantData"
                                                                                    ).then((results) =>
                                                                                        results.json().then((obj) => {
                                                                                            if (results.status == 200 || results.status == 201) {
                                                                                                this.setState({
                                                                                                    VariantData: obj.data,


                                                                                                });
                                                                                                Notiflix.Loading.Remove();
                                                                                            }
                                                                                        }))
                                                                                });
                                                                            }}
                                                                            className="custom-select my-1 mr-sm-2"
                                                                        >
                                                                            <option>Select Item</option>
                                                                            {this.state.ProductData.map(
                                                                                (variant) => (
                                                                                    <option
                                                                                        key={variant.value}
                                                                                        value={variant.value}
                                                                                    >
                                                                                        {variant.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>

                                                                    </div>
                                                                    <div className="col-12">
                                                                        <label class="my-1 mr-2">Variant Name *</label>

                                                                        <select className="form-control"
                                                                            placeholder="Select Variant"
                                                                            value={this.state.VariantName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    VariantName: text.target.value
                                                                                })
                                                                            }}


                                                                        >
                                                                            <option value="">
                                                                                Select Variant
                                                                            </option>
                                                                            {this.state.VariantData.map(
                                                                                (variant) => (
                                                                                    <option
                                                                                        key={variant.value}
                                                                                        value={variant.value}
                                                                                    >
                                                                                        {variant.label}
                                                                                    </option>
                                                                                )
                                                                            )}


                                                                        </select>

                                                                    </div>

                                                                    <div className="col-12" style={{ marginTop: '10px' }}>
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Upload Keyline
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <br />
                                                                            <input
                                                                                type="file"
                                                                                id="CategoryName"
                                                                                className="form-control"
                                                                                style={{ display: "block" }}
                                                                                accept="application/pdf"
                                                                                onChange={(e) => {
                                                                                    if (
                                                                                        e.target.files[0].size <
                                                                                        5000000
                                                                                    ) {
                                                                                        const reader = new FileReader();
                                                                                        const file =
                                                                                            e.target.files[0];
                                                                                        reader.onloadend = () => {

                                                                                            this.setState({
                                                                                                KeyLineFile: file,

                                                                                            });
                                                                                        };
                                                                                        reader.readAsDataURL(file);
                                                                                    } else {
                                                                                        Notiflix.Notify.Failure(
                                                                                            "File too large, upload file less than 5 Mb."
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <hr />
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                            </Col>
                                                            <Col sm="6">

                                                                {this.state.KeyLineid == null ?
                                                                    <button
                                                                        onClick={this.onSaveData.bind(this)}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "5px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Save Keyline

                                                                    </button>
                                                                    :
                                                                    <button
                                                                        onClick={this.onSaveData.bind(this)}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "5px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Update Keyline

                                                                    </button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Col>


                                                {/* ======Brand List */}
                                                {this.state.KeylineData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    }
                                                    if (
                                                        this.state.searchInput !== "" &&
                                                        (data.variantname
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase()))
                                                    ) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <CardBody className="categoryCard">

                                                            <Row className="card-row">

                                                                <Col sm="12">
                                                                    <div className="row">
                                                                        <div className="col-12" style={{ marginTop: '10px' }}>
                                                                            <p>
                                                                                <b>Variant Name</b> -{" "}
                                                                                <span

                                                                                >
                                                                                    {data.variantname}
                                                                                </span>
                                                                            </p>

                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p>
                                                                                <b>Keyline</b> -{" "}
                                                                                <span

                                                                                >
                                                                                    <a href={data.fld_key_line_doc} target="_blank" download={data.fld_key_line_doc}><button className="btn Bechofy-btn waves-effect waves-light btn-sm"
                                                                                        style={{ marginTop: "5px", color: "white" }}>View Keyline</button></a>
                                                                                </span>
                                                                                <span

                                                                                >
                                                                                    <button className="btn Bechofy-btn waves-effect waves-light btn-sm ml-3"
                                                                                        style={{ marginTop: "5px", color: "white" }}
                                                                                        onClick={() => {
                                                                                            Notiflix.Loading.Dots()
                                                                                            PostApiCall.postRequest({
                                                                                                keylineid: data.fld_id,
                                                                                            }, "DeleteKeyline").then((results) =>
                                                                                                results.json().then((obj) => {
                                                                                                    if (results.status == 200 || results.status == 201) {
                                                                                                        Notiflix.Loading.Remove();
                                                                                                        Notiflix.Notify.Success("Keyline deleted successfully")
                                                                                                        this.getKeyLine()
                                                                                                    } else {
                                                                                                        Notiflix.Loading.Remove();
                                                                                                        Notiflix.Notify.Failure("Something went wrong, Please try again later")
                                                                                                    }
                                                                                                }))
                                                                                        }}>Delete Keyline</button>
                                                                                </span>
                                                                            </p>

                                                                        </div>







                                                                    </div>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>

                                                                <Col sm="12">



                                                                    {/* <span>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    VariantName: data.fld_variant_id,
                                                                                    KeyLineid: data.fld_id,
                                                                                   
                                                                                })
                                             
                                                                                window.location.href = "#upload-card"
                                                                            }}
                                                                            // onClick={this.onSaveData.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "10px", color: "white" }}
                                                                        >
                                                                            <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                            Edit Keyline
                                                                        </button>
                                                                    </span> */}



                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>






                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default KeyLineMaster;