import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";

import ApexRadial from "./ApexRadial";
import ReactApexChart from "react-apexcharts";

class MonthlyEarning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			TotalSales: [],



			saleSseries1: [],
			options: {
				chart: {
					width: '100%',
					type: 'pie',
				},
				labels: ['Sales Value', ' Today', 'This Month', 'This Year'],
				theme: {
					monochrome: {
						enabled: true
					}
				},
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -5
						}
					}
				},

				dataLabels: {
					formatter(val, opts) {
						const name = opts.w.globals.labels[opts.seriesIndex]
						return [name, val.toFixed(1) + '%']
					}
				},
				legend: {
					show: false
				}
			},









		};


		this.togglemodal.bind(this);
	}

	togglemodal = () => {
		this.setState((prevState) => ({
			modal: !prevState.modal,
		}));
	};
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Custom("");

		//get sales count
		GetApiCall.getRequest("GetSalesTotalValuesCurrentMonth").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data)
				this.setState({
					TotalSales: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);


		// =========SalesRadialGraph==========
		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DashboardSalesCard"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj4) => {
				if (results.status == 200 || results.status == 201) {
					//	console.log(obj4.data)
					this.setState({
						saleSseries1: [
							obj4.data[0].total_sales,
							obj4.data[0].today_sales,
							obj4.data[0].this_month_sales,
							obj4.data[0].this_year_sales],

						CustomerData: obj4.data,


					});




					Notiflix.Loading.Remove();
				}
			}))




	}
	render() {
		return (
			<React.Fragment>
				{" "}
				<Card style={{ height: "290px" }}>
					<CardBody>
						<h4>Sales Summary</h4>
						<Row>
							<Col sm="7">
								<p className="text-muted">
									For the Month of{" "}
									{this.state.TotalSales.map((item, i) => {
										return item.month_name;
									})}
								</p>
								<h3>
									{this.state.TotalSales.map((item, i) => {
										return "₹" + item.total_sales;
									})}
								</h3>
								<p className="text-muted">
									<span className="text-success mr-2">
										{" "}
										{this.state.TotalSales.map((item, i) => {
											if (item.last_month_sales > item.total_sales) {
												return (
													(parseFloat(item.total_sales / item.last_month_sales).toFixed(4) * 100 -
														100) *
													-1 +
													"%"
												);
											}
											if (item.last_month_sales < item.total_sales) {
												return (
													parseFloat(item.last_month_sales / item.total_sales).toFixed(4) * 100 + "%"
												);
											}
										})}{" "}  {" "}
										<i className="mdi mdi-arrow-up"></i>
										{this.state.TotalSales.map((item, i) => {
											if (item.last_month_sales < item.total_sales) {
												return <i className="mdi mdi-arrow-up"></i>;
											}
											if (item.last_month_sales > item.total_sales) {
												return <i className="mdi mdi-arrow-down"></i>;
											}
										})}
									</span>{" "}
									From previous period
								</p>
								{/* <div className="mt-4">
                            </div> */}
							</Col>
							<Col sm="5" className="monthlyChart">

								<ReactApexChart
									options={this.state.options}
									series={this.state.saleSseries1}
									type="pie"
									height={210}
									width={300}
								/>


							</Col>
						</Row>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default MonthlyEarning;
