import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class AddAttributeMaster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			JobTitle: "",
			JobLocation: "",
			JobType: "",
			SalaryRange: "",
			JobType: "",

			Status: "Active",
			Name: "",

			CategoryData: [],
			CategorySelect: [],
			groupArray: [],
			SubCategory: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetAttributeMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					CategoryData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		//       fld_categoryname: "Stationery "
		// fld_subcategoryname: "Pens & Pencils "
	}

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription(text) {
		this.setState({
			JobDescription: text.editor.getData(),
		});
	}

	SaveAttribute() {
		if (this.state.Name != "") {
			this.onPost();
		} else {
			Notiflix.Notify.Failure("Please enter attribute name");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				attributename: this.state.Name,
				status: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
			},
			"AddAttributeMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					// //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("New Attribute successfully Mapped.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure("Error Occured");
				}
			})
		);
	};

	render() {
		// //console.log(temp)
		//     //console.log(this.groupBy(this.state.CategoryData,'fld_categoryname'))
		//  //console.log(this.state)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy /"}
							breadcrumbItem={"Add New Attribute"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Attribute Master
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Add New Attribute
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="form-group">
																			<label for="CategoryName">
																				Attribute Name
																			</label>
																			<input
																				type="text"
																				id="CategoryName"
																				className="form-control my-1 mr-sm-2"
																				value={this.state.Name}
																				onChange={(name) => {
																					this.setState({
																						Name: name.target.value,
																					});
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card></Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	{
																		<div className="form-group my-1 mr-sm-2">
																			<label for="CategoryName">
																				Status
																				<span className="mandatory">*</span>
																			</label>
																			<br />
																			<label class="radio-inline">
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "Active"
																							? true
																							: false
																					}
																					onChange={() => {
																						this.setState({
																							Status: "Active",
																						});
																					}}
																				/>{" "}
																				Active
																			</label>
																			<label
																				class="radio-inline"
																				style={{ marginLeft: "10px" }}
																			>
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "Inactive"
																							? true
																							: false
																					}
																					onChange={() => {
																						this.setState({
																							Status: "Inactive",
																						});
																					}}
																				/>{" "}
																				Inactive
																			</label>
																		</div>
																	}
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveAttribute.bind(this)}
																	>
																		Save New Attribute
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddAttributeMaster;
