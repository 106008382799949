import React, { Component } from "react";

import PostApiCall from "../../Api";

import Notiflix from "notiflix";

import Select from 'react-select';
import { CSVLink } from "react-csv";
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'


class CsvDownload extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();

    this.state = {
      StateData: [],

      StateId: [],
      WarehouseData: [],

      WareHouseId: [],
      fileData: [],


    };
  }






  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        countryid: 101,
      },
      "GetState"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((objstate) => {
        if (results.status == 200 || results.status == 201) {
          if (objstate.data.length != 0) {
            // this.props.setstate()

            this.setState({

              StateData: objstate.data,


            }

            );
          }
          Notiflix.Loading.Dots("Please wait...");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_status='Active' AND fld_state=${objstate.data[0].value}`,
              recordCount: 'fld_name as label,fld_warehouseid as value'

            },
            "GetWarehouseByState"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                if (obj.data.length != 0) {

                  // //console.log(obj.data)
                  this.setState({
                    WarehouseData: obj.data,


                  });
                }
              }
            }))
          Notiflix.Loading.Remove();

        }
      }))




  }

  onChangeState(state) {
    this.setState(
      {
        //    StateId: state.target.value,
        StateId: state,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {

            whereClause: `where fld_status='Active' AND fld_state=${this.state.StateId.value}`,
            recordCount: 'fld_name as label,fld_warehouseid as value'

          },
          "GetWarehouseByState"
        ).then((results) =>
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              if (obj.data.length != 0) {


                this.setState({
                  WarehouseData: obj.data,
                });
              }
              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }

  onChangeWareHouse(warehouse) {
    this.setState({
      //WareHouseId: warehouse.target.value,
      WareHouseId: warehouse,
    });
  }



  DownalodStockCSV = () => {

    if (JSON.stringify(this.state.StateId) != "[]") {
      if (JSON.stringify(this.state.WareHouseId) != "[]") {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
          {
            warehouse: this.state.WareHouseId.value
          },
          "downloadStockcsv"
        ).then((results) =>
          // const objs = JSON.parse(result._bodyText)
          results.json().then((obj) => {
            if (results.status == 200 || results.status == 201) {
              if (obj.data.length != 0) {
                // this.props.setstate()

                this.setState({

                  fileData: obj.data,

                }, () => {
                  setTimeout(() => {
                    this.csvLink.current.link.click();
                  });
                });
              }
              Notiflix.Loading.Remove();
            }
          }))
      }
      else {
        Notiflix.Notify.Failure('Please select Warehouse')
      }
    }
    else {
      Notiflix.Notify.Failure('Please select State')
    }
  }


  render() {
    return (


      <React.Fragment>
        <div className="d-flex justify-content-center" style={{ height: '370px', marginBottom: '25px' }}>
          <div class="mt-1 price-card py-5 col-10">

            <div style={{ marginTop: '-25px' }}>
              <h4 className="stepper-heading d-flex justify-content-center align-items-center">To update pricing upload the CSV file with the latest pricing of products.</h4><br />
            </div>

            <div className="col-12">
              <label className="mt-1">Select State</label>
              <Select



                options={this.state.StateData}
                value={this.state.StateId}
                onChange={this.onChangeState.bind(this)}




                isSearchable={true}
                isClearable={true} />

              <label className="mt-2">Select Warehouse</label>

              <Select
                options={this.state.WarehouseData}
                value={this.state.WareHouseId}
                onChange={this.onChangeWareHouse.bind(this)}
                isSearchable={true}
                isClearable={true} />





            </div>
            <div>
              <button className="btn downloadcsv-button mt-4 mb-4" style={{ float: 'right' }}

                onClick={this.DownalodStockCSV.bind(this)}
              >
                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>



                Download CSV</button>

              <CSVLink
                data={this.state.fileData}
                filename={this.state.WareHouseId.label + '.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}







export default CsvDownload;



