import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-responsive-modal/styles.css";
import moment from "moment";
import Searchicon from '../../assets/images/dashboardimages/search.png'
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import Saveicon from '../../assets/images/dashboardimages/filesave.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas" style={{ marginBottom: '-5px' }}>
        <div className="img-wrap brandimages img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);


class BoxMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {

            imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',

            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
            ImageData: [],
            BoxName: "",
            BoxId: null,

            CompanyId: null,
            BoxData: [],
            searchInput: '',
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,
            BoxLogo: null,
            ImageName: null
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("");


        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                WhereClause: ''
            },
            "Get_BrandMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        BoxData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))

    }





    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };


    onSaveData = () => {
        if (this.state.BoxName != "") {
            if (this.state.imagePreviewUrl != 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png') {
                this.onPost();
            } else {
                Notiflix.Notify.Failure("Please upload box image");
            }
        } else {
            Notiflix.Notify.Failure("Please enter the box name");
        }
    }






    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        PostApiCall.postRequest(
            {
                BoxId: this.state.BoxId,
                name: this.state.BoxName,
                status: this.state.Status === "Yes" ? "Active" : "Inactive",
                createdon: moment().format("YYYY-MM-DD"),
                updatedon: moment().format("YYYY-MM-DD"),
                updatedby: details[0].fld_userid,
                action: this.state.BoxId === null ? 'INSERT' : 'UPDATE',
            },
            "AddBrand"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {

                    PostApiCall.postRequest(
                        {
                            id: JSON.parse(JSON.stringify(obj.data[0])).BoxId,
                            image:
                                JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/BrandImages/" +
                                this.state.BoxName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,
                            updatedon: moment().format("YYYY-MM-DD"),
                            updatedby: details[0].fld_userid,
                        },
                        "UpdateBrandImage"
                    ).then((results1) =>
                        results1.json().then((obj1) => {
                            if (results1.status == 200 || results1.status == 201) {
                                // //console.log(obj1)

                                Notiflix.Notify.Success("Brand successfully added. Image upload in process");
                                this.onUploadImage(obj);
                                //window.location.reload();
                            }
                            else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj1.data);
                            }
                        }))


                    //  //console.log(obj)
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );
    };
    async onUploadImage(obj) {


        Notiflix.Loading.Dots("Uploading Images...");

        let response;
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);


        if (this.state.BoxLogo != this.state.imagePreviewUrl) {
            if (this.state.outputFileName != undefined) {
                var options = {
                    maxSizeMB: 0.05,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                imageCompression(this.state.originalImage, options)
                    .then((compressedFile) => {

                        const form = new FormData();

                        form.append("file", compressedFile);
                        form.append("foldername", "BrandImages");
                        form.append(
                            "filename", this.state.BoxName.trim().replace(/\s/g, "-") +
                            "-" + compressedFile.name

                        );
                        // //console.log(this.state.BoxName.trim().replace(/\s/g, "-") +
                        // 	"-" + compressedFile.name)
                        response = fetch(this.state.ImageApiUrl, {
                            method: "POST",
                            body: form,
                        }).then(response => response.json())
                            .then(data => {
                                // //console.log(res.data.Message)
                                // //console.log((JSON.parse(JSON.stringify(obj.data[0]))).CategoryId)
                                this.setState({
                                    ImageName: data.Message.split(",")[2].split("=")[1].trim()
                                })
                                Notiflix.Loading.Remove()
                                Notiflix.Notify.Success("Image successfully uploaded.")
                                window.location.reload();
                            }

                            )
                        // //console.log(response);


                    })
            } else {
                Notiflix.Loading.Remove();
                Notiflix.Notify.Failure("Please Upload Brand Image");
            }
        } else {
            PostApiCall.postRequest(
                {
                    id: JSON.parse(JSON.stringify(obj.data[0])).BoxId,
                    image: this.state.imagePreviewUrl,
                    updatedon: moment().format("YYYY-MM-DD"),
                    updatedby: details[0].fld_userid,
                },
                "UpdateBrandImage"
            ).then((results1) =>
                results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                        // //console.log(obj1)
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Success("Brand successfully added.");
                        window.location.reload();
                    }
                    else {
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Failure(obj1.data);
                    }
                })
            );

        }
    }


    // onEditData = () => {

    // }


    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>




                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Box Master"} urlPath={"/boxsize_master"} />


                        {/* ==========Vertical======= */}



                        <Row>

                            <Col xl="12">
                                <Card className="overflow-hidden pagebackground">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text" id="upload-card">
                                                        Box Master
                                                    </h5>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </Card>
                                    <Card >

                                        <CardBody className="pt-0">
                                            <Row>
                                                <Col xs="4">
                                                    <CardBody className="categoryCard">

                                                        <Row className="card-row">
                                                            <Col sm="6">
                                                                <div className="imagecard">
                                                                    <div className="form-group ">
                                                                        <label for="sw-arrows-first-name">
                                                                            Upload Box Image
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <div class="div1">
                                                                            <ImgUpload
                                                                                onChange={this.photoUpload}
                                                                                src={this.state.imagePreviewUrl}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <hr style={{border:'1px solid #fff'}}/> */}
                                                            </Col>
                                                            <Col sm="6" style={{ marginBottom: '-10px' }}>
                                                                <div className="row">
                                                                    {this.state.BoxId == null ?
                                                                        <h5 >Add New Box</h5>
                                                                        :
                                                                        <h5 >Edit Box </h5>
                                                                    }
                                                                    <div className="col-12">
                                                                        <label class="my-1 mr-2">Box Name *</label>

                                                                        <input
                                                                            className="form-control"
                                                                            name="name"
                                                                            type="text"
                                                                            placeholder="Enter Vertical Name"
                                                                            value={this.state.BoxName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    BoxName: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-12" style={{ marginTop: '10px' }}>
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Status
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <br />
                                                                            <label class="radio-inline">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "Yes"
                                                                                            ? true
                                                                                            : false
                                                                                    }

                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "Yes",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Active
                                                                            </label>
                                                                            <label
                                                                                class="radio-inline"
                                                                                style={{ marginLeft: "10px" }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "No"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "No",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Inactive
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <hr />
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                            </Col>
                                                            <Col sm="6">

                                                                {this.state.BoxId == null ?
                                                                    <a
                                                                        onClick={this.onSaveData.bind(this)}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "5px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Save Box

                                                                    </a>
                                                                    :
                                                                    <a
                                                                        onClick={this.onSaveData.bind(this)}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "5px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Update Box

                                                                    </a>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Col>


                                                {/* ======Brand List */}
                                                {this.state.BoxData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    }
                                                    if (
                                                        this.state.searchInput !== "" &&
                                                        (data.fld_name
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase()) ||

                                                            `${data.fld_status
                                                                ? data.fld_status.toLowerCase()
                                                                : ""
                                                                }`.includes(this.state.searchInput.toLowerCase()))
                                                    ) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <CardBody className="categoryCard">

                                                            <Row className="card-row">
                                                                <Col sm="5" style={{ marginTop: '15px' }}>
                                                                    <div className="imagecard">
                                                                        <div className="form-group custom-file-upload fas">

                                                                            <div class="div1 img-wrap brandimages">
                                                                                <img

                                                                                    src={data.fld_logo}
                                                                                    style={{ width: "100%", height: "100%", borderRadius: "5%" }}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <hr style={{border:'1px solid #fff'}}/> */}
                                                                </Col>
                                                                <Col sm="7">
                                                                    <div className="row">
                                                                        <div className="col-12" style={{ marginTop: '10px' }}>
                                                                            <p>
                                                                                <b>Box Name</b> -{" "}
                                                                                <span

                                                                                >
                                                                                    {data.fld_name}
                                                                                </span>
                                                                            </p>

                                                                        </div>

                                                                        <div className="col-12">

                                                                            <p>
                                                                                <b>Total Items</b> - {" "}
                                                                                <span>
                                                                                    {data.total_items}
                                                                                </span>
                                                                            </p>
                                                                            <p>
                                                                                <b>Total Variants</b> - {" "}
                                                                                <span>
                                                                                    {data.total_variant}
                                                                                </span>
                                                                            </p>
                                                                        </div>





                                                                    </div>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6" className="BrandStatus">
                                                                    <p >
                                                                        <b>Status</b> -{" "}
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    data.fld_status == "Active"
                                                                                        ? "green"
                                                                                        : "red",
                                                                            }}
                                                                        >
                                                                            {data.fld_status}
                                                                        </span>
                                                                    </p>
                                                                </Col>
                                                                <Col sm="6">


                                                                    <span><img src={deleteicon} alt="block user" className="btnicons"
                                                                        style={{ float: 'right', marginLeft: '5px', marginTop: "15px" }}

                                                                        onClick={() => {
                                                                            confirmAlert({
                                                                                title: "Confirm to Delete",
                                                                                message:
                                                                                    "Are you sure you want to delete brand.",
                                                                                buttons: [
                                                                                    {
                                                                                        label: "Yes",
                                                                                        onClick: () => {
                                                                                            Notiflix.Loading.Dots("");

                                                                                            PostApiCall.postRequest(
                                                                                                {
                                                                                                    id: data.fld_brand_id,
                                                                                                    status: "Inactive",
                                                                                                },
                                                                                                "Delete_Brand"
                                                                                            ).then((results) =>
                                                                                                // const objs = JSON.parse(result._bodyText)
                                                                                                results.json().then((obj) => {
                                                                                                    if (
                                                                                                        results.status == 200 ||
                                                                                                        results.status == 201
                                                                                                    ) {
                                                                                                        Notiflix.Loading.Remove();
                                                                                                        Notiflix.Notify.Success(
                                                                                                            "Brand successfully deleted."
                                                                                                        );
                                                                                                        window.location.reload();
                                                                                                    } else {
                                                                                                        Notiflix.Loading.Remove();
                                                                                                        Notiflix.Notify.Failure(
                                                                                                            "Something went wrong, try again later."
                                                                                                        );
                                                                                                    }
                                                                                                })
                                                                                            );
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        label: "No",
                                                                                        // onClick: () => alert('Click No')
                                                                                    },
                                                                                ],
                                                                            });
                                                                        }}
                                                                    ></img></span>
                                                                    <span>
                                                                        <a
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    BoxName: data.fld_name,
                                                                                    BoxId: data.fld_brand_id,
                                                                                    Status: data.fld_status == 'Active' ? "Yes" : "No"
                                                                                })
                                                                                if (data.fld_logo != null) {

                                                                                    this.setState({
                                                                                        imagePreviewUrl: data.fld_logo,
                                                                                        BoxLogo: data.fld_logo
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    this.setState({
                                                                                        imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',

                                                                                    })
                                                                                }
                                                                                window.location.href = "#upload-card"
                                                                            }}
                                                                            // onClick={this.onSaveData.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "10px", color: "white" }}
                                                                        >
                                                                            <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                            Edit Brand
                                                                        </a>
                                                                    </span>



                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>






                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default BoxMaster;