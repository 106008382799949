import React, { Component } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import NoImage from "../../assets/images/NoImage.png"



class TopCities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      CartData: []
    };

  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    var customer = localStorage.getItem('customerDetails');
    var CustomerDetails = JSON.parse(customer)

    Notiflix.Loading.Dots("Please wait...");



    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);


    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);


    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();
          // console.log(res1.data)

          this.setState({ CartData: res1.data });
        }
      });
  };



  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 800,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              In Cart
            </CardTitle>



            {this.state.CartData.length != 0 ?
              <Slider {...settings}>
                {this.state.CartData.map((data, i) => (
                  <div class="best-products">
                    <div class="product-item">
                      <div class="img-container" width="50%">
                        <img src={data.VariantImage ? data.VariantImage : NoImage} alt="product image"></img>
                      </div>

                      <div class="product-desc">
                        <h4>
                          {data.fld_variantname} </h4>

                      </div>
                    </div>
                  </div>
                )

                )}
              </Slider>

              :
              <p>There is no item present in the customer's cart.</p>
            }

          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default TopCities;
