import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

import Notiflix from "notiflix";
import { Card, CardBody, Media } from "reactstrap";
import PostApiCall from "../../Api";

class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [{
				name: 'Sales Summary',
				data: []
			}],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "Month",
					categories: [

						"January",
						"February",
						"March",
						"April",
						"May",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December",

					],
				},
				tooltip: {
					x: {
						format: "₹",
					},
				},
			},
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("Please wait...");



		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DashboardYearlySales"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {
					this.setState({
						SalesData: obj2.data,

					});

					const categories = [


						"January",
						"February",
						"March",
						"April",
						"May",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December",

					];

					this.setState({
						categories: categories,
					});

					const data = new Array(12).fill(0);


					for (var i = 0; i < obj2.data.length; i++) {


						for (var j = 0; j < categories.length; j++) {


							// console.log(categories[j])

							if (obj2.data[i].month == categories[j]) {
								data[i] = obj2.data[i].total_sales

							}

							this.setState({
								series: [
									{ name: "Sales Summary", data: data },

								],
							});


						}
					}


					Notiflix.Loading.Remove();
				}
			}))

	}

	render() {
		//   console.log(this.state.series)

		return (
			<React.Fragment>
				<Card className="mini-stats-wid" style={{ marginLeft: '10px' }}>
					<CardBody>
						<Media>
							<Media body>
								<h4 className="mb-0">Sales Summary Chart </h4>
								<ReactApexChart
									options={this.state.options}
									series={this.state.series}
									type="area"
									height={210}
									width={910}
								/>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</React.Fragment>


		);
	}
}

export default Chart;

