import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logo from "../../assets/images/bechofylogo.png";
import logox from "../../assets/images/bechofylogo.png";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        errormassege: '',
        error: false
      },
      username: {
        value: '',
        errormassege: '',
        error: false
      },
      password: {
        value: '',
        errormassege: '',
        error: false
      }

    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.registerUser(values);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }




  onEmailChange = (e) => {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (!reg.test(e.target.value)) {

      this.setState({
        ...this.state,
        email: {
          ...this.email,
          errormassege: "invalid email",
          error: true,
          value: e.target.value
        }
      })

    }
    else {
      this.setState({
        ...this.state,
        email: {
          ...this.email,

          error: false,
          value: e.target.value
        }
      })

    }


  }
  onEmailBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        email: {
          ...this.email,
          errormassege: "Email Feild could not be empty",
          error: true,
        }
      })

    }



  }
  onUsernameChange = (e) => {

    this.setState({
      ...this.state,
      username: {
        ...this.username,
        value: e.target.value
      }
    })
  }


  onUsernameBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        username: {
          ...this.username,
          errormassege: "Username Feild could not be empty",
          error: true,
        }
      })

    }



  }
  onPasswordChange = (e) => {

    this.setState({
      ...this.state,
      password: {
        ...this.password,
        value: e.target.value
      }
    })
  }


  onPasswordBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        password: {
          ...this.password,
          errormassege: "Password Feild could not be empty",
          error: true,
        }
      })

    }



  }



  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col className="col-7">
                        <div className="Bechofy-muted-text p-4">
                          <h5 className="Bechofy-muted-text">Free Register</h5>
                          <p>Get your free Skote account now.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="col-md-6">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logox} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4 d-none d-lg-block" style={{
                          position: 'absolute',
                          left: '291px',
                          top: '-33px'
                        }}>
                          <img src={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_storelogo} alt="" height="34" />

                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.user && this.props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {this.props.registrationError &&
                          this.props.registrationError ? (
                          <Alert color="danger">
                            {this.props.registrationError}
                          </Alert>
                        ) : null}




                        <div className="form-group">
                          <label for="email">Email</label>
                          <input value={this.state.email.value}
                            onBlur={(e) => {
                              this.onEmailBlur(e)
                            }}
                            onChange={(e) => this.onEmailChange(e)} type="email" className="form-control" placeholder="Enter email" id="email" />
                          {this.state.email.error && <div className="is-invalid text-danger">{this.state.email.errormassege}</div>}

                        </div>
                        <div className="form-group">
                          <label for="username">User Name</label>
                          <input value={this.state.username.value}
                            onBlur={(e) => {
                              this.onUsernameBlur(e)
                            }}
                            onChange={(e) => this.onUsernameChange(e)} type="text" className="form-control" placeholder="Enter username" id="username" />
                          {this.state.username.error && <div className="is-invalid text-danger">{this.state.username.errormassege}</div>}

                        </div>
                        <div className="form-group">
                          <label for="pwd">Password:</label>
                          <input type="password" onChange={(e) => {
                            this.onPasswordChange(e)
                          }}
                            onBlur={(e) => {
                              this.onPasswordBlur(e)
                            }}
                            className="form-control" placeholder="Enter password" id="pwd" />
                          {this.state.password.error && <div className="is-invalid text-danger">{this.state.password.errormassege}</div>}

                        </div>
                        {/* <div className="form-group form-check">
                                                    <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox"/> Remember me
                                                    </label>
                                                </div>
                                                <div className="mt-3">
                                                    <button className="btn Bechofy-btn btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div> */}





                        <div className="mt-4">
                          <button
                            className="btn Bechofy-btn btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Bechofy{" "}
                            <Link to="#" className="Bechofy-text">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium Bechofy-text"
                    >
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  {/* <p>
                    <p>© {new Date().getFullYear()} Bechofy. Powered  <i className="mdi mdi-heart text-danger"></i> by Global Trendz</p>
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, { registerUser, apiError, registerUserFailed })(Register);
