import React,{Component} from "react";
import SideBar from "./SideBar";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class Profile extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return(
            <React.Fragment>
                <div className="page-content">
					<Container fluid >
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-0">
                                        <SideBar/>
                                    </div>
                                    <div className="col-lg-9 col-md-12">
                                        <CardBody>
                                            <CardTitle className="usertitle sidebar-heading my-4">
												Add or Edit Address
                                            </CardTitle>
                                            <Row>
                                                <div class="col-lg-5 col-md-5 col-sm-2 mb-3">
                                                    <Card className="p-3 px-4 address-card">
                                                        <div class="address-content">
                                                            <h5>Deepanshi Jain</h5>
                                                            <p>Kath Mandi, Railway Road, <br/>
                                                            Bahadurgarh, Haryana <br/> 124507, India</p>
                                                            <h6>+91 8168095773</h6>
                                                        </div>
                                                        <div class="row address-icon-row">
                                                            <div class="col-6"></div>
                                                            <div class="col-6">
                                                                <a class="address-icon">
                                                                    <i class="fas fa-edit"
                                                                        style={{
                                                                            float:'right',
                                                                            fontSize: '20px',
                                                                        }}>
                                                                    </i>
                                                                </a>
                                                                <a class="address-icon">
                                                                    <i class="fas fa-trash"
                                                                        style={{
                                                                            float:'right',
                                                                            fontSize: '20px',
                                                                            marginRight:'10%'
                                                                        }}>
                                                                    </i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-2 mb-3">
                                                    <div class="d-flex align-items-center justify-content-center address-box-profile address-card">
                                                        <div class="address-plus">
                                                            <a class="address-icon">
                                                                <i class="fas fa-plus-circle"
                                                                    style={{
                                                                        fontSize: '60px',
                                                                    }}
                                                                    onClick={() => {
                                                                        window.location.href = "/addressbook_form";
                                                                    }}
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Profile;