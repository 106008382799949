import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class AddCarrerManagment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			searchInput: "",
			VerticleData: [],
			ViewClick: "true",
			open: false,
			Status: "Active",
			Name: "",
			openedit: false,
			Id: "",
			CategoryData: [],
			Category: [],
			VerticleDrop: [],
			Cat: [],
			PredefinedCategory: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetVerticalMapping").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticleData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetVerticalCategory").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CategoryData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticleDrop: obj.data,
				});
				// //console.log(obj.data)
				Notiflix.Loading.Remove();
			})
		);
	}

	SaveAttribute() {
		//  //console.log(this.state.Name)
		if (this.state.Name != "") {
			if (JSON.stringify(this.state.Category) != "[]") {
				this.onPost();
			} else {
				Notiflix.Notify.Failure("Please select category");
			}
		} else {
			Notiflix.Notify.Failure("Please enter vertical name");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var addcategory = "";
		if (this.state.PredefinedCategory.length == this.state.Category.length) {
			for (var i = 0; i < this.state.Category.length; i++) {
				if (i == 0) {
					addcategory = this.state.Category[i].fld_categoryid;
				} else {
					addcategory =
						addcategory + "," + this.state.Category[i].fld_categoryid;
				}
			}
		} else {
			for (var i = 0; i < this.state.Category.length; i++) {
				if (i == 0) {
					addcategory = this.state.Category[i].fld_categoryid;
				} else {
					addcategory =
						addcategory + "," + this.state.Category[i].fld_categoryid;
				}
			}
		}

		PostApiCall.postRequest(
			{
				verticlemappingid: this.state.Name,
				Categoryid: addcategory,
				active: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				createdby: details[0].fld_userid,
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
			},
			"AddVerticleMapping"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("New Vertical successfully added.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};

	onChangeName(text) {
		this.setState(
			{
				Name: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.Name,
					},
					"GetCatgeoryById"
				).then((results2) =>
					results2.json().then((obj2) => {
						if (results2.status == 200 || results2.status == 201) {
							//  //console.log(obj2.data)
							let arr = [];
							if (obj2.data) {
								obj2.data.map((item) => {
									arr.push({ category: item.fld_categoryname });
								});
							}
							// //console.log(arr.fld_subcategoryname)
							this.setState({
								Cat: arr,
							});

							let arr2 = [];
							if (obj2.data) {
								// //console.log(obj2.data)
								obj2.data.map((item) => {
									arr2.push({ fld_categoryid: item.fld_categoryid });
								});
							}

							// //console.log(arr2)
							this.setState({
								Category: arr2,
								PredefinedCategory: arr2,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Vertical Mapping  Master"}
							breadcrumbItem={"Map New Vertical"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Map New Vertical
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Map New Vertical
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="form-group">
																			<label for="validationCustom01">
																				Vertical Name
																				<span class="mandatory">*</span>
																			</label>
																			<select
																				value={this.state.Name}
																				onChange={this.onChangeName.bind(this)}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">
																					Select Vertical{" "}
																				</option>
																				{this.state.VerticleDrop.map(
																					(attri) => (
																						<option
																							key={attri.value}
																							value={attri.value}
																						>
																							{attri.label}
																						</option>
																					)
																				)}
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Assign Category to Vertical
																</h5>
															</div>
														</Col>
													</Row>
													<Row></Row>
												</div>

												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																{this.state.CategoryData.map((data, index) => (
																	<div class="col-md-6">
																		{this.state.Cat.length > 0 && (
																			<input
																				defaultChecked={
																					this.state.Cat.length > 0
																						? this.state.Cat.map((item) => {
																							if (
																								item.category ===
																								data.fld_categoryname
																							) {
																								return true;
																							} else {
																								return false;
																							}
																						}).filter((item) => {
																							if (item == true) {
																								return true;
																							}
																							if (item !== true) {
																								return false;
																							}
																						})[0] == true
																							? true
																							: false
																								? null
																								: null
																						: null
																				}
																				onClick={() => {
																					var ar = [...this.state.Category];

																					if (ar.includes(data)) {
																						ar.splice(ar.indexOf(data), 1);
																					} else {
																						ar.push(data);
																					}

																					this.setState({
																						Category: ar,
																					});
																				}}
																				type="checkbox"
																			/>
																		)}

																		{this.state.Cat.length == 0 && (
																			<input
																				checked={
																					this.state.Cat.length > 0
																						? this.state.Cat.map((item) => {
																							if (
																								item.category ===
																								data.fld_categoryname
																							) {
																								return true;
																							} else {
																								return false;
																							}
																						}).filter((item) => {
																							if (item == true) {
																								return true;
																							}
																							if (item !== true) {
																								return false;
																							}
																						})[0] == true
																							? true
																							: false
																								? null
																								: null
																						: null
																				}
																				onClick={() => {
																					var ar = [...this.state.Category];

																					if (ar.includes(data)) {
																						ar.splice(ar.indexOf(data), 1);
																					} else {
																						ar.push(data);
																					}

																					this.setState({
																						Category: ar,
																					});

																					// //console.log(ar)
																				}}
																				type="checkbox"
																			/>
																		)}

																		<span> {data.fld_categoryname}</span>
																	</div>
																))}
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "7px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveAttribute.bind(this)}
																	>
																		Map Attribute
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddCarrerManagment;
