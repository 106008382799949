import React, { Component } from "react";

import moment from "moment";
import Notiflix from "notiflix";
import { Card, CardBody, Col, Row } from "reactstrap";
import PostApiCall from "../../Api";

class LatestTranaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomerData: []
        }
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {

                WhereClause: 'Order By fld_customer_id DESC',
                RecordCount: 'distinct Top 10 fld_customer_id,fld_name,fld_email_address,fld_phone_number,CityName,StateName,fld_created_on '
            },
            "GetCustomerList"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        CustomerData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <div>
                        <Row>


                            <Col xs="6">
                                <div className="Bechofy-text p-3">
                                    <h4 className="Bechofy-text" style={{ color: '#495057' }}>New Customers</h4>

                                </div>
                            </Col>
                            {/* <hr /> */}
                        </Row>
                    </div>

                    <CardBody className="pt-0">
                        <Row>

                            <Col xs="12">

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                            <th className="centered" style={{ width: '300px' }}>Customer Name</th>
                                            <th className="centered" style={{ width: '300px' }}>Email Address</th>
                                            <th className="centered">Contact No.</th>
                                            <th className="centered" style={{ width: '145px' }}>City</th>
                                            <th className="centered" style={{ width: '160px' }}>State</th>
                                            <th className="centered">Registered On </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.CustomerData.map((data, i) => (
                                            <tr>
                                                <td className="centered">{i + 1}</td>
                                                <td className="centered">{data.fld_name}</td>
                                                <td className="centered">{data.fld_email_address}</td>
                                                <td className="centered">{data.fld_phone_number}</td>

                                                <td className="centered">{data.CityName ? data.CityName : "NA"}</td>
                                                <td className="centered">{data.StateName ? data.StateName : "NA"}</td>
                                                <td className="centered">{moment(data.fld_created_on).format("DD/MM/YYYY")}</td>

                                            </tr>
                                        ))}
                                    </tbody>

                                </table>

                            </Col>

                        </Row>
                    </CardBody>
                </Card>


                {/* ==========Latest Pickups ==========*/}

            </React.Fragment>
        );
    }
}

export default LatestTranaction;
