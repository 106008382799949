import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";

class CategoryMappingMaster extends Component {
	state = {
		enabled: false,
		startDate: new Date(),
		endDate: new Date(),
		imageValue: "",
		isImageValid: null,
		category: "",
		FilterValue: "",

		productCategoryDetails: [
			{
				categoryValue: "",
				subCategory: [],
			},
		],
	};
	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};

	onMapChange = () => { };

	onHn;

	render() {
		const options = [
			{ value: "chocolate", label: "Chocolate" },
			{ value: "strawberry", label: "Strawberry" },
			{ value: "vanilla", label: "Vanilla" },
		];
		//console.log(this.state.productCategoryDetails);

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy/Product Management"}
							breadcrumbItem={"CategoryMappingMaster"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													Product
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Category Mapping Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0" style={{ height: "60vh" }}>
										<Row>
											<Col xs="12">
												<div className="form my-4">
													{this.state.productCategoryDetails.map((x, i) => {
														return (
															<div className="row ">
																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Select Category
																		</label>
																		<select
																			onChange={(e) => {
																				this.setState({
																					...this.state,
																					category: e.target.value,
																				});
																			}}
																			className="form-control"
																		>
																			<option value="">Select Category</option>
																			<option value="Category1">
																				Category1
																			</option>
																			<option value="Category2">
																				Category2
																			</option>
																			<option value="Category3">
																				Category3
																			</option>
																			<option value="Category4">
																				Category4
																			</option>
																		</select>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Select Sub Category
																		</label>
																		<Select
																			isMulti
																			name="colors"
																			options={options}
																			onChange={(e) => {
																				this.setState({
																					...this.state,
																					FilterValue: e,
																				});
																			}}
																			value={this.state.FilterValue}
																			className="basic-multi-select"
																			classNamePrefix="select"
																		/>
																	</div>
																</div>
																<div className="col-1 offset-1">
																	<div className="form-group">
																		<label for="CategoryName">Map</label>

																		<button
																			onClick={this.onMapChange}
																			className="btn Bechofy-btn form-control"
																			type="button"
																		>
																			go
																		</button>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1    my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={() => { }}
														>
															Create
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default CategoryMappingMaster;
