import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-responsive-modal/styles.css";

import Searchicon from '../../assets/images/dashboardimages/search.png';



class GST extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchInput: "",
            GSTData: [],
            ViewClick: "true",
            open: false,
            Status: "Active",
            GSTName: "",
            openedit: false,
            GSTId: null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            isdivvisible: false
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("");

        PostApiCall.postRequest(
            {
                WhereClause: '',
                RecordCount: '*'
            },
            "GetGST"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        GSTData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }

    onSaveData() {
        if (this.state.GSTName != "") {
            this.onPost();
        } else {
            Notiflix.Notify.Failure("Please enter gst percent");
        }
    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        PostApiCall.postRequest(
            {
                fldgstid: this.state.GSTId,
                fldgstpercentage: this.state.GSTName,
                fldstatus: this.state.Status,
                updated_by: details[0].fld_userid,
            },
            "AddGST"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    //   //console.log(obj)
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("New GST % successfully added.");
                    window.location.reload();
                } else {
                    Notiflix.Notify.Failure(obj);
                }
            })
        );
    };





    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <div className="row">
                            <div className="col-md-6">

                                <div>
                                    <ul class="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>                                        <li>GST Master</li>
                                        <li>GST List</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

                            </div>


                        </div>





                        <Row>

                            <Col xl="12">
                                <Card className="overflow-hidden pagebackground">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        GST Master List
                                                    </h5>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </Card>
                                    <Row>
                                        <Col xl="4">
                                            <Card>

                                                <CardBody className="pt-0">
                                                    <Row>
                                                        <Col xs="12">
                                                            {this.state.Id == null ?
                                                                <h5 style={{ marginTop: '12px' }}>Add New GST (%)</h5>
                                                                :
                                                                <h5 style={{ marginTop: '12px' }}>Edit GST (%)</h5>
                                                            }

                                                            <Row className="card-row">
                                                                <Col sm="12">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label class="my-1 mr-2">GST (%)</label>

                                                                            <input
                                                                                className="form-control"
                                                                                name="name"
                                                                                type="text"
                                                                                placeholder="Enter GST (5) value"
                                                                                value={this.state.GSTName}
                                                                                onChange={(text) => {
                                                                                    if (
                                                                                        this.state.DecimalRegex.test(
                                                                                            text.target.value
                                                                                        )) {
                                                                                        this.setState({
                                                                                            GSTName: text.target.value,
                                                                                            isdivvisible: true
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="bg-image py-2 " style={{ display: this.state.isdivvisible == true ? "flex" : "none", }}>
                                                                                <h1 style={{ margin: '0' }}> {this.state.GSTName} %</h1>
                                                                            </div>
                                                                            {/* <h1> {this.state.GST} %</h1> */}
                                                                            {/* <hr style={{ border: '1px solid red' }} /> */}
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-12" style={{ marginTop: '10px' }}>
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Status
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <br />
                                                                            <label class="radio-inline">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "Active"
                                                                                            ? true
                                                                                            : false
                                                                                    }

                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "Active",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Active
                                                                            </label>
                                                                            <label
                                                                                class="radio-inline"
                                                                                style={{ marginLeft: "10px" }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "InActive"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "Inactive",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Inactive
                                                                            </label>
                                                                        </div>


                                                                    </div>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                </Col>
                                                                <Col sm="6">

                                                                    {this.state.GSTId == null ?
                                                                        <button
                                                                            onClick={this.onSaveData.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Save
                                                                        </button> :
                                                                        <button
                                                                            onClick={this.onSaveData.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    }
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                        <Col xl="8">
                                            <Row>


                                                {/* =====GST List */}
                                                {this.state.GSTData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    }
                                                    if (
                                                        this.state.searchInput !== "" &&
                                                        (data.fld_gstpercentage.toString()
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase()) ||

                                                            `${data.fld_status
                                                                ? data.fld_status.toLowerCase()
                                                                : ""
                                                                }`.includes(this.state.searchInput.toLowerCase()))
                                                    ) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <Card>
                                                            <CardBody className="categoryCard">

                                                                <Row className="card-row">

                                                                    <Col sm="12">
                                                                        <div className="row">
                                                                            <div className="col-12">

                                                                                <p >
                                                                                    <b>GST (%)</b> -{" "}
                                                                                    <span

                                                                                    >
                                                                                        {data.fld_gstpercentage} %
                                                                                    </span>
                                                                                </p>
                                                                            </div>


                                                                            <div className="col-12" style={{ marginTop: '5px' }}>
                                                                                <div className="form-group">
                                                                                    <p >
                                                                                        <b>Status</b> -{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    data.fld_status == "Active"
                                                                                                        ? "green"
                                                                                                        : "red",
                                                                                            }}
                                                                                        >
                                                                                            {data.fld_status}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>


                                                                            </div>






                                                                        </div>
                                                                    </Col>
                                                                    <hr />
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="6" className="BrandStatus">

                                                                    </Col>
                                                                    <Col sm="6">

                                                                        <button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    Status: data.fld_status,

                                                                                    GSTId: data.fld_gstid,
                                                                                    GSTName: data.fld_gstpercentage,
                                                                                });
                                                                            }}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Edit GST(%)
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>








                                </Card>
                            </Col>
                        </Row>
                    </Container >
                </div >
            </React.Fragment >
        );
    }
}
export default GST;
