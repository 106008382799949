import React, { Component } from "react";

import { connect } from "react-redux";


// reactstrap

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


// import images

// Redux Store

//i18n
import { withNamespaces } from "react-i18next";
import Notiflix from "notiflix";
// import GETApiCall from "../../GETAPI";
import PostApiCall from "../../Api";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      Menu: [],
      SubMenu: [],
      Details: [],
      ClientName: ""

    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }


  /**
   * Toggles the sidebar
   */

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  componentDidMount() {



    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
    // var matchingMenuItem = null;
    // var ul = document.getElementById("navigation");
    // var items = ul.getElementsByTagName("a");
    // for (var i = 0; i < items.length; ++i) {
    //   if (this.props.location.pathname === items[i].pathname) {
    //     matchingMenuItem = items[i];
    //     break;
    //   }
    // }
    // if (matchingMenuItem) {
    //   this.activateParentDropdown(matchingMenuItem);
    // }

    // =====as admin and member======

    // check if client is loaded or not if the 

    if (localStorage.getItem("ClientDetails") == null) {

      Notiflix.Notify.Failure("Session timed out. Please access the page from main business domain again.")

    } else {
      var clientDetails = localStorage.getItem("ClientDetails");
      var clientInformation = JSON.parse(clientDetails)
      this.setState({
        ClientName: clientInformation[0].fld_companyname,
      })
    }
    var login = localStorage.getItem('LoginDetail');
    var details = JSON.parse(login)
    //  //console.log(details)

    var lastlogin = localStorage.getItem('lastlogin');
    var LastDateTime = JSON.parse(lastlogin)

    // //console.log(LastDateTime)
    this.setState({
      Details: details[0],
      Logintime: LastDateTime
    })








    Notiflix.Loading.Custom("");


    PostApiCall.postRequest({

      staffid: 1,
      // staffid : details[0].fld_designation,

    }, "MenuList").then((results) =>


      results.json().then(obj => {
        // //console.log(results)
        if (results.status == 200 || results.status == 201) {

          // //  //console.log(obj.data)
          this.setState({
            Menu: obj.data
          })


          PostApiCall.postRequest({

            staffid: 1,
            // staffid: details[0].fld_designation,

          }, "SubmenuList").then((resultssub) =>

            // const objs = JSON.parse(result._bodyText)
            resultssub.json().then(objsub => {
              if (resultssub.status == 200 || resultssub.status == 201) {

                //   //console.log(objsub.data)
                var data = []
                var arr = new Array(Object.keys(obj.data).length).fill([])


                if (obj.data.length == 0) {
                  Notiflix.Loading.Remove()
                }
                for (var i = 0; i < Object.keys(obj.data).length; i++) {


                  data = []
                  for (var j = 0; j < Object.keys(objsub.data).length; j++) {

                    if (obj.data[i].fld_menuid == objsub.data[j].fld_parentid) {

                      data.push(objsub.data[j])
                      arr[i] = data
                      //   //console.log(arr)
                      this.setState({
                        SubMenu: arr
                      })



                    }

                  }
                  if (i == Object.keys(obj.data).length - 1) {
                    localStorage.setItem('SubMenuRights', JSON.stringify(arr))
                    Notiflix.Loading.Remove()
                  }


                }

              } else {
                Notiflix.Loading.Remove()
                Notiflix.Notify.Failure(objsub.data)
              }
            }))
        } else {
          Notiflix.Loading.Remove()
          Notiflix.Notify.Failure(obj.data)
        }
      }))



  }



  render() {
    return (
      <React.Fragment>
        <header
          id="page-topbar"
          style={{ backgroundColor: "#1a1819", color: "white" }}
        >
          <div className="navbar-header">
            <div className="d-flex">
              <h3 className="CompanyName">{this.state.ClientName}</h3>

            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("Search") + "..."}
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>

              <NotificationDropdown />

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, null)(withNamespaces()(Header));
