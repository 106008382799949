import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import Notiflix from "notiflix";
import PostApiCall from "../../../Api";

//Import images
import NoNotifications from "../../../assets/images/NoNotifications.svg";

//i18n
import { withNamespaces } from "react-i18next";

class NotificationDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			Notifications: [],
			NotificationsCount: [],
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		Notiflix.Loading.Dots("");

		//get notification details
		PostApiCall.postRequest(
			{
				id: details[0].fld_userid,
			},
			"GetAdminPanelNotifications"
		).then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					Notifications: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);

		//get notification count
		PostApiCall.postRequest(
			{
				id: details[0].fld_userid,
			},
			"GetAdminPanelNotificationsCount"
		).then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					NotificationsCount: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}
	render() {
		return (
			<React.Fragment>
				<Dropdown
					isOpen={this.state.menu}
					toggle={this.toggle}
					className="dropdown d-inline-block"
					tag="li"
				>
					<DropdownToggle
						className="btn header-item noti-icon waves-effect"
						tag="button"
						id="page-header-notifications-dropdown"
					>
						<i className="bx bx-bell bx-tada"></i>
						{this.state.NotificationsCount.map((item, i) => {
							return (
								<span className="badge badge-danger badge-pill">
									{item.notif_count}
								</span>
							);
						})}
					</DropdownToggle>



					<DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
						<div className="p-3">
							<Row className="align-items-center">
								<Col>
									<h6 className="m-0"> {this.props.t("Notifications")} </h6>
								</Col>

							</Row>
						</div>

						{this.state.Notifications.length == 0 ?
							<SimpleBar style={{ height: "230px" }}>
								<div className="media">
									<div className="media-body" style={{ position: "absolute", top: "13%", right: "25%" }}>
										<img src={NoNotifications} height="100px" width="100px"></img>
										<p>No Notifications found ! </p>
									</div>
								</div>
							</SimpleBar>
							:


							<SimpleBar style={{ height: "230px" }}>
								{this.state.Notifications.map((item, i) => {
									return (
										<Link to="" className="text-reset notification-item">
											<div className="media">
												<div className="media-body">
													<h6 className="mt-0 mb-1">{item.fld_header}</h6>
													<div className="font-size-12 text-muted">
														<p className="mb-1">{item.fld_description}</p>
														<p className="mb-0">
															<i className="mdi mdi-clock-outline"></i>{" "}
															{item.notif_duration}{" "}
														</p>
													</div>
												</div>
											</div>
										</Link>
									);
								})}
							</SimpleBar>
						}
						<div className="p-2 border-top" style={{ display: this.state.Notifications.length == 0 ? "none" : "block" }}>
							<Link
								className="btn btn-sm btn-link font-size-14 btn-block text-center"
								to="/view_notification"
							>
								{" "}
								{this.props.t("View all")}{" "}
							</Link>
						</div>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}
export default withNamespaces()(NotificationDropdown);
