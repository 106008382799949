import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "react-confirm-alert/src/react-confirm-alert.css";

class VariantManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			MaterialData: [],
			open: false,
			openedit: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
			VariantData: [],
			variantId: null,
			variantPriceId: null,
			MRP: null,
			sellingPrice: null,
			costPrice: null,
			websitedisprice: null,
			variantName: null,
		};
	}

	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");
		PostApiCall.postRequest(
			{
				id: null,
			},
			"GetVariantData"
		).then((results4) =>
			results4.json().then((obj4) => {
				if (results4.status == 200 || results4.status == 201) {
					this.setState({
						VariantData: obj4.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);
	}
	UpdateVariant() {
		if (this.state.Name != "") {
			this.onPost2();
		} else {
			Notiflix.Notify.Failure("Please enter verticlename");
		}
	}

	onPost2 = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				variantpriceid: this.state.variantPriceId,
				variantid: this.state.variantId,
				mrp: this.state.MRP,
				costPrice: this.state.costPrice,
				sellingPrice: this.state.sellingPrice,
				websiteDiscountPrice: this.state.websiteDisPrice,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVariantPrice"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Variant successfully updated.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};
	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "SKU",
					field: "sku",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Variant Name",
					field: "variantname",
					sort: "disabled",
					width: 400,
				},
				{
					label: "BarCode",
					field: "barcode",
					sort: "disabled",
					width: 150,
				},
				{
					label: "MRP",
					field: "mrp",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Selling Price",
					field: "sellingprice",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Cost Price",
					field: "costprice",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Discount Price",
					field: "websiteDiscountPrice",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Update Price",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],

			rows: this.state.VariantData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_itemname
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_subcategoryname
							? data.fld_subcategoryname.toLowerCase()
							: ""
							}`.includes(this.state.searchInput.toLowerCase()))
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: <td>{i + 1}</td>,
					sku: <td>{data.fld_sku}</td>,
					variantname: <td>{data.fld_variantname}</td>,
					barcode: <td>{data.fld_barcode}</td>,
					mrp: <td>{data.fld_mrp}</td>,
					sellingprice: <td>{data.fld_selling_price}</td>,
					costprice: <td>{data.fld_cost_price}</td>,
					websitediscountprice: <td>{data.fld_website_discount_price}</td>,
					action: (
						<td className="text-center actionuser">
							<i
								className="fas fa-edit btn"
								style={{ fontSize: "15px", marginTop: "-11px" }}
								onClick={() => {
									this.setState({
										openedit: true,
										variantName: data.fld_variantname,
										variantId: data.fld_variantid,
										MRP: data.fld_mrp,
										sellingPrice: data.fld_selling_price,
										costPrice: data.fld_cost_price,
										websitedisprice: data.fld_website_discount_price,
										variantPriceId: data.fld_variantpriceid,
									});

									//    this.props.setfootweartype(data.fld_type)
								}}
							></i>
						</td>
					),
				};
			}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Modal
							open={this.state.openedit}
							onClose={() => {
								this.setState({ openedit: false });
							}}
							center
						>
							<div class="modal-content modelcontent2">
								<div
									class="modal-header Bechofy-bg-soft-pink"
									style={{ background: "#777f80" }}
								>
									<h4 class="modal-title text-white">Update Variant</h4>
								</div>
								<div class="modal-body">
									<div class="col-md-12">
										<div class="form-group mb-3">
											<label for="validationCustom01">
												Variant Name<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												class="form-control"
												value={this.state.variantName}
												onChange={(text) => {
													this.setState({
														variantName: text.target.value,
													});
												}}
											/>
										</div>
										<div class="form-group mb-3">
											<label for="validationCustom01">
												MRP<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												class="form-control"
												value={this.state.MRP}
												onChange={(text) => {
													this.setState({
														MRP: text.target.value,
													});
												}}
											/>
										</div>
										<div class="form-group mb-3">
											<label for="validationCustom01">
												Selling Price<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												class="form-control"
												value={this.state.sellingPrice}
												onChange={(text) => {
													this.setState({
														sellingPrice: text.target.value,
													});
												}}
											/>
										</div>
										<div class="form-group mb-3">
											<label for="validationCustom01">
												Cost Price <span class="mandatory">*</span>
											</label>
											<input
												type="text"
												class="form-control"
												value={this.state.costPrice}
												onChange={(text) => {
													this.setState({
														costPrice: text.target.value,
													});
												}}
											/>
										</div>
										<div class="form-group mb-3">
											<label for="validationCustom01">
												Website Discount Price<span class="mandatory">*</span>
											</label>
											<input
												type="text"
												class="form-control"
												value={this.state.websitedisprice}
												onChange={(text) => {
													this.setState({
														websitedisprice: text.target.value,
													});
												}}
											/>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button
										className="btn align-items-center Bechofy-btn"
										type="submit"
										style={{ float: "right" }}
										onClick={() => {
											this.setState({
												openedit: false,
												Status: "Active",
											});
										}}
									>
										Close
									</button>

									<button
										style={{ float: "right" }}
										className="btn align-items-center Bechofy-btn "
										onClick={this.UpdateVariant.bind(this)}
									>
										Update Variant
									</button>
									<span></span>
								</div>
							</div>
						</Modal>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Product Management"}
							breadcrumbItem={"Variant List"}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Product Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Variant List
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row></Row>
												</CardBody>
												<CardBody className="pt-0">
													<Row>
														<MDBCol md="3" style={{ marginBottom: "10px" }}>
															<input
																className="form-control"
																type="text"
																placeholder="Search"
																aria-label="Search"
																onChange={(e) => this.seachBarHandler(e)}
																value={this.state.searchInput}
															/>
														</MDBCol>
														<Col md="12">
															{this.state.VariantData.length == 0 ? (
																<p style={{ textAlign: "center" }}>
																	<b>No Variant List Available</b>
																</p>
															) : (
																<MDBDataTableV5
																	striped
																	bordered
																	small
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																>
																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTableV5>
															)}
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default VariantManagement;
