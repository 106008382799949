import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";

class SubMenuMaster extends Component {
	state = {
		enabled: false,
		startDate: new Date(),
		endDate: new Date(),
		imageValue: "",
		isImageValid: null,
		inputList: [{ menuName: "", subMenuName: "", pageName: null }],
	};

	onAddMoreHandler = () => {
		this.setState({
			inputList: [...this.state.inputList, { menuName: "", pageNAme: null }],
		});
	};

	onRemoveHandler = (index) => {
		let list = [...this.state.inputList];

		//   list.splice(index, 1);
		list = list.filter((data) => data !== index);
		this.setState({ ...this.state, inputList: list });
	};

	handleInputChange = (e, index) => {
		const { name, value } = e.target;

		//console.log(name, value);
		const list = [...this.state.inputList];
		list[index][name] = value;
		this.setState({ ...this.state, inputList: list });
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy/Menu Management"}
							breadcrumbItem={"Sub Menu Master"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													Menu
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Sub Menu Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="form my-4">
													{this.state.inputList.map((x, i) => {
														return (
															<div className="row">
																<div className="col col-3">
																	<label
																		class="my-1 mr-2"
																		for="inlineFormCustomSelectPref"
																	>
																		Menu Type
																	</label>
																	<select
																		name="menuName"
																		onChange={(e) => {
																			this.handleInputChange(e, i);
																		}}
																		class="custom-select my-1 mr-sm-2"
																		id="inlineFormCustomSelectPref"
																	>
																		<option selected>Choose...</option>
																		<option value={"Menu1"}>Menu1</option>
																		<option value={"Menu2"}>Menu2</option>
																	</select>
																</div>
																<div className="col col-3">
																	<label class="my-1 mr-2">SubMenu Name</label>

																	<input
																		className="form-control"
																		name="subMenuName"
																		placeholder="Enter Sub Menu Name"
																		value={
																			this.state.inputList[i]["subMenuName"]
																		}
																		onChange={(e) => {
																			this.handleInputChange(e, i);
																		}}
																	/>
																</div>
																<div className="col col-3">
																	<label
																		class="my-1 mr-2"
																		for="inlineFormCustomSelectPref"
																	>
																		Sub Menu Type
																	</label>
																	<select
																		name="pageName"
																		onChange={(e) => {
																			//console.log(this.state.inputList);
																			this.handleInputChange(e, i);
																		}}
																		class="custom-select my-1 mr-sm-2"
																		id="inlineFormCustomSelectPref"
																	>
																		<option selected>Choose...</option>
																		<option
																			value={
																				"/" +
																				`${this.state.inputList[i]["subMenuName"]}`
																					.split(" ")
																					.join("")
																					.toLowerCase()
																			}
																		>
																			{"/" +
																				`${this.state.inputList[i]["subMenuName"]}`
																					.split(" ")
																					.join("")
																					.toLowerCase()}
																		</option>
																	</select>
																</div>
																<div className="col col-3">
																	<label class="my-1 mr-2">Action Type</label>
																	<br />

																	{this.state.inputList.length !== 1 && (
																		<button
																			onClick={() => this.onRemoveHandler(x)}
																			className="btn btn-danger m-2"
																		>
																			Remove
																		</button>
																	)}
																	{this.state.inputList.length - 1 === i && (
																		<button
																			onClick={this.onAddMoreHandler}
																			className="btn Bechofy-btn"
																		>
																			Add More
																		</button>
																	)}
																</div>
															</div>
														);
													})}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1    my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={() => { }}
														>
															Create
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default SubMenuMaster;
