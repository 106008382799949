import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-responsive-modal/styles.css";

import Searchicon from '../../assets/images/dashboardimages/search.png';


class AttributePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			searchInput: "",
			AttributeData: [],
			ViewClick: "true",
			open: false,
			Status: "Active",
			Name: "",
			openedit: false,
			Id: null,
			AttributeName: null
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetAttributeMasterList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data)

				this.setState({
					AttributeData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}

	onSaveData() {
		if (this.state.AttributeName != null) {
			this.onPost();
		} else {
			Notiflix.Notify.Failure("Please enter attribute name");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				attributename: this.state.AttributeName,
				status: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
			},
			"AddAttributeMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("New Attribute successfully added.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};

	UpdateAttribute() {
		if (this.state.AttributeName != null) {
			this.onPost2();
		} else {
			Notiflix.Notify.Failure("Please enter attribute name");
		}
	}

	onPost2 = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				id: this.state.Id,
				attributename: this.state.AttributeName,
				status: this.state.Status,
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
			},
			"UpdateAttributeMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Attribute successfully updated.");
					window.location.reload();
				} else {
					Notiflix.Notify.Failure(obj);
				}
			})
		);
	};




	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>Attribute Management</li>
										<li>Attribute List</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								{/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

							</div>


						</div>





						<Row>

							<Col xl="12">
								<Card className="overflow-hidden pagebackground">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text" id="upload-card">
														Attribute List
													</h5>
												</div>
											</Col>

										</Row>
									</div>

									<Card style={{ padding: '10px 20px' }}>
										<div className="row my-1 ">


											<div className="col-12">
												<div class="input-group">


													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1">
															<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
													</div>
													<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
														onChange={(e) => this.seachBarHandler(e)}
														value={this.state.searchInput}

													/>
												</div>
											</div>


										</div>
									</Card>
									<Row>
										<Col xl="4">
											<Card>

												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															{this.state.Id == null ?
																<h5 style={{ marginTop: '12px' }}>Add New Attribute</h5>
																:
																<h5 style={{ marginTop: '12px' }}>Edit Attribute</h5>
															}

															<Row className="card-row">
																<Col sm="12">
																	<div className="col-12">
																		<label class="my-1 mr-2">Attribute Name</label>

																		<input
																			className="form-control"
																			name="name"
																			type="text"
																			placeholder="Enter Attribute Name"
																			value={this.state.AttributeName}
																			onChange={(text) => {
																				this.setState({
																					AttributeName: text.target.value,
																				});
																			}}
																		/>
																	</div>

																	<div className="col-12" style={{ marginTop: '10px' }}>
																		<div className="form-group">
																			<label for="CategoryName">
																				Status
																				<span className="mandatory">*</span>
																			</label>
																			<br />
																			<label class="radio-inline">
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "Active"
																							? true
																							: false
																					}

																					onChange={() => {
																						this.setState({
																							Status: "Active",
																						});
																					}}
																				/>{" "}
																				Active
																			</label>
																			<label
																				class="radio-inline"
																				style={{ marginLeft: "10px" }}
																			>
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "InActive"
																							? true
																							: false
																					}
																					onChange={() => {
																						this.setState({
																							Status: "InActive",
																						});
																					}}
																				/>{" "}
																				Inactive
																			</label>
																		</div>


																	</div>
																</Col>
																<hr />
															</Row>
															<Row>
																<Col sm="6">
																</Col>
																<Col sm="6">

																	{this.state.Id == null ?
																		<button
																			onClick={this.onSaveData.bind(this)}
																			className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																			style={{ marginTop: "5px", color: "white" }}
																		>
																			Save Attribute
																		</button> :
																		<button
																			onClick={this.UpdateAttribute.bind(this)}
																			className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																			style={{ marginTop: "5px", color: "white" }}
																		>
																			Update Attribute
																		</button>
																	}
																</Col>
															</Row>

														</Col>
													</Row>
												</CardBody>
											</Card>

										</Col>
										<Col xl="8">
											<Row>


												{/* ======Attribute List */}
												{this.state.AttributeData.filter((data) => {
													if (this.state.searchInput == "") {
														return data;
													}
													if (
														this.state.searchInput !== "" &&
														(data.fld_attributename
															.toLowerCase()
															.includes(this.state.searchInput.toLowerCase()) ||

															`${data.fld_status
																? data.fld_status.toLowerCase()
																: ""
																}`.includes(this.state.searchInput.toLowerCase()))
													) {
														return data;
													}
												}).map((data, i) => (

													<Col xs="4">
														<Card>
															<CardBody className="categoryCard">

																<Row className="card-row">

																	<Col sm="12">
																		<div className="row">
																			<div className="col-12">

																				<p >
																					<b>Attribute Name</b> -{" "}
																					<span

																					>
																						{data.fld_attributename}
																					</span>
																				</p>
																			</div>


																			<div className="col-12" style={{ marginTop: '5px' }}>
																				<div className="form-group">
																					<p >
																						<b>Status</b> -{" "}
																						<span
																							style={{
																								color:
																									data.fld_status == "Active"
																										? "green"
																										: "red",
																							}}
																						>
																							{data.fld_status}
																						</span>
																					</p>
																				</div>


																			</div>






																		</div>
																	</Col>
																	<hr />
																</Row>
																<Row>
																	<Col sm="6" className="BrandStatus">

																	</Col>
																	<Col sm="6">

																		<button
																			onClick={() => {
																				this.setState({
																					Status: data.fld_status,

																					Id: data.fld_attributeid,
																					AttributeName: data.fld_attributename,
																				});
																				window.location.href = "#upload-card"
																			}}
																			className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																			style={{ marginTop: "5px", color: "white" }}
																		>
																			Edit Attribute
																		</button>
																	</Col>
																</Row>
															</CardBody>
														</Card>
													</Col>
												))}
											</Row>
										</Col>
									</Row>








								</Card>
							</Col>
						</Row>
					</Container >
				</div >
			</React.Fragment >
		);
	}
}
export default AttributePage;
