import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import imageCompression from "browser-image-compression";



const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);

class AddCoating extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        ////console.log("param " + action);
        this.state = {
            imagePreviewUrl:
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
            ImageData: [],
            VerticalData: [],
            CoatingName: "",
            CoatingID: null,
            Action: action,
            CoatingDescription: "",
            Verticalname: "",
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,
            coatingType: "",
            ImageName: null,
            CoatingImage: null
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });




        var det = localStorage.getItem("CoatingDetails");
        var Coatingdata = JSON.parse(det);

        if (Coatingdata != null) {
            if (this.state.Action == "update") {
                this.setState({
                    CoatingName: Coatingdata.fld_coating_name,
                    CoatingID: Coatingdata.fld_coating_id,
                    Status: Coatingdata.fld_status == 'Active' ? 'Yes' : 'No',
                    coatingType: Coatingdata.fld_coatingtype,

                });
            }

            if (Coatingdata.fld_image != null) {
                this.setState({
                    imagePreviewUrl: Coatingdata.fld_image,
                    CoatingImage: Coatingdata.fld_image,
                });
            } else {
                this.setState({
                    imagePreviewUrl:
                        "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
                });
            }

            if (this.state.Action == "update") {
                new Promise((resolve, reject) => {
                    setTimeout(resolve, 1000);
                }).then(() => {
                    this.setState({
                        CoatingDescription: Coatingdata.fld_description,
                        // TermsCondition:OfferData.fld_termscondition,
                    });
                });
            }
        }
    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };



    onChangeDescription(text) {
        this.setState({
            CoatingDescription: text.editor.getData(),
        });
    }

    onSaveData() {
        if (this.state.CoatingName != "") {
            if (this.state.coatingType != "") {
                if (
                    this.state.imagePreviewUrl !=
                    "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
                ) {
                    this.onPost();
                } else {
                    Notiflix.Notify.Failure("Please upload coating image");
                }
            } else {
                Notiflix.Notify.Failure("Please select coating type");
            }
        } else {
            Notiflix.Notify.Failure("Please enter the coating name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        if (this.state.CoatingID == null) {

            if (this.state.CoatingImage != this.state.imagePreviewUrl) {

                if (this.state.outputFileName != undefined) {

                    PostApiCall.postRequest(
                        {
                            coatingid: this.state.CoatingID,
                            coatingname: this.state.CoatingName,
                            description: this.state.CoatingDescription,
                            status: this.state.Status == "Yes" ? "Active" : "Inactive",
                            coatingtype: this.state.coatingType,
                            image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/OuterCoating/" +
                                this.state.CoatingName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,

                        },
                        "CoatingMaster"
                    ).then((resultcategory) =>
                        resultcategory.json().then((obj) => {
                            if (resultcategory.status == 200 || resultcategory.status == 201) {
                                Notiflix.Notify.Success("Coating successfully added. Image upload in process");

                                this.onUploadImage(obj);

                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        })
                    );

                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure("Please Upload Coating Image");
                }
            }
            else {
                this.onUpdateCoating()
            }
        }
        else {

            this.onUpdateCoating()
        }

    }



    onUpdateCoating = () => {

        PostApiCall.postRequest(
            {
                coatingid: this.state.CoatingID,
                coatingname: this.state.CoatingName,
                description: this.state.CoatingDescription,
                status: this.state.Status == "Yes" ? "Active" : "Inactive",
                coatingtype: this.state.coatingType,
                image: this.state.imagePreviewUrl,

            },
            "CoatingMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Notify.Success("Coating successfully added. Image upload in process");

                    this.onUploadImage(obj);
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );

    }



    async onUploadImage(obj) {
        Notiflix.Loading.Dots("Uploading Images...");

        let response;

        if (this.state.outputFileName != undefined) {

            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {



                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "OuterCoating");
                    form.append(
                        "filename",
                        this.state.CoatingName.trim().replace(/\s/g, "-") +
                        "-" +
                        compressedFile.name

                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then(response => response.json())
                        .then(data => {

                            Notiflix.Loading.Remove()
                            Notiflix.Notify.Success("Image successfully uploaded.")
                            window.location.href = "/coating_master";
                        }

                        )



                })
        }
        else {
            Notiflix.Loading.Remove()
            Notiflix.Notify.Success("Image successfully uploaded.")
            window.location.href = "/coating_master";
        }




    }

    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Coating Master"} breadcrumbItem1={this.state.CoatingID == null ? "Add New Coating" : "Update Coating"} urlPath={"/coating_master"} urlPath1={this.state.CoatingID == null ? "/add_coating/create" : "/add_coating/update"} />
                        <Row>
                            <Col xl="12">


                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    {this.state.CoatingID == null ?
                                                        <h5 className="Bechofy-text">
                                                            Add New Coating
                                                        </h5>
                                                        :
                                                        <h5 className="Bechofy-text">
                                                            Update Coating
                                                        </h5>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row  ">
                                                        <div className="col col-12">
                                                            <div className="row">

                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <label for="CoatingName">
                                                                            Coating Name
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="CoatingName"
                                                                            className="form-control"
                                                                            value={this.state.CoatingName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    CoatingName: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <label for="CoatingName">
                                                                            Coating Type
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <select
                                                                            className="form-control col-6"
                                                                            value={this.state.coatingType}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    coatingType: text.target.value,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value="">Select coating type</option>
                                                                            <option value="No coating">No coating</option>
                                                                            <option value="Inner coating">Inner coating</option>
                                                                            <option value="Outer coating">Outer coating</option>

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <label for="CoatingName">
                                                                            Show On Website
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <br />
                                                                        <label class="radio-inline">
                                                                            <input
                                                                                type="radio"
                                                                                name="optradio"
                                                                                //  disabled={!this.state.IsVisible}
                                                                                checked={
                                                                                    this.state.Status == "Yes"
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={() => {
                                                                                    this.setState({
                                                                                        Status: "Yes",
                                                                                    });
                                                                                }}
                                                                            />{" "}
                                                                            Yes
                                                                        </label>
                                                                        <label
                                                                            class="radio-inline"
                                                                            style={{ marginLeft: "10px" }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                name="optradio"
                                                                                //  disabled={!this.state.IsVisible}
                                                                                checked={
                                                                                    this.state.Status == "No"
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={() => {
                                                                                    this.setState({
                                                                                        Status: "No",
                                                                                    });
                                                                                }}
                                                                            />{" "}
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div class="form-group">
                                                                        <label for="sw-arrows-first-name">
                                                                            Description(maximum 500 Characters)
                                                                        </label>

                                                                        <div class="niceeditors">
                                                                            <CKEditor
                                                                                config={{
                                                                                    extraPlugins:
                                                                                        "justify,font,colorbutton",
                                                                                }}
                                                                                data={this.state.CoatingDescription}
                                                                                onChange={this.onChangeDescription.bind(
                                                                                    this
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-1"></div>
                                                            <div class="col-md-11">
                                                                <label for="sw-arrows-first-name">
                                                                    Upload Coating Image (Size &lt; 100kb,
                                                                    500*500)<span className="mandatory">*</span>
                                                                </label>
                                                                <div class="div1">
                                                                    <ImgUpload
                                                                        onChange={this.photoUpload}
                                                                        src={this.state.imagePreviewUrl}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            {this.state.CoatingID == null ? <span>Save Coating</span> : <span>Update Coating</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
                                    {/* </Card> */}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddCoating;