import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import ReactChipInput from "react-chip-input";

class AddCarrerManagment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			JobTitle: "",
			JobLocation: "",
			JobType: "",
			SalaryRange: "",
			JobType: "",

			Status: "Active",
			Name: "",
			chips: [],

			CategoryData: [],
			CategorySelect: [],
			groupArray: [],
			SubCategory: [],
			Attribute: "",
			AttributeData: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetAttributeMaster_Drop").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data);
				this.setState({
					AttributeData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		//       fld_categoryname: "Stationery "
		// fld_subcategoryname: "Pens & Pencils "
	}

	//   groupBy=(objectArray, property)=> {

	//     return objectArray.reduce((acc, obj) => {
	//        const key = obj[property];
	//        if (!acc[key]) {
	//           acc[key] = [];
	//        }
	//        // Add object to list for given key's value
	//        acc[key].push(obj);
	//        return acc;
	//     }, {});

	//  }

	addChip = (value) => {
		const chips = this.state.chips.slice();
		chips.push(value);
		this.setState({ chips });
		//console.log(chips);
	};
	removeChip = (index) => {
		const chips = this.state.chips.slice();
		chips.splice(index, 1);
		this.setState({ chips });
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription(text) {
		this.setState({
			JobDescription: text.editor.getData(),
		});
	}

	SaveAttribute() {
		//console.log("hi");

		//console.log(this.state.SubCategory);
	}

	//   onPost = () => {

	//     Notiflix.Loading.Dots('Please wait...');

	//     var login=localStorage.getItem('LoginDetail');
	//     var details=JSON.parse(login)

	//     PostApiCall.postRequest ({
	//        jobtitle : this.state.JobTitle,
	//        location : this.state.JobLocation,
	//        jobtype : this.state.JobType,
	//        description : this.state.JobDescription,
	//        salaryrange : this.state.SalaryRange,
	//        status : this.state.Status,
	//        created_on : moment().format('lll'),
	//        updated_on : moment().format('lll'),
	//        updated_by : details[0].fld_userid

	//       },"AddCareer").then((resultcategory =>
	//     resultcategory.json().then(obj => {
	//         if(resultcategory.status == 200 || resultcategory.status == 201){
	//           Notiflix.Loading.Remove();
	//           Notiflix.Notify.Success('New Job successfully added.')
	//            window.location.href = '/career-management'

	//         }else
	//           {
	//             Notiflix.Notify.Failure('Error Occured')
	//           }
	//     })
	//     ))

	//   }

	render() {
		//     let temp=[]

		//     this.state.CategoryData.map((data,i)=>{
		//       temp.push(data.fld_categoryname)
		//     })
		//     temp=[...new Set(temp)]

		// // //console.log(temp)
		// //     //console.log(this.groupBy(this.state.CategoryData,'fld_categoryname'))
		//         //  //console.log(this.state)

		// let groupTemp=this.groupBy(this.state.CategoryData,'fld_categoryname')
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs title={"Bechofy / Add New Attribute Details "} />

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Attribute Value Master
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Add New Attribute Value
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	{/* <div className="col col-6">
                   <div className="form-group">
                   <label for="CategoryName">Category Name</label>
                   <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref"
                   name="UserType" 
                   onChange={(text)=>{
                       this.setState({
                        UnitofMeasurement:text.target.value
                       })
                   }}
                   value={this.state.UnitofMeasurement}
                   >
                       <option>Select Category Name</option>
                       <option>Recycled Stationary</option>
                                       
                                        </select>
                  </div>
                     
                     </div> */}

																	<div className="col col-12">
																		<div className="form-group">
																			<label for="CategoryName">
																				Attribute Name
																			</label>
																			<select
																				value={this.state.Attribute}
																				onChange={(test) => {
																					this.setState({
																						Attribute: test.target.value,
																					});
																				}}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">
																					Select Attribute{" "}
																				</option>
																				{this.state.AttributeData.map(
																					(attri) => (
																						<option
																							key={attri.value}
																							value={attri.value}
																						>
																							{attri.label}
																						</option>
																					)
																				)}
																			</select>
																		</div>
																	</div>
																	<div className="col col-12">
																		<div className="form-group">
																			<label for="CategoryName">
																				{" "}
																				Attribute Values
																			</label>

																			<ReactChipInput
																				classes="class1 class2"
																				chips={this.state.chips}
																				onSubmit={(value) =>
																					this.addChip(value)
																				}
																				onRemove={(index) =>
																					this.removeChip(index)
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveAttribute.bind(this)}
																	>
																		Save New Attribute Value
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddCarrerManagment;
