import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import imageCompression from "browser-image-compression";

const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);

class DesignMaster extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        this.state = {
            imagePreviewUrl:
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",

            Status: "Yes",
            ImageData: [],
            Action: action,
            CategoryList: [],
            ProductName: null,
            ProductData: [],
            clicked: false,
            VariantData: [],
            DesignId: null,
            DesignName: null,
            Variant: null,
            DesignDescription: ''


        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        //   this.props.setclearbrand()

        Notiflix.Loading.Dots("Please wait...");

        GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    ProductData: obj.data,
                });
            })
        );


        var det = localStorage.getItem("DesignDetails");
        var DesignData = JSON.parse(det);



        if (DesignData != null) {
            if (this.state.Action == "update") {
                // console.log(DesignData)
                this.setState({
                    // ProductName: DesignData.
                    DesignId: DesignData.fld_design_id,
                    ProductName: DesignData.fld_product_id,
                    Status: DesignData.fld_status == 'Active' ? 'Yes' : 'No',
                    DesignName: DesignData.fld_design_name,
                    Variant: DesignData.fld_variant_id
                }, () => {

                    PostApiCall.postRequest(
                        {

                            whereClause: `where fld_productid=${DesignData.fld_product_id}`,
                            recordCount: 'fld_variantid as value,fld_variantname as label'
                        },
                        "GetVariantData"
                    ).then((results) =>
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                this.setState({
                                    VariantData: obj.data,

                                });
                                Notiflix.Loading.Remove();
                            }
                        }))
                });



                new Promise((resolve, reject) => {
                    setTimeout(resolve, 1000);
                }).then(() => {
                    this.setState({
                        DesignDescription: DesignData.fld_description,
                    });
                });
            }
        }
    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };

    onChangeDescription(text) {
        this.setState({
            DesignDescription: text.editor.getData(),
        });
    }

    onSaveData() {
        if (this.state.ProductName != null) {
            if (this.state.Variant != null) {
                if (this.state.DesignName != null) {
                    this.onPost()
                } else {
                    Notiflix.Notify.Failure("Please enter the design name");
                }
            } else {
                Notiflix.Notify.Failure("Please select  variant name");
            }
        } else {
            Notiflix.Notify.Failure("Please select product name");
        }
    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        if (this.state.DesignId == null) {



            PostApiCall.postRequest(
                {
                    designid: this.state.DesignId,
                    designname: this.state.DesignName,
                    variantid: this.state.Variant,
                    description: this.state.DesignDescription,
                    status: this.state.Status == "Yes" ? "Active" : "Inactive",
                    productid: this.state.ProductName,

                },
                "ProductDesignMaster"
            ).then((resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Success("Design successfully added");
                        //window.location.href = "/design_master"
                    } else {
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Failure(obj.data);
                    }
                })
            );

        }
        else {
            this.onUpdateDesign()
        }
    };

    onUpdateDesign = () => {

        PostApiCall.postRequest(
            {
                designid: this.state.DesignId,
                designname: this.state.DesignName,
                variantid: this.state.Variant,
                description: this.state.DesignDescription,
                status: this.state.Status == "Yes" ? "Active" : "Inactive",
                productid: this.state.ProductName,

            },
            "ProductDesignMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("Design successfully updated");
                    window.location.href = "/design_master"
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );

    }





    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Design Master"} breadcrumbItem1={this.state.DesignId == null ? "Add New Design" : "Update Design"} urlPath={"/design_master"} urlPath1={this.state.SubCategoryId == null ? "/add_design/create" : "/add_design/update"} />


                        <Row>
                            <Col xl="12">

                                <Card className="overflow-hidden">
                                    <Row>
                                        <Col xl="12">
                                            <Card className="overflow-hidden">
                                                <div className="Bechofy-bg-soft-pink">
                                                    <Row>
                                                        ]
                                                        <Col xs="3">
                                                            <div className="Bechofy-text p-3">
                                                                {this.state.DesignId == null ?
                                                                    <h5 className="Bechofy-text">
                                                                        Add New Design
                                                                    </h5>
                                                                    :
                                                                    <h5 className="Bechofy-text">
                                                                        Update Design
                                                                    </h5>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <CardBody className="pt-0">
                                                    <Row>
                                                        <Col xs="12">
                                                            <div className="form my-4">
                                                                <div className="row  ">
                                                                    <div className="col col-12">
                                                                        <div className="row">
                                                                            <div className="col col-3">
                                                                                <label
                                                                                    className="my-1 mr-2"
                                                                                    for="inlineFormCustomSelectPref"
                                                                                >
                                                                                    Item Name
                                                                                    <span className="mandatory">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                                <select
                                                                                    value={this.state.ProductName}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            ProductName: text.target.value
                                                                                        },
                                                                                            () => {
                                                                                                Notiflix.Loading.Dots("Please wait...");

                                                                                                PostApiCall.postRequest(
                                                                                                    {

                                                                                                        whereClause: `where fld_productid=${this.state.ProductName}`,
                                                                                                        recordCount: 'fld_variantid as value,fld_variantname as label'
                                                                                                    },
                                                                                                    "GetVariantData"
                                                                                                ).then((results) =>
                                                                                                    results.json().then((obj) => {
                                                                                                        if (results.status == 200 || results.status == 201) {
                                                                                                            this.setState({
                                                                                                                VariantData: obj.data,

                                                                                                            });
                                                                                                            Notiflix.Loading.Remove();
                                                                                                        }
                                                                                                    }))

                                                                                            }
                                                                                        )
                                                                                    }}
                                                                                    className="custom-select my-1 mr-sm-2"

                                                                                >
                                                                                    <option value="">
                                                                                        Select Item Name
                                                                                    </option>
                                                                                    {this.state.ProductData.map(
                                                                                        (product) => (
                                                                                            <option
                                                                                                key={product.value}
                                                                                                value={product.value}
                                                                                            >
                                                                                                {product.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>

                                                                            <div className="col col-3">
                                                                                <label
                                                                                    className="my-1 mr-2"
                                                                                    for="inlineFormCustomSelectPref"
                                                                                >
                                                                                    Variant Name
                                                                                    <span className="mandatory">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                                <select
                                                                                    value={this.state.Variant}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Variant: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                    className="custom-select my-1 mr-sm-2"
                                                                                >
                                                                                    <option>Select Variant</option>
                                                                                    {this.state.VariantData.map(
                                                                                        (variant) => (
                                                                                            <option
                                                                                                key={variant.value}
                                                                                                value={variant.value}
                                                                                            >
                                                                                                {variant.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-3">
                                                                                <div className="form-group">
                                                                                    <label for="DesignName">
                                                                                        Design Name
                                                                                        <span className="mandatory">
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        id="DesignName"
                                                                                        className="form-control my-1 mr-sm-2"
                                                                                        value={this.state.DesignName}
                                                                                        onChange={(text) => {
                                                                                            this.setState({
                                                                                                DesignName:
                                                                                                    text.target.value,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-3">
                                                                                <div className="form-group my-1 mr-sm-2">
                                                                                    <label for="CategoryName">
                                                                                        Show On Website
                                                                                        <span className="mandatory">
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                    <br />
                                                                                    <label class="radio-inline">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="optradio"
                                                                                            checked={
                                                                                                this.state.Status == "Yes"
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onChange={() => {
                                                                                                this.setState({
                                                                                                    Status: "Yes",
                                                                                                });
                                                                                            }}
                                                                                        />{" "}
                                                                                        Yes
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline"
                                                                                        style={{ marginLeft: "10px" }}
                                                                                    >
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="optradio"

                                                                                            checked={
                                                                                                this.state.Status == "No"
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            onChange={() => {
                                                                                                this.setState({
                                                                                                    Status: "No",
                                                                                                });
                                                                                            }}
                                                                                        />{" "}
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12">
                                                                                <div class="form-group">
                                                                                    <label for="sw-arrows-first-name">
                                                                                        Description(maximum 500
                                                                                        Characters)
                                                                                    </label>

                                                                                    <div class="niceeditors">
                                                                                        <CKEditor
                                                                                            config={{
                                                                                                extraPlugins:
                                                                                                    "justify,font,colorbutton",
                                                                                            }}
                                                                                            data={
                                                                                                this.state.DesignDescription
                                                                                            }
                                                                                            onChange={this.onChangeDescription.bind(
                                                                                                this
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="row">
                                                                        <div className="col-md-1"></div>
                                                                        <div class="col-md-5">
                                                                            <label for="sw-arrows-first-name">
                                                                                Upload Sub Category Image (Size &lt;
                                                                                100kb, 500*500)
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <div class="div1">
                                                                                <ImgUpload
                                                                                    onChange={this.photoUpload}
                                                                                    src={this.state.imagePreviewUrl}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>







                                            <Card>
                                                <CardBody className="py-1 my-1">
                                                    <Row>
                                                        <Col xs="12">
                                                            <div className="row">
                                                                <div className="col-3 offset-9">
                                                                    <button
                                                                        style={{ float: "right" }}
                                                                        className="btn align-items-center Bechofy-btn "
                                                                        onClick={this.onSaveData.bind(this)}
                                                                    >
                                                                        {this.state.DesignId == null ? <span>Save Design</span> : <span>Update Design</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                            {/* <WelcomeComp /> */}
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default DesignMaster;