import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Select from "react-select";


import Searchicon from '../../assets/images/dashboardimages/search.png'


import Editicon from '../../assets/images/dashboardimages/pencil.png'
import GtLogo from '../../assets/images/bechofylogo.png'


class WarehouseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            WarehouseName: '',
            WarehouseState: null,
            StateData: [],
            WarehouseData: [],
            PinCodeData: [],
            Pincodes: [],
            PincodesData: [],
            Status: 'Active',
            MappingData: [],
            SearchField: null,
            WarehousePincodeData: [],
            searchInput: ''
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                countryid: 101,
            },
            "GetState"
        ).then((results) =>
            results.json().then((objcon) => {
                if (results.status == 200 || results.status == 201) {
                    if (objcon.data.length != 0) {


                        var dt = []
                        dt.push({ label: 'All', value: 0 })
                        var newdat = [...dt, ...objcon.data]
                        this.setState({
                            StateData: newdat,

                        }
                        );

                        Notiflix.Loading.Remove();
                    }
                }
            })
        );



        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {

                whereClause: `where fld_status='Active'`,
                recordCount: 'fld_name as label,fld_warehouseid as value'

            },
            "GetWarehouseByState"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {


                        this.setState({
                            WarehouseData: obj.data,
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );



        // ========

        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {

                whereClause: '',
                RecordCount: '*'

            },
            "GetWarehousePincode"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        //console.log(obj.data)
                        this.setState({
                            WarehousePincodeData: obj.data,
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );

    }




    onSaveData() {
        //console.log(this.state.WarehouseState)
        if (this.state.WarehouseName != "") {
            if (this.state.WarehouseState != null) {


                if (this.state.Pincodes.length > 0 || this.state.WarehouseState == 0) {
                    this.onPost();
                } else {
                    Notiflix.Notify.Failure("Please select at least one Pincode");
                }
            } else {
                Notiflix.Notify.Failure("Please select state");
            }
        } else {
            Notiflix.Notify.Failure("Please select Warehouse Name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        var addpincode = "";

        for (var i = 0; i < this.state.Pincodes.length; i++) {
            if (i == 0) {
                addpincode = this.state.Pincodes[i].value;
            } else {
                addpincode = addpincode + ", " + this.state.Pincodes[i].value;
            }
        }

        PostApiCall.postRequest(
            {
                warehouseid: this.state.WarehouseName,
                stateid: this.state.WarehouseState,
                pincode: addpincode,
                active: this.state.Status,
                CreatedBy: details[0].fld_userid,
            },
            "Add_Warehouse_Pincode"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("warehouse is successfully Mapped with pincode")
                    window.location.reload()

                } else {
                    Notiflix.Notify.Failure(obj.data)
                }
            })
        );
    };

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    getPincode = () => {
        PostApiCall.postRequest(
            {
                id: this.state.WarehouseState,
            },
            "GetPincodeByState"
        ).then((results3) => {
            // //console.log(results3.json())

            results3.json().then((obj3) => {
                if (
                    results3.status == 200 ||
                    results3.status == 201
                ) {

                    this.setState({
                        PincodesData: obj3.data,
                    });

                    Notiflix.Loading.Remove();
                }
            });
        });

    }


    getPincodebyState = () => {
        PostApiCall.postRequest(
            {

                whereClause: `where fld_warehouseid=${this.state.WarehouseName} AND fld_stateid=${this.state.WarehouseState}`,
                RecordCount: '*'

            },
            "GetWarehousePincode"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {

                        for (var i = 0; i < obj.data.length; i++) {



                            var sp = []
                            for (var j = 0; j < obj.data[i].pincode.split(',').length; j++) {

                                sp.push({
                                    label: obj.data[i].pincode.split(',')[j],
                                    value: obj.data[i].pincode.split(',')[j]
                                })
                            }

                            this.setState({
                                Pincodes: sp
                            })

                        }
                    }

                }
                Notiflix.Loading.Remove();
            }))
    }

    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },

                {
                    label: "Warehouse Name",
                    field: "name",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "State Name",
                    field: "sname",
                    sort: "disabled",
                    width: 600,
                },

                {
                    label: "Pincode",
                    field: "pincode",
                    sort: "disabled",
                    width: 600,
                },


                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.WarehousePincodeData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()))

                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.state_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    sname: (data.state_name),
                    name: (data.fld_name),
                    pincode: (data.pincode),
                    action:
                        (<img src={Editicon} alt="Edit mapping" style={{ marginLeft: '2px' }} className="btnicons"
                            onClick={() => {
                                this.setState({
                                    WarehouseName: data.fld_warehouseid,
                                    WarehouseState: data.fld_stateid
                                }, () => {

                                    this.getPincode()

                                    this.getPincodebyState()
                                })
                            }}
                        ></img>
                        )
                }
            })
        }
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Warehouse Pincode Mapping"} urlPath={"/warehouse_pincode"} />

                        <Row>
                            <Col xl='12'>
                                <Card >
                                    <div className='Bechofy-bg-soft-pink col-12'>
                                        <Row>
                                            <Col xs='3'>
                                                <div className='Bechofy-text  p-3'>
                                                    <h5 className='Bechofy-text'>
                                                        Warehouse Mapping with Pincode
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className='pt-0 usercarddata'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className="row">

                                                        <div className='col-6'>
                                                            <div className='form-group'>
                                                                <label for='CategoryName'>
                                                                    Warehouse Name
                                                                    <span className='mandatory'>*</span>
                                                                </label>
                                                                <select
                                                                    type='text'
                                                                    id='CategoryName'
                                                                    className='form-control'
                                                                    name='Name'
                                                                    value={this.state.WarehouseName}
                                                                    onChange={(text) => {
                                                                        this.setState({
                                                                            WarehouseName: text.target.value
                                                                        })

                                                                    }}
                                                                >
                                                                    <option>Select Warehouse</option>
                                                                    {this.state.WarehouseData.map(
                                                                        (warehouse) => (
                                                                            <option
                                                                                key={warehouse.value}
                                                                                value={warehouse.value}
                                                                            >
                                                                                {warehouse.label}
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className='col-6'>

                                                            <div className="form-group">
                                                                <label for="CategoryName">
                                                                    State
                                                                    <span className='mandatory'>*</span></label>
                                                                <select
                                                                    value={this.state.WarehouseState}
                                                                    onChange={(text) => {
                                                                        this.setState({
                                                                            WarehouseState: text.target.value
                                                                        }, () => {
                                                                            this.getPincode()
                                                                            this.getPincodebyState()
                                                                        })

                                                                    }}
                                                                    className='form-control'
                                                                >
                                                                    <option value="">Select State </option>
                                                                    {this.state.StateData.map((attri) => (
                                                                        <option
                                                                            key={attri.value}
                                                                            value={attri.value}
                                                                        >
                                                                            {attri.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                        </div>


                                                        <div className='col-12' style={{ display: this.state.WarehouseState == 0 ? 'none' : 'block' }}>
                                                            <div className='form-group'>
                                                                <label for='CategoryName'>
                                                                    Pincode
                                                                    <span className='mandatory'>*</span>
                                                                </label>
                                                                <Select
                                                                    options={this.state.PincodesData}
                                                                    value={this.state.Pincodes}
                                                                    onChange={(as) => {
                                                                        this.setState({ Pincodes: as });
                                                                    }}
                                                                    isMulti
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className='py-1    my-1'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group my-1 mr-sm-2'>
                                                            <label for='CategoryName'>Status</label>
                                                            <br />
                                                            <label class='radio-inline'>
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Active" ? true : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Active",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Active
                                                            </label>
                                                            <label
                                                                class='radio-inline'
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Inactive"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Inactive",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Inactive
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button
                                                            style={{ float: "right", marginTop: "13px" }}
                                                            className='btn align-items-center Bechofy-btn '
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card >
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                        <div className="row my-1">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}> Warehouse Mapping with Pincode List</h4>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>


                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </MDBCol>

                                            <Col md="12">

                                                <MDBDataTable
                                                    hover
                                                    // scrollY
                                                    striped
                                                    bordered
                                                    data={data}
                                                    seachTop={false}
                                                    entriesOptions={[25, 50, 100]}

                                                >

                                                    <MDBTableHead columns={data.columns} />
                                                    <MDBTableBody rows={data.rows} />
                                                </MDBDataTable>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default WarehouseMaster;
