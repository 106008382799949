import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class AddAttributeMaster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Status: "Active",

			CategoryData: [],
			CategorySelect: [],
			groupArray: [],
			SubCategory: [],
			AttributeData: [],
			Attribute: "",
			subcat: [],
			PredefinedSubcategory: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetAttributeMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					CategoryData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetAttributeMaster_Drop").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					AttributeData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		//       fld_categoryname: "Stationery "
		// fld_subcategoryname: "Pens & Pencils "
	}

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({
			...this.state,
			imageValue: file,
			isImageValid: isvalid,
		});
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription(text) {
		this.setState({
			JobDescription: text.editor.getData(),
		});
	}

	SaveAttribute() {
		//console.log(JSON.stringify(this.state.SubCategory));
		if (this.state.Attribute != "") {
			if (JSON.stringify(this.state.SubCategory) != "[]") {
				this.onPost();
			} else {
				Notiflix.Notify.Failure("Please select sub category.");
			}
		} else {
			Notiflix.Notify.Failure("Please select attribute");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var addSubCategory = "";
		if (
			this.state.PredefinedSubcategory.length == this.state.SubCategory.length
		) {
			for (var i = 0; i < this.state.SubCategory.length; i++) {
				if (i == 0) {
					addSubCategory = this.state.SubCategory[i].fld_subcategoryid;
				} else {
					addSubCategory =
						addSubCategory + "," + this.state.SubCategory[i].fld_subcategoryid;
				}
			}
		} else {
			for (var i = 0; i < this.state.SubCategory.length; i++) {
				if (i == 0) {
					addSubCategory = this.state.SubCategory[i].fld_subcategoryid;
				} else {
					addSubCategory =
						addSubCategory + "," + this.state.SubCategory[i].fld_subcategoryid;
				}
			}
		}

		PostApiCall.postRequest(
			{
				attributeid: this.state.Attribute,
				SubcategoryId: addSubCategory,
				active: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
			},
			"AddAttributeMapping"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Attribute successfully mapped.");
					window.location.href = "/attribute";
				} else {
					Notiflix.Notify.Failure("Obj");
				}
			})
		);
	};
	onChangeattribute(text) {
		this.setState(
			{
				Attribute: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.Attribute,
					},
					"GetSubcategoryById"
				).then((results2) =>
					// const objs = JSON.parse(result._bodyText)
					results2.json().then((obj2) => {
						if (results2.status == 200 || results2.status == 201) {
							// //console.log(obj2.data.fld_subcategoryname)
							//console.log(obj2.data);
							let arr = [];
							if (obj2.data) {
								obj2.data.map((item) => {
									arr.push({ subcategory: item.fld_subcategoryname });
								});
							}
							// //console.log(arr.fld_subcategoryname)
							this.setState({
								subcat: arr,
							});

							let arr2 = [];
							if (obj2.data) {
								// //console.log(obj2.data)
								obj2.data.map((item) => {
									arr2.push({ fld_subcategoryid: item.FLD_SubCategoryID });
								});
							}

							// //console.log(arr2)
							this.setState({
								SubCategory: arr2,
								PredefinedSubcategory: arr2,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	render() {
		// //console.log(this.state.subcat)

		let temp = [];

		this.state.CategoryData.map((data, i) => {
			temp.push(data.fld_categoryname);
		});
		temp = [...new Set(temp)];

		let groupTemp = this.groupBy(this.state.CategoryData, "fld_categoryname");
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Attribute Mapping  Master"}
							breadcrumbItem={"Map New Attribute"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Attribute Mapping Master
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Map New Attribute
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="form-group">
																			<label for="CategoryName">
																				Attribute Name
																			</label>
																			<select
																				value={this.state.Attribute}
																				onChange={this.onChangeattribute.bind(
																					this
																				)}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">
																					Select Attribute{" "}
																				</option>
																				{this.state.AttributeData.map(
																					(attri) => (
																						<option
																							key={attri.value}
																							value={attri.value}
																						>
																							{attri.label}
																						</option>
																					)
																				)}
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Assign Category & Sub Category to Attribute
																</h5>
															</div>
														</Col>
													</Row>
													<Row></Row>
												</div>

												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																{temp.map((info, index) => (
																	<div
																		class="col-md-6"
																		style={{
																			marginTop: "1%",
																			marginBottom: "1%",
																		}}
																	>
																		<span>
																			{info}
																			{groupTemp[info] !== undefined &&
																				groupTemp[info].map((data) => {
																					// //console.log(this.state.subcat.fld_subcategoryname)
																					return (
																						<div
																							class="col-md-4"
																							style={{ marginTop: "4px" }}
																						>
																							{this.state.subcat.length > 0 && (
																								<input
																									defaultChecked={
																										this.state.subcat.length > 0
																											? this.state.subcat
																												.map((item) => {
																													if (
																														item.subcategory ===
																														data.fld_subcategoryname
																													) {
																														return true;
																													} else {
																														return false;
																													}
																												})
																												.filter((item) => {
																													if (item == true) {
																														return true;
																													}
																													if (item !== true) {
																														return false;
																													}
																												})[0] == true
																												? true
																												: false
																													? null
																													: null
																											: null
																									}
																									onClick={() => {
																										var ar = [
																											...this.state.SubCategory,
																										];

																										//console.log(ar);
																										if (ar.includes(data)) {
																											ar.splice(
																												ar.indexOf(data),
																												1
																											);
																										} else {
																											ar.push(data);
																										}

																										this.setState({
																											SubCategory: ar,
																										});

																										//console.log(
																										//this.state.subcat
																										//);
																									}}
																									type="checkbox"
																								/>
																							)}
																							{this.state.subcat.length ==
																								0 && (
																									<input
																										checked={
																											this.state.subcat.length > 0
																												? this.state.subcat
																													.map((item) => {
																														if (
																															item.subcategory ===
																															data.fld_subcategoryname
																														) {
																															return true;
																														} else {
																															return false;
																														}
																													})
																													.filter((item) => {
																														if (item == true) {
																															return true;
																														}
																														if (item !== true) {
																															return false;
																														}
																													})[0] == true
																													? true
																													: false
																														? null
																														: null
																												: null
																										}
																										onClick={() => {
																											var ar = [
																												...this.state.SubCategory,
																											];

																											//console.log(ar);
																											if (ar.includes(data)) {
																												ar.splice(
																													ar.indexOf(data),
																													1
																												);
																											} else {
																												ar.push(data);
																											}

																											this.setState({
																												SubCategory: ar,
																											});

																											//console.log(
																											//this.state.subcat
																											//);
																										}}
																										type="checkbox"
																									/>
																								)}{" "}
																							<span>
																								{" "}
																								{data.fld_subcategoryname}
																							</span>
																						</div>
																					);
																				})}
																		</span>
																	</div>
																))}
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "7px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveAttribute.bind(this)}
																	>
																		Map Attribute
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddAttributeMaster;
