import React, { Component } from "react";
import {
	Row, Col, Card, CardBody, Media,
} from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";


class SocialSource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			socials: [
				{ title: "Facebook", bgColor: "bg-primary", iconClass: "mdi-facebook", description: "125" },
				{ title: "Twitter", bgColor: "bg-info", iconClass: "mdi-twitter", description: "112" },
				{ title: "Instagram", bgColor: "bg-pink", iconClass: "mdi-instagram", description: "104" }
			],
			RefundSummaryAmount: [],
			RefundSummaryAll: [],
			RefundOrderThisMonth: [],
			RefundOrderThisYear: []
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			customSvgUrl: 'http://backoffice.Maxxchange.in/static/media/logo.b5951bfc.png',
			svgSize: '80px',
		});
		Notiflix.Loading.Custom("");

		PostApiCall.postRequest(
			{
				WhereClause: 'All'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						RefundSummaryAll: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund Amount'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						RefundSummaryAmount: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund This Month'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						RefundOrderThisMonth: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
		PostApiCall.postRequest(
			{
				WhereClause: 'Refund This Year'
			},
			"Get_DashboardRefundSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						RefundOrderThisYear: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))

	}

	render() {
		return (
			<React.Fragment>

				<Card className="mini-stats-wid">
					<CardBody>
						<Media>
							<Media body>
								<Row>
									<Col xl="6">
										<h4 className="mb-0">Refund Summary

										</h4>
									</Col>
								</Row>
								<hr />
								<p className="text-muted font-weight-medium">
									All Refund Orders
									{this.state.RefundSummaryAll.map((data, i) => {

										return (
											<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
												{data.order_count}
											</span>
										)


									})}
								</p>
								<hr />
								<p className="text-muted font-weight-medium">
									Refund Amount
									{this.state.RefundSummaryAmount.map((data, i) => {

										return (
											<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
												{data.order_count == null ? 0 : data.order_count}
											</span>
										)


									})}
								</p>
								<hr />
								<p className="text-muted font-weight-medium">
									Refund Order this Month
									{this.state.RefundOrderThisMonth.map((data, i) => {

										return (
											<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
												{data.order_count}
											</span>
										)


									})}
								</p>
								<hr />
								<p className="text-muted font-weight-medium">
									Refund Order this Year
									{this.state.RefundOrderThisYear.map((data, i) => {

										return (
											<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
												{data.order_count}
											</span>
										)


									})}
								</p>

							</Media>
						</Media>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default SocialSource;