import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import { connect } from "react-redux";

import {
	setname,
	setemail,
	setmobile,
	setdob,
	setanniversary,
	setdesignation,
	setdepartment,
	setusertype,
	setaddress,
	setcountry,
	setstate,
	setcity,
	setpincode,
	setaadharcard,
	setpancard,
	setdrivinglicenses,
	setaccountno,
	setbankname,
	setbranch,
	setIfsccode,
	setSalary,
	setuserpassword,
	setuserconfirmpassword,
	setclearuser,
} from "../../components/Actions/ActionType";

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class AddUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl:
				"https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",

			ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
			Status: "Active",
			ImageData: [],

			UnitofMeasurement: "",
			Price: 0,
			Points: 0,
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			RawMaterialName: "",
			GenderData: [
				{ value: "Male", label: "Male" },
				{ value: "Female", label: "Female" },
				{ value: "Others", label: "Others" },
			],
			Gender: "",
			CountryData: [],
			CityData: [],
			StateData: [],
			CountryId: 0,
			StateId: 0,
			CityId: 0,
			Name: "",
			Mobile: "",
			Email: "",
			DOB: "",
			AnniversaryDate: "",
			Designation: "",
			Department: "",
			UserType: "",
			Address: "",
			Country: "select",
			State: "",
			City: "",
			Pincode: "",
			AadharCard: "",
			PANCard: "",
			DrivingLicenses: "",
			BankName: "",
			Branch: "",
			AccountNumber: "",
			IFSCCode: "",
			Salary: "",
			Password: "",
			ConfirmPassword: "",
			UserData: [
				{ value: "Admin", label: "Admin" },
				{ value: "Super Admin", label: "Super Admin" },
				{ value: "Users", label: "Users" },
			],
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex: /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			DepartmentData: [],
			AadharData: [],
			AadharUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddPdf`,
			PanData: [],
			PanUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddPdf`,
			DrivingData: [],
			DrivingUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddPdf`,
			DesignationData: [],
			BankData: [],
			isNewPasswordVisible: false,
			isConPasswordVisible: false,
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("Get_Department").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					DepartmentData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetBankMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					BankData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetDesignation").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					DesignationData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryData: obj.data,
				});

				if (obj.data.length != 0) {
					// this.props.setcountry(obj.data[100].label)

					this.setState({
						CountryId: 101,
						Country: obj.data[102].value,
					});
				}

				PostApiCall.postRequest(
					{
						countryid: obj.data[102].value,
					},
					"GetState"
				).then((results) =>
					// const objs = JSON.parse(result._bodyText)
					results.json().then((objstate) => {
						if (results.status == 200 || results.status == 201) {
							if (objstate.data.length != 0) {
								// this.props.setstate()

								this.setState({
									StateId: objstate.data[0].value,

									StateData: objstate.data,
									State: objstate.data[0].label,
								});
							}

							PostApiCall.postRequest(
								{
									stateid: objstate.data[0].value,
								},
								"GetCity"
							).then((resultscity) =>
								// const objs = JSON.parse(result._bodyText)
								resultscity.json().then((objcity) => {
									if (resultscity.status == 200 || resultscity.status == 201) {
										if (objcity.data.length != 0) {
											// this.props.setcity(objcity.data[0].label)
											this.setState({
												CityId: objcity.data[0].value,
												CityData: objcity.data,
												City: objcity.data[0].label,
											});
										}
									}
								})
							);
						}
					})
				);
			})
		);
	}

	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
		}
	};

	onChangeCountry(country) {
		// //console.log( this.state.CountryData[parseInt(country.target.value) - 1].label)
		// //console.log(country.target.value)
		this.setState({
			CountryId: country.target.value,
			Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
		});
		//  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country: this.state.CountryData[parseInt(country.target.value) + 1]
					.label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// this.props.setstate(obj.data[0].label)

					this.setState({
						StateId: obj.data[0].value,
						State: obj.data[0].label,
					});

					PostApiCall.postRequest(
						{
							stateid: obj.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								this.setState({
									CityData: objcity.data,
									StateData: obj.data,
								});
								if (objcity.data.length > 0) {
									// this.props.setcity(objcity.data[0].label)

									this.setState({
										CityId: objcity.data[0].value,
										City: objcity.data[0].label,
									});
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	}

	onChangeState(state) {
		//  this.props.setstate(state.target.value)
		this.setState({
			StateId: state.target.value,
		});

		Notiflix.Loading.Dots("Please wait...");

		for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
			if (this.state.StateData[i].value == state.target.value) {
				// this.props.setstate();
				this.setState({
					State: this.state.StateData[i].label,
				});
			}
		}

		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						// this.props.setcity(obj.data[0].label)
						this.setState({
							CityData: obj.data,
							CityId: obj.data[0].value,
							City: obj.data[0].label,
						});
					}
					Notiflix.Loading.Remove();
					// this.props.cityData(obj.data)
				}
			})
		);
	}

	onChangeCity(city) {
		// this.props.setcity(city.target.value)
		this.setState({
			CityId: city.target.value,
		});

		for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
			if (this.state.CityData[i].value == city.target.value) {
				this.setState({
					City: this.state.CityData[i].label,
				});
				// this.props.setcity(this.state.CityData[i].label);
			}
		}
	}

	onSaveData() {
		if (
			this.state.imagePreviewUrl !=
			"https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png"
		) {
			if (this.state.Name != "") {
				if (this.state.Email != "") {
					if (this.state.EmailRegex.test(this.state.Email)) {
						if (this.state.Mobile != "") {
							if (this.state.Mobile.length == 10) {
								if (this.state.Address != "") {
									if (this.state.Country != "") {
										if (this.state.State != "") {
											if (this.state.City != "") {
												if (this.state.Pincode != "") {
													if (this.state.Pincode.length == 6) {
														if (this.state.Gender != "") {
															if (this.state.DOB != "") {
																if (this.state.AadharCard != "") {
																	if (this.state.AadharCard.length == 12) {
																		if (
																			JSON.stringify(this.state.AadharData) !=
																			"[]"
																		) {
																			if (this.state.Password != "") {
																				if (this.state.ConfirmPassword != "") {
																					if (
																						this.state.ConfirmPassword ==
																						this.state.Password
																					) {
																						this.onPost();
																					} else {
																						Notiflix.Notify.Failure(
																							"Password and confirm password does not match"
																						);
																					}
																				} else {
																					Notiflix.Notify.Failure(
																						"Please enter confirm password"
																					);
																				}
																			} else {
																				Notiflix.Notify.Failure(
																					"Please enter password"
																				);
																			}
																		} else {
																			Notiflix.Notify.Failure(
																				"Please upload Aadhar card "
																			);
																		}
																	} else {
																		Notiflix.Notify.Failure(
																			"Please enter valid Aadhar card number"
																		);
																	}
																} else {
																	Notiflix.Notify.Failure(
																		"Please enter aadhar card"
																	);
																}
															} else {
																Notiflix.Notify.Failure(
																	"Please select date of birth"
																);
															}
														} else {
															Notiflix.Notify.Failure("Please select gender");
														}
													} else {
														Notiflix.Notify.Failure(
															"Please enter valid pincode"
														);
													}
												} else {
													Notiflix.Notify.Failure("Please enter pincode");
												}
											} else {
												Notiflix.Notify.Failure("Please enter city");
											}
										} else {
											Notiflix.Notify.Failure("Please enter state");
										}
									} else {
										Notiflix.Notify.Failure("Please select country");
									}
								} else {
									Notiflix.Notify.Failure("Please enter address");
								}
							} else {
								Notiflix.Notify.Failure("Please enter valid mobile number");
							}
						} else {
							Notiflix.Notify.Failure("Please enter mobile number");
						}
					} else {
						Notiflix.Notify.Failure("Please enter valid email");
					}
				} else {
					Notiflix.Notify.Failure("Please enter email");
				}
			} else {
				Notiflix.Notify.Failure("Please enter name");
			}
		} else {
			Notiflix.Notify.Failure("Please upload user photo");
		}

		// this.onPost();
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				name: this.state.Name,
				email: this.state.Email,
				mobile: this.state.Mobile,
				gender: this.state.Gender,
				dateofbirth: this.state.DOB,
				adhaar: this.state.AadharCard,
				pan: this.state.PANCard,
				drivinglicense: this.state.DrivingLicenses,
				bankname: this.state.BankName,
				accountno: this.state.AccountNumber,
				branch_name: this.state.Branch,
				ifsccode: this.state.IFSCCode,
				presentaddress: this.state.Address,
				presentcountry: this.state.Country,
				presentstate: this.state.State,
				presentcity: this.state.City,
				presentpincode: this.state.Pincode,
				password: this.state.ConfirmPassword,
				status: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
			},
			"add-rider"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					// //console.log(obj)
					this.onUploadPan(obj);
				} else {
					this.onUploadPan(obj);
				}
			})
		);
	};

	onUploadPan(obj) {
		// //console.log('image')
		Notiflix.Loading.Dots("Please wait...");
		// //console.log('images')

		if (JSON.stringify(this.state.PanData) != "[]") {
			const form = new FormData();

			form.append("file", this.state.PanData);
			form.append("foldername", "Riderpdf");
			form.append(
				"filename",
				this.state.Name.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).UserId
			);

			fetch(this.state.PanUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						//     //console.log(res.data)
						// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).UserId)
						PostApiCall.postRequest(
							{
								id: JSON.parse(JSON.stringify(obj.data[0])).UserId,
								pancardupload:
									"http://demo.globaltrendz.online/Bechofy/images/Riderpdf/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
							},
							"UpdateRider_Pan"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									this.onUploadDL(obj);
								}
							})
						);
					});
			});
		} else {
			this.onUploadDL(obj);
		}
	}

	onUploadDL(obj) {
		// //console.log('image')
		Notiflix.Loading.Dots("Please wait...");
		// //console.log('images')

		if (JSON.stringify(this.state.DrivingData) != "[]") {
			const form = new FormData();

			form.append("file", this.state.DrivingData);
			form.append("foldername", "Riderpdf");
			form.append(
				"filename",
				this.state.Name.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).UserId
			);

			fetch(this.state.DrivingUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						// //console.log(res.data)
						// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).UserId)
						PostApiCall.postRequest(
							{
								id: JSON.parse(JSON.stringify(obj.data[0])).UserId,
								drivingupload:
									"http://demo.globaltrendz.online/Bechofy/images/Riderpdf/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
							},
							"UpdateRiderDL"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									this.onPostAadhar(obj);
								}
							})
						);
					});
			});
		} else {
			this.onPostAadhar(obj);
		}
	}

	onPostAadhar(obj) {
		// //console.log('image')
		Notiflix.Loading.Dots("Please wait...");
		// //console.log('images')

		if (JSON.stringify(this.state.AadharData) != "[]") {
			const form = new FormData();

			form.append("file", this.state.AadharData);
			form.append("foldername", "Riderpdf");
			form.append(
				"filename",
				this.state.Name.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).UserId
			);

			fetch(this.state.AadharUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						//     //console.log(res.data)
						// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).UserId)
						PostApiCall.postRequest(
							{
								id: JSON.parse(JSON.stringify(obj.data[0])).UserId,
								aadharcardupload:
									"http://demo.globaltrendz.online/Bechofy/images/Riderpdf/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
							},
							"UpdateRiderAadhar"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									this.onUploadImage(obj);
								}
							})
						);
					});
			});
		} else {
			this.onUploadImage(obj);
		}
	}

	onUploadImage(obj) {
		Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		if (JSON.stringify(this.state.ImageData) != "[]") {
			const form = new FormData();

			form.append("file", this.state.ImageData);
			form.append("foldername", "Rider");
			form.append(
				"filename",
				this.state.Name.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).UserId
			);

			fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						//     //console.log(res.data)
						// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).UserId)
						PostApiCall.postRequest(
							{
								id: JSON.parse(JSON.stringify(obj.data[0])).UserId,
								image:
									"http://demo.globaltrendz.online/Bechofy/images/Rider/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
								updatedon: moment().format("YYYY-MM-DD"),
								updatedby: details[0].fld_userid,
							},
							"UpdateRidermage"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									Notiflix.Loading.Remove();
									Notiflix.Notify.Success("Rider successfully added.");

									window.location.href = "/rider_management";
								}
							})
						);
					});
			});
		} else {
			Notiflix.Loading.Remove();
			// //console.log('error occured')
		}
	}

	render() {
		//console.log(this.state);

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Rider Manager / Rider list"}
							breadcrumbItem={"Add New Rider"}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Rider Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>

									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text  p-3">
																<h5 className="Bechofy-text">
																	Add New Rider
																</h5>
															</div>
														</Col>
													</Row>
												</div>

												<CardBody className="pt-0 usercarddata info">
													<CardTitle className="usertitle">
														Basic Personal Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-md-4">
																				<label for="sw-arrows-first-name">
																					Upload Rider Image (Size &lt; 100kb,
																					500*500)
																					<span className="mandatory">*</span>
																				</label>
																				<div class="div1">
																					<ImgUpload
																						onChange={this.photoUpload}
																						src={this.state.imagePreviewUrl}
																					/>
																				</div>
																			</div>
																			<div className="col-8">
																				<div className="row">
																					<div className="col-12">
																						<div className="form-group">
																							<label for="CategoryName">
																								Rider name
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								name="Name"
																								value={this.state.Name}
																								onChange={(text) => {
																									this.setState({
																										Name: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Email
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="email"
																								id="CategoryName"
																								className="form-control"
																								name="Email"
																								value={this.state.Email}
																								onChange={(text) => {
																									this.setState({
																										Email: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Mobile no
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.Mobile}
																								onChange={(mobile) => {
																									if (
																										this.state.NumRegex.test(
																											mobile.target.value
																										) &&
																										mobile.target.value
																											.length <= 10
																									) {
																										this.setState({
																											Mobile:
																												mobile.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-12">
																						<div className="form-group">
																							<label for="">
																								Address
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<textarea
																								className="form-control"
																								row="50"
																								col="30"
																								value={this.state.Address}
																								onChange={(text) => {
																									this.setState({
																										Address: text.target.value,
																									});
																								}}
																							></textarea>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								Country
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.CountryId}
																								onChange={this.onChangeCountry.bind(
																									this
																								)}
																							>
																								<option selected>
																									Select Country
																								</option>
																								{this.state.CountryData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								State
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.StateId}
																								onChange={this.onChangeState.bind(
																									this
																								)}
																							>
																								<option selected>
																									Select State
																								</option>
																								{this.state.StateData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>
																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								City
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.CityId}
																								onChange={this.onChangeCity.bind(
																									this
																								)}
																							>
																								<option selected>
																									Select City
																								</option>
																								{this.state.CityData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label for="CategoryName">
																								Pincode
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.Pincode}
																								onChange={(pincode) => {
																									if (
																										this.state.NumRegex.test(
																											pincode.target.value
																										) &&
																										pincode.target.value
																											.length <= 6
																									) {
																										this.setState({
																											Pincode:
																												pincode.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								Gender
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								value={this.state.Gender}
																								onChange={(text) => {
																									this.setState({
																										Gender: text.target.value,
																									});
																								}}
																							>
																								<option>Select Gender</option>
																								{this.state.GenderData.map(
																									(gender) => (
																										<option
																											key={gender.value}
																											value={gender.value}
																										>
																											{gender.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Date of Birth
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="date"
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.DOB}
																								onChange={(text) => {
																									this.setState({
																										DOB: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>

												<CardBody className="pt-0 usercarddata identi">
													<CardTitle className="usertitle">
														Identification Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-12">
																				<div className="row">
																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								Aadhar Card Number
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.AadharCard}
																								onChange={(aadhar) => {
																									if (
																										this.state.NumRegex.test(
																											aadhar.target.value
																										) &&
																										aadhar.target.value
																											.length <= 12
																									) {
																										this.setState({
																											AadharCard:
																												aadhar.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								PAN Card Number
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.PANCard}
																								onChange={(text) => {
																									if (
																										this.state.AlphaNumericRegex.test(
																											text.target.value
																										) &&
																										text.target.value.length <=
																										10
																									) {
																										this.setState({
																											PANCard:
																												text.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								Driving License
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={
																									this.state.DrivingLicenses
																								}
																								onChange={(text) => {
																									this.setState({
																										DrivingLicenses:
																											text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								Upload Aadhar Card
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="file"
																								id="CategoryName"
																								className="form-control"
																								style={{ display: "block" }}
																								accept="application/pdf"
																								onChange={(e) => {
																									if (
																										e.target.files[0].size <
																										3000000
																									) {
																										const reader = new FileReader();
																										const file =
																											e.target.files[0];
																										reader.onloadend = () => {
																											this.setState({
																												AadharData: file,
																											});
																										};
																										reader.readAsDataURL(file);
																									} else {
																										Notiflix.Notify.Failure(
																											"File too large, upload file less than 3 Mb."
																										);
																									}
																								}}
																							></input>
																						</div>
																					</div>
																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								Upload PAN Card
																							</label>
																							<input
																								type="file"
																								id="CategoryName"
																								className="form-control"
																								style={{ display: "block" }}
																								accept="application/pdf"
																								onChange={(e) => {
																									if (
																										e.target.files[0].size <
																										3000000
																									) {
																										const reader = new FileReader();
																										const file =
																											e.target.files[0];
																										reader.onloadend = () => {
																											this.setState({
																												PanData: file,
																											});
																										};
																										reader.readAsDataURL(file);
																									} else {
																										Notiflix.Notify.Failure(
																											"File too large, upload file less than 3 Mb."
																										);
																									}
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col-4">
																						<div className="form-group">
																							<label for="CategoryName">
																								Upload Driving License
																							</label>
																							<input
																								type="file"
																								id="CategoryName"
																								className="form-control"
																								style={{ display: "block" }}
																								accept="application/pdf"
																								onChange={(e) => {
																									if (
																										e.target.files[0].size <
																										3000000
																									) {
																										const reader = new FileReader();
																										const file =
																											e.target.files[0];
																										reader.onloadend = () => {
																											this.setState({
																												DrivingData: file,
																											});
																										};
																										reader.readAsDataURL(file);
																									} else {
																										Notiflix.Notify.Failure(
																											"File too large, upload file less than 3 Mb."
																										);
																									}
																								}}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>

												<CardBody className="pt-0 usercarddata identi">
													<CardTitle className="usertitle">
														Bank Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-12">
																				<div className="row">
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Bank Name
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.BankName}
																								onChange={(text) => {
																									this.setState({
																										BankName: text.target.value,
																									});
																								}}
																							>
																								<option selected>
																									Select Bank
																								</option>
																								{this.state.BankData.map(
																									(bank) => (
																										<option
																											key={bank.value}
																											value={bank.value}
																										>
																											{bank.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Branch
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.Branch}
																								onChange={(text) => {
																									this.setState({
																										Branch: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Account Number
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.AccountNumber}
																								onChange={(text) => {
																									this.setState({
																										AccountNumber:
																											text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								IFSC Code
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.IFSCCode}
																								onChange={(text) => {
																									this.setState({
																										IFSCCode: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>

												<CardBody className="pt-0 usercarddata userinfo2">
													<CardTitle className="usertitle">
														Rider Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-12">
																				<div className="row">
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Password
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type={
																									this.state
																										.isNewPasswordVisible
																										? "text"
																										: "password"
																								}
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								name="Password"
																								value={this.state.Password}
																								onChange={(text) => {
																									this.setState({
																										Password: text.target.value,
																									});
																								}}
																							/>

																							<span class="login-icon-change-pass">
																								{/* <i class="icon-dual" data-feather="lock"></i>*/}
																								<i
																									style={{
																										color: this.state
																											.isNewPasswordVisible
																											? "#777f80"
																											: "",
																									}}
																									className="fa fa-eye"
																									onClick={() => {
																										this.setState({
																											isNewPasswordVisible: !this
																												.state
																												.isNewPasswordVisible,
																										});
																									}}
																								/>
																							</span>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Confirm Password
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type={
																									this.state
																										.isConPasswordVisible
																										? "text"
																										: "password"
																								}
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								name="ConfirmPassword"
																								value={
																									this.state.ConfirmPassword
																								}
																								onChange={(text) => {
																									this.setState({
																										ConfirmPassword:
																											text.target.value,
																									});
																								}}
																							/>
																							<span class="login-icon-change-pass">
																								{/* <i class="icon-dual" data-feather="lock"></i>*/}
																								<i
																									style={{
																										color: this.state
																											.isConPasswordVisible
																											? "#777f80"
																											: "",
																									}}
																									className="fa fa-eye"
																									onClick={() => {
																										this.setState({
																											isConPasswordVisible: !this
																												.state
																												.isConPasswordVisible,
																										});
																									}}
																								/>
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Rider Status
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right", marginTop: "5px" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		Save & Add New Rider
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		usercredentials: state.UserReducer,
	};
}

export default connect(mapStateToProps, {
	setname,
	setemail,
	setmobile,
	setdob,
	setanniversary,
	setdesignation,
	setdepartment,
	setusertype,
	setaddress,
	setcountry,
	setstate,
	setcity,
	setpincode,
	setaadharcard,
	setpancard,
	setdrivinglicenses,
	setaccountno,
	setbankname,
	setbranch,
	setIfsccode,
	setSalary,
	setuserpassword,
	setuserconfirmpassword,
	setclearuser,
})(AddUser);
