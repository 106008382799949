import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { ProSidebar, Menu, SidebarHeader, MenuItem, SubMenu, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {FaAddressCard, FaHistory, FaUserLock} from 'react-icons/fa';
import { CgProfile, CgLogOut } from "react-icons/cg";
import ResponsiveSideBar from "./ResponsiveSideBar";
import { Link } from 'react-router-dom';

class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {}
    }

    logout = () => {
        if (localStorage.getItem("userData")) 
        {
            localStorage.removeItem("userData")
            localStorage.removeItem("wishlist")
        }
    }

    render(){
        return(
            <React.Fragment>
                <div class="toggle-icon">
                    <ResponsiveSideBar/>
                </div>
                <div class="sidebar">
                    <ProSidebar style={{width:'inherit'}}>
                        <SidebarHeader>
                            <div class="profile-circle">
                                <img class="profile-img" src="https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head.png"></img>
                            </div>
                            <div class="user-name">
                                <h5 style={{color:'white'}}>Deepanshi Jain</h5>
                            </div>
                        </SidebarHeader>
                        <SidebarContent>
                            <Menu iconShape="square">
                                <MenuItem icon={<CgProfile size="20px" />}>
                                    Profile 
                                    <Link to="/account_profile" />
                                </MenuItem>
                                <MenuItem icon={<FaAddressCard size="20px"/>}>
                                    Address
                                    <Link to="/add_address" />
                                </MenuItem>
                                <MenuItem icon={<FaHistory size="20px"/>}>
                                    Order History
                                    <Link to="/order_history" />
                                </MenuItem>
                                <MenuItem icon={<FaUserLock size="20px"/>}>
                                    Change Password
                                    <Link to="/change_password" />
                                </MenuItem>
                                <MenuItem icon={<CgLogOut size="20px"/>}>
                                    Logout
                                    <Link to="/" />
                                </MenuItem>
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter></SidebarFooter>
                    </ProSidebar>
                </div>
            </React.Fragment>
        );
    }
}

export default SideBar;