import React, { Component } from "react";

import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container } from "reactstrap";

var width = '100%'
var height = 300


const ImgUploadCover = ({
    onChange,
    src
}) =>
    <label htmlFor="photo-upload-1" className="custom-file-upload fas" style={{ width: width, height: height + 'px', borderRadius: '10px' }}>

        <div className="img-wrap1 img-upload1" style={{ width: width - 10, height: height - 15 + 'px', borderRadius: '10px' }}>
            <img for="photo-upload-1" src={src} style={{ width: width, height: height - 15 + 'px', borderRadius: '10px' }} />
        </div>
        <input
            accept="image/*"
            id="photo-upload-1" type="file" onChange={onChange} />
    </label>


class UiCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrlCover: '../../assets/images/Cover-bechofylogo.png',
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <CardBody className="py-1 my-1">
                            <Row>
                                <Col xs="12">
                                    <div className="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-2">
                                                <div></div>
                                                <div>
                                                    <ImgUploadCover onChange={(e) => this.photoUploadCover(e)} src={this.state.imagePreviewUrlCover} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardBody className="py-1 my-1">
                            <Row>
                                <Col xs="12">
                                    <div className="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-2">
                                                <div></div>
                                                <div>
                                                    <ImgUploadCover onChange={(e) => this.photoUploadCover(e)} src={this.state.imagePreviewUrlCover} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardBody className="py-1 my-1">
                            <Row>
                                <Col xs="12">
                                    <div className="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-2">
                                                <div></div>
                                                <div>
                                                    <ImgUploadCover onChange={(e) => this.photoUploadCover(e)} src={this.state.imagePreviewUrlCover} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UiCards;
