import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import editicon from '../../assets/images/dashboardimages/edit.png';
import Saveicon from '../../assets/images/dashboardimages/filesave.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);


class MaterialMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            ImageChanged: null,
            MaterialData: [],
            searchInput: '',
            ImageName: null,
            MaterialImage: null,
            MaterialName: "",
            MaterialId: null,
            Status: "Yes",
            Cost: null,
            sequence: null,
            MaterialDescription: null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,


        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        localStorage.removeItem("MaterialDetails")
        //   this.props.setclearbrand()

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                recordCount: '*',
                whereClause: ``,

            },
            "GetStickerMaterialMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        MaterialData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }

    onUpdateMaterial = () => {



        PostApiCall.postRequest(
            {
                id: this.state.MaterialId,
                materialname: this.state.MaterialName,
                description: this.state.MaterialDescription,
                status: this.state.Status == "Yes" ? "Active" : "Inactive",
                materialprice: this.state.Cost,
                image: this.state.MaterialImage,
                sequence: this.state.sequence

            },
            "AddMaterialMaster"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Notify.Success("Material successfully added. Image upload in process");
                    this.setState(({
                        MaterialImage: null,
                        imagePreviewUrl: "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                        MaterialName: null,
                        MaterialId: null,
                        Status: null,
                        Cost: null,
                        sequence: null,
                        MaterialDescription: null
                    }))
                    PostApiCall.postRequest(
                        {
                            recordCount: '*',
                            whereClause: ``,

                        },
                        "GetStickerMaterialMaster"
                    ).then((results) =>
                        // const objs = JSON.parse(result._bodyText)
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                // console.log(obj.data)
                                this.setState({
                                    MaterialData: obj.data,

                                });
                                Notiflix.Loading.Remove();
                            }
                        }))
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );

    }

    async onUploadImage(obj) {
        Notiflix.Loading.Dots("Uploading Images...");

        let response;

        if (this.state.outputFileName != undefined) {

            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {



                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "MaterialImages");
                    form.append(
                        "filename",
                        this.state.MaterialName.trim().replace(/\s/g, "-") +
                        "-" +
                        compressedFile.name

                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then(response => response.json())
                        .then(data => {

                            Notiflix.Loading.Remove()
                            this.setState(({
                                MaterialImage: null,
                                imagePreviewUrl: "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                MaterialName: null,
                                MaterialId: null,
                                Status: null,
                                Cost: null,
                                sequence: null,
                                MaterialDescription: null
                            }))
                            Notiflix.Notify.Success("Image successfully uploaded.")
                            window.location.href = "/materialmaster";
                        }

                        )
                    // //console.log(response);


                })
        }
        else {
            Notiflix.Loading.Remove()
            Notiflix.Notify.Success("Image successfully uploaded.")
            window.location.href = "/materialmaster";
        }




    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            ImageChanged: "true",
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };

    onSaveData() {


        const foundItem = this.state.MaterialData.find((item => (item.fld_sequence === this.state.sequence || String(item.fld_sequence) === this.state.sequence) && item.fld_id != this.state.MaterialId));
        if (!foundItem) {
            if (this.state.MaterialName != "") {
                if (
                    this.state.imagePreviewUrl !=
                    "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
                ) {
                    if (this.state.Cost != null) {
                        this.onPost();

                    }
                    else {
                        Notiflix.Notify.Failure("Please enter the Material cost");
                    }
                } else {
                    Notiflix.Notify.Failure("Please upload Material image");
                }
            } else {
                Notiflix.Notify.Failure("Please enter the Material name");
            }
        } else {
            Notiflix.Notify.Failure("Please enter a unique sequence");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);


        if (this.state.ImageChanged === 'true') {

            if (this.state.outputFileName != undefined) {

                PostApiCall.postRequest(
                    {
                        id: this.state.MaterialId,
                        materialname: this.state.MaterialName,
                        description: this.state.MaterialDescription,
                        status: this.state.Status == "Yes" ? "Active" : "Inactive",
                        materialprice: this.state.Cost,
                        image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/MaterialImages/" +
                            this.state.MaterialName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,

                    },
                    "AddMaterialMaster"
                ).then((resultcategory) =>
                    resultcategory.json().then((obj) => {
                        if (resultcategory.status == 200 || resultcategory.status == 201) {
                            Notiflix.Notify.Success("Material successfully added. Image upload in process");
                            this.setState(({
                                MaterialImage: null,
                                imagePreviewUrl: "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png",
                                MaterialName: null,
                                MaterialId: null,
                                Status: null,
                                Cost: null,
                                sequence: null,
                                MaterialDescription: null
                            }))
                            this.onUploadImage(obj);
                            Notiflix.Loading.Remove();
                        } else {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Failure(obj.data);
                        }
                    })
                );

            } else {
                Notiflix.Loading.Remove();
                Notiflix.Notify.Failure("Please Upload Material Image");
            }
        }
        else {
            this.onUpdateMaterial()
        }
    }




    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs breadcrumbItem={"Material Master"} urlPath={"/Material_master"} />


                        <Row>

                            <Col xl="12">
                                <div className="overflow-hidden" id="upload-card">

                                    {/* <Card className="overflow-hidden"> */}
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        Material List
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </Card>
                                    <Card >

                                        <CardBody className="pt-0">

                                            <Row>
                                                <Col xs="4">
                                                    <CardBody className="categoryCard" >

                                                        <Row className="card-row">
                                                            <Col sm="6">
                                                                <div className="imagecard">
                                                                    <div className="form-group ">
                                                                        <label for="sw-arrows-first-name">
                                                                            Upload Material Image (1024*1024 px)
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <div class="div1">
                                                                            <ImgUpload
                                                                                onChange={this.photoUpload}
                                                                                src={this.state.imagePreviewUrl}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <hr style={{border:'1px solid #fff'}}/> */}
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className="row" >
                                                                    <h5>Add New Material</h5>
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Material Name
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="MaterialName"
                                                                            className="form-control"
                                                                            value={this.state.MaterialName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    MaterialName: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label for="MaterialName">
                                                                            Material Description
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="MaterialDescription"
                                                                            className="form-control"
                                                                            value={this.state.MaterialDescription}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    MaterialDescription: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group" >

                                                                        <label for="MaterialName">
                                                                            Material Price
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="MaterialName"
                                                                            className="form-control"
                                                                            value={this.state.Cost}
                                                                            onChange={(text) => {
                                                                                if (
                                                                                    this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        Cost: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label class="my-1 mr-2">Sequence *</label>

                                                                        <input
                                                                            className="form-control"
                                                                            name="name"
                                                                            type="text"
                                                                            placeholder="Seq"
                                                                            value={this.state.sequence}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    sequence: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12" style={{ marginTop: '10px' }}>
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Status
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <br />
                                                                            <label class="radio-inline">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "Yes"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "Yes",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Active
                                                                            </label>
                                                                            <label
                                                                                class="radio-inline"
                                                                                style={{ marginLeft: "10px" }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "No"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "No",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Inactive
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <hr />
                                                        </Row>
                                                        <Row>
                                                            <Col sm="6">
                                                            </Col>
                                                            <Col sm="6">

                                                                <a
                                                                    onClick={this.onSaveData.bind(this)}
                                                                    className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                    style={{ marginTop: "5px", color: "white" }}
                                                                >
                                                                    <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                    Save
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Col>
                                                {this.state.MaterialData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    } if (
                                                        this.state.searchInput !== "" &&
                                                        (data.fld_material_name
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase())

                                                        )) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <CardBody className="categoryCard">
                                                            <CardTitle className="mb-4">
                                                                {data.fld_material_name}
                                                            </CardTitle>
                                                            <Row className="card-row">
                                                                <Col sm="4">
                                                                    <div className="imagecard">
                                                                        <img
                                                                            src={data.fld_image}
                                                                            className="categoryimage"
                                                                        ></img>
                                                                    </div>
                                                                    {/* <hr style={{border:'1px solid #fff'}}/> */}
                                                                </Col>
                                                                <Col sm="8">

                                                                    <p style={{ paddingLeft: '25px' }}><b>Description : </b> {data.fld_material_description}</p>
                                                                    <p style={{ paddingLeft: '25px' }}><b>Sequence : </b> {data.fld_sequence}</p>
                                                                    <p style={{ paddingLeft: '25px' }}><b>Price : </b> ₹ {data.fld_material_price}</p>

                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="categorystatus">
                                                                        <b>Status</b> -{" "}
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    data.fld_status == "Active" || data.fld_status == 'True'
                                                                                        ? "green"
                                                                                        : "red",
                                                                            }}
                                                                        >
                                                                            {data.fld_status}
                                                                        </span>
                                                                    </p>
                                                                </Col>
                                                                <Col sm="6">

                                                                    <a
                                                                        onClick={() => {
                                                                            this.setState(({
                                                                                MaterialImage: data.fld_image,
                                                                                imagePreviewUrl: data.fld_image,
                                                                                MaterialName: data.fld_material_name,
                                                                                MaterialId: data.fld_id,
                                                                                Status: data.fld_status == "Active" ? "Yes" : "No",
                                                                                Cost: data.fld_material_price,
                                                                                sequence: data.fld_sequence,
                                                                                MaterialDescription: data.fld_material_description
                                                                            }))
                                                                            window.location.href = "#upload-card"
                                                                        }}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "10px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Edit Material
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    {/* </Card> */}
                                    {/* </Row> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default MaterialMaster;
