import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class BannerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,

			UserData: [],
			Id: "",
			searchInput: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetCustomerList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data);

				this.setState({
					UserData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}


	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Website Banner",
					field: "website",
					sort: "disabled",
					width: 400,
				},

				{
					label: "Mobile Banner",
					field: "mobile",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Mobile App Banner",
					field: "mobileapp",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Vertical",
					field: "vertical",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Type",
					field: "type",
					sort: "disabled",
					width: 400,
				},


				{
					label: "Url",
					field: "url",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Show on website",
					field: "show",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: [
				{
					serial: 1,
					website: 'test',
					mobile: 'test',
					mobileapp: 'test',
					vertical: 'test',
					type: 'test',
					url: 'test',
					show: 'yes',

					action: (
						<td className="text-center actionuser" style={{ border: 'none' }}>
							{/* <i className="fas fa-eye btn" style={{fontSize:'15px',marginTop:'-11px'}}></i> */}
							<i
								className="fas fa-eye btn"
								style={{ fontSize: "15px", }}
								onClick={() => {
									// localStorage.setItem("userdetails", JSON.stringify(data));
									window.location.href = "/view_customer";
								}}
							></i>
						</td>
					)

				},

			]
			// rows: this.state.UserData.filter((data) => {
			// 	//console.log(data.fld_email.toLowerCase(), this.state.searchInput);
			// 	let name = [
			// 		...data.fld_name
			// 			.toLowerCase()
			// 			.matchAll(this.state.searchInput.toLowerCase()),
			// 	];

			// 	if (this.state.searchInput.length > 0 && name[0]) {
			// 		return data;
			// 	}

			// 	if (
			// 		this.state.searchInput.length > 0 &&
			// 		data.fld_email.toLowerCase() === this.state.searchInput.toLowerCase()
			// 	) {
			// 		return data;
			// 	}
			// 	if (
			// 		this.state.searchInput.length > 0 &&
			// 		data.fld_mobile.toLowerCase() === this.state.searchInput.toLowerCase()
			// 	) {
			// 		return data;
			// 	}

			// 	if (
			// 		this.state.searchInput.length > 0 &&
			// 		data.fld_status.toLowerCase() === this.state.searchInput.toLowerCase()
			// 	) {
			// 		return data;
			// 	}

			// 	if (this.state.searchInput.length == 0) {
			// 		return data;
			// 	}
			// }).map((data, i) => {
			// 	return {
			// 		serial: <td>{i + 1}</td>,
			// 		name: <td>{data.fld_name}</td>,
			// 		city: <td>Delhi</td>,
			// 		state: <td>DElhi</td>,
			// 		registeredOn: <td>08/06/2021</td>,
			// 		orderno:"2" <td>1</td>,
			// 		totalvalue: <td>100</td>,

			// 		action: (
			// 			<td className="text-center actionuser">
			// 				{/* <i className="fas fa-eye btn" style={{fontSize:'15px',marginTop:'-11px'}}></i> */}
			// 				<i
			// 					className="fas fa-edit btn"
			// 					style={{ fontSize: "15px", marginTop: "-11px" }}
			// 					onClick={() => {
			// 						localStorage.setItem("customerdetails", JSON.stringify(data));
			// 						window.location.href = "/edit-customer";
			// 					}}
			// 				></i>
			// 				<span className="btn" style={{ marginTop: "-9px" }}>
			// 					<i
			// 						className="fa fa-trash py-auto "
			// 						aria-hidden="true"
			// 						style={{ fontSize: "15px" }}
			// 						onClick={() => {
			// 							confirmAlert({
			// 								title: "Confirm to Delete",
			// 								message: "Are you sure you want to delete customer.",
			// 								buttons: [
			// 									{
			// 										label: "Yes",
			// 										onClick: () => {
			// 											Notiflix.Loading.Dots("");

			// 											PostApiCall.postRequest(
			// 												{
			// 													id: data.fld_customerid,
			// 													status: "Disabled",
			// 												},
			// 												"DeleteCustomer"
			// 											).then((results) =>
			// 												// const objs = JSON.parse(result._bodyText)
			// 												results.json().then((obj) => {
			// 													if (
			// 														results.status == 200 ||
			// 														results.status == 201
			// 													) {
			// 														Notiflix.Loading.Remove();
			// 														Notiflix.Notify.Success(
			// 															"User successfully deleted."
			// 														);
			// 														window.location.reload();
			// 													} else {
			// 														Notiflix.Loading.Remove();
			// 														Notiflix.Notify.Failure(
			// 															"Something went wrong, try again later."
			// 														);
			// 													}
			// 												})
			// 											);
			// 										},
			// 									},
			// 									{
			// 										label: "No",
			// 										// onClick: () => alert('Click No')
			// 									},
			// 								],
			// 							});
			// 						}}
			// 					/>
			// 				</span>
			// 			</td>
			// 		),
			// 	};
			// }),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Customer Management"}
							breadcrumbItem={"Customer List"}
						/>
						<Row>
							<Col xl="12">

								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink" style={{ height: '75px' }}>
													<div className="row my-1">
														Banner List
													</div>

												</div>

												<Card className="overflow-hidden">
													<Row>
														
														
														<Col xs="6">
															<div className="Bechofy-text  p-3">
																<button className="btn align-items-center btn Bechofy-btn"
																	style={{ float: 'right' }}
																	onClick={() => {
																		window.location.href = '/add_banner'
																	}}

																>
																	Add New Banner

																</button>

															</div>
														</Col>

													</Row>




													<CardBody className="pt-0">
														<Row>
															<MDBCol md="6" style={{ marginBottom: "10px" }}>
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) => this.seachBarHandler(e)}
																	value={this.state.searchInput}
																/>
															</MDBCol>

															<Col md="12">
																{/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
																<MDBDataTable
																	hover
																	// scrollY
																	striped
																	bordered
																	data={data}
																	seachTop={false}
																>

																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTable>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>

							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default BannerList;
