import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Parser from "html-react-parser";
const inr = require('inr-words');

class Dispatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderData: [],
            AmountInWords: "",
            PaymentBy: "",
            ClientName: "",
            ClientLogo: "",
            ClientAddress: "",
            ClientCity: "",
            ClientPhoneNumber: "",
            ClientCountry: "",
            ClinetPincode: "",
            ClientState: "",
            ClientWebsite: "",
            ClientEmail: "",
            ClientGST: ""
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        if (localStorage.getItem("ClientDetails") == null) {

            Notiflix.Notify.Failure("Session timed out. Please access the page from main business domain again.")

        } else {
            var clientDetails = localStorage.getItem("ClientDetails");
            var clientInformation = JSON.parse(clientDetails)
            this.setState({
                ClientName: clientInformation[0].fld_companyname,
                ClientLogo: clientInformation[0].fld_storelogo,
                ClientPhoneNumber: clientInformation[0].fld_mobilenumber,
                ClientAddress: clientInformation[0].fld_address,
                ClientCity: clientInformation[0].fld_city,
                ClientCountry: clientInformation[0].fld_country,
                ClientEmail: clientInformation[0].fld_email,
                ClinetPincode: clientInformation[0].fld_pincode,
                ClientState: clientInformation[0].fld_state,
                ClientWebsite: clientInformation[0].fld_website,
                ClientGST: clientInformation[0].fld_gst
            })
        }

        //   this.props.setclearbrand()
        var customer = localStorage.getItem('Orderdetails');
        var CustomerDetails = JSON.parse(customer)
        // console.log(CustomerDetails)


        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                whereClause: `where fld_dispatch_id=${this.props.match.params.orderid}`,
                select: "*"
            },
            "GetDispatchDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        OrderData: obj.data,
                        PaymentBy: obj.data[0].fld_contactperson
                    });
                    this.state.OrderData.map((data, i) => {
                        if (i === 0) {

                            let words = inr(Math.round(data.fld_total_to_bepaid) + Math.round(data.Shipping_Charg) + Math.round(data.Shipping_Charg_gst));
                            this.setState({
                                AmountInWords: words,
                            });
                        }
                    })
                    Notiflix.Loading.Remove();
                }
            }))


    }


    DownloadInvoicePdf() {

        var html2pdf = require('html2pdf.js')
        var element = document.getElementById('orderform');
        var opt = {
            margin: 1,
            filename: 'invoice_form.pdf',
            image: { type: 'png', quality: 0.98 },
            html2canvas: {
                scale: 1.1, useCORS: true,
                allowTaint: true, proxy: null
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            //   pagebreak: {avoid: ‘div’}
        };
        // //console.log(opt)
        // New Promise-based usage:
        html2pdf().from(element).set(opt).save().then(() => {

        });

    }
    getGstRate = (orderDate) => {
        const date = new Date(orderDate);
        return date >= new Date("09/09/2024") ? 12 : 18;
    };

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs breadcrumbItem={"View Invoice"} urlPath={"/view-invoice"} />


                        <Row>
                            <Col xl="12">
                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="globalTrendzEcomm-text  p-3">
                                                    <h5 className="globalTrendzEcomm-text" style={{ color: "#fff" }}>View Invoice</h5>
                                                </div>
                                            </Col>
                                            <Col xs="6">
                                                <div className="DownloadPdf">
                                                    <a className="btn align-items-center btn MaxXchange-btn" style={{
                                                        marginTop: "10px",
                                                        background: 'white',
                                                        color: 'grey',
                                                        marginRight: '20px',
                                                        float: "right"
                                                    }}
                                                        onClick={this.DownloadInvoicePdf}>Download Invoice Form </a>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                    <div id="orderform">
                                        <table
                                            style={{
                                                width: '630px', textAlign: 'center', marginLeft: 'auto',
                                                marginRight: 'auto', bottom: '0px', borderRightColor: '#000',
                                                marginTop: '30px',
                                                borderCollapse: 'collapse'
                                            }} border="1" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                {this.state.OrderData.map((data, i) => {
                                                    if (i === 0) {
                                                        return (
                                                            <tr>
                                                                <td colspan="2" style={{ width: '20%', verticalAlign: 'middle' }}><img src={`data:image/jpeg;base64,${this.state.ClientLogo}`} style={{ width: '65%', marginRight: 'auto', marginLeft: 'auto', verticalAlign: "middle" }} /> </td>
                                                                <td colspan="8" style={{ width: '80%' }}> <h2 style={{ textAlign: 'center', fontSize: '25px', fontWeight: 'bold' }}>
                                                                    {this.state.ClientName}</h2><p style={{ textAlign: 'center' }}>{data.fld_address_warehouse}
                                                                        <br />Phone: {this.state.ClientPhoneNumber}
                                                                        <br />GST: {this.state.ClientGST}</p>

                                                                    <tr rowspan="8" class="success" style={{ display: 'table', width: '100%', backgroundColor: '#f7f7f7' }}>
                                                                        <td colspan="8" style={{ textAlign: 'right', paddingRight: '1%', fontWeight: 'bold', fontSize: '20px', }}>
                                                                            Retail Tax Invoice</td></tr></td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                                }

                                                {this.state.OrderData.map((data, i) => {
                                                    if (i === 0) {
                                                        return (<tr>
                                                            <td colspan="1" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Order Date</span></td>

                                                            <td colspan="3" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                {data.fld_order_date}</td>

                                                            <td colspan="3" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Customer Order No.</span></td>
                                                            <td style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                {data.fld_order_number}
                                                            </td>

                                                        </tr>
                                                        )
                                                    }
                                                })}
                                                {this.state.OrderData.map((data, i) => {
                                                    if (i === 0) {
                                                        return (

                                                            <tr>
                                                                <td colspan="1" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Invoice Number</span></td>

                                                                <td colspan="9" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    {data.fld_invoice_number ? data.fld_invoice_number : "0"}</td>

                                                                {/* <td colspan="1" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Slot</span></td>

                                                                <td style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    {data.fld_order_date}</td> */}

                                                            </tr>


                                                        )
                                                    }
                                                })}


                                                <tr class="success">
                                                    <td colspan="4" style={{ paddingTop: '1%', paddingBottom: '1%', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Billing
                                                        Address</td>
                                                    <td colspan="4" style={{ paddingTop: '1%', paddingBottom: '1%', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                        Shipping Address</td>
                                                </tr>
                                                {this.state.OrderData.map((data, i) => {
                                                    if (i === 0) {
                                                        return (
                                                            <tr>
                                                                <td colspan="4" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    {Parser(
                                                                        ("<p>" + data.fld_billing_address_all + "</p>")
                                                                            .replace(/font-family/g, "")
                                                                            .replace(/<br\/?>/gi, " ")
                                                                    )}
                                                                </td>

                                                                <td colspan="4" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    {Parser(
                                                                        ("<p>" + data.fld_shipping_address_all + "</p>")
                                                                            .replace(/font-family/g, "")
                                                                            .replace(/<br\/?>/gi, " ")
                                                                    )}
                                                                </td>
                                                            </tr>



                                                        )
                                                    }
                                                })}

                                            </tbody>
                                        </table>
                                        <table

                                            style={{
                                                width: '630px',
                                                textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', borderRightColor:
                                                    '#000', borderTop: 'hidden'
                                            }} border="1" cellspacing="0" cellPadding="0">
                                            <tbody>
                                                <tr class="success">
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>S.No.</span></td>

                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>HSN Code</span></td>
                                                    <td colSpan={3} style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>
                                                        Product</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Quantity</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Rate Per Unit</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Gross Price(A)</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Discount(B)</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>GST Rate(%)</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>GST Amount(C)</span></td>
                                                    <td style={{ paddingTop: '1%', paddingBottom: '1%', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Total Amount<br />(A-B+C)</span></td>
                                                    {/* <!--   <td style="padding-top: 1%; padding-bottom: 1%;text-align:center"><span style={{fontWeight:'bold'}}></span></td> -->
               */}
                                                </tr>
                                                {this.state.OrderData.map((data, i) => (
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{data.fld_hsncode}</td>
                                                        <td colSpan={3} style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                            {data.variant_name} - {data.fld_weight} <br />
                                                            {/* <span><b>{data.item_name}</b></span> */}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>{data.fld_quantity}</td>
                                                        <td style={{ textAlign: 'center' }}>{parseFloat(data.fld_netvalue).toFixed(2)}</td>
                                                        <td style={{ textAlign: 'center' }}>{parseFloat(data.fld_quantity * data.fld_netvalue).toFixed(2)}</td>
                                                        <td style={{ textAlign: 'center' }}>{parseFloat(data.fld_discount_amount).toFixed(2)}</td>
                                                        <td style={{ textAlign: 'center' }}>{data.fld_GSTRate}</td>
                                                        <td style={{ textAlign: 'center' }}>{parseFloat(data.fld_GSTamount).toFixed(2)}</td>

                                                        <td style={{ textAlign: 'right', paddingRight: '1%' }}>{parseFloat(data.fld_item_value).toFixed(2)}</td>
                                                    </tr>

                                                ))}

                                            </tbody>
                                        </table>

                                        <table style={{
                                            width: '630px',
                                            textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', borderRightColor:
                                                '#000', borderTop: 'hidden'
                                        }} border="1" cellspacing="0" cellpadding="0">

                                            <tbody>

                                                <tr style={{ width: '100%' }}>
                                                    <td style={{ textAlign: 'left', paddingLeft: '1%', width: '65%' }}>
                                                        <table style={{
                                                            width: '350px', borderRightColor:
                                                                '#000', marginRight: ' -60px',
                                                            borderCollapse: 'collapse'
                                                        }} border="1">
                                                            {this.state.OrderData.map((data, i) => {
                                                                if (i === 0) {
                                                                    const gstRate = this.getGstRate(data.fld_order_date);
                                                                    return (
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colspan="10" style={{ textAlign: 'center', paddingTop: '1%', paddingBottom: '1%' }}><span style={{
                                                                                    fontWeight:
                                                                                        'bold'
                                                                                }}>Total Products to be Delivered : {this.state.OrderData.length} Products ({data.Total_Item_Quantity} Items)</span></th>
                                                                            </tr>

                                                                            <tr class="success">
                                                                                <th style={{ textAlign: 'center' }}>GST (%)</th>
                                                                                <th style={{ textAlign: 'center' }}>Item</th>
                                                                                <th style={{ textAlign: 'center' }}>SGST</th>
                                                                                <th style={{ textAlign: 'center' }}>CGST</th>
                                                                                <th style={{ textAlign: 'center' }}>IGST</th>
                                                                                <th style={{ textAlign: 'center' }}>Amount</th>


                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>0%</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_In_GstRate_0}</td>

                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_sgst_Amnt_In_GstRate_0}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_cgst_Amnt_In_GstRate_0}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_igst_Amnt_In_GstRate_0}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_Amnt_In_GstRate_0}</td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>5%</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_In_GstRate_5}</td>

                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_sgst_Amnt_In_GstRate_5}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_cgst_Amnt_In_GstRate_5}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_igst_Amnt_In_GstRate_5}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_Amnt_In_GstRate_5}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {/* 12% */}
                                                                                    GST({gstRate}%)
                                                                                    </td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_In_GstRate_12}</td>

                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_sgst_Amnt_In_GstRate_12}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_cgst_Amnt_In_GstRate_12}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_igst_Amnt_In_GstRate_12}</td>
                                                                                 <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_Amnt_In_GstRate_12}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {/* 12% */}
                                                                                    GST({gstRate}%)
                                                                                    </td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_In_GstRate_18}</td>

                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_sgst_Amnt_In_GstRate_18}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_cgst_Amnt_In_GstRate_18}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_igst_Amnt_In_GstRate_18}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_Amnt_In_GstRate_18}</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>28%</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_In_GstRate_28}</td>

                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_sgst_Amnt_In_GstRate_28}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_cgst_Amnt_In_GstRate_28}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_igst_Amnt_In_GstRate_28}</td>
                                                                                <td style={{ textAlign: 'center', paddingRight: '1%' }}>{data.Item_Amnt_In_GstRate_28}</td>

                                                                            </tr>



                                                                        </tbody>
                                                                    )
                                                                }
                                                            })}

                                                        </table>


                                                    </td>
                                                    {this.state.OrderData.map((data, i) => {
                                                        if (i === 0) {
                                                            return (
                                                                <td>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Value Before Discount</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.fld_sub_total_mrp).toFixed(2)}
                                                                        </td>

                                                                    </tr>

                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Discount ({data.fld_coupon_code})</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.fld_coupon_discount == null ? 0 : data.fld_coupon_discount).toFixed(2)}
                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Taxable Value</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.fld_sub_total_mrp - data.fld_coupon_discount).toFixed(2)}
                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            GST</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {data.Item_Amnt_In_GstRate_5 != null ? data.Item_Amnt_In_GstRate_5 : data.Item_Amnt_In_GstRate_12 != null ? data.Item_Amnt_In_GstRate_12 : data.Item_Amnt_In_GstRate_18 != null ? data.Item_Amnt_In_GstRate_18 : data.Item_Amnt_In_GstRate_28 != null ? data.Item_Amnt_In_GstRate_28 : "0.00"}
                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Net Invoice Value Before Shipping(A)</span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.fld_total_to_bepaid).toFixed(2)}
                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Shipping(B) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.Shipping_Charg == null ? 0.00 : data.Shipping_Charg).toFixed(2)}

                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            GST on Shipping(C) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.Shipping_Charg_gst == null ? 0.00 : data.Shipping_Charg_gst).toFixed(2)}

                                                                        </td>

                                                                    </tr>
                                                                    <tr style={{ width: '100%', display: 'table' }}>
                                                                        <td style={{ textAlign: 'right', padding: '1%' }}><span style={{ fontWeight: 'bold' }}>
                                                                            Total Billed Value(A+B+C) </span></td><td style={{ textAlign: 'right', paddingRight: '1%', width: '35%' }}> &#8377;
                                                                            {parseFloat(data.fld_total_to_bepaid + data.Shipping_Charg + data.Shipping_Charg_gst).toFixed(2)}

                                                                        </td>

                                                                    </tr>

                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                </tr>


                                                <tr>
                                                    <td colspan="10" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Amount in Words:</span> {this.state.AmountInWords} {" "}Only</td>
                                                </tr>
                                                {this.state.OrderData.map((data, i) => {
                                                    if (i === 0) {
                                                        return (

                                                            <tr>
                                                                <td colspan="10" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                    <span style={{ fontWeight: 'bold' }}>Payment Mode:{"  "}</span>   {data.Payment_Mode}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                                <tr>
                                                    {/* <td colspan="5" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Amount in Words:</span>{this.state.AmountInWords}</td> */}
                                                    <td colspan="5" style={{ textAlign: 'left', paddingLeft: '1%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Payment By: </span> {" "}{this.state.PaymentBy}</td>
                                                </tr>








                                            </tbody>
                                        </table>
                                        <table style={{
                                            width: '630px',
                                            textAlign: 'center',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            borderRightColor: '#000',
                                            borderTop: 'hidden',
                                            fontFamily: 'Lato, sans-serif',
                                            borderCollapse: 'collapse',
                                            marginBottom: '30px'
                                        }}
                                            border="1"
                                            cellspacing="1"
                                            cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td colspan="4"></td>
                                                    <td colspan="6"

                                                        style={{
                                                            paddingTop: '1%',
                                                            paddingBottom: '1%',
                                                            textAlign: 'center',
                                                            background: '#f7f7f7'
                                                        }}
                                                    >
                                                        Have a Question?<br /> Call us on {this.state.ClientPhoneNumber} or Email us
                                                        at {this.state.ClientEmail}
                                                    </td>
                                                </tr>

                                                <tr
                                                    class="success"
                                                    style={{ backgroundColor: '#f7f7f7' }}
                                                >
                                                    <td colspan="4"></td>
                                                    <td colspan="6"

                                                        style={{
                                                            paddingTop: '1%',
                                                            paddingBottom: '1%',
                                                            textAlign: 'center',
                                                            background: '#f7f7f7'
                                                        }}
                                                    >
                                                        SHOP ONLINE AT
                                                        &nbsp; <a href="https://www.flosilshop.com/"
                                                        >{this.state.ClientWebsite}</a
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    {/* <WelcomeComp /> */}
                                </Card>
                            </Col>
                        </Row>

                    </Container>

                </div>
            </React.Fragment>
        );
    }
}
export default Dispatch;
