import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";







// import DatePicker from 'react-date-picker';

class StickerPriceCalculation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            ShapeData: [],
            MaterialData: [],

            Shape: null,
            Length: null,
            Width: null,
            Material: [],
            NumRegex: /^[0-9]*$/,
            Quantity: null,
            PriceCalculatedData: [],
            GST: null


        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");





        PostApiCall.postRequest(
            {
                recordCount: '*',
                whereClause: ``,

            },
            "GetStickerShapeMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    // console.log(obj.data)
                    this.setState({
                        ShapeData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
        PostApiCall.postRequest(
            {
                recordCount: '*',
                whereClause: ``,

            },
            "GetStickerMaterialMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    // console.log(obj.data)
                    this.setState({
                        MaterialData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))



    }


    calculatePrice = () => {
        PostApiCall.postRequest(
            {
                quantity: this.state.Quantity,
                shape: this.state.Shape,
                digital: 'Y',
                material: this.state.Material,
                length: this.state.Length,
                width: this.state.Width,
            },
            "GetStickerPrice").then((resultdes) =>
                resultdes.json().then((obj1) => {

                    // console.log(obj1.data)
                    this.setState({
                        PriceCalculatedData: obj1.data,
                    }

                    );
                })
            )

    }




    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {
        const { text, suggestions } = this.state;





        // =========VariantList=============
        const pricedata = {
            columns: [
                {
                    label: "Total Sheets",
                    field: "TSM",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "UPS",
                    field: "TSC",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Material Cost",
                    field: "MC",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Printing Cost",
                    field: "PC",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Digital Cutting Cost",
                    field: "DCC",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Shipping Cost ",
                    field: "SC",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Total Cost",
                    field: "TC",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Discounted Total",
                    field: "Discount",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Total Incl. GST",
                    field: "GST",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Final Unit Cost",
                    field: "FinalUnitCost",
                    sort: "disabled",
                    width: 150,
                }
            ], // end for variant
            rows: this.state.PriceCalculatedData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
            }).map((data, i) => {
                return {
                    DCC: (
                        "₹ " + parseFloat(data.digitalcutting).toFixed(2)
                    ),
                    PC: (
                        "₹ " + parseFloat(data.printing).toFixed(2)

                    ),
                    MC: (
                        "₹ " + parseFloat(data.material).toFixed(2)
                    ),
                    SC: (
                        "₹ " + parseFloat(data.shipping).toFixed(2)
                    ),
                    TSM: (
                        +  parseFloat(data.totalsheetminimum).toFixed(2)
                    ),
                    TSC: (
                        (data.totalsticker)
                    ),

                    UC: (
                        "₹ " + parseFloat(data.unitcost).toFixed(2)
                    ),
                    TC: (
                        "₹ " + parseFloat(data.totalwithoutdiscountandgst).toFixed(2)
                    ),
                    Discount: (
                        "₹ " + parseFloat(data.costincgst).toFixed(2)
                    ),
                    GST: (
                        "₹ " + parseFloat(data.total).toFixed(2)
                    ),
                    FinalUnitCost: (
                        "₹ " + parseFloat(data.unitcostincgst).toFixed(2)
                    ),

                }
            })
        }





        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Price Calculation Master"} urlPath={"/price_calculation"} />

                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Calculate Price By</h4>
                                                </div>
                                            </div>




                                        </div>
                                    </div>


                                    <Card style={{ padding: '5px 20px' }} >
                                        <div className="row my-1 ">


                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Shape"
                                                        value={this.state.Item}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Shape: text.target.value
                                                            })
                                                        }}

                                                    >
                                                        <option value="">
                                                            Select Shape
                                                        </option>
                                                        {this.state.ShapeData.map(
                                                            (product) => (
                                                                <option
                                                                    key={product.fld_id}
                                                                    value={product.fld_id}
                                                                >
                                                                    {product.fld_shape_name}
                                                                </option>
                                                            )
                                                        )}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-3">

                                                <div className="form-group">
                                                    <input className="form-control" value={this.state.Length} placeholder="Enter Length"
                                                        onChange={(text) => {
                                                            if (this.state.NumRegex.test(text.target.value)) {

                                                                this.setState({
                                                                    Length: text.target.value
                                                                })
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>




                                            <div className="col-3">

                                                <div className="form-group">
                                                    <input className="form-control" value={this.state.Width} placeholder="Enter Width"
                                                        onChange={(text) => {
                                                            if (this.state.NumRegex.test(text.target.value)) {

                                                                this.setState({
                                                                    Width: text.target.value
                                                                })
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>


                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.Material}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Material: text.target.value
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Material
                                                        </option>
                                                        {this.state.MaterialData.map(
                                                            (material) => (
                                                                <option
                                                                    key={material.fld_id}
                                                                    value={material.fld_id}
                                                                >{material.fld_material_name}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-3">

                                                <div className="form-group">
                                                    <input className="form-control" value={this.state.Quantity} placeholder="Enter Quantity"
                                                        onChange={(text) => {
                                                            if (this.state.NumRegex.test(text.target.value)) {

                                                                this.setState({
                                                                    Quantity: text.target.value
                                                                })
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {
                                                            if (this.state.Shape != null) {
                                                                if (this.state.Length != null) {
                                                                    if (this.state.Width != null) {
                                                                        if (this.state.Quantity != null) {
                                                                            if (this.state.Material != null) {

                                                                                this.calculatePrice()

                                                                            }
                                                                            else {
                                                                                Notiflix.Notify.Failure("Please select the Material");
                                                                            }
                                                                        }
                                                                        else {
                                                                            Notiflix.Notify.Failure("Please enter quantity ");
                                                                        }
                                                                    }
                                                                    else {
                                                                        Notiflix.Notify.Failure("Please end width");
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.Failure("Please enter length");
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure("Please select the shape");
                                                            }
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Calculate Price{" "}

                                                    </button>




                                                </div>
                                            </div>






                                        </div>
                                    </Card>

                                    <Card >
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-4">
                                                    <div className="form-group" >
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Price List</h4>
                                                    </div>

                                                </div>






                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>



                                                <Col md="12 mt-2" >

                                                    {this.state.PriceCalculatedData.length !== 0 ?
                                                        <MDBDataTable
                                                            hover
                                                            // scrollY
                                                            striped
                                                            bordered
                                                            data={pricedata}
                                                            seachTop={false}
                                                            entriesOptions={[25, 50, 100]}
                                                            displayEntries={false}
                                                        >

                                                            <MDBTableHead columns={pricedata.columns} />
                                                            <MDBTableBody rows={pricedata.rows} />
                                                        </MDBDataTable>
                                                        :
                                                        <p>
                                                            No Data Found
                                                        </p>
                                                    }


                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Card>

                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}
export default StickerPriceCalculation;
