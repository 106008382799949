import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import imageCompression from "browser-image-compression";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";



const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                for="photo-upload"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);


const ImgUploadNew = ({ onChange, src,id }) => (
    <label htmlFor={id} className="custom-file-upload fas">
        <div className="img-wrap-new img-upload">
            <img
                htmlFor={id}
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id={id} type="file" onChange={onChange} />
    </label>
);

class AddDesignImage extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        this.state = {
            imagePreviewUrl:
                "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            Status: "Yes",
            ImageData: [],
            VerticalData: [],
            BoardName: "",
            DesignImageId: null,
            Action: action,
            BoardDescription: "",
            Verticalname: "",
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,

            ImageName: null,
            BoardImage: null,
            Cost: null,
            GSM: null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            DesignData: [],
            Size: "D",

            Variant: null,
            VariantData: [],
            DesignName: null,
            DesignImageData: [],
            ImageUrl: "",
            ProductData: [],
            Product: null,

        };
    }

    getDesignImage() {
        PostApiCall.postRequest(
            {

                whereClause: `where fld_design_id=${this.state.DesignName}`,
                recordCount: '*'
            },
            "GetDesign"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    if (obj.data.length != 0) {
                        this.setState({
                            DesignImageData: obj.data,
                           // Size: obj.data[0].VariantImage.split('#')[1].split('$')[2]


                        });
                    }
                    Notiflix.Loading.Remove();
                }
            }))
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        PostApiCall.postRequest(
            {

                whereClause: '',
                recordCount: 'fld_productid as value,fld_itemname as label'
            },
            "GetProductMaster"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        ProductData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))



        // var det = localStorage.getItem("BoardDetails");
        // var BoardData = JSON.parse(det);

        // if (BoardData != null) {
        //     if (this.state.Action == "update") {
        //         this.setState({
        //             BoardName: BoardData.fld_board_name,
        //             DesignImageId: BoardData.fld_board_id,
        //             Status: BoardData.fld_status == 'Active' ? 'Yes' : 'No',
        //             Cost: BoardData.fld_cost,
        //             GSM: BoardData.fld_gsm,

        //         });
        //     }

        //     if (BoardData.fld_image != null) {
        //         this.setState({
        //             imagePreviewUrl: BoardData.fld_image,
        //             BoardImage: BoardData.fld_image,
        //         });
        //     } else {
        //         this.setState({
        //             imagePreviewUrl:
        //                 "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
        //         });
        //     }

        //     if (this.state.Action == "update") {
        //         new Promise((resolve, reject) => {
        //             setTimeout(resolve, 1000);
        //         }).then(() => {
        //             this.setState({
        //                 BoardDescription: BoardData.fld_description,
        //                 // TermsCondition:OfferData.fld_termscondition,
        //             });
        //         });
        //     }
        // }
    }

    // ==Image Upload onChange Function=======
    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };



    onChangeDescription(text) {
        this.setState({
            BoardDescription: text.editor.getData(),
        });
    }

    onSaveData() {
        if (this.state.Variant != null) {
            if (this.state.DesignName != null) {
                if (
                    this.state.imagePreviewUrl !=
                    "https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
                ) {
                    if (this.state.Size != null) {
                        this.onPost();

                    } else {
                        Notiflix.Notify.Failure("Please enter size");
                    }
                }
                else {
                    Notiflix.Notify.Failure("Please upload design image");
                }
            } else {
                Notiflix.Notify.Failure("Please select design name");
            }
        } else {
            Notiflix.Notify.Failure("Please select variant name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        if (this.state.DesignImageId == null) {

            if (this.state.BoardImage != this.state.imagePreviewUrl) {

                if (this.state.outputFileName != undefined) {

                    PostApiCall.postRequest(
                        {
                            designimageid: this.state.DesignImageId,
                            designid: this.state.DesignName,
                            image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" + this.state.outputFileName,
                            productid: null,
                            size: this.state.Size
                        },
                        "ProductDesignImage"
                    ).then((resultcategory) =>
                        resultcategory.json().then((obj) => {
                            if (resultcategory.status == 200 || resultcategory.status == 201) {
                                Notiflix.Notify.Success("Design successfully added. Image upload in process");

                                this.onUploadImage(obj);

                            } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(obj.data);
                            }
                        })
                    );

                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure("Please Upload Design Image");
                }
            }
            else {
                console.log("Update")
                this.onUpdateDesign()
            }
        }
        else {
            console.log("Update")
            this.onUpdateDesign()
        }

    }



    onUpdateDesign = () => {
        if (this.state.outputFileName != undefined) {
            // if(this.state.Size != null){
            PostApiCall.postRequest(
                {
                    designimageid: this.state.DesignImageId,
                    designid: this.state.DesignName,
                    image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" + this.state.outputFileName,

                    productid: null,
                    size: this.state.Size

                },
                "ProductDesignImage"
            ).then((resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        Notiflix.Notify.Success("Design Image successfully added. Image upload in process");

                        this.onUploadImage(obj);
                    } else {
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Failure(obj.data);
                    }
                })
            );

        }
        else {
            PostApiCall.postRequest(
                {
                    designimageid: this.state.DesignImageId,
                    designid: this.state.DesignName,
                    image: this.state.imagePreviewUrl,
                    productid: null,
                    size: this.state.Size

                },
                "ProductDesignImage"
            ).then((resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        Notiflix.Notify.Success("Design Image successfully added. Image upload in process");

                        this.onUploadImage(obj);
                    } else {
                        Notiflix.Loading.Remove();
                        Notiflix.Notify.Failure(obj.data);
                    }
                })
            );

        }
    }



    async onUploadImage(obj) {
        Notiflix.Loading.Dots("Uploading Images...");

        let response;

        if (this.state.outputFileName != undefined) {
            console.log('not defined')

            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {



                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "ProductImages");
                    form.append(
                        "filename",
                        compressedFile.name

                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then(response => response.json())
                        .then(data => {

                            Notiflix.Loading.Remove()
                            Notiflix.Notify.Success("Image successfully uploaded.")
                            this.getDesignImage()
                            // window.location.reload();

                        }

                        )
                    // //console.log(response);


                })
        }
        else {
            Notiflix.Loading.Remove()
            Notiflix.Notify.Success("Image successfully uploaded.")
            this.getDesignImage()
            // window.location.href = "/board_master";
        }




    }



    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Design Image"} breadcrumbItem1={this.state.DesignImageId == null ? "Add New Image" : "Update Image"} urlPath={"/design_image"} urlPath1={this.state.DesignImageId == null ? "/add_designimage/create" : "/add_designimage/update"} />
                        <Row>
                            <Col xl="12">


                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    {this.state.DesignImageId == null ?
                                                        <h5 className="Bechofy-text">
                                                            Add New Design Image
                                                        </h5>
                                                        :
                                                        <h5 className="Bechofy-text">
                                                            Update Design Image
                                                        </h5>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row  ">
                                                        <div className="col col-12">
                                                            <div className="row">
                                                                <div className="col col-3">
                                                                    <label
                                                                        className="my-1 mr-2"
                                                                        for="inlineFormCustomSelectPref"
                                                                    >
                                                                        Item Name
                                                                        <span className="mandatory">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <select
                                                                        value={this.state.Product}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                Product: text.target.value,
                                                                            }, () => {
                                                                                PostApiCall.postRequest(
                                                                                    {

                                                                                        whereClause: `where fld_productid=${this.state.Product}`,
                                                                                        recordCount: 'fld_variantid as value,fld_variantname as label'
                                                                                    },
                                                                                    "GetVariantData"
                                                                                ).then((results) =>
                                                                                    results.json().then((obj) => {
                                                                                        if (results.status == 200 || results.status == 201) {
                                                                                            this.setState({
                                                                                                VariantData: obj.data,


                                                                                            });
                                                                                            Notiflix.Loading.Remove();
                                                                                        }
                                                                                    }))
                                                                            });
                                                                        }}
                                                                        className="custom-select my-1 mr-sm-2"
                                                                    >
                                                                        <option>Select Item</option>
                                                                        {this.state.ProductData.map(
                                                                            (variant) => (
                                                                                <option
                                                                                    key={variant.value}
                                                                                    value={variant.value}
                                                                                >
                                                                                    {variant.label}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <div className="col col-3">
                                                                    <label
                                                                        className="my-1 mr-2"
                                                                        for="inlineFormCustomSelectPref"
                                                                    >
                                                                        Variant Name
                                                                        <span className="mandatory">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <select
                                                                        value={this.state.Variant}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                Variant: text.target.value,
                                                                            }, () => {
                                                                                PostApiCall.postRequest(
                                                                                    {

                                                                                        whereClause: `where fld_variant_id=${this.state.Variant}`,
                                                                                        recordCount: ' distinct fld_design_id as value,fld_design_name as label, fld_status'
                                                                                    },
                                                                                    "GetDesign"
                                                                                ).then((results) =>
                                                                                    results.json().then((obj) => {
                                                                                        if (results.status == 200 || results.status == 201) {
                                                                                            // console.log(obj.data)
                                                                                            this.setState({
                                                                                                DesignData: obj.data.filter(val => val.fld_status == "Active"),
                                                                                                //Size: obj.data[0].VariantImage.split('#')[1].split('$')[2]

                                                                                            });
                                                                                            Notiflix.Loading.Remove();
                                                                                        }
                                                                                    }))
                                                                            });
                                                                        }}
                                                                        className="custom-select my-1 mr-sm-2"
                                                                    >
                                                                        <option>Select Variant</option>
                                                                        {this.state.VariantData.map(
                                                                            (variant) => (
                                                                                <option
                                                                                    key={variant.value}
                                                                                    value={variant.value}
                                                                                >
                                                                                    {variant.label}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>

                                                                <div className="col col-3">
                                                                    <label
                                                                        className="my-1 mr-2"
                                                                        for="inlineFormCustomSelectPref"
                                                                    >
                                                                        Design Name
                                                                        <span className="mandatory">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <select
                                                                        value={this.state.DesignName}
                                                                        onChange={(text) => {
                                                                            this.setState({
                                                                                DesignName: text.target.value
                                                                            }, () => {
                                                                                PostApiCall.postRequest(
                                                                                    {

                                                                                        whereClause: `where fld_design_id=${this.state.DesignName}`,
                                                                                        recordCount: '*'
                                                                                    },
                                                                                    "GetDesign"
                                                                                ).then((results) =>
                                                                                    results.json().then((obj) => {
                                                                                        if (results.status == 200 || results.status == 201) {
                                                                                            // console.log(obj.data)
                                                                                            if (obj.data.length != 0) {
                                                                                                this.setState({
                                                                                                    DesignImageData: obj.data,
                                                                                                    Size: obj.data[0].VariantImage.split('#')[1].split('$')[2]


                                                                                                });
                                                                                            }
                                                                                            Notiflix.Loading.Remove();
                                                                                        }
                                                                                    }))
                                                                            })
                                                                        }}
                                                                        className="custom-select my-1 mr-sm-2"

                                                                    >
                                                                        <option value="">
                                                                            Select Design Name
                                                                        </option>
                                                                        {this.state.DesignData.map(
                                                                            (Design) => (
                                                                                <option
                                                                                    key={Design.value}
                                                                                    value={Design.value}
                                                                                >
                                                                                    {Design.label}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="DesignName">
                                                                            Size/Folder Name
                                                                            <span className="mandatory">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="DesignName"
                                                                            className="form-control my-1 mr-sm-2"
                                                                            value={this.state.Size}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Size:
                                                                                        text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>




                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">

                                                        <div class="col-11">
                                                            <label for="sw-arrows-first-name">
                                                                Upload Design Image (Size 1500*1500) <span className="mandatory">*</span>
                                                            </label>
                                                            <div class="div1">
                                                                <ImgUpload
                                                                    onChange={this.photoUpload}
                                                                    src={this.state.imagePreviewUrl}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            {this.state.DesignImageId == null ? <span>Save Design Image</span> : <span>Update Design Image</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
                                {/* </Card> */}


                                <Card style={{ display: this.state.DesignImageData.length == 0 ? 'none' : 'block' }}>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Design Images
                                        </CardTitle>
                                        <div className="row">
                                            {this.state.DesignImageData && this.state.DesignImageData.map((data, i) => (
                                                data.VariantImage != null ? data.VariantImage.split('#').map((img, i) => (
                                                    <div className="col-md-3">
                                                        <div class="best-products">
                                                            <div class="product-item border-0">
                                                                <div class="img-container" id={img.split("$")[1]} width="50%" onClick={() => {
                                                                    console.log(img.split("$")[1])
                                                                }}>
                                                                    <ImgUploadNew
                                                                        onChange={(e) => {
                                                                            e.preventDefault();
                                                                          
                                                                            const imageFile = e.target.files[0];
                                                                            this.setState({
                                                                                imagePreviewUrl: URL.createObjectURL(imageFile),
                                                                                originalImage: imageFile,
                                                                                outputFileName: imageFile.name,
                                                                                uploadImage: true,
                                                                                DesignImageId: img.split("$")[1],
                                                                                BoardImage: img.split("$")[0],
                                                                                Size: img.split("$")[2]
                                                                            });
                                                                            const designimageid = img.split("$")[1];
                                                                            // console.log(designimageid)
                                                                            
                                                                            if (imageFile.name != undefined) {

                                                                                PostApiCall.postRequest(
                                                                                    {
                                                                                        designimageid: designimageid,
                                                                                        designid: this.state.DesignName,
                                                                                        image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" + imageFile.name,
                                                                                        productid: null,
                                                                                        size: this.state.Size

                                                                                    },
                                                                                    "ProductDesignImage"
                                                                                ).then((resultcategory) =>
                                                                                    resultcategory.json().then((obj) => {
                                                                                        if (resultcategory.status == 200 || resultcategory.status == 201) {
                                                                                            Notiflix.Notify.Success("Design Image successfully added. Image upload in process");

                                                                                            this.onUploadImage(obj);
                                                                                        } else {
                                                                                            Notiflix.Loading.Remove();
                                                                                            Notiflix.Notify.Failure(obj.data);
                                                                                        }
                                                                                    })
                                                                                );
                                                                            }
                                                                        }
                                                                        }
                                                                        src={img.split("$")[0]}
                                                                        id={i}
                                                                    />
                                                                    {/* <a
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                imagePreviewUrl: img.split("$")[0],
                                                                                DesignImageId: img.split("$")[1],
                                                                                BoardImage: img.split("$")[0],
                                                                                Size: data.fld_size
                                                                            })
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        <img src={img.split("$")[0]} alt=""></img>
                                                                    </a> */}
                                                                    <span style={{ position: "absolute", left: "35px", top: "28px", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                ImageUrl: img.split("$")[0]
                                                                            }, () => {
                                                                                confirmAlert({
                                                                                    title: "Confirm to Delete",
                                                                                    message:
                                                                                        "Are you sure you want to delete the image?",
                                                                                    buttons: [
                                                                                        {
                                                                                            label: "Yes",
                                                                                            onClick: () => {
                                                                                                Notiflix.Loading.Dots("");

                                                                                                PostApiCall.postRequest(
                                                                                                    {
                                                                                                        imageid: parseInt(img.split("$")[1]),

                                                                                                    },
                                                                                                    "Delete_Image"
                                                                                                ).then((results) =>
                                                                                                    // const objs = JSON.parse(result._bodyText)
                                                                                                    results.json().then((obj) => {
                                                                                                        if (
                                                                                                            results.status == 200 ||
                                                                                                            results.status == 201
                                                                                                        ) {
                                                                                                            Notiflix.Loading.Remove();
                                                                                                            Notiflix.Notify.Success("Image successfully deleted.");
                                                                                                        } else {
                                                                                                            Notiflix.Loading.Remove();
                                                                                                            Notiflix.Notify.Failure(
                                                                                                                "Something went wrong, try again later."
                                                                                                            );
                                                                                                        }
                                                                                                    })
                                                                                                );
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            label: "No",
                                                                                            // onClick: () => alert('Click No')
                                                                                        },
                                                                                    ],
                                                                                });
                                                                            })
                                                                        }
                                                                        }
                                                                    ><span class="material-symbols-outlined">
                                                                            backspace
                                                                        </span></span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <p>No Image Found</p>
                                            )

                                            )}

                                        </div>










                                    </CardBody>
                                </Card>





                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddDesignImage;