import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol } from "mdbreact";
import GetApiCall from "../../GETAPI";
import { Link } from "react-router-dom";
import PostApiCall from "../../Api";
import Notiflix from "notiflix";
class cutomerList extends Component {
	state = {
		data: [],
		searchInput: "",
	};

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetCareerList").then(async (res) => {
			let data = await res.json();

			this.setState({ data: data.data });
			Notiflix.Loading.Remove();
		});
	}

	deleteRow = (id) => {
		PostApiCall.postRequest(
			{
				id: id,
				status: "Disabled",
			},
			"DeleteCareer"
		).then(async (res) => {
			if (res.status == 200) {
				await res.json();
				window.location.reload();
			}
		});
	};

	updateRow = (data) => {
		localStorage.setItem("careerdetails", JSON.stringify(data));
		window.location.href = "/edit-career";
	};

	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 120,
				},
				{
					label: "Job Title",
					field: "job",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Location",
					field: "location",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Job Type",
					field: "type",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Salary Range",
					field: "salary",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 250,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],

			rows: this.state.data
				.filter((data) => {
					//console.log(data);
					let jobtitle = [
						...data.fld_jobtitle
							.toLowerCase()
							.matchAll(this.state.searchInput.toLowerCase()),
					];
					let location = [
						...data.Name.toLowerCase().matchAll(
							this.state.searchInput.toLowerCase()
						),
					];

					if (this.state.searchInput.length > 0 && jobtitle[0]) {
						return data;
					}

					if (this.state.searchInput.length > 0 && location[0]) {
						return data;
					}

					if (
						this.state.searchInput.length > 0 &&
						data.fld_status.toLowerCase() ===
						this.state.searchInput.toLowerCase()
					) {
						return data;
					}

					if (
						this.state.searchInput.length > 0 &&
						data.fld_jobtype.toLowerCase() ===
						this.state.searchInput.toLowerCase()
					) {
						return data;
					}

					if (this.state.searchInput.length == 0) {
						return data;
					}
				})
				.map((item, i) => {
					return {
						serial: i + 1,
						job: item.fld_jobtitle,
						location: item.Name,
						type: item.fld_jobtype,
						salary: item.fld_salaryrange,
						status: item.fld_status,
						action: (
							<td className=" actionuser">
								{" "}
								<span className="btn" style={{ marginTop: "-9px" }}>
									<i
										className="fa fa-trash py-auto "
										aria-hidden="true"
										style={{
											fontSize: "15px",
											position: "relative",
											left: "80px",
										}}
										onClick={() => this.deleteRow(item.fld_careerid)}
									></i>
								</span>
								<i
									className="fas fa-edit btn"
									style={{
										fontSize: "15px",
										marginTop: "-11px",
										position: "relative",
										left: "80px",
									}}
									onClick={() => this.updateRow(item)}
								></i>
							</td>
						),
					};
				}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Career Management"}
							breadcrumbItem={"Career List"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Career Management
													</h5>
												</div>
											</Col>
										</Row>
									</div>

									<Card className="overflow-hidden">
										<div className="Bechofy-bg-soft-pink">
											<Row>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<h5 className="Bechofy-text">
															Career List
														</h5>
													</div>
												</Col>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<Link
															to="/add-career"
															style={{ float: "right", marginTop: "-5px" }}
															className="btn align-items-center btn Bechofy-btn "
														>
															Add New Job{" "}
															<i className="fa fa-plus" aria-hidden="true"></i>
														</Link>
													</div>
												</Col>
											</Row>
										</div>
										<CardBody className="pt-0">
											<Row></Row>
										</CardBody>
										<CardBody className="pt-0">
											<Row>
												<Col xs="12">
													<MDBCol md="3" style={{ marginBottom: "10px" }}>
														<input
															className="form-control"
															type="text"
															placeholder="Search"
															aria-label="Search"
															onChange={(e) =>
																this.setState({ searchInput: e.target.value })
															}
															value={this.state.searchInput}
														/>
													</MDBCol>
													<MDBDataTableV5
														striped
														bordered
														small
														data={data}
														searchBottom={false}
														seachTop={true}
														className="tablecol"
													/>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default cutomerList;
