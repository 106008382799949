import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


import "react-datepicker/dist/react-datepicker.css";

import Notiflix from "notiflix";
import PostApiCall from "../../../Api";





class categoryManagement extends Component {
	constructor(props) {
		super(props);

		this.state = {

			PriceData: [],
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			SP: 0,
			CP: 0,
			MRP: 0,
			WDP: 0


		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});


		var VariantID = JSON.parse(localStorage.getItem("variantid"));


		var StateID = JSON.parse(localStorage.getItem("stateId"))

		PostApiCall.postRequest(
			{
				stateid: StateID,
				variantid: VariantID
			},
			"downloadcsv"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						PriceData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))






	}

	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};






	onPost = () => {

		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var VariantID = JSON.parse(localStorage.getItem("variantid"));
		var StateID = JSON.parse(localStorage.getItem("stateId"))


		PostApiCall.postRequest(
			{
				variantpriceid: null,
				variantid: VariantID,
				mrp: this.state.MRP,
				costPrice: this.state.CP,
				sellingPrice: this.state.SP,
				websiteDiscountPrice: this.state.SP,
				stateid: StateID,
				updatedby: details[0].fld_userid,


			},
			"Add_Price"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					// //console.log(obj1)
					Notiflix.Loading.Remove();
					localStorage.removeItem("variantid");
					localStorage.removeItem("stateId");
					Notiflix.Notify.Success("Price successfully added.");

					window.location.href = "/price_management";
				}
				else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj1.data);
				}
			})
		);


	}

	render() {
		// //console.log(this.state.imagePreviewUrl)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/price_management">Price Management</a></li>

										<li>Add New Price</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								{/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

							</div>


						</div>

						<Row>
							<Col xl="12">
								{/* <Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Category Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div> */}
								{/* <Card className="overflow-hidden">

                        <Row>
                            <Col xl="12"> */}

								<Card className="overflow-hidden pagebackground">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Add New Price
													</h5>
												</div>
											</Col>

										</Row>
									</div>

									<Row style={{ marginTop: '15px' }}>
										<Col xl="4" >
											<Card className="" style={{ height: '225px' }}>
												<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}
												>
													<Row>
														{this.state.PriceData.map((data, i) => {
															return (
																<Col xs="7">
																	<div className="Bechofy-text p-3">
																		<h5 className="Bechofy-text">{data.ProductName}</h5>
																		<p><b>SKU</b> {data.SKU}</p>

																	</div>
																</Col>
															)
														})}

													</Row>
												</div>


												<CardBody className="pt-4">

													<Row>
														<Col xs="6">
															<p>MRP</p>
															<p>Cost Price</p>
															<p> Selling Price</p>


														</Col>
														{this.state.PriceData.map((data, i) => (


															<Col xs="6">
																<p>{data.MRP}</p>

																<p>{data.CostPrice}</p>
																<p>₹ {data.SellingPrice}</p>



															</Col>
														))}

													</Row>
												</CardBody>
											</Card>

										</Col>

										<Col md="8">
											<Card className="mini-stats-wid">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Add Price
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody>
													<Row>

														<Col xs="12">
															<div className="col col-12">
																<div className="row">


																	<div className="col-6">
																		<div className="form-group">
																			<label for="CategoryName">
																				MRP

																			</label>
																			<input
																				type="text"
																				id="CategoryName"
																				className="form-control "
																				value={this.state.MRP}
																				onChange={(text) => {
																					if (this.state.DecimalRegex.test(
																						text.target.value
																					)) {
																						this.setState({
																							MRP: text.target.value
																						})
																					}
																				}}


																			/>
																		</div>
																	</div>

																	<div className="col-6">
																		<div className="form-group">
																			<label for="CategoryName">
																				Cost Price

																			</label>
																			<input
																				type="text"
																				id="CategoryName"
																				className="form-control "
																				value={this.state.CP}
																				onChange={(text) => {
																					if (this.state.DecimalRegex.test(
																						text.target.value
																					)) {
																						this.setState({
																							CP: text.target.value
																						})
																					}
																				}}


																			/>
																		</div>
																	</div>


																	<div className="col-6">
																		<div className="form-group">
																			<label for="CategoryName">
																				Selling Price
																			</label>
																			<input
																				type="text"
																				id="CategoryName"
																				className="form-control "
																				value={this.state.SP}
																				onChange={(text) => {
																					if (this.state.DecimalRegex.test(
																						text.target.value
																					)) {
																						this.setState({
																							SP: text.target.value
																						})
																					}
																				}}


																			/>
																		</div>
																	</div>








																</div>

															</div>










														</Col>
													</Row>

												</CardBody>
											</Card>
										</Col>
									</Row>

								</Card>



								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={() => {
																if (this.state.MRP != 0) {
																	if (this.state.CP != 0) {
																		if (this.state.SP != 0) {
																			this.onPost()


																		}
																		else {
																			Notiflix.Notify.Failure('Please enter Selling Price')
																		}
																	}
																	else {
																		Notiflix.Notify.Failure('Please enter Cost Price')
																	}

																}
																else {
																	Notiflix.Notify.Failure('Please enter MRP')
																}
															}}
														>
															Add Price
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>

								</Card>


							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default categoryManagement;
