import React,{Component} from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";

class SelectState extends Component{
    constructor(props) {
		super(props);
		this.state = {
			StateData:[]
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		// Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("Get_State").then((resultdes) =>
		resultdes.json().then((obj) => {
			this.setState({
				StateData: obj.data,
			});
			Notiflix.Loading.Remove();
		}))
	}
    render(){
        return(
            <React.Fragment>
                <div className="d-flex justify-content-center">
                <div class="mt-3 price-card py-5 col-10">
                    <div>
                        <h3 className="stepper-heading d-flex justify-content-center align-items-center">Select the State for which you want the product price to be updated</h3><br/>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <select
                          className="form-control col-6"
                          value={this.state.State}
                          onChange={(text) => {
                            this.setState({
                              State: text.target.value,
                            
                            });
                          }}
                        >
                          <option value="">Select State</option>
                          {this.state.StateData.map((data) => {
                            return (
                              <option
                                key={data.label}
                                value={data.value}
                              >
                                {data.label}
                              </option>
                            );
                          })}
                        </select>
                    </div>
                </div>
                </div>
            </React.Fragment>
        )
    }
}
export default SelectState