import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol } from "mdbreact";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../Api";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import ReactChipInput from "react-chip-input";
import Notiflix from "notiflix";

class cutomerList extends Component {
  state = {
    data: [],
    searchInput: "",
    AttributeData: [],
    ViewClick: "true",
    open: false,
    Status: "Active",
    Name: "",
    openedit: false,
    Id: "",
    Status: "Active",
    Name: "",
    chips: [],
    Attributedrop: [],
    Attribute: "",
    Checbox: false,
  };

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest("GetAttributeMaster_Drop").then((resultdes) =>
      resultdes.json().then((obj) => {
        //console.log(obj.data);
        this.setState({
          Attributedrop: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );

    GetApiCall.getRequest("GetAttributeValue").then((resultdes) =>
      resultdes.json().then((obj) => {
        //console.log(obj.data);

        this.setState({
          AttributeData: obj.data,
        });

        Notiflix.Loading.Remove();
      })
    );
  }

  SaveAttribute() {
    if (this.state.Attribute != "") {
      if (this.state.chips != "") {
        this.onPost();
      } else {
        Notiflix.Notify.Failure("Please enter attribute value");
      }
    } else {
      Notiflix.Notify.Failure("Please enter attribute name");
    }
  }

  //   fld_attributeid: 2
  // fld_attributename: "Color"
  // fld_attributesvalue: "Black"
  // fld_attributevalueid: 1
  // fld_status: "Active"

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    PostApiCall.postRequest(
      {
        mappingId: this.state.Attribute,
        attributeValue: this.state.chips.join(","),
        status: this.state.Status,
        updatedon: moment().format("YYYY-MM-DD"),
        updatedby: details[0].fld_userid,
      },
      "AddAttributeValue"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          //   //console.log(obj)
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success(" Attribute value successfully added.");
          window.location.reload();
        } else {
          Notiflix.Notify.Failure(obj);
        }
      })
    );
  };

  // UpdateAttribute(){
  //   if(this.state.Attribute!=''){
  //     if(this.state.chips!=''){

  //       this.onPost2();
  //     }
  //     else{
  //       Notiflix.Notify.Failure('Please enter attribute value')
  //     }

  //   }
  //   else{
  //     Notiflix.Notify.Failure('Please enter attribute name')
  //   }

  // }

  // onPost2 = () => {

  //   Notiflix.Loading.Dots('Please wait...');

  //   var login=localStorage.getItem('LoginDetail');
  //   var details=JSON.parse(login)

  //   PostApiCall.postRequest ({
  //    attributevalueid:this.state.Id,
  //   mappingId : this.state.Attribute,
  //    attributeValue : this.state.chips,
  //    status : this.state.Status,

  //    updatedon : moment().format('YYYY-MM-DD'),
  //    updatedby :details[0].fld_userid,

  //     },"UpdateAttributeValue").then((resultcategory =>
  //   resultcategory.json().then(obj => {
  //       if(resultcategory.status == 200 || resultcategory.status == 201){
  //       //   //console.log(obj)
  //         Notiflix.Loading.Remove();
  //         Notiflix.Notify.Success(' Attribute value successfully added.')
  //          window.location.reload();

  //       }else
  //         {
  //           Notiflix.Notify.Failure(obj)
  //         }
  //   })
  //   ))

  // }

  addChip = (value) => {
    const chips = this.state.chips.slice();
    chips.push(value);
    this.setState({ chips });
    //console.log(chips);
  };
  removeChip = (index) => {
    const chips = this.state.chips.slice();
    chips.splice(index, 1);
    this.setState({ chips });
  };

  onChangeAttribute(text) {
    this.setState(
      {
        Attribute: text.target.value,
      },
      () => {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
          {
            id: this.state.Attribute,
          },
          "GetAttributeValue_Byid"
        ).then((results2) =>
          // const objs = JSON.parse(result._bodyText)
          results2.json().then((obj2) => {
            if (results2.status == 200 || results2.status == 201) {
              //console.log(obj2.data);
              let arr = [];
              if (obj2.data) {
                obj2.data.map((item) => {
                  arr.push(item.fld_attributesvalue);
                });
              }

              this.setState({
                chips: arr,
              });

              Notiflix.Loading.Remove();
            }
          })
        );
      }
    );
  }

  render() {
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 120,
        },
        {
          label: "Attribute Name",
          field: "job",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Attribute Value",
          field: "value",
          sort: "disabled",
          width: 150,
        },

        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 150,
        },
      ],

      rows:
        this.state.Checbox == false
          ? this.state.AttributeData.map((data, item) => {
              return {
                serial: item + 1,
                job: data.fld_attributename,
                value: data.fld_attributesvalue,
                status: data.fld_status,
              };
            })
          : this.state.AttributeData.filter((data) => {
              if (data.fld_status == "Active") {
                return data;
              }
            }).map((data, item) => {
              return {
                serial: item + 1,
                job: data.fld_attributename,
                value: data.fld_attributesvalue,
                status: data.fld_status,
              };
            }),
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Modal
              open={this.state.open}
              onClose={() => {
                this.setState({ open: false });
              }}
              center
            >
              <div class="modal-content modelcontent2">
                <div
                  class="modal-header Bechofy-bg-soft-pink"
                  style={{ background: "#777f80" }}
                >
                  <h4 class="modal-title text-white">
                    Add / Update Attribute Value
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="validationCustom01">
                        Attribute Name<span class="mandatory">*</span>
                      </label>
                      <select
                        value={this.state.Attribute}
                        onChange={this.onChangeAttribute.bind(this)}
                        className="custom-select my-1 mr-sm-2"
                      >
                        <option value="">Select Attribute </option>
                        {this.state.Attributedrop.map((attri) => (
                          <option key={attri.value} value={attri.value}>
                            {attri.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col col-12">
                    <div className="form-group">
                      <label for="CategoryName"> Attribute Values</label>

                      <ReactChipInput
                        classes="class1 class2"
                        chips={this.state.chips}
                        onSubmit={(value) => this.addChip(value)}
                        onRemove={(index) => this.removeChip(index)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div class="form-group mb-3">
                      <label for="validationCustom01">
                        Status<span class="mandatory">*</span>
                      </label>
                      <br />
                      <label class="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          checked={this.state.Status == "Active" ? true : false}
                          onChange={() => {
                            this.setState({
                              Status: "Active",
                            });
                          }}
                        />{" "}
                        Active
                      </label>
                      <label
                        class="radio-inline"
                        style={{ marginLeft: "10px" }}
                      >
                        <input
                          type="radio"
                          name="optradio"
                          checked={
                            this.state.Status == "Inactive" ? true : false
                          }
                          onChange={() => {
                            this.setState({
                              Status: "Inactive",
                            });
                          }}
                        />{" "}
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    className="btn align-items-center Bechofy-btn"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={() => {
                      this.setState({
                        open: false,
                        Status: "Active",
                      });
                      window.location.reload();
                    }}
                  >
                    Close
                  </button>

                  <button
                    style={{ float: "right" }}
                    className="btn align-items-center Bechofy-btn "
                    onClick={this.SaveAttribute.bind(this)}
                  >
                    Save New Attribute Value
                  </button>
                  <span></span>
                </div>
              </div>
            </Modal>

            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Bechofy / Master Management"}
              breadcrumbItem={"Attribute List"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ background: "#777f80" }}
                  >
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text text-white">
                            Attribute Value Master
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <div className="Bechofy-bg-soft-pink">
                          <Row>
                            <Col xs="6">
                              <div className="Bechofy-text p-3">
                                <h5 className="Bechofy-text">
                                  Attribute Value List
                                </h5>
                              </div>
                            </Col>
                            <Col xs="6">
                              <div className="Bechofy-text p-3">
                                <a
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                    });
                                  }}
                                  style={{
                                    float: "right",
                                    marginTop: "-5px",
                                    color: "#777f80",
                                  }}
                                  className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                >
                                  Add / Update Attribute Value{" "}
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-0">
                          <Row></Row>
                        </CardBody>
                        <CardBody className="pt-0">
                          <Row>
                            <Col xs="12">
                              <MDBCol
                                md="12"
                                style={{ marginBottom: "10px" }}
                                className="d-flex justify-content-between px-0"
                              >
                                <span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    onChange={(e) =>
                                      this.setState({
                                        searchInput: e.target.value,
                                      })
                                    }
                                    value={this.state.searchInput}
                                  />
                                </span>

                                <span>
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="activecheckattributevalue"
                                      // checked = {this.state.Checbox}
                                      onChange={() => {
                                        if(this.state.Checbox == false){
                                          this.setState({
                                            Checbox: true,
                                          });
                                          const removeFav =
                                            this.state.AttributeData;
                                            removeFav.filter((data) => {
                                              if (data.fld_status === "Active") {
                                                return data;
                                              }
                                            })
                                        }else if(this.state.Checbox == true){
                                          this.setState({
                                            Checbox: false,
                                          });
                                          const removeFav =
                                            this.state.AttributeData;
                                            removeFav.filter((data) => {
                                              if (data.fld_status === "Active" || data.fld_status === "Inactive") {
                                                return data;
                                              }
                                            })
                                        }
                                       
                                      }}
                                    />
                                    <label
                                      class="form-check-label"
                                      style={{
                                        fontSize: "15px",
                                        color: "#777f80",
                                      }}
                                    >
                                      Show Only Active
                                    </label>
                                  </div>
                                </span>
                              </MDBCol>
                              <MDBDataTableV5
                                striped
                                bordered
                                small
                                data={data}
                                searchBottom={false}
                                seachTop={true}
                                className="tablecol"
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {/* <WelcomeComp /> */}
                    </Col>
                  </Row>
                </Card>

                {/* <WelcomeComp /> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default cutomerList;
