import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
import GetApiCall from "../../GETAPI";



class WarehouseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Status: "Active",
            ImageData: [],
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            CountryData: [],
            CityData: [],
            StateData: [],
            CountryId: 0,
            StateId: 0,
            CityId: 0,
            Name: "",
            Mobile: null,
            Email: "",
            Website: "",
            Address: "",
            Country: "",
            State: "",
            City: "",
            Pincode: "",

            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex:
                /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            Landmark: "",
            Gstnumber: null,
            Pannumber: null,
            Tannumber: null,
            StoreName: "",
            StoreURL: "",
            Designation: "",
            ContactPerson: "",
            AboutCompany: "",
            IFSCCode: null,
            AccountNumber: null,
            BankName: null,
            Branch: null,
            BankData: [],

            WarehouseId: null
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        if (localStorage.getItem('warehouseId') != null) {
            var warehouseID = JSON.parse(localStorage.getItem('warehouseId'))
            // console.log(warehouseID)

            PostApiCall.postRequest(
                {
                    whereClause: `where fld_warehouseid= ${warehouseID.fld_warehouseid}`,
                    recordCount: '*'
                },
                "GetWarehouseByState"
            ).then((results) =>
                results.json().then((objWarehouse) => {
                    if (results.status == 200 || results.status == 201) {
                        // console.log(objWarehouse.data)
                        if (objWarehouse.data.length != 0) {

                            this.setState({
                                Name: objWarehouse.data[0].fld_name,
                                Status: objWarehouse.data[0].fld_status,
                                Gstnumber: objWarehouse.data[0].fld_gst,
                                Pannumber: objWarehouse.data[0].fld_pan,
                                Tannumber: objWarehouse.data[0].fld_tan,
                                ContactPerson: objWarehouse.data[0].fld_contact_person,
                                Mobile: objWarehouse.data[0].fld_contact_phone,
                                Email: objWarehouse.data[0].fld_contact_email,
                                Address: objWarehouse.data[0].fld_address,
                                Landmark: objWarehouse.data[0].fld_landmark,
                                Country: objWarehouse.data[0].fld_country,
                                State: objWarehouse.data[0].fld_state,
                                City: objWarehouse.data[0].fld_city,
                                Pincode: objWarehouse.data[0].fld_pincode,
                                AccountNumber: objWarehouse.data[0].fld_accountnumber,
                                BankName: objWarehouse.data[0].fld_bankname,
                                Branch: objWarehouse.data[0].fld_branch,
                                IFSCCode: objWarehouse.data[0].fld_ifsc,
                                WarehouseId: objWarehouse.data[0].fld_warehouseid,
                            }, () => {
                                GetApiCall.getRequest("GetCountry").then((resultdes) =>
                                    resultdes.json().then((obj) => {
                                        // console.log(obj.data)
                                        this.setState({
                                            CountryData: obj.data,
                                        });

                                        if (obj.data.length != 0) {
                                            this.setState({
                                                CountryId: obj.data.filter(
                                                    (value) => value.value == this.state.Country)[0].value
                                            });
                                        }


                                        PostApiCall.postRequest(
                                            {
                                                countryid: obj.data.filter(
                                                    (value) => value.value == this.state.Country
                                                )[0].value,
                                            },
                                            "GetState"
                                        ).then((results) =>
                                            results.json().then((objstate) => {
                                                if (results.status == 200 || results.status == 201) {
                                                    if (objstate.data.length != 0) {

                                                        this.setState({
                                                            StateId: objstate.data.filter(
                                                                (value) => value.value == this.state.State
                                                            )[0].value,

                                                            StateData: objstate.data,
                                                            State: objstate.data[0].value,
                                                        });
                                                    }

                                                    PostApiCall.postRequest(
                                                        {
                                                            stateid: objstate.data.filter(
                                                                (value) => value.value == this.state.State
                                                            )[0].value,
                                                        },
                                                        "GetCity"
                                                    ).then((resultscity) =>
                                                        resultscity.json().then((objcity) => {
                                                            if (resultscity.status == 200 || resultscity.status == 201) {
                                                                if (objcity.data.length != 0) {
                                                                    this.setState({
                                                                        CityId: objcity.data.filter(
                                                                            (value) => value.value == this.state.City
                                                                        )[0].value,
                                                                        CityData: objcity.data,
                                                                        City: objcity.data[0].value,
                                                                    });
                                                                }
                                                            }
                                                        })
                                                    );
                                                }
                                            })
                                        );
                                    }
                                    ))
                            });


                            GetApiCall.getRequest("GetCountry").then((resultdes) =>
                                resultdes.json().then((obj) => {
                                    this.setState({
                                        CountryData: obj.data,
                                    });

                                    if (obj.data.length != 0) {
                                        this.setState({
                                            CountryId: obj.data.filter(
                                                (value) => value.label == objWarehouse.data[0].countryname
                                            )[0].value,
                                        });
                                    }

                                    PostApiCall.postRequest(
                                        {
                                            countryid: obj.data.filter(
                                                (value) => value.label == objWarehouse.data[0].countryname
                                            )[0].value,
                                        },
                                        "GetState"
                                    ).then((results) =>
                                        results.json().then((objstate) => {
                                            if (results.status == 200 || results.status == 201) {
                                                if (objstate.data.length != 0) {

                                                    this.setState({
                                                        StateId: objstate.data.filter(
                                                            (value) => value.label == objWarehouse.data[0].statename
                                                        )[0].value,

                                                        StateData: objstate.data,
                                                        State: objstate.data[0].value,
                                                    });
                                                }

                                                PostApiCall.postRequest(
                                                    {
                                                        stateid: objstate.data.filter(
                                                            (value) => value.value == objWarehouse.data[0].statename
                                                        )[0].value,
                                                    },
                                                    "GetCity"
                                                ).then((resultscity) =>
                                                    resultscity.json().then((objcity) => {
                                                        if (resultscity.status == 200 || resultscity.status == 201) {
                                                            if (objcity.data.length != 0) {
                                                                this.setState({
                                                                    CityId: objcity.data.filter(
                                                                        (value) => value.value == this.state.City
                                                                    )[0].value,
                                                                    CityData: objcity.data,
                                                                    City: objcity.data[0].value,
                                                                });
                                                            }
                                                        }
                                                    })
                                                );
                                            }
                                        })
                                    );
                                })
                            );



                        }
                        Notiflix.Loading.Remove();
                    }
                }))

        }

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        // //console.log(details);

        Notiflix.Loading.Dots("Please wait...");

        GetApiCall.getRequest("GetCountry").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    CountryData: obj.data,
                });

                if (obj.data.length != 0) {
                    this.setState({
                        CountryId: 101,
                        Country: obj.data[102].value,
                    });
                }

                PostApiCall.postRequest(
                    {
                        countryid: obj.data[102].value,
                    },
                    "GetState"
                ).then((results) =>
                    results.json().then((objstate) => {
                        if (results.status == 200 || results.status == 201) {
                            if (objstate.data.length != 0) {
                                this.setState({
                                    StateId: objstate.data[0].value,

                                    StateData: objstate.data,
                                    State: objstate.data[0].value,
                                });
                            }

                            PostApiCall.postRequest(
                                {
                                    stateid: objstate.data[0].value,
                                },
                                "GetCity"
                            ).then((resultscity) =>
                                resultscity.json().then((objcity) => {
                                    if (resultscity.status == 200 || resultscity.status == 201) {
                                        if (objcity.data.length != 0) {
                                            this.setState({
                                                CityId: objcity.data[0].value,
                                                CityData: objcity.data,
                                                City: objcity.data[0].value,
                                            });
                                        }
                                    }
                                })
                            );
                        }
                    })
                );
            })
        );

        GetApiCall.getRequest("GetBankMaster").then((resultdes) =>
            resultdes.json().then((obj) => {
                // //console.log(obj.data)
                this.setState({
                    BankData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
    }



    onChangeCountry(country) {
        this.setState({
            CountryId: country.target.value,
            Country: this.state.CountryData[parseInt(country.target.value) + 1].value,
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country:
                    this.state.CountryData[parseInt(country.target.value) + 1].value,
            },
            "GetState"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        StateId: obj.data[0].value,
                        State: obj.data[0].value,
                    });

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value,
                        },
                        "GetCity"
                    ).then((resultscity) =>
                        resultscity.json().then((objcity) => {
                            if (resultscity.status == 200 || resultscity.status == 201) {
                                this.setState({
                                    CityData: objcity.data,
                                    StateData: obj.data,
                                });
                                if (objcity.data.length > 0) {
                                    this.setState({
                                        CityId: objcity.data[0].value,
                                        City: objcity.data[0].value,
                                    });
                                }
                                Notiflix.Loading.Remove();
                            }
                        })
                    );
                }
            })
        );
    }

    onChangeState(state) {
        this.setState({
            StateId: state.target.value,
        });

        Notiflix.Loading.Dots("Please wait...");

        for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
            if (this.state.StateData[i].value == state.target.value) {
                this.setState({
                    State: this.state.StateData[i].value,
                });
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value,
            },
            "GetCity"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {
                        this.setState({
                            CityData: obj.data,
                            CityId: obj.data[0].value,
                            City: obj.data[0].value,
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
    }

    onChangeCity(city) {
        this.setState({
            CityId: city.target.value,
        });

        for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
            if (this.state.CityData[i].value == city.target.value) {
                this.setState({
                    City: this.state.CityData[i].value,
                });
            }
        }
    }

    onSaveData() {
        if (this.state.Name != "") {
            if (this.state.Email != "") {
                if (this.state.EmailRegex.test(this.state.Email)) {
                    if (this.state.ContactPerson !== "") {
                        if (this.state.Mobile != null) {
                            if (this.state.Mobile.length == 10) {
                                if (this.state.Address != "") {
                                    if (this.state.Country != "") {
                                        if (this.state.State != "") {
                                            if (this.state.City != "") {
                                                if (this.state.Pincode != "") {
                                                    this.onPost();
                                                } else {
                                                    Notiflix.Notify.Failure("Please enter pincode");
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please enter city");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter state");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please select country");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please enter Address");
                                }
                            } else {
                                Notiflix.Notify.Failure(
                                    "Please enter contact's valid mobile number"
                                );
                            }
                        } else {
                            Notiflix.Notify.Failure(
                                "Please enter contact's mobile number"
                            );
                        }
                    } else {
                        Notiflix.Notify.Failure(
                            "Please enter Contact Person's Name"
                        );
                    }
                } else {
                    Notiflix.Notify.Failure("Please enter valid Contact's email");
                }
            } else {
                Notiflix.Notify.Failure("Please enter Contact's email");
            }

        } else {
            Notiflix.Notify.Failure("Please enter Warehouse's name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);


        PostApiCall.postRequest(
            {
                id: this.state.WarehouseId,
                name: this.state.Name,
                status: this.state.Status,
                gst: this.state.Gstnumber == null ? null : this.state.Gstnumber,
                pan: this.state.Pannumber == null ? null : this.state.Pannumber,
                tan: this.state.Tannumber == null ? null : this.state.Tannumber,
                contactperson: this.state.ContactPerson,
                contactphone: this.state.Mobile,
                contactemail: this.state.Email,
                address: this.state.Address,
                landmark: this.state.Landmark,
                country: this.state.Country,
                state: this.state.State,
                city: this.state.City,
                pincode: this.state.Pincode,
                accNumber: this.state.AccountNumber == null ? null : this.state.AccountNumber,
                bankName: this.state.BankName == null ? null : this.state.BankName,
                branch: this.state.Branch == null ? null : this.state.Branch,
                ifsc: this.state.IFSCCode == null ? null : this.state.IFSCCode,
                active: null,
                createdOn: moment().format("YYYY-MM-DD"),
                updatedOn: moment().format("YYYY-MM-DD"),
                createdBy: details[0].fld_userid,
                updatedBy: details[0].fld_userid,
            },
            "Add_Warehouse"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Notify.Success("Warehouse Added Successfully")
                    Notiflix.Loading.Remove();
                    window.location.href = "/warehouse_master"
                } else {
                    Notiflix.Notify.Failure(obj)
                }

            })
        );
    };

    render() {
        // console.log(this.state.StateId)
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Add Warehouse"} urlPath={"/warehouse_master"} />


                        <Row>
                            <Col xl='12'>
                                <Card className='overflow-hidden'>
                                    <div className='Bechofy-bg-soft-pink'>
                                        <Row>
                                            <Col xs='3'>
                                                <div className='Bechofy-text  p-3'>
                                                    <h5 className='Bechofy-text'>
                                                        Add Warehouse
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className='pt-0 usercarddata'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Warehouse Name
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Name'
                                                                                    value={this.state.Name}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Name: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Email
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='email'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Email'
                                                                                    value={this.state.Email}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Email: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    GST Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='GSTNUM'
                                                                                    value={this.state.Gstnumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Gstnumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    PAN Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Pannum'
                                                                                    value={this.state.Pannumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Pannumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    TAN Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='TanNum'
                                                                                    value={this.state.Tannumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Tannumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Person Name
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name=' Contact Person'
                                                                                    value={this.state.ContactPerson}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            ContactPerson: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Person Phone
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    value={this.state.Mobile}
                                                                                    onChange={(mobile) => {
                                                                                        if (
                                                                                            this.state.NumRegex.test(
                                                                                                mobile.target.value
                                                                                            ) &&
                                                                                            mobile.target.value.length <= 10
                                                                                        ) {
                                                                                            this.setState({
                                                                                                Mobile: mobile.target.value,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label for=''>
                                                                            Address
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <textarea
                                                                            className='form-control'
                                                                            row='50'
                                                                            col='30'
                                                                            value={this.state.Address}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Address: text.target.value,
                                                                                });
                                                                            }}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            Landmark
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control'
                                                                            name='website'
                                                                            value={this.state.Landmark}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Landmark: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            Country
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.CountryId}
                                                                            onChange={this.onChangeCountry.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.CountryData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.label}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            State
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.StateId}
                                                                            onChange={this.onChangeState.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.StateData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.value}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            City
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.CityId}
                                                                            onChange={this.onChangeCity.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.CityData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.label}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            Pincode
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control my-1 mr-sm-2'
                                                                            value={this.state.Pincode}
                                                                            onChange={(pincode) => {
                                                                                if (
                                                                                    this.state.NumRegex.test(
                                                                                        pincode.target.value
                                                                                    ) &&
                                                                                    pincode.target.value.length <= 6
                                                                                ) {
                                                                                    this.setState({
                                                                                        Pincode: pincode.target.value,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody className="pt-0 usercarddata identi">
                                        <CardTitle className="usertitle">
                                            Bank Information
                                        </CardTitle>
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row">
                                                        <div className="col col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Bank Name
                                                                                </label>
                                                                                <select
                                                                                    class="custom-select my-1 mr-sm-2"
                                                                                    id="inlineFormCustomSelectPref"
                                                                                    name="UserType"
                                                                                    value={this.state.BankName}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            BankName: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <option selected>
                                                                                        Select Bank
                                                                                    </option>
                                                                                    {this.state.BankData.map(
                                                                                        (bank) => (
                                                                                            <option
                                                                                                key={bank.value}
                                                                                                value={bank.value}
                                                                                            >
                                                                                                {bank.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Branch
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control my-1 mr-sm-2"
                                                                                    value={this.state.Branch}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Branch: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Account Number
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control"
                                                                                    value={this.state.AccountNumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            AccountNumber:
                                                                                                text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    IFSC Code
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control"
                                                                                    value={this.state.IFSCCode}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            IFSCCode: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className='py-1    my-1'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group my-1 mr-sm-2'>
                                                            <label for='CategoryName'>Status</label>
                                                            <br />
                                                            <label class='radio-inline'>
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Active" ? true : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Active",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Active
                                                            </label>
                                                            <label
                                                                class='radio-inline'
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Inactive"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Inactive",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Inactive
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button
                                                            style={{ float: "right", marginTop: "5px" }}
                                                            className='btn align-items-center Bechofy-btn '
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            Save & Add Warehouse
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default WarehouseMaster;
