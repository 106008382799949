import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import addicon from "../../assets/images/dashboardimages/add.png";
import editicon from "../../assets/images/dashboardimages/edit.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GetApiCall from "../../GETAPI";

class StickerCalcHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StickerCalcHeaderData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    localStorage.removeItem("StickerCalcHeaderDetails");

    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest(
      "GetStickerCalcHeader"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            StickerCalcHeaderData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Sticker Calculation Header"
              breadcrumbItem="Sticker Calculation Header"
            />
            <Row>
              <Col lg="12">
                <div className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text">
                            Sticker Calculation Header List
                          </h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <Link
                            to={`/add_sticker_calc_header/create`}
                            style={{
                              float: "right",
                              marginTop: "-5px",
                              background: "white",
                              color: "grey",
                            }}
                            className="btn align-items-center btn Bechofy-btn AddNewBtn"
                          >
                            Add New Sticker Calculation Header{" "}
                            <span className="">
                              <img
                                src={addicon}
                                alt="add sticker calculation header"
                                className="btnicons"
                                style={{ marginRight: "5px" }}
                              ></img>{" "}
                            </span>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Card style={{ padding: "10px 20px" }}>
                    <div className="row my-1 ">
                      <div className="col-12">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <span className="">
                                <img
                                  src={Searchicon}
                                  alt="search"
                                  className="btnicons"
                                ></img>
                              </span>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type the search phrase you wish to search within the grid"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={(e) => this.seachBarHandler(e)}
                            value={this.state.searchInput}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <CardBody>
                      <Row>
                        {this.state.StickerCalcHeaderData.map((data, i) => (
                          <Col xs="4">
                            <CardBody className="categoryCard">
                              <CardTitle className="mb-4">
                                Sticker Calculation Header ID: {data.fld_id}
                              </CardTitle>
                              <Row className="card-row">
                                <Col sm="12">
                                  <p>
                                    <b>Sheet Length: </b>{" "}
                                    {data.fld_sheet_length}
                                  </p>
                                  <p>
                                    <b>Sheet Width: </b> {data.fld_sheet__width}
                                  </p>
                                  <p>
                                    <b>Sticker Length: </b>{" "}
                                    {data.fld_sticker_length}
                                  </p>
                                  <p>
                                    <b>Sticker Width: </b>{" "}
                                    {data.fld_sticker_width}
                                  </p>
                                  <p>
                                    <b>Trial Sheet: </b> {data.fld_trial_sheet}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="card-row">
                                <Col sm="12">
                                  <Link
                                    to={`/edit_sticker_calc_header/${data.fld_id}`}
                                    className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                    style={{
                                      marginTop: "10px",
                                      color: "white",
                                    }}
                                  >
                                    <span className="">
                                      <img
                                        src={editicon}
                                        alt="edit sticker calculation header"
                                        className="btnicons"
                                        style={{ marginRight: "5px" }}
                                      ></img>{" "}
                                    </span>
                                    Edit Sticker Calculation Header
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default StickerCalcHeader;
