import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
// import Notiflix from "notiflix";
import EmptyBox from "../../assets/images/users/UploadPlaceHolder.png";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";
const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class BrandMaster extends Component {
	constructor(props) {
		super(props);
		const { action } = "insert";
		////console.log("param " + action);
		this.state = {
			imagePreviewUrl:
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
			Status: "Yes",
			ImageData: [],
			BrandName: "",
			BrandId: null,
			Action: action,
			CompanyId: null,
		};
	}

	onAddMoreHandler = () => {
		this.setState({
			inputList: [...this.state.inputList, { menuName: "", pageNAme: null }],
		});
	};

	onRemoveHandler = (index) => {
		let list = [...this.state.inputList];

		//   list.splice(index, 1);
		list = list.filter((data) => data !== index);
		this.setState({ ...this.state, inputList: list });
	};

	handleInputChange = (e, index) => {
		const { name, value } = e.target;
		this.setState({ ...this.state, [name]: value });
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({ ...this.state, imageValue: file, isImageValid: isvalid });
	};

	onSaveData() {
		if (this.state.BrandName != "") {
			if (
				this.state.imagePreviewUrl !=
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
			) {
				this.onPost();
			} else {
				Notiflix.Notify.Failure("Please upload brand image");
			}
		} else {
			Notiflix.Notify.Failure("Please enter the brand name");
		}
	}
	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		// //console.log("this.state.Action " + this.state.Action);
		PostApiCall.postRequest(
			{
				brandid: this.state.BrandId,
				name: this.state.BrandName,
				status: this.state.Status == "Yes" ? "Active" : "Inactive",
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				action: this.state.Action,
			},
			"AddBrand"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					this.onUploadImage(obj);
					//  //console.log(obj)
				} else {
					this.onUploadImage(obj);
				}
			})
		);
	};

	onUploadImage(obj) {
		Notiflix.Loading.Dots("Please wait...");
		// //console.log('images')
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		if (JSON.stringify(this.state.ImageData) != "[]") {
			const form = new FormData();

			form.append("file", this.state.ImageData);
			form.append("foldername", "brandImage");
			form.append(
				"filename",
				this.state.BrandName.trim().replace(/\s/g, "-") +
				"-" +
				JSON.parse(JSON.stringify(obj.data[0])).BrandId
			);

			fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {
						//     //console.log(res.data)
						// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).CategoryId)
						PostApiCall.postRequest(
							{
								id: JSON.parse(JSON.stringify(obj.data[0])).BrandId,
								image:
									"http://demo.globaltrendz.online/Bechofy/images/brandImages/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
								updatedon: moment().format("YYYY-MM-DD"),
								updatedby: details[0].fld_userid,
							},
							"UpdateBrandImage"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									Notiflix.Loading.Remove();
									Notiflix.Notify.Success("Brand successfully added.");
									window.location.href = "/category_management";
								}
							})
						);
					});
			});
		} else {
			Notiflix.Loading.Remove();
			Notiflix.Notify.Failure("Error Occured");
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy/Menu Management"}
							breadcrumbItem={"Main Menu Master"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="9">
												<div className="Bechofy-text  p-3">
													{/* <h5 className="Bechofy-text">Rider Area Assignment</h5> */}
													Menu
												</div>
											</Col>
											<Col xs="3">
												<div className="Bechofy-text float-right p-3">
													<h5 className="Bechofy-text">
														Main Menu Master
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="form my-4">
													<div className="row">
														<div className="col-4 ml-3 ">
															<div className="row">
																<div className="col col-12">
																	<div className="form-group ">
																		<label for="sw-arrows-first-name">
																			Upload Brand Image (Size
																			1500*1500)
																			<span className="mandatory">*</span>
																		</label>
																		<div class="div1">
																			<ImgUpload
																				onChange={this.photoUpload}
																				src={this.state.imagePreviewUrl}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col col-7">
															<div className="row">
																<div className="col-6">
																	<label class="my-1 mr-2">Brand Name</label>

																	<input
																		className="form-control"
																		name="name"
																		type="text"
																		placeholder="Enter Brand Name"
																		value={this.state.BrandName}
																		onChange={(text) => {
																			this.setState({
																				BrandName: text.target.value,
																			});
																		}}
																	/>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Is Enabled
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Yes"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Yes",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "No"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "No",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={this.onSaveData.bind(this)}
														>
															Save Brand
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>

								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default BrandMaster;
