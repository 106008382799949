import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import moment from "moment";
import { connect } from "react-redux";

import {
	setname,
	setemail,
	setmobile,
	setdob,
	setanniversary,
	setdesignation,
	setdepartment,
	setusertype,
	setaddress,
	setcountry,
	setstate,
	setcity,
	setpincode,
	setaadharcard,
	setpancard,
	setdrivinglicenses,
	setaccountno,
	setbankname,
	setbranch,
	setIfsccode,
	setSalary,
	setuserpassword,
	setuserconfirmpassword,
	setclearuser,
} from "../../components/Actions/ActionType";
import GetApiCall from "../../GETAPI";

class addCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Status: "Active",
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			GenderData: [
				{ value: "Male", label: "Male" },
				{ value: "Female", label: "Female" },
				{ value: "Others", label: "Others" },
			],
			Gender: "",
			CountryData: [],
			CityData: [],
			StateData: [],
			CountryId: 0,
			StateId: 0,
			CityId: 0,
			Name: "",
			Mobile: "",
			Email: "",
			DOB: "",
			Address: "",
			Country: "",
			State: "",
			City: "",
			Pincode: "",
			Password: "",
			ConfirmPassword: "",
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			Landmark: "",
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex: /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			isNewPasswordVisible: false,
			isConPasswordVisible: false,
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetDesignation").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					DesignationData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryData: obj.data,
				});

				if (obj.data.length != 0) {
					// this.props.setcountry(obj.data[100].label)

					this.setState({
						CountryId: 101,
						Country: obj.data[102].label,
					});
				}

				PostApiCall.postRequest(
					{
						countryid: obj.data[102].value,
					},
					"GetState"
				).then((results) =>
					// const objs = JSON.parse(result._bodyText)
					results.json().then((objstate) => {
						if (results.status == 200 || results.status == 201) {
							if (objstate.data.length != 0) {
								// this.props.setstate()

								this.setState({
									StateId: objstate.data[0].value,

									StateData: objstate.data,
									State: objstate.data[0].label,
								});
							}

							PostApiCall.postRequest(
								{
									stateid: objstate.data[0].value,
								},
								"GetCity"
							).then((resultscity) =>
								// const objs = JSON.parse(result._bodyText)
								resultscity.json().then((objcity) => {
									if (resultscity.status == 200 || resultscity.status == 201) {
										if (objcity.data.length != 0) {
											// this.props.setcity(objcity.data[0].label)
											this.setState({
												CityId: objcity.data[0].value,
												CityData: objcity.data,
												City: objcity.data[0].label,
											});
										}
									}
								})
							);
						}
					})
				);
			})
		);
	}

	onChangeCountry(country) {
		// //console.log(typeof(country.target.value));

		//  //console.log(this.state.CountryData[parseInt(country.target.value) +1])
		this.setState({
			CountryId: country.target.value,
			Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
		});
		//  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country: this.state.CountryData[parseInt(country.target.value) + 1]
					.label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//  this.props.setstate(obj.data[0].label)
					// //console.log(obj);
					this.setState({
						StateId: obj.data[0].value,
						State: obj.data[0].label,
					});

					PostApiCall.postRequest(
						{
							stateid: obj.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								this.setState({
									CityData: objcity.data,
									StateData: obj.data,
								});
								if (objcity.data.length > 0) {
									// this.props.setcity(objcity.data[0].label)

									this.setState({
										CityId: objcity.data[0].value,
										City: objcity.data[0].label,
									});
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	}

	onChangeState(state) {
		//  this.props.setstate(state.target.value)
		this.setState({
			StateId: state.target.value,
		});

		Notiflix.Loading.Dots("Please wait...");

		for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
			if (this.state.StateData[i].value == state.target.value) {
				// this.props.setstate();
				this.setState({
					State: this.state.StateData[i].label,
				});
			}
		}

		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						// this.props.setcity(obj.data[0].label)
						this.setState({
							CityData: obj.data,
							CityId: obj.data[0].value,
							City: obj.data[0].label,
						});
					}
					Notiflix.Loading.Remove();
					// this.props.cityData(obj.data)
				}
			})
		);
	}

	onChangeCity(city) {
		// this.props.setcity(city.target.value)
		this.setState({
			CityId: city.target.value,
		});

		for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
			if (this.state.CityData[i].value == city.target.value) {
				this.setState({
					City: this.state.CityData[i].label,
				});
				// this.props.setcity(this.state.CityData[i].label);
			}
		}
	}

	onSaveData() {
		//console.log(this.state.Country);

		if (this.state.Name != "") {
			if (this.state.Email != "") {
				if (this.state.EmailRegex.test(this.state.Email)) {
					if (this.state.Mobile != "") {
						if (this.state.Mobile.length == 10) {
							if (this.state.Address != "") {
								if (this.state.Country != "") {
									if (this.state.State != "") {
										if (this.state.City != "") {
											if (this.state.Pincode != "") {
												if (this.state.Pincode.length == 6) {
													if (this.state.Gender != "") {
														if (this.state.Password != "") {
															if (this.state.ConfirmPassword != "") {
																if (
																	this.state.ConfirmPassword ==
																	this.state.Password
																) {
																	this.onPost();
																} else {
																	Notiflix.Notify.Failure(
																		"Password and confirm password does not match"
																	);
																}
															} else {
																Notiflix.Notify.Failure(
																	"Please enter confirm password"
																);
															}
														} else {
															Notiflix.Notify.Failure("Please enter password");
														}
													} else {
														Notiflix.Notify.Failure("Please select gender");
													}
												} else {
													Notiflix.Notify.Failure("Please enter valid pincode");
												}
											} else {
												Notiflix.Notify.Failure("Please enter pincode");
											}
										} else {
											Notiflix.Notify.Failure("Please enter city");
										}
									} else {
										Notiflix.Notify.Failure("Please enter state");
									}
								} else {
									Notiflix.Notify.Failure("Please select country");
								}
							} else {
								Notiflix.Notify.Failure("Please enter address");
							}
						} else {
							Notiflix.Notify.Failure("Please enter valid mobile number");
						}
					} else {
						Notiflix.Notify.Failure("Please enter mobile number");
					}
				} else {
					Notiflix.Notify.Failure("Please enter valid email");
				}
			} else {
				Notiflix.Notify.Failure("Please enter email");
			}
		} else {
			Notiflix.Notify.Failure("Please enter name");
		}

		// this.onPost();
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");


		let details = JSON.parse(localStorage.getItem("LoginDetail"));

		PostApiCall.postRequest(
			{
				name: this.state.Name,
				email: this.state.Email,
				mobile: this.state.Mobile,
				gender: this.state.Gender,
				dateofbirth: this.state.DOB,
				presentaddress: this.state.Address,
				presentcountry: this.state.Country,
				presentstate: this.state.State,
				presentcity: this.state.City,
				presentpincode: this.state.Pincode,
				password: this.state.ConfirmPassword,
				status: this.state.Status,
				createdon: moment().format("DD/MM/YYYY"),
				updatedon: moment().format("DD/MM/YYYY"),
				updatedby: details[0].fld_userid,
				landmark: this.state.Landmark,
			},
			"add-customer"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (obj && obj.data == "Email Address already registered.") {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure("Email Address already registered.");
				} else {
					this.onPostMailer();
					// Notiflix.Loading.Remove()

					// Notiflix.Notify.Success('New Customer Successfully Added.')
					// window.location.href='/customer-management'
				}
			})
		);
	};

	onPostMailer = () => {
		Notiflix.Loading.Dots("Please wait...");

		//   var det = localStorage.getItem('RawMaterialDetails')
		// var RawData = JSON.parse(det)
		// let details = JSON.parse(localStorage.getItem('LoginDetail'))

		PostApiCall.postRequest(
			{
				// name : this.state.Name,
				email: this.state.Email,
				password: this.state.ConfirmPassword,
			},
			"GuestMailer"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (obj && obj.data == "Email Address already registered.") {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure("Email Address already registered.");
				} else {
					// //console.log('Successfull')
					// this.onPostMailer();
					Notiflix.Loading.Remove();

					Notiflix.Notify.Success(
						"New Customer Successfully Added and Password is successfully send to customer mail id."
					);
					window.location.href = "/customer-management";
				}
			})
		);
	};

	render() {
		//  //console.log(this.state)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Customer Management "}
							breadcrumbItem={"Add New Customer"}
						/>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Customer Management
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>

									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text  p-3">
																<h5 className="Bechofy-text">
																	Add New Customer
																</h5>
															</div>
														</Col>
													</Row>
												</div>

												<CardBody
													className="pt-0 usercarddata"
													style={{ height: "613px!important" }}
												>
													<CardTitle className="usertitle">
														Basic Personal Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-12">
																				<div className="row">
																					<div className="col-12">
																						<div className="form-group">
																							<label for="CategoryName">
																								Name
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								name="Name"
																								value={this.state.Name}
																								onChange={(text) => {
																									this.setState({
																										Name: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Email
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="email"
																								id="CategoryName"
																								className="form-control"
																								name="Email"
																								value={this.state.Email}
																								onChange={(text) => {
																									this.setState({
																										Email: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Mobile no
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control"
																								value={this.state.Mobile}
																								onChange={(mobile) => {
																									if (
																										this.state.NumRegex.test(
																											mobile.target.value
																										) &&
																										mobile.target.value
																											.length <= 10
																									) {
																										this.setState({
																											Mobile:
																												mobile.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-12">
																						<div className="form-group">
																							<label for="">
																								Address
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<textarea
																								className="form-control"
																								row="50"
																								col="30"
																								value={this.state.Address}
																								onChange={(text) => {
																									this.setState({
																										Address: text.target.value,
																									});
																								}}
																							></textarea>
																						</div>
																					</div>

																					<div className="col-12">
																						<div className="form-group">
																							<label for="">Landmark</label>
																							<textarea
																								className="form-control"
																								row="50"
																								col="30"
																								value={this.state.Landmark}
																								onChange={(text) => {
																									this.setState({
																										Landmark: text.target.value,
																									});
																								}}
																							></textarea>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								Country
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.CountryId}
																								onChange={this.onChangeCountry.bind(
																									this
																								)}
																							>
																								{this.state.CountryData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								State
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.StateId}
																								onChange={this.onChangeState.bind(
																									this
																								)}
																							>
																								{this.state.StateData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>
																					<div className="col-3">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								City/Area
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								name="UserType"
																								value={this.state.CityId}
																								onChange={this.onChangeCity.bind(
																									this
																								)}
																							>
																								{this.state.CityData.map(
																									(schedule) => (
																										<option
																											key={schedule.label}
																											value={schedule.value}
																										>
																											{schedule.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-3">
																						<div className="form-group">
																							<label for="CategoryName">
																								Pincode
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="text"
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.Pincode}
																								onChange={(pincode) => {
																									if (
																										this.state.NumRegex.test(
																											pincode.target.value
																										) &&
																										pincode.target.value
																											.length <= 6
																									) {
																										this.setState({
																											Pincode:
																												pincode.target.value,
																										});
																									}
																								}}
																							/>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label
																								class="my-1 mr-2"
																								for="inlineFormCustomSelectPref"
																							>
																								Gender
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<select
																								class="custom-select my-1 mr-sm-2"
																								id="inlineFormCustomSelectPref"
																								value={this.state.Gender}
																								onChange={(text) => {
																									this.setState({
																										Gender: text.target.value,
																									});
																								}}
																							>
																								<option>Select Gender</option>
																								{this.state.GenderData.map(
																									(gender) => (
																										<option
																											key={gender.value}
																											value={gender.value}
																										>
																											{gender.label}
																										</option>
																									)
																								)}
																							</select>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Date of Birth
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type="date"
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								value={this.state.DOB}
																								onChange={(text) => {
																									this.setState({
																										DOB: text.target.value,
																									});
																								}}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>

												<CardBody className="pt-0 usercarddata userinfo2">
													<CardTitle className="usertitle">
														customer Information
													</CardTitle>
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-12">
																				<div className="row">
																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Password
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type={
																									this.state
																										.isNewPasswordVisible
																										? "text"
																										: "password"
																								}
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								name="Password"
																								value={this.state.Password}
																								onChange={(text) => {
																									this.setState({
																										Password: text.target.value,
																									});
																								}}
																							/>
																							<span class="login-icon-change-pass">
																								{/* <i class="icon-dual" data-feather="lock"></i>*/}
																								<i
																									style={{
																										color: this.state
																											.isNewPasswordVisible
																											? "#777f80"
																											: "",
																									}}
																									className="fa fa-eye"
																									onClick={() => {
																										this.setState({
																											isNewPasswordVisible: !this
																												.state
																												.isNewPasswordVisible,
																										});
																									}}
																								/>
																							</span>
																						</div>
																					</div>

																					<div className="col-6">
																						<div className="form-group">
																							<label for="CategoryName">
																								Confirm Password
																								<span className="mandatory">
																									*
																								</span>
																							</label>
																							<input
																								type={
																									this.state
																										.isConPasswordVisible
																										? "text"
																										: "password"
																								}
																								id="CategoryName"
																								className="form-control my-1 mr-sm-2"
																								name="ConfirmPassword"
																								value={
																									this.state.ConfirmPassword
																								}
																								onChange={(text) => {
																									this.setState({
																										ConfirmPassword:
																											text.target.value,
																									});
																								}}
																							/>
																							<span class="login-icon-change-pass">
																								{/* <i class="icon-dual" data-feather="lock"></i>*/}
																								<i
																									style={{
																										color: this.state
																											.isConPasswordVisible
																											? "#777f80"
																											: "",
																									}}
																									className="fa fa-eye"
																									onClick={() => {
																										this.setState({
																											isConPasswordVisible: !this
																												.state
																												.isConPasswordVisible,
																										});
																									}}
																								/>
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Customer Status
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right", marginTop: "5px" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		Save & Add New Customer
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		usercredentials: state.UserReducer,
	};
}

export default connect(mapStateToProps, {
	setname,
	setemail,
	setmobile,
	setdob,
	setanniversary,
	setdesignation,
	setdepartment,
	setusertype,
	setaddress,
	setcountry,
	setstate,
	setcity,
	setpincode,
	setaadharcard,
	setpancard,
	setdrivinglicenses,
	setaccountno,
	setbankname,
	setbranch,
	setIfsccode,
	setSalary,
	setuserpassword,
	setuserconfirmpassword,
	setclearuser,
})(addCustomer);
