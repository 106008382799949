import { MDBCol } from "mdbreact";
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Searchicon from "../../assets/images/dashboardimages/search.png";


import Saveicon from '../../assets/images/dashboardimages/filesave.png';
import Editicon from '../../assets/images/dashboardimages/pencil.png';

class StickerShippingMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StickerShippingData: [],
      searchInput: "",
      StickerSheetWeight: "",
      MinimumCharge: "",
      RatePerSheet: "",
      Status: "",
      IsEdit: false,
      StickerShippingId: ""
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    localStorage.removeItem("StickerShippingDetails");

    Notiflix.Loading.Dots("Please wait...");

    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: ``,
      },
      "GetStickerShippingMaster"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            StickerShippingData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  onSaveData() {
    if (this.state.StickerSheetWeight !== "") {
      if (this.state.MinimumCharge !== "") {
        if (this.state.RatePerSheet !== "") {
          this.onPost();
        } else {
          Notiflix.Notify.Failure("Please enter Sheet Weight.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter Minimum Charge.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter Rate Per Sheet.");
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    PostApiCall.postRequest(
      {
        id: this.state.StickerShippingId, // ID with response
        eachstickersheetweight: this.state.StickerSheetWeight,
        minimumcharge: this.state.MinimumCharge,
        status: this.state.Status,
        ratepersheet: this.state.RatePerSheet
      },
      "AddShippingMaster"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Shipping charges updated successfully");
          PostApiCall.postRequest(
            {
              recordCount: "*",
              whereClause: ``,
            },
            "GetStickerShippingMaster"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                this.setState({
                  StickerShippingData: obj.data,
                });
                Notiflix.Loading.Remove();
              }
            })
          );
          this.setState(({
            StickerShippingId: null,
            StickerSheetWeight: null,
            MinimumCharge: null,
            Status: null,
            RatePerSheet: null,
            IsEdit: false
          }))

        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(
            "Something went wrong, please try again later"
          );
        }
      })
    );
  };

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            <div className="row">
              <div className="col-md-6">

                <div>
                  <ul class="breadcrumbs">
                    <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
                    <li>Master Management</li>
                    <li>Sticker Shipping</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

              </div>


            </div>
            <Row>
              <Col xl="12">
                <Card className="pagebackground" id="upload-card">


                  <Card >
                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                      <div className="row my-1">
                        <div className="col-4">
                          <div className="form-group">
                            <h4 className="FilterCard" style={{ marginTop: '10px' }}>Shipping Charges</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      <Row>


                        <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                          <div class="input-group mb-3" >


                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}

                            />
                          </div>
                        </MDBCol>

                        <Col md="12">

                          <Card >

                            <CardBody className="pt-0">
                              <Row>
                                <Col xs="4">
                                  <CardBody className="categoryCard">
                                    <Row className="card-row">
                                      <Col sm="12">
                                        <h5>Add New Shipping</h5>
                                      </Col>
                                    </Row>
                                    <Row className="card-row">
                                      <Col sm="6">
                                        <div className="row">

                                          {/* <div className="col-12">
                                            <label class="my-1 mr-2">Sticker Sheet Weight *</label>

                                            <input
                                              className="form-control"
                                              name="name"
                                              type="text"
                                              placeholder="Sticker Sheet Weight"
                                              value={this.state.StickerSheetWeight}
                                              onChange={(text) => {
                                                this.setState({
                                                  StickerSheetWeight: text.target.value,
                                                });
                                              }}
                                            />
                                          </div> */}
                                          <div className="col-12">
                                            <label class="my-1 mr-2">Minimum Charge*</label>

                                            <input
                                              className="form-control"
                                              name="name"
                                              type="text"
                                              placeholder="Minimum Charge"
                                              value={this.state.MinimumCharge}
                                              onChange={(text) => {
                                                this.setState({
                                                  MinimumCharge: text.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-12">
                                            <label class="my-1 mr-2">Rate Per Sheet *</label>

                                            <input
                                              className="form-control"
                                              name="name"
                                              type="text"
                                              placeholder="Rate Per Sheet"
                                              value={this.state.RatePerSheet}
                                              onChange={(text) => {
                                                this.setState({
                                                  RatePerSheet: text.target.value,
                                                });
                                              }}
                                            />

                                          </div>

                                          <div className="col-12" style={{ marginTop: '10px' }}>
                                            <div className="form-group">
                                              <label for="CategoryName">
                                                Status
                                                <span className="mandatory">*</span>
                                              </label>
                                              <br />
                                              <label class="radio-inline">
                                                <input
                                                  type="radio"
                                                  name="optradio"
                                                  //  disabled={!this.state.IsVisible}
                                                  checked={
                                                    this.state.Status == "Yes"
                                                      ? true
                                                      : false
                                                  }

                                                  onChange={() => {
                                                    this.setState({
                                                      Status: "Yes",
                                                    });
                                                  }}
                                                />{" "}
                                                Active
                                              </label>
                                              <label
                                                class="radio-inline"
                                                style={{ marginLeft: "10px" }}
                                              >
                                                <input
                                                  type="radio"
                                                  name="optradio"
                                                  //  disabled={!this.state.IsVisible}
                                                  checked={
                                                    this.state.Status == "No"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() => {
                                                    this.setState({
                                                      Status: "No",
                                                    });
                                                  }}
                                                />{" "}
                                                Inactive
                                              </label>
                                            </div>
                                          </div>

                                        </div>
                                      </Col>
                                      <hr />
                                    </Row>
                                    <Row>
                                      {this.state.IsEdit == true ? (
                                        <><Col sm="6">
                                        </Col><Col sm="6">

                                            <a
                                              onClick={this.onSaveData.bind(this)}
                                              className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                              style={{ marginTop: "5px", color: "white" }}
                                            >
                                              <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                              Save
                                            </a>
                                          </Col></>)
                                        : ""}
                                    </Row>
                                  </CardBody>
                                </Col>


                                {/* ======Brand List */}
                                {this.state.StickerShippingData.filter((data) => {
                                  if (this.state.searchInput == "") {
                                    return data;
                                  }
                                  if (
                                    this.state.searchInput !== "" &&
                                    (data.fld_SizeName
                                      .toLowerCase()
                                      .includes(this.state.searchInput.toLowerCase()) ||

                                      `${data.fld_status
                                        ? data.fld_status.toLowerCase()
                                        : ""
                                        }`.includes(this.state.searchInput.toLowerCase()))
                                  ) {
                                    return data;
                                  }
                                }).map((data, i) => (
                                  <Col xs="4">
                                    <CardBody className="categoryCard">

                                      <Row className="card-row">
                                        <Col sm="7">
                                          <div className="row">
                                            {/* <div className="col-12" style={{ marginTop: '10px' }}>
                                              <p>
                                                <b>Sticker Sheet Weight</b> -{" "}
                                                <span>
                                                  {data.fld_each_sticker_sheet_weight}
                                                </span>
                                              </p>

                                            </div> */}
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                              <p>
                                                <b>Minimum Charge</b> -{" "}
                                                <span>
                                                  ₹ {data.fld_minimum_charge}
                                                </span>
                                              </p>

                                            </div>

                                          </div>
                                          <div className="row">
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                              <p>
                                                <b>Rate Per Sheet</b> -{" "}
                                                <span>
                                                  ₹ {data.fld_rate_per_sheet}
                                                </span>
                                              </p>

                                            </div>
                                          </div>

                                        </Col>
                                        <hr />
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="BrandStatus">
                                          <p >
                                            <b>Status</b> -{" "}
                                            <span
                                              style={{
                                                color:
                                                  data.fld_status == "Active" || data.fld_status == "Yes"
                                                    ? "green"
                                                    : "red",
                                              }}
                                            >
                                              {data.fld_status}
                                            </span>
                                          </p>
                                        </Col>
                                        <Col sm="8">
                                          <span>
                                            <a
                                              onClick={() => {
                                                this.setState({
                                                  StickerSheetWeight: data.fld_each_sticker_sheet_weight,
                                                  MinimumCharge: data.fld_minimum_charge,
                                                  RatePerSheet: data.fld_rate_per_sheet,
                                                  Status: data.fld_status,
                                                  IsEdit: true,
                                                  StickerShippingId: data.fld_id
                                                })
                                                window.location.href = "#upload-card"

                                              }}
                                              // onClick={this.onSaveData.bind(this)}
                                              className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                              style={{ marginTop: "5px", color: "white" }}
                                            >
                                              <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                              Edit
                                            </a>
                                          </span>


                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Col>
                                ))}
                              </Row>
                            </CardBody>
                          </Card>

                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Card>

                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* </Card>

							</Col>
						</Row> */}
          </Container>
        </div >
      </React.Fragment >
    );
  }
}

export default StickerShippingMaster;
