import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
// import Notiflix from "notiflix";
import EmptyBox from "../../assets/images/users/UploadPlaceHolder.png";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";


var width = '100%'
var height = 300

var widthm = 365
var heightm = 400


var widthapp = 250
var heightapp = 150


const ImgUploadCover = ({
	onChange,
	src
}) =>
	<label htmlFor="photo-upload-1" className="custom-file-upload fas" style={{ width: width, height: height + 'px', borderRadius: '10px' }}>

		<div className="img-wrap1 img-upload1" style={{ width: width - 10, height: height - 15 + 'px', borderRadius: '10px' }}>
			<img for="photo-upload-1" src={src} style={{ width: width, height: height - 15 + 'px', borderRadius: '10px' }} />
		</div>
		<input
			accept="image/*"
			id="photo-upload-1" type="file" onChange={onChange} />
	</label>



const ImgUploadCover1 = ({
	onChange1,
	src1
}) =>
	<label htmlFor="photo-upload-2" className="custom-file-upload fas" style={{ width: widthm, height: heightm, borderRadius: '10px' }}>

		<div className="img-wrap1 img-upload1" style={{ width: widthm - 15, height: heightm - 15, borderRadius: '10px' }}>
			<img for="photo-upload-2" src={src1} style={{ width: widthm - 15, height: heightm - 15, borderRadius: '10px' }} />
		</div>
		<input
			accept="image/*"
			id="photo-upload-2" type="file" onChange={onChange1} />
	</label>

const ImgUploadCover2 = ({
	onChange2,
	src2
}) =>
	<label htmlFor="photo-upload-3" className="custom-file-upload fas" style={{ width: widthapp, height: heightapp, borderRadius: '10px' }}>

		<div className="img-wrap1 img-upload1" style={{ width: widthapp - 15, height: heightapp - 15 + 'px', borderRadius: '10px' }}>
			<img for="photo-upload-3" src={src2} style={{ width: widthapp - 15, height: heightapp - 15 + 'px', borderRadius: '10px' }} />
		</div>
		<input
			accept="image/*"
			id="photo-upload-3" type="file" onChange={onChange2} />
	</label>



class categoryManagement extends Component {
	constructor(props) {
		super(props);
		// const { action } = props.match.params;
		////console.log("param " + action);
		this.state = {


			UrlRegex: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,

			VerticalData: [
				{ value: '', label: 'Select Vertical' },
				{ value: 'Home', label: 'Home' },
				{ value: 'Food & Supplements', label: 'Food & Supplements' },
				{ value: 'Footwear', label: 'Footwear' },
				{ value: 'Socks', label: 'Socks' },
				{ value: 'Festive Offer', label: 'Festive Offer' },
				{ value: 'Insurance', label: 'Insurance' },
				{ value: 'Doctor', label: 'Doctors' },
				{ value: 'Dietician', label: 'Dietitian' },
				{ value: 'Sell With Us', label: 'Sell With Us' },
				{ value: 'Careers', label: 'Careers' },
				{ value: 'Contact Us', label: 'Contact Us' }
			],
			TypeData: [
				{ value: '', label: 'Select Type' },
				{ value: 'Listing Page', label: 'Listing Page' },
				{ value: 'Detail Page', label: 'Detail Page' },
				{ value: 'Detail Page Side Banner', label: 'Detail Page Side Banner' },
			],

			Vertical: '',
			Type: '',
			Url: '',
			ShowOnWebsite: 'Yes',

			IsType: false,
			WebSizeText: '',
			MobileSizeText: '',
			MobileAppSizeText: '',
			ImageDataCover: [],
			ImageDataCover1: [],
			ImageDataCover2: [],
			file: '',
			file1: '',
			file2: '',
			imagePreviewUrlCover: '../../assets/images/Cover-bechofylogo.png',
			imagePreviewUrlCover1: '../../assets/images/Cover-bechofylogo.png',
			imagePreviewUrlCover2: '../../assets/images/Cover-bechofylogo.png',

			ImageApiUrl: 'https://images.beatmysugar.com/api/Image/SaveImage',

		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});



	}

	photoUploadCover = e => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {


				this.setState({
					file: file,
					imagePreviewUrlCover: reader.result,
					ImageDataCover: file
				});

			}
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 200 kb.");
		}
	}

	photoUploadCover1 = e => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {

				this.setState({
					file1: file,
					imagePreviewUrlCover1: reader.result,
					ImageDataCover1: file
				});

			}
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 200 kb.");
		}
	}


	photoUploadCover2 = e => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {

				this.setState({
					file2: file,
					imagePreviewUrlCover2: reader.result,
					ImageDataCover2: file
				});

			}
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 200 kb.");
		}
	}

	// onSaveData() {
	// 	if (this.state.VerticalId != "") {
	// 		if (this.state.CategoryName != "") {
	// 			if (
	// 				this.state.imagePreviewUrl !=
	// 				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
	// 			) {
	// 				this.onPost();
	// 			} else {
	// 				Notiflix.Notify.Failure("Please upload category image");
	// 			}
	// 		} else {
	// 			Notiflix.Notify.Failure("Please enter the category name");
	// 		}
	// 	} else {
	// 		Notiflix.Notify.Failure("Please enter the vertical name");
	// 	}
	// }

	// onPost = () => {
	// 	Notiflix.Loading.Dots("Please wait...");
	// 	var login = localStorage.getItem("LoginDetail");
	// 	var details = JSON.parse(login);

	// 	PostApiCall.postRequest(
	// 		{
	// 			categoryid: this.state.CategoryID,
	// 			verticalid: this.state.Verticalname,
	// 			categoryname: this.state.CategoryName,
	// 			description: this.state.CategoryDescription,
	// 			showOnWebsite: this.state.Status == "Yes" ? "Active" : "Inactive",
	// 			action: this.state.Action,
	// 			createdon: moment().format("YYYY-MM-DD"),
	// 			updatedon: moment().format("YYYY-MM-DD"),
	// 			updatedby: details[0].fld_userid,
	// 		},
	// 		"AddCategory"
	// 	).then((resultcategory) =>
	// 		resultcategory.json().then((obj) => {
	// 			if (resultcategory.status == 200 || resultcategory.status == 201) {
	// 				this.onUploadImage(obj);
	// 				//  //console.log(obj)
	// 			} else {
	// 				this.onUploadImage(obj);
	// 			}
	// 		})
	// 	);
	// };
	// onUploadImage(obj) {
	// 	Notiflix.Loading.Dots("Please wait...");
	// 	// //console.log('images')
	// 	var login = localStorage.getItem("LoginDetail");
	// 	var details = JSON.parse(login);

	// 	if (JSON.stringify(this.state.ImageData) != "[]") {
	// 		const form = new FormData();

	// 		form.append("file", this.state.ImageData);
	// 		form.append("foldername", "categoryImages");
	// 		form.append(
	// 			"filename",
	// 			this.state.CategoryName.trim().replace(/\s/g, "-") +
	// 				"-" +
	// 				JSON.parse(JSON.stringify(obj.data[0])).CategoryId
	// 		);

	// 		fetch(this.state.ImageApiUrl, {
	// 			method: "POST",
	// 			body: form,
	// 		}).then((image) => {
	// 			image
	// 				.json()
	// 				.then((data) => ({
	// 					data: data,
	// 					status: image.status,
	// 				}))
	// 				.then((res) => {
	// 					//     //console.log(res.data)
	// 					// //console.log((JSON.parse(JSON.stringify(obj.data[0]))).CategoryId)
	// 					PostApiCall.postRequest(
	// 						{
	// 							id: JSON.parse(JSON.stringify(obj.data[0])).CategoryId,
	// 							image:
	// 								"http://demo.globaltrendz.online/Bechofy/images/categoryImages/" +
	// 								res.data.Message.split(",")[2].split("=")[1].trim(),
	// 							updatedon: moment().format("YYYY-MM-DD"),
	// 							updatedby: details[0].fld_userid,
	// 						},
	// 						"UpdateCategoryImage"
	// 					).then((results1) =>
	// 						results1.json().then((obj1) => {
	// 							if (results1.status == 200 || results1.status == 201) {
	// 								// //console.log(obj1)
	// 								Notiflix.Loading.Remove();
	// 								Notiflix.Notify.Success("Category successfully added.");
	// 								window.location.href = "/category-management";
	// 							}
	// 						})
	// 					);
	// 				});
	// 		});
	// 	} else {
	// 		Notiflix.Loading.Remove();
	// 		Notiflix.Notify.Failure("Error Occured");
	// 	}
	// }

	render() {
		// //console.log(this.state.imagePreviewUrl)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={
								"Bechofy / Banner Management / Banner List / Add New Banner"
							}
						/>

						<Row>
							<Col xl="12">


								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Add New Banner
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="form my-4">
													<div className="row  ">
														<div className="col col-12">
															<div className="row">
																<div className="col-6">
																	<label
																		className="my-1 mr-2"
																		for="inlineFormCustomSelectPref"
																	>
																		Vertical
																	</label>
																	<select type="text" class="form-control"
																		value={this.state.Vertical}
																		onChange={(text) => {

																			this.setState({
																				Vertical: text.target.value
																			})



																			if (text.target.value == '') {
																				this.setState({
																					IsType: false,
																					WebSizeText: '',
																					MobileSizeText: '',
																					MobileAppSizeText: ''
																				})
																				width = '100%'
																				height = 300
																			}

																			else if (text.target.value == 'Home' || text.target.value == 'Insurance' || text.target.value == 'Sell With Us' || text.target.value == 'Contact Us' || text.target.value == 'Careers') {
																				this.setState({
																					IsType: false,
																					WebSizeText: '(Banner must be 1600 X 400 in dimension, less than 300 kb)',
																					MobileSizeText: '(Banner must be 365 X 400 in dimension, less than 300 kb)',
																					MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
																					Type: ''
																				})
																				width = '100%'
																				height = 300
																				widthm = 365
																				heightm = 400
																				widthapp = 250
																				heightapp = 150
																			} else {

																				this.setState({
																					IsType: true,
																					WebSizeText: '',
																					MobileSizeText: '',
																					MobileAppSizeText: ''
																				})
																			}


																		}}
																	>

																		{this.state.VerticalData.map(title => (

																			<option key={title.value} value={title.value}>
																				{title.label}
																			</option>
																		))}
																	</select>

																</div>
																<div className="col-6">
																	<div className="form-group">
																		<label for="CategoryName">
																			Type
																			<span className="mandatory">*</span>
																		</label>
																		<select type="text" class="form-control"
																			value={this.state.Type}
																			onChange={(text) => {

																				if (text.target.value == 'Listing Page' || text.target.value == 'Detail Page') {

																					this.setState({

																						WebSizeText: '(Banner must be 1600 X 180 in dimension, less than 300 kb)',
																						MobileSizeText: '(Banner must be 365 X 60 in dimension, less than 300 kb)',
																						MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',
																					})
																					width = '100%'
																					height = 200
																					widthm = 365
																					heightm = 100
																					widthapp = 250
																					heightapp = 150

																				} else if (text.target.value == 'Detail Page Side Banner') {

																					this.setState({

																						WebSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
																						MobileSizeText: '(Banner must be 340 X 310 in dimension, less than 300 kb)',
																						MobileAppSizeText: '(Banner must be 250 X 150 in dimension, less than 300 kb)',

																					})
																					width = '340px'
																					height = 310
																					widthm = 340
																					heightm = 310
																					widthapp = 250
																					heightapp = 150

																				}
																				this.setState({
																					Type: text.target.value
																				})

																			}}
																		>

																			{this.state.TypeData.map(title => (

																				<option key={title.value} value={title.value}>
																					{title.label}
																				</option>
																			))}
																		</select>

																	</div>
																</div>


																<div className="col-12">
																	<div class="form-group">
																		<label for="sw-arrows-first-name">
																			URL (URL of the page to which banner should navigate when it is clicked.)
																			<span className="mandatory">*</span>
																		</label>

																		<input type="text" class="form-control" id="validationCustom05"
																			value={this.state.Url}
																			onChange={(text) => {

																				this.setState({
																					Url: text.target.value
																				})

																			}}
																		/>

																	</div>
																</div>
															</div>
														</div>

														{/* <div className="row">
																<div className="col-md-1"></div>
																<div class="col-md-11">
																	<label for="sw-arrows-first-name">
																		Upload Category Image (Size &lt; 100kb,
																		500*500)<span className="mandatory">*</span>
																	</label>
																	<div class="div1">
																		<ImgUpload
																			onChange={this.photoUpload}
																			src={this.state.imagePreviewUrl}
																		/>
																	</div>
																</div>
															</div> */}
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								<Card>
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Upload Website Banner
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<div>
															</div>
															<label for="validationCustom05">Website Banner<span className="mandatory">* </span>
																<span> {this.state.WebSizeText}</span>
															</label>
															<div>
																<ImgUploadCover onChange={(e) => this.photoUploadCover(e)} src={this.state.imagePreviewUrlCover} />

															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>

								</Card>


								<Card>
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Upload Mobile Banner
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<div>
																<label for="validationCustom05"> Mobile Banner<span className="mandatory">* </span>
																	<span> {this.state.MobileSizeText}</span>
																</label>

																<div>
																	<ImgUploadCover1 onChange1={(e) => this.photoUploadCover1(e)} src1={this.state.imagePreviewUrlCover1} />

																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>

								</Card>


								<Card>
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Upload Mobile App Banner
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<div>
															</div>
															<label for="validationCustom05"> Mobile App Banner<span className="mandatory">* </span>
																<span> {this.state.MobileAppSizeText}</span>
															</label>

															<div>
																<ImgUploadCover2 onChange2={(e) => this.photoUploadCover2(e)} src2={this.state.imagePreviewUrlCover2} />


															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>




								</Card>

								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
														// onClick={this.onSaveData.bind(this)}
														>
															Save Banner
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									{/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
									{/* </Card> */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default categoryManagement;
