import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import Downloadicon from '../../assets/images/dashboardimages/upload-file.png'

import Select from 'react-select';
import moment from 'moment'

class UploadCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StateData: [],
            UploadCSVURL: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "uploadcsv",
            CSVFile: [],
            PriceState: []
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                countryid: 101,
            },
            "GetState"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((objstate) => {
                if (results.status == 200 || results.status == 201) {
                    if (objstate.data.length != 0) {
                        // this.props.setstate()

                        this.setState({

                            StateData: objstate.data,

                        });
                        Notiflix.Loading.Remove();

                    }
                }
            }))

    }


    uploadFile = () => {
        if (JSON.stringify(this.state.PriceState) != "[]") {

            if (JSON.stringify(this.state.CSVFile) != "[]") {

                Notiflix.Loading.Dots("Please wait...");

                const form = new FormData();

                form.append("file", this.state.CSVFile);
                form.append("foldername", "PriceCSV");
                form.append(
                    "filename", moment().format('ss') + this.state.CSVFile.name

                );

                fetch(this.state.UploadCSVURL, {
                    method: "POST",
                    body: form,
                }).then((image) => {
                    image
                        .json()
                        .then((data) => ({
                            data: data,
                            status: image.status,
                        }))
                        .then((res) => {
                            //console.log(res.data.Message)
                            PostApiCall.postRequest(
                                {
                                    id: null,
                                    filename: res.data.Message.split(",")[2].split("=")[1].trim(),
                                    filelocation: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/CSV/PriceCSV`,
                                    state: this.state.PriceState.value,
                                    filestatus: 'NEW',
                                    localpath: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_docs_directory}/CSV/PriceCSV/`

                                },
                                "addVariantPriceindb"
                            ).then((results1) =>
                                results1.json().then((obj1) => {
                                    if (results1.status == 200 || results1.status == 201) {
                                        // //console.log(obj1)
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Success("Variant Price successfully added.");
                                        window.location.reload();
                                    }
                                    else {
                                        Notiflix.Loading.Remove();
                                        Notiflix.Notify.Failure(obj1.data);
                                    }
                                })
                            );


                        })
                })
            }
            else {
                Notiflix.Notify.Failure('Please upload document')

            }
        }
        else {
            Notiflix.Notify.Failure('Please select state')
        }

    }
    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center" style={{ height: '295px', marginBottom: '25px' }}>
                    <div class="mt-1 price-card py-5 col-10">
                        <div style={{ marginTop: '-25px' }}>
                            <h4 className="stepper-heading d-flex justify-content-center align-items-center">To update pricing upload the CSV file with the latest pricing of products.</h4><br />
                        </div>
                        <div className="col-12">
                            <Select
                                // className="form-control"
                                options={this.state.StateData}


                                value={this.state.PriceState}
                                onChange={(value) => {

                                    this.setState({
                                        PriceState: value
                                    })

                                }}




                                isSearchable={true}
                                isClearable={true} />
                        </div>
                        <div className="col-12" style={{ marginTop: '3%' }}>
                            <div className="form-group">
                                <label for="CategoryName">
                                    Upload Price List (.csv)
                                </label>
                                <input
                                    type="file"
                                    id="CategoryName"
                                    className="form-control"
                                    style={{ display: 'block' }}
                                    accept="application/csv"

                                    onChange={(e) => {
                                        if (
                                            e.target.files[0].size <
                                            9000000
                                        ) {
                                            const reader = new FileReader();
                                            const file =
                                                e.target.files[0];
                                            reader.onloadend = () => {
                                                this.setState({
                                                    CSVFile: file,
                                                });
                                            };
                                            reader.readAsDataURL(file);
                                        } else {
                                            Notiflix.Notify.Failure(
                                                "File too large, upload file less than 9 Mb."
                                            );
                                        }
                                    }}



                                />
                            </div>
                        </div>
                        <div>
                            <button className="btn downloadcsv-button mt-1 mb-1" style={{ float: 'right' }}
                                onClick={this.uploadFile}
                            >
                                <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                Upload Price</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default UploadCSV