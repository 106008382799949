import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class AddCarrerManagment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Status: "Active",

			CategoryData: [],
			CategorySelect: [],
			groupArray: [],
			SubCategory: [],
			AttributeData: [],
			Attribute: "",
			MapData: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		var det = localStorage.getItem("MappingDetails");
		var Mapdata = JSON.parse(det);
		// //console.log(Mapdata)
		this.setState({
			MapData: Mapdata,
		});
		this.setState({
			Attribute: Mapdata.FLD_AttributeID,
			SubCategory: Mapdata.fld_subcategoryname,
		});

		// var sp = []
		//   if(Mapdata.fld_subcategoryname != null){
		//       for(var i = 0; i < Mapdata.fld_subcategoryname.length; i++){

		//           sp.push({})
		//       }
		//       this.setState({
		//         Service : sp
		//       })
		//   }

		// FLD_Active: "Active    "
		// FLD_AttributeID: 6
		// FLD_MappingID: 2
		// FLD_SubCategoryID: 9
		// fld_attributename: "Material"
		// fld_categoryname: "Stationery "
		// fld_subcategoryname: "Bookmarks "

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("GetAttributeMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				//   //console.log(obj.data.fld_subcategoryid)
				this.setState({
					CategoryData: obj.data,
				});
				// for(var i=0;i<obj.data.length;i++){
				//     // //console.log(obj.data[i].fld_subcategoryname)
				//     // //console.log(this.state.MapData.fld_subcategoryname)
				//     if(obj.data[i].fld_subcategoryname==this.state.MapData.fld_subcategoryname){
				//        this.setState({
				//            SubCategory:true
				//        })

				//     }
				// }

				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetAttributeMaster_Drop").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data)
				this.setState({
					AttributeData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		//       fld_categoryname: "Stationery "
		// fld_subcategoryname: "Pens & Pencils "
	}

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onImagePickerHanlder = (id, file, isvalid) => {
		this.setState({
			...this.state,
			imageValue: file,
			isImageValid: isvalid,
		});
	};

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription(text) {
		this.setState({
			JobDescription: text.editor.getData(),
		});
	}

	SaveAttribute() {
		if (this.state.Attribute != "") {
			if (JSON.stringify(this.state.SubCategory) != "[]") {
				//   this.onPost();
				//console.log(this.state.MapData.FLD_MappingID);
			} else {
				Notiflix.Notify.Failure("Please select sub category.");
			}
		} else {
			Notiflix.Notify.Failure("Please select attribute");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var addSubCategory = "";
		for (var i = 0; i < this.state.SubCategory.length; i++) {
			if (i == 0) {
				addSubCategory = this.state.SubCategory[i].fld_subcategoryid;
			} else {
				addSubCategory =
					addSubCategory + ", " + this.state.SubCategory[i].fld_subcategoryid;
			}
		}

		//console.log(addSubCategory);
		PostApiCall.postRequest(
			{
				attributeid: this.state.Attribute,
				SubcategoryId: addSubCategory,
				active: this.state.Status,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
			},
			"AddAttributeMapping"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Attribute successfully mapped.");
					window.location.href = "/attribute";
				} else {
					Notiflix.Notify.Failure("Obj");
				}
			})
		);
	};

	render() {
		let temp = [];

		this.state.CategoryData.map((data, i) => {
			temp.push(data.fld_categoryname);
		});
		temp = [...new Set(temp)];

		let groupTemp = this.groupBy(this.state.CategoryData, "fld_categoryname");
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy / Attribute Mapping  Master"}
							breadcrumbItem={"Map New Attribute"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Attribute Mapping Master
													</h5>
												</div>
											</Col>
										</Row>
										<Row></Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Map New Attribute
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="form-group">
																			<label for="CategoryName">
																				Attribute Name
																			</label>
																			<select
																				value={this.state.Attribute}
																				onChange={(test) => {
																					this.setState({
																						Attribute: test.target.value,
																					});
																				}}
																				className="custom-select my-1 mr-sm-2"
																			>
																				<option value="">
																					Select Attribute{" "}
																				</option>
																				{this.state.AttributeData.map(
																					(attri) => (
																						<option
																							key={attri.value}
																							value={attri.value}
																						>
																							{attri.label}
																						</option>
																					)
																				)}
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card>
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="6">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Assign Category & Sub Category to Attribute
																</h5>
															</div>
														</Col>
													</Row>
													<Row></Row>
												</div>

												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																{temp.map((info, index) => (
																	<div
																		class="col-md-6"
																		style={{
																			marginTop: "1%",
																			marginBottom: "1%",
																		}}
																	>
																		{/* <input  
                          id={info.fld_categoryid}
                          checked={this.state.CategorySelect.includes(info) ? true : false}
                          onChange={()=>{
                            var ar = [...this.state.CategorySelect]
  
                            if(ar.includes(info)){
  
                              ar.splice(ar.indexOf(info),1)
  
                            }else{
                              ar.push(info)
                            }
  
                            this.setState({
                              CategorySelect : ar
                            });
  
                          }}
                          type="checkbox" /> */}
																		<span>
																			{info}
																			{groupTemp[info] !== undefined &&
																				groupTemp[info].map((data) => {
																					// //console.log(data)
																					return (
																						<div
																							class="col-md-4"
																							style={{ marginTop: "4px" }}
																						>
																							{" "}
																							<input
																								defaultChecked={
																									data.fld_subcategoryname ==
																										this.state.MapData
																											.fld_subcategoryname
																										? true
																										: this.state.SubCategory.includes(
																											data
																										)
																											? true
																											: false
																								}
																								onChange={() => {
																									var ar = [
																										...this.state.SubCategory,
																									];

																									if (ar.includes(data)) {
																										ar.splice(
																											ar.indexOf(data),
																											1
																										);
																									} else {
																										ar.push(data);
																									}

																									this.setState({
																										SubCategory: ar,
																									});
																								}}
																								type="checkbox"
																							/>{" "}
																							<span>
																								{" "}
																								{data.fld_subcategoryname}
																							</span>
																						</div>
																					);
																				})}
																		</span>
																	</div>
																))}
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Status<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Active
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			Inactive
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "7px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveAttribute.bind(this)}
																	>
																		Update Map Attribute
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddCarrerManagment;
