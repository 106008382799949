import React, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Viewicon from '../../assets/images/dashboardimages/file.png'



class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderData: [],
            searchInput: "",
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Custom("");

        var customer = localStorage.getItem('customerDetails');
        var CustomerDetails = JSON.parse(customer)


        PostApiCall.postRequest(
            {
                WhereClause: `where fld_customer_id=${CustomerDetails}`
            },
            "GetCustomerOrderDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // //console.log(obj.data)
                    this.setState({
                        OrderData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Order Number",
                    field: "number",
                    sort: "disabled",
                    width: 600,
                },

                {
                    label: "Order Date",
                    field: "date",
                    sort: "disabled",
                    width: 600,
                },

                {
                    label: "Total items",
                    field: "item",
                    sort: "disabled",
                    width: 300,
                },

                {
                    label: "Order Value",
                    field: "ordervalue",
                    sort: "disabled",
                    width: 300,
                },
                {
                    label: "Order Status",
                    field: "status",
                    sort: "disabled",
                    width: 300,
                },
                // {
                //     label: "Invoice",
                //     field: "invoice",
                //     sort: "disabled",
                //     width: 200,
                // },



                // {
                //     label: "Action",
                //     field: "action",
                //     sort: "disabled",
                //     width: 100,
                // },
            ],
            rows: this.state.OrderData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_order_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_order_number
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_payment_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    number: (data.fld_order_number),
                    date: (data.fld_order_date),

                    item: (data.total_items),
                    ordervalue: (data.fld_order_amount),
                    status: (data.fld_order_status),
                    // invoice: <span className="btn align-items-center Bechofy-btn"
                    // >
                    //     <a onClick={() => {
                    //         localStorage.setItem("Orderdetails", JSON.stringify(data.fld_order_id));
                    //         window.location.href = "/view_order";
                    //     }}>

                    //         <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span> Download Invoice</a>
                    // </span>,

                    // action:
                    //     <span className="btn align-items-center Bechofy-btn"
                    //     >
                    //         <a onClick={() => {
                    //             localStorage.setItem("Orderdetails", JSON.stringify(data.fld_order_id));
                    //             window.location.href = "/view_order";
                    //         }}>   <span className=""><img src={Viewicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span> View Order</a>
                    //     </span>
                }
            })
        }


        return (
            <React.Fragment>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <h4 className="mb-0">Order History </h4>
                        <Row>
                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                <div class="input-group mb-3">


                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                        onChange={(e) => this.seachBarHandler(e)}
                                        value={this.state.searchInput}

                                    />
                                </div>
                            </MDBCol>

                            <Col md="12">

                                {this.state.OrderData.length != 0 ?
                                    <MDBDataTable
                                        hover
                                        // scrollY
                                        striped
                                        bordered
                                        data={data}
                                        seachTop={false}
                                        entriesOptions={[10, 25, 50, 100]}

                                    >

                                        <MDBTableHead columns={data.columns} />
                                        <MDBTableBody rows={data.rows} />
                                    </MDBDataTable>
                                    :
                                    <p>No Data Found</p>}
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </React.Fragment>


        );
    }
}

export default Chart;
