import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Parser from "html-react-parser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import addicon from '../../assets/images/dashboardimages/add.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import editicon from '../../assets/images/dashboardimages/edit.png'

class BoardMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BoardData: [],
            searchInput: ''
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        localStorage.removeItem("BoardDetails")
        //   this.props.setclearbrand()

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                recordCount: '*',
                whereClause: ``,

            },
            "GetBoard"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        BoardData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }



    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs breadcrumbItem={"Board Master"} urlPath={"/board_master"} />


                        <Row>
                            <Col xl="12">
                                <div className="overflow-hidden">

                                    {/* <Card className="overflow-hidden"> */}
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        Board List
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <Link
                                                        to={`/add_board/create`}
                                                        style={{
                                                            float: "right", marginTop: "-5px", background: 'white',
                                                            color: 'grey'
                                                        }}
                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Add New Board{" "}
                                                        <span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </Card>
                                    <Card >

                                        <CardBody className="pt-0">

                                            <Row>
                                                {this.state.BoardData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    } if (
                                                        this.state.searchInput !== "" &&
                                                        (data.fld_board_name
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase()) 
                                                          
                                                    )) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <CardBody className="categoryCard">
                                                            <CardTitle className="mb-4">
                                                                {data.fld_board_name}
                                                            </CardTitle>
                                                            <Row className="card-row">
                                                                <Col sm="4">
                                                                    <div className="imagecard">
                                                                        <img
                                                                            src={data.fld_image}
                                                                            className="categoryimage"
                                                                        ></img>
                                                                    </div>
                                                                    {/* <hr style={{border:'1px solid #fff'}}/> */}
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="">
                                                                        <p className="coatingdesc">
                                                                            {" "}
                                                                            {data.fld_description == null
                                                                                ? ""
                                                                                : Parser(
                                                                                    data.fld_description
                                                                                        .replace(/font-family/g, "")
                                                                                        .replace(/<p>/g, "")
                                                                                )}
                                                                        </p>
                                                                    </div>
                                                                    <p style={{paddingLeft:'25px'}}><b>Cost Per Kg : </b> ₹ {data.fld_cost}</p>
                                                                    
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <p className="categorystatus">
                                                                        <b>Status</b> -{" "}
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    data.fld_status == "Active" || data.fld_showOnWebsite == 'True'
                                                                                        ? "green"
                                                                                        : "red",
                                                                            }}
                                                                        >
                                                                            {data.fld_status}
                                                                        </span>
                                                                    </p>
                                                                </Col>
                                                                <Col sm="6">
                                                                    {/* <span className="btn deleteicnbtn">
                                                                        <span className="">
                                                                            <img src={deleteicon} alt="block user" className="btnicons"

                                                                                onClick={() => {
                                                                                    confirmAlert({
                                                                                        title: "Confirm to Delete",
                                                                                        message:
                                                                                            "Are you sure you want to delete coating.",
                                                                                        buttons: [
                                                                                            {
                                                                                                label: "Yes",
                                                                                                onClick: () => {
                                                                                                    Notiflix.Loading.Dots("");

                                                                                                    PostApiCall.postRequest(
                                                                                                        {
                                                                                                            id: data.fld_coating_id,
                                                                                                            showOnWebsite: "Disabled",
                                                                                                        },
                                                                                                        "Delete_Category"
                                                                                                    ).then((results) =>
                                                                                                        // const objs = JSON.parse(result._bodyText)
                                                                                                        results.json().then((obj) => {
                                                                                                            if (
                                                                                                                results.status == 200 ||
                                                                                                                results.status == 201
                                                                                                            ) {
                                                                                                                Notiflix.Loading.Remove();
                                                                                                                Notiflix.Notify.Success(
                                                                                                                    "Coating successfully deleted."
                                                                                                                );
                                                                                                                window.location.reload();
                                                                                                            } else {
                                                                                                                Notiflix.Loading.Remove();
                                                                                                                Notiflix.Notify.Failure(
                                                                                                                    obj.data
                                                                                                                );
                                                                                                            }
                                                                                                        })
                                                                                                    );
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                label: "No",
                                                                                                // onClick: () => alert('Click No')
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                }}
                                                                            ></img> </span>
                                                                    </span> */}
                                                                    <a
                                                                        onClick={() => {
                                                                            localStorage.setItem(
                                                                                "BoardDetails",
                                                                                JSON.stringify(data)
                                                                            );
                                                                            window.location.href = "/add_board/update";
                                                                        }}
                                                                        className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                        style={{ marginTop: "10px", color: "white" }}
                                                                    >
                                                                        <span className=""><img src={editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                                        Edit Board
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    {/* </Card> */}
                                    {/* </Row> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default BoardMaster;
