import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CustomInput } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import imageCompression from "browser-image-compression";



const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class categoryManagement extends Component {
	constructor(props) {
		super(props);
		const { action } = props.match.params;
		////console.log("param " + action);
		this.state = {
			imagePreviewUrl:
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
			Status: "Yes",
			ImageData: [],
			VerticalData: [],
			CategoryName: "",
			CategoryID: null,
			Action: action,
			CategoryDescription: "",
			Verticalname: "",
			originalImage: [],
			originalLink: "",
			clicked: false,
			uploadImage: false,

			ImageName: null,
			CategoryImage: null
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});


		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);

		var det = localStorage.getItem("CategoryDetails");
		var CategoryData = JSON.parse(det);

		if (CategoryData != null) {
			if (this.state.Action == "update") {
				this.setState({
					CategoryName: CategoryData.fld_categoryname,
					CategoryID: CategoryData.fld_categoryid,
					Status: CategoryData.fld_showOnWebsite == 'Active' ? 'Yes' : 'No',
					Verticalname: CategoryData.fld_vertical_id,
				});
			}

			if (CategoryData.fld_image != null) {
				this.setState({
					imagePreviewUrl: CategoryData.fld_image,
					CategoryImage: CategoryData.fld_image,
				});
			} else {
				this.setState({
					imagePreviewUrl:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
				});
			}

			if (this.state.Action == "update") {
				new Promise((resolve, reject) => {
					setTimeout(resolve, 1000);
				}).then(() => {
					this.setState({
						CategoryDescription: CategoryData.fld_description,
						// TermsCondition:OfferData.fld_termscondition,
					});
				});
			}
		}
	}

	// ==Image Upload onChange Function=======
	photoUpload = (e) => {
		e.preventDefault();
		const imageFile = e.target.files[0];
		this.setState({
			imagePreviewUrl: URL.createObjectURL(imageFile),
			originalImage: imageFile,
			outputFileName: imageFile.name,
			uploadImage: true
		});
	};



	onChangeDescription(text) {
		this.setState({
			CategoryDescription: text.editor.getData(),
		});
	}

	onSaveData() {
		if (this.state.Verticalname != "") {
			if (this.state.CategoryName != "") {
				if (
					this.state.imagePreviewUrl !=
					"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
				) {
					this.onPost();
				} else {
					Notiflix.Notify.Failure("Please upload category image");
				}
			} else {
				Notiflix.Notify.Failure("Please enter the category name");
			}
		} else {
			Notiflix.Notify.Failure("Please enter the vertical name");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		if (this.state.CategoryImage != this.state.imagePreviewUrl) {

			if (this.state.outputFileName != undefined) {

				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryID,
						verticalid: this.state.Verticalname,
						categoryname: this.state.CategoryName,
						description: this.state.CategoryDescription,
						showOnWebsite: this.state.Status == "Yes" ? "Active" : "Inactive",
						action: this.state.Action,
						createdon: moment().format("YYYY-MM-DD"),
						updatedon: moment().format("YYYY-MM-DD"),
						updatedby: details[0].fld_userid,
					},
					"AddCategory"
				).then((resultcategory) =>
					resultcategory.json().then((obj) => {
						if (resultcategory.status == 200 || resultcategory.status == 201) {
							this.onPostAddCategoryImage(obj);
							//  //console.log(obj)
						} else {
							Notiflix.Loading.Remove();
							Notiflix.Notify.Failure(obj.data);
						}
					})
				);

			} else {
				Notiflix.Loading.Remove();
				Notiflix.Notify.Failure("Please Upload Category Image");
			}
		}
		else {
			this.onUpdateCategory()
		}
	};



	onUpdateCategory = () => {
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				categoryid: this.state.CategoryID,
				verticalid: this.state.Verticalname,
				categoryname: this.state.CategoryName,
				description: this.state.CategoryDescription,
				showOnWebsite: this.state.Status == "Yes" ? "Active" : "Inactive",
				action: this.state.Action,
				createdon: moment().format("YYYY-MM-DD"),
				updatedon: moment().format("YYYY-MM-DD"),
				updatedby: details[0].fld_userid,
			},
			"AddCategory"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					this.onPostAddCategoryImage(obj);
					//  //console.log(obj)
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);

	}

	onPostAddCategoryImage(obj) {
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		if (this.state.CategoryImage != this.state.imagePreviewUrl) {


			PostApiCall.postRequest(
				{
					id: JSON.parse(JSON.stringify(obj.data[0])).CategoryId,
					image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/categoryImages/" +
						this.state.CategoryName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,
					updatedon: moment().format("YYYY-MM-DD"),
					updatedby: details[0].fld_userid,
				},
				"UpdateCategoryImage"
			).then((results1) =>
				results1.json().then((obj1) => {
					if (results1.status == 200 || results1.status == 201) {
						// //console.log(obj1)
						// Notiflix.Loading.Remove();
						Notiflix.Notify.Success("Category successfully added. Image upload in process");

						this.onUploadImage(obj);
					}
				})
			);
		}
		else {

			PostApiCall.postRequest(
				{
					id: JSON.parse(JSON.stringify(obj.data[0])).CategoryId,
					image: this.state.imagePreviewUrl,
					updatedon: moment().format("YYYY-MM-DD"),
					updatedby: details[0].fld_userid,
				},
				"UpdateCategoryImage"
			).then((results1) =>
				results1.json().then((obj1) => {
					if (results1.status == 200 || results1.status == 201) {
						// //console.log(obj1)
						Notiflix.Loading.Remove();
						Notiflix.Notify.Success("Category successfully updated.");

						window.location.href = "/category-management";
					}
					else {

						Notiflix.Loading.Remove();
						Notiflix.Notify.Failure(obj1.data);
					}
				})
			);

		}


	}

	async onUploadImage(obj) {
		Notiflix.Loading.Dots("Uploading Images...");
		//console.log('images')

		let response;

		if (this.state.outputFileName != undefined) {

			var options = {
				maxSizeMB: 0.05,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			imageCompression(this.state.originalImage, options)
				.then((compressedFile) => {

					//console.log("filename",
					//this.state.CategoryName.trim().replace(/\s/g, "-") +
					//"-" +
					//compressedFile.name)

					const form = new FormData();

					form.append("file", compressedFile);
					form.append("foldername", "categoryImages");
					form.append(
						"filename",
						this.state.CategoryName.trim().replace(/\s/g, "-") +
						"-" +
						compressedFile.name

					);
					response = fetch(this.state.ImageApiUrl, {
						method: "POST",
						body: form,
					}).then(response => response.json())
						.then(data => {
							//console.log(data.Message)
							//console.log((JSON.parse(JSON.stringify(obj.data[0]))).CategoryId)
							// this.setState({
							// 	ImageName: data.Message.split(",")[2].split("=")[1].trim()
							// })
							Notiflix.Loading.Remove()
							Notiflix.Notify.Success("Image successfully uploaded.")
							window.location.href = "/category-management";
						}

						)
					// //console.log(response);


				})
		}




	}

	render() {
		// //console.log(this.state.imagePreviewUrl)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={"Category Management"} breadcrumbItem1={this.state.CategoryID == null ? "Add New Category" : "Update Category"} urlPath={"/category-management"} urlPath1={this.state.CategoryID == null ? "/add-category/create" : "/add-category/update"} />
						<Row>
							<Col xl="12">


								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													{this.state.CategoryID == null ?
														<h5 className="Bechofy-text">
															Add New Category
														</h5>
														:
														<h5 className="Bechofy-text">
															Update Category
														</h5>
													}
												</div>
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<Row>
											<Col xs="12">
												<div className="form my-4">
													<div className="row  ">
														<div className="col col-12">
															<div className="row">
																<div className="col-4">
																	<label
																		className="my-1 mr-2"
																		for="inlineFormCustomSelectPref"
																	>
																		Vertical Name
																		<span className="mandatory">*</span>
																	</label>
																	<select
																		value={this.state.Verticalname}
																		onChange={(text) => {
																			this.setState({
																				Verticalname: text.target.value,
																			});
																		}}
																		className="custom-select my-1 mr-sm-2"
																	>
																		<option>Select Vertical</option>
																		{this.state.VerticalData.map(
																			(Vertical) => (
																				<option
																					key={Vertical.value}
																					value={Vertical.value}
																				>
																					{Vertical.label}
																				</option>
																			)
																		)}
																	</select>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Category Name
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="text"
																			id="CategoryName"
																			className="form-control"
																			value={this.state.CategoryName}
																			onChange={(text) => {
																				this.setState({
																					CategoryName: text.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Show On Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Yes"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Yes",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "No"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "No",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>

																<div className="col-12">
																	<div class="form-group">
																		<label for="sw-arrows-first-name">
																			Description(maximum 500 Characters)
																		</label>

																		<div class="niceeditors">
																			<CKEditor
																				config={{
																					extraPlugins:
																						"justify,font,colorbutton",
																				}}
																				data={this.state.CategoryDescription}
																				onChange={this.onChangeDescription.bind(
																					this
																				)}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="row">
															<div className="col-md-1"></div>
															<div class="col-md-11">
																<label for="sw-arrows-first-name">
																	Upload Category Image (Size &lt; 100kb,
																	500*500)<span className="mandatory">*</span>
																</label>
																<div class="div1">
																	<ImgUpload
																		onChange={this.photoUpload}
																		src={this.state.imagePreviewUrl}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody className="py-1 my-1">
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-3 offset-9">
														<button
															style={{ float: "right" }}
															className="btn align-items-center Bechofy-btn "
															onClick={this.onSaveData.bind(this)}
														>
															{this.state.CategoryID == null ? <span>Save Category</span> : <span>Update Category</span>}
														</button>
													</div>
												</div>
											</Col>
										</Row>
									</CardBody>
									{/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
									{/* </Card> */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default categoryManagement;