import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

const ImgUpload = ({ onChange, src, readOnly }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input
			disabled={readOnly}
			readOnly={readOnly}
			accept="image/*"
			id="photo-upload"
			type="file"
			onChange={onChange}
		/>
	</label>
);

class RawMaterial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl:
				"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
			Status: "Active",
			ImageData: [],
			UnitofMeasurementData: [
				{ value: "gm", label: "gm" },
				{ value: "kg", label: "kg" },
				{ value: "ml", label: "ml" },
				{ value: "ltr", label: "ltr" },
			],
			UnitofMeasurement: "",
			Price: 0,
			Points: 0,
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			RawMaterialName: "",
			readOnly: false,
		};
	}

	componentDidMount() {
		const script = document.createElement("script");
		script.src = "/assets/js/pages/form-wizard.init.js";
		script.async = true;
		document.body.appendChild(script);

		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		// this.props.setclearfootwearitem()

		// Notiflix.Loading.Dots('');

		var det = localStorage.getItem("RawMaterialDetails");
		var RawData = JSON.parse(det);

		// var view = localStorage.getItem('viewclick')
		// var viewdata = JSON.parse(view)
		// //console.log(viewdata)

		//console.log(RawData);

		if (RawData != null) {
			this.setState({
				RawMaterialName: RawData.fld_rawmaterialname,
				Status: RawData.fld_status,
				UnitofMeasurement: RawData.fld_unitofmeasurement,
				Price: RawData.fld_price,
				Points: RawData.fld_points,
				readOnly: RawData.readOnly,
			});
		}

		if (RawData.fld_image != null) {
			this.setState({
				imagePreviewUrl: RawData.fld_image,
			});
		} else {
			this.setState({
				imagePreviewUrl:
					"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
			});
		}
	}

	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 300000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
		}
	};

	onSaveData() {
		if (
			this.state.imagePreviewUrl !=
			"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png"
		) {
			if (this.state.RawMaterialName) {
				if (this.state.UnitofMeasurement != "") {
					if (this.state.Price != "") {
						if (this.state.Points != "") {
							// //console.log(this.state.Price)
							this.onPost();
						} else {
							Notiflix.Notify.Failure("Please enter Points");
						}
					} else {
						Notiflix.Notify.Failure("Please enter price");
					}
				} else {
					Notiflix.Notify.Failure("Please select unit of measurement");
				}
			} else {
				Notiflix.Notify.Failure("Please enter raw material name");
			}
		} else {
			Notiflix.Notify.Failure("Please upload raw material image");
		}
	}

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var det = localStorage.getItem("RawMaterialDetails");
		var RawData = JSON.parse(det);
		PostApiCall.postRequest(
			{
				id: RawData.fld_rawid,
				rawmaterialname: this.state.RawMaterialName,
				unitofmeasurement: this.state.UnitofMeasurement,
				price: this.state.Price,
				points: this.state.Points,
				status: this.state.Status,
				updatedon: moment().format("lll"),
				updatedby: 0,
			},
			"Update_Rawmaterial"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//console.log(obj);
					this.onUploadImage(obj);
				} else {
					this.onUploadImage(obj);
				}
			})
		);
	};
	onUploadImage(obj) {
		//console.log("image");
		Notiflix.Loading.Dots("Please wait...");
		//console.log("images");
		const form = new FormData();

		form.append("file", this.state.ImageData);
		form.append("foldername", "RawMaterial");
		form.append(
			"filename",
			this.state.RawMaterialName.trim().replace(/\s/g, "-") +
			"-" +
			JSON.parse(JSON.stringify(obj.data[0])).RawMaterialId
		);

		fetch(this.state.ImageApiUrl, {
			method: "POST",
			body: form,
		}).then((image) => {
			image
				.json()
				.then((data) => ({
					data: data,
					status: image.status,
				}))
				.then((res) => {
					//console.log(res.data);
					//console.log(JSON.parse(JSON.stringify(obj.data[0])).RawMaterialId);
					PostApiCall.postRequest(
						{
							id: JSON.parse(JSON.stringify(obj.data[0])).RawMaterialId,
							image:
								"http://demo.globaltrendz.online/Bechofy/images/RawMaterial/" +
								res.data.Message.split(",")[2].split("=")[1].trim(),
							updatedon: moment().format("lll"),
							updatedby: 1,
						},
						"Update_RawmaterialImage"
					).then((results1) =>
						results1.json().then((obj1) => {
							if (results1.status == 200 || results1.status == 201) {
								// //console.log(obj1)
								Notiflix.Loading.Remove();
								Notiflix.Notify.Success("Raw Material successfully Updated.");

								window.location.href = "/raw-material";
							}
						})
					);
				});
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={
								"Bechofy / Raw Material Management / Raw Material List "
							}
							breadcrumbItem={"Add Raw Material"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Raw Material Management
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<Card className="overflow-hidden">
										<div className="Bechofy-bg-soft-pink">
											<Row>
												<Col xs="3">
													<div className="Bechofy-text  p-3">
														<h5 className="Bechofy-text">
															Edit Raw Material
														</h5>
													</div>
												</Col>
												<Col xs="9">
													<div
														style={{
															display: this.state.readOnly ? "" : "none",
														}}
														className="Bechofy-text text-right p-3"
													>
														<button
															onClick={() => {
																this.setState({
																	...this.state,
																	readOnly: false,
																});
															}}
															className="Bechofy-btn btn"
														>
															Edit Raw Material
														</button>
													</div>
												</Col>
											</Row>
										</div>
										<CardBody className="pt-0">
											<Row>
												<Col xs="12">
													<div className="form my-4">
														<div className="row">
															<div className="col col-12">
																<div className="row">
																	<div className="col-md-4">
																		<label for="sw-arrows-first-name">
																			Upload Raw Material Image (Size &lt;
																			100kb, 500*500)
																			<span className="mandatory">*</span>
																		</label>
																		<div class="div1">
																			<ImgUpload
																				readOnly={this.state.readOnly}
																				onChange={this.photoUpload}
																				src={this.state.imagePreviewUrl}
																			/>
																		</div>
																	</div>

																	<div className="col-md-8">
																		<div className="row">
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Raw Material Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.RawMaterialName}
																						readOnly={this.state.readOnly}
																						onChange={(text) => {
																							this.setState({
																								RawMaterialName:
																									text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="form-group">
																					<label
																						class="my-1 mr-2"
																						for="inlineFormCustomSelectPref"
																					>
																						Unit of Measurement
																						<span className="mandatory">*</span>
																					</label>
																					<select
																						class="custom-select my-1 mr-sm-2"
																						id="inlineFormCustomSelectPref"
																						disabled={this.state.readOnly}
																						name="UserType"
																						onChange={(text) => {
																							this.setState({
																								UnitofMeasurement:
																									text.target.value,
																							});
																						}}
																						value={this.state.UnitofMeasurement}
																					>
																						<option>
																							Select Unit of Measurement
																						</option>
																						{this.state.UnitofMeasurementData.map(
																							(Category) => (
																								<option
																									key={Category.value}
																									value={Category.value}
																								>
																									{Category.label}
																								</option>
																							)
																						)}
																					</select>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.Price}
																						disabled={this.state.readOnly}
																						onChange={(price) => {
																							if (
																								this.state.DecimalRegex.test(
																									price.target.value
																								)
																							) {
																								this.setState({
																									Price: price.target.value,
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Points
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="email"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						disabled={this.state.readOnly}
																						value={this.state.Points}
																						onChange={(point) => {
																							if (
																								this.state.DecimalRegex.test(
																									point.target.value
																								)
																							) {
																								this.setState({
																									Points: point.target.value,
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
									<Card>
										<CardBody className="py-1    my-1">
											<Row>
												<Col xs="12">
													<div className="row">
														<div className="col-6">
															<div className="form-group my-1 mr-sm-2">
																<label for="CategoryName">
																	Raw Material Status
																	<span className="mandatory">*</span>
																</label>
																<br />
																<label class="radio-inline">
																	<input
																		disabled={this.state.readOnly}
																		type="radio"
																		name="optradio"
																		//  disabled={!this.state.IsVisible}
																		checked={
																			this.state.Status == "Active"
																				? true
																				: false
																		}
																		onChange={() => {
																			this.setState({
																				Status: "Active",
																			});
																		}}
																	/>{" "}
																	Yes
																</label>
																<label
																	class="radio-inline"
																	style={{ marginLeft: "10px" }}
																>
																	<input
																		type="radio"
																		name="optradio"
																		disabled={this.state.readOnly}
																		//  disabled={!this.state.IsVisible}
																		checked={
																			this.state.Status == "Inactive"
																				? true
																				: false
																		}
																		onChange={() => {
																			this.setState({
																				Status: "Inactive",
																			});
																		}}
																	/>
																	No
																</label>
															</div>
														</div>
														<div className="col-6">
															<button
																disabled={this.state.readOnly}
																style={{ float: "right", marginTop: "15px" }}
																className="btn align-items-center Bechofy-btn "
																onClick={this.onSaveData.bind(this)}
															>
																Update Raw Material
															</button>
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>{" "}
								{/* <WelcomeComp /> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default RawMaterial;
