import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

class AddStickerCalcHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sheetLength: null,
      sheetWidth: null,
      stickerLength: null,
      stickerWidth: null,
      trialSheet: null,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });
  }

  onSaveStickerCalcHeader = () => {
    // Add your save logic here
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Add Sticker Calculation Header" breadcrumbItem="Add Sticker Calculation Header" />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text">Add New Sticker Calculation Header</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <Col lg="6" className="form-group">
                              <label>Sheet Length*</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.sheetLength}
                                onChange={(text) => {
                                  if (this.state.DecimalRegex.test(text.target.value)) {
                                    this.setState({
                                      sheetLength: text.target.value,
                                    });
                                  }
                                }}
                              />
                            </Col>
                            <Col lg="6" className="form-group">
                              <label>Sheet Width*</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.sheetWidth}
                                onChange={(text) => {
                                  if (this.state.DecimalRegex.test(text.target.value)) {
                                    this.setState({
                                      sheetWidth: text.target.value,
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </div>
                          <div className="row">
                            <div className="col-3 offset-9">
                              <button
                                style={{ float: "right" }}
                                className="btn align-items-center Bechofy-btn "
                                onClick={this.onSaveStickerCalcHeader}
                              >
                                Save Sticker Calculation Header
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddStickerCalcHeader;
