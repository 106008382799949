import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import {
	Row, Col, Card, CardBody, CardTitle, Button,



	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Media,
	Table,
} from "reactstrap";
import { Link } from "react-router-dom";

class Chart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					data: []
				}
			],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "Month",
					categories: [

						"April",
						"May",
						"June",
						"July",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
						"Jan",
						"Feb",
						"Mar",

					],
				},
				tooltip: {
					x: {
						format: "₹",
					},
				},
			},
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Custom("");


		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DashboardYearlySales"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						SalesData: obj2.data,

					});

					const categories = [

						"April",
						"May",
						"June",
						"July",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
						"Jan",
						"Feb",
						"Mar",

					];

					this.setState({
						categories: categories,
					});

					const data = new Array(12).fill(0);


					for (var i = 0; i < obj2.data.length; i++) {


						for (var j = 0; j < categories.length; j++) {


							// //console.log(categories[j])

							if (obj2.data[i].month == categories[j]) {
								data[i] = obj2.data[i].total_sales

							}


							this.setState({
								series: [
									{ name: "Order Summary", data: data },

								],
							});


						}
					}


					Notiflix.Loading.Remove();
				}
			}))
		// GetApiCall.getRequest("Get_SalesSummaryChart").then((resultdes) =>
		// 	resultdes.json().then((obj) => {
		// 		this.setState({
		// 			MonthWiseSales: obj.data,
		// 		});
		// 		//console.log(obj.data)

		// 		const categories = [
		// 			"Jan",
		// 			"Feb",
		// 			"Mar",
		// 			"Apr",
		// 			"May",
		// 			"Jun",
		// 			"Jul",
		// 			"Aug",
		// 			"Sep",
		// 			"Oct",
		// 			"Nov",
		// 			"Dec",
		// 		];

		// 		this.setState({
		// 			categories: categories,
		// 		});

		// 		const data = new Array(12).fill(0);
		// 		const data2 = new Array(12).fill(0);
		// 		for (var j = 0; j < categories.length; j++) {
		// 			// //console.log(categories[j])
		// 			// if(moment(obj.data[i].fld_orderdate).format('MM YYYY')==categories[j]+ ' '+ moment().format('YYYY')){
		// 			//     //console.log(obj.data[i].fld_laundary_price)
		// 			//   }
		// 		}

		// 			for (var j = 0; j < categories.length; j++) {

		// 			for (var i = 0; i < obj.data.length; i++) {

		// 				// //console.log(obj.data[i].fld_laundary_price)
		// 				// //console.log(obj.data[i].fld_service_price)


		// 				if (
		// 					moment(obj.data[i].fld_orderdate).format("MMM YYYY") ==
		// 					categories[j] + " " + moment().format("YYYY")
		// 				) {
		// 					//console.log(obj.data[i].fld_orderDate)
		// 					data[i] = obj.data[i].fld_laundary_price;
		// 					data2[i] = obj.data[i].fld_service_price;
		// 				}
		// 			}
		// 		}

		// 		// this.setState({
		// 		// 	series: [
		// 		// 		{ name: "Laundry", data: data },
		// 		// 		{ name: "Service", data: data2 },
		// 		// 	],
		// 		// });
		// 		//   this.setState({series:[...this.state.series,{name : 'Order Count',data:data2}]})
		// 	})
		// );
	}

	render() {
		//   //console.log(this.state.series)

		return (
			<React.Fragment>
				<Card className="mini-stats-wid">
					<CardBody>
						<Media>
							<Media body>
								<h4 className="mb-0">Order Summary Chart </h4>
								<ReactApexChart
									options={this.state.options}
									series={this.state.series}
									type="area"
									height={260}
									width={535}
								/>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</React.Fragment>


		);
	}
}

export default Chart;
