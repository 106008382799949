import React, { Component } from "react";
import {
    Card,
    CardBody, Col, Container,
    Row
} from "reactstrap";


import "react-datepicker/dist/react-datepicker.css";
import { TiDeleteOutline } from "react-icons/ti";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import moment from "moment";


import imageCompression from "browser-image-compression";
import ImageUploadIcon from "../../assets/images/Upload.jpg";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const ImgUploadNew = ({ onChange, src }) => (
    <label htmlFor="photo-upload-new" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                htmlFor="photo-upload-new"
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id="photo-upload-new" type="file" onChange={onChange} />
    </label>
);

const ImgUpload = ({ onChange, src, id }) => (
    <label htmlFor={id} className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img
                htmlFor={id}
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept="image/*" id={id} type="file" onChange={onChange} />
    </label>
);

var randomnumber = Math.floor(100000 + Math.random() * 900000);


class AddImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isImageValid: null,
            imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',


            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",

            imagedata: [],

            VariantImageID: null,
            VariantName: [],
            VariantData: [],
            Photos: [],
            VariantID: null,


            VariantImage: null,
            ProductVariantId: null,
            RandomNumber: randomnumber




        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        // Notiflix.Loading.Dots("Please wait...");
        // console.log(localStorage.getItem('VariantImageID'))

        if (localStorage.getItem('VariantImageID') != null) {
            PostApiCall.postRequest(
                {
                    whereClause: `where fld_variantimageid=${JSON.parse(localStorage.getItem('VariantImageID'))}`
                },
                "Get_VariantImage"
            ).then((results8) =>
                results8.json().then((obj8) => {
                    // console.log(obj8.data)
                    if (
                        results8.status == 200 ||
                        results8.status == 201
                    ) {
                        if (obj8.data.length != 0) {
                            this.setState({
                                imagePreviewUrl: obj8.data[0].fld_imageurl,
                                VariantImageID: obj8.data[0].fld_variantimageid,
                                Photos: obj8.data,
                                VariantImage: obj8.data[0].fld_imageurl,
                                VariantID: obj8.data[0].fld_variantid,

                                ProductVariantId: null

                            })
                        }


                    }
                }))
        }



        if (localStorage.getItem('VariantIdForImage') != null) {


            var VariantData = JSON.parse(localStorage.getItem('VariantIdForImage'))
            this.setState({
                VariantID: VariantData.fld_variantid,

                VariantName: VariantData.fld_variantname,
                ProductVariantId: VariantData.fld_variantid,

            })


            PostApiCall.postRequest(
                {
                    whereClause: `where fld_variantid=${VariantData.fld_variantid}`
                },
                "Get_VariantImage"
            ).then((results8) =>
                results8.json().then((obj8) => {
                    if (
                        results8.status == 200 ||
                        results8.status == 201
                    ) {
                        this.setState({
                            Photos: obj8.data
                        })


                    }
                }))
        }


    }













    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        // console.log(imageFile)
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };


    onSaveVariant = () => {
        if (this.state.imagePreviewUrl != 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png') {
            this.onAddImage();
        } else {
            Notiflix.Notify.Failure("Please upload Variant image");
        }

    }

    onAddImage = () => {
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        if (this.state.VariantImage != this.state.imagePreviewUrl) {

            if (this.state.outputFileName != undefined) {
                // console.log('added')

                PostApiCall.postRequest(
                    {
                        variantimageid: this.state.VariantImageID,
                        variantid: this.state.VariantID,
                        imageurl:
                            JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
                            this.state.RandomNumber + "-" + this.state.outputFileName,
                        createdOn: moment().format("YYYY-MM-DD"),
                        updatedOn: moment().format("YYYY-MM-DD"),
                        updatedBy: details[0].fld_userid,
                    },
                    "AddVariantImage"
                ).then((results1) =>
                    results1.json().then((obj1) => {
                        if (results1.status == 200 || results1.status == 201) {

                            localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));

                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Image Uploading in Process");
                            this.onUploadImage();
                            // window.location.reload();
                        }
                        else {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Failure(obj1);
                        }
                    })
                );
            }
            else {

            }
        }

        else {
            this.onUpdateVariantImage()
        }
    }


    onUploadImage = () => {

        Notiflix.Loading.Dots("Please wait...");
        let response;


        if (this.state.outputFileName != undefined) {
            var options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {

                    // //console.log(compressedFile)

                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "ProductImages");
                    form.append(
                        "filename", this.state.RandomNumber +
                        "-" + compressedFile.name

                    );

                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then(response => response.json())
                        .then(data => {



                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Variant image is successfully added");
                            window.location.reload();

                        });
                })


        } else {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure("Please Upload Variant Image");
        }




    }

    onUpdateVariantImage = () => {

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        PostApiCall.postRequest(
            {
                variantimageid: this.state.VariantImageID,
                variantid: this.state.VariantID,
                imageurl: this.state.imagePreviewUrl,
                createdOn: moment().format("YYYY-MM-DD"),
                updatedOn: moment().format("YYYY-MM-DD"),
                updatedBy: details[0].fld_userid,
            },
            "AddVariantImage"
        ).then((results1) =>
            results1.json().then((obj1) => {
                if (results1.status == 200 || results1.status == 201) {
                    // //console.log(obj1)
                    localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));

                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("Variant image is successfully added.");
                    window.location.reload();
                }
                else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );
    }



    render() {

        // //console.log(this.state.imagePreviewUrl)
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 800,
            autoplay: true,
            slidesToShow: 4,
            slidesToScroll: 1,
        };
        let progessed = 0


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <div className="row">
                            <div className="col-md-6">

                                <div>
                                    <ul class="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Variant List</a></li>
                                        <li><a href='/addnewvariant'>Add New Variant</a></li>
                                        <li>Add New Variant Image</li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-6">
                                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

                            </div>
                        </div>




                        <Row>
                            <Col xl="12">
                                <Card
                                    className={'overflow-hidden ' + this.state.DisplayImages}
                                >
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text mb-0"> Upload {this.state.VariantName} Images</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody>
                                        <div className="row">
                                            {this.state.Photos.map((data, i) => (
                                                <div className="col-3">
                                                    <div className="best-products">
                                                        <div className="img-container" width="50%">
                                                            <ImgUpload
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    const imageFile = e.target.files[0];
                                                                    const size = e.target.files[0].size;
                                                                    if (size < 2e6) {
                                                                        this.setState({
                                                                            imagePreviewUrl: URL.createObjectURL(imageFile),
                                                                            originalImage: imageFile,
                                                                            outputFileName: imageFile.name
                                                                        });
                                                                        var login = localStorage.getItem("LoginDetail");
                                                                        var details = JSON.parse(login);
                                                                        if (imageFile.name != undefined) {
                                                                            //
                                                                            PostApiCall.postRequest(
                                                                                {
                                                                                    variantimageid: data.fld_variantimageid,
                                                                                    variantid: this.state.VariantID,
                                                                                    imageurl:
                                                                                        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
                                                                                        this.state.RandomNumber + "-" + imageFile.name,
                                                                                    createdOn: moment().format("YYYY-MM-DD"),
                                                                                    updatedOn: moment().format("YYYY-MM-DD"),
                                                                                    updatedBy: details[0].fld_userid,
                                                                                    type: "image"
                                                                                },
                                                                                "AddVariantImage"
                                                                            ).then((results1) =>
                                                                                results1.json().then((obj1) => {
                                                                                    if (results1.status == 200 || results1.status == 201) {
                                                                                        localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                                                                        Notiflix.Loading.Remove();
                                                                                        Notiflix.Notify.Success("Image Uploading in Process");
                                                                                        this.onUploadImage('image');
                                                                                    }
                                                                                    else {
                                                                                        Notiflix.Loading.Remove();
                                                                                        Notiflix.Notify.Failure(obj1);
                                                                                    }
                                                                                })
                                                                            );
                                                                        }
                                                                    } else {
                                                                        Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                                                                    }
                                                                }
                                                                }
                                                                src={data.fld_imageurl}
                                                                id={data.fld_variantimageid}
                                                            />
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "13px",
                                                                    top: "0px",
                                                                    cursor: "pointer",
                                                                    // background: "black"
                                                                }}
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            ImageUrl: data.fld_imageurl,
                                                                        })
                                                                    PostApiCall.postRequest(
                                                                        {
                                                                            variantimageid:
                                                                                data.fld_variantimageid,
                                                                        },
                                                                        "Delete_VariantImage"
                                                                    ).then((results) =>
                                                                        results.json().then((obj) => {
                                                                            if (
                                                                                results.status == 200 ||
                                                                                results.status == 201
                                                                            ) {
                                                                                Notiflix.Loading.Remove();
                                                                                Notiflix.Notify.Success(
                                                                                    "Image successfully deleted."
                                                                                );
                                                                                PostApiCall.postRequest(
                                                                                    {
                                                                                        whereClause: `where fld_variantid=${this.state.VariantID}`
                                                                                    },
                                                                                    "Get_VariantImage"
                                                                                ).then((results8) =>
                                                                                    results8.json().then((obj8) => {
                                                                                        if (
                                                                                            results8.status == 200 ||
                                                                                            results8.status == 201
                                                                                        ) {

                                                                                            if (obj8.data != null) {
                                                                                                this.setState({
                                                                                                    Photos: obj8.data,
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }))
                                                                            } else {
                                                                                Notiflix.Loading.Remove();
                                                                                Notiflix.Notify.Failure(
                                                                                    "Something went wrong, try again later."
                                                                                );
                                                                            }
                                                                        }))
                                                                }
                                                                }
                                                            >
                                                                <span className="material-symbols-outlined">
                                                                    <TiDeleteOutline />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                            <div className="col-3">
                                                <div className="best-products">
                                                    <ImgUploadNew
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            const imageFile = e.target.files[0];
                                                            const size = e.target.files[0].size;
                                                            if (size < 2e6) {
                                                                this.setState({
                                                                    imagePreviewUrl: URL.createObjectURL(imageFile),
                                                                    originalImage: imageFile,
                                                                    outputFileName: imageFile.name
                                                                });
                                                                var login = localStorage.getItem("LoginDetail");
                                                                var details = JSON.parse(login);
                                                                if (imageFile.name != undefined) {
                                                                    //
                                                                    PostApiCall.postRequest(
                                                                        {
                                                                            variantimageid: null,
                                                                            variantid: this.state.VariantID,
                                                                            imageurl:
                                                                                JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
                                                                                this.state.RandomNumber + "-" + imageFile.name,
                                                                            createdOn: moment().format("YYYY-MM-DD"),
                                                                            updatedOn: moment().format("YYYY-MM-DD"),
                                                                            updatedBy: details[0].fld_userid,
                                                                            type: "image"
                                                                        },
                                                                        "AddVariantImage"
                                                                    ).then((results1) =>
                                                                        results1.json().then((obj1) => {
                                                                            if (results1.status == 200 || results1.status == 201) {
                                                                                localStorage.setItem("VariantImageID", JSON.stringify(obj1.data[0].VariantImageId));
                                                                                Notiflix.Loading.Remove();
                                                                                Notiflix.Notify.Success("Image Uploading in Process");
                                                                                this.onUploadImage('image');
                                                                            }
                                                                            else {
                                                                                Notiflix.Loading.Remove();
                                                                                Notiflix.Notify.Failure(obj1);
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure("Max file size allowed is 2 Mb.");
                                                            }
                                                        }

                                                        }
                                                        src={ImageUploadIcon}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* -row */}

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddImage;
