import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";

import moment from "moment";
import GetApiCall from "../../GETAPI";

import imageCompression from "browser-image-compression";


const ImgUpload = ({ onChange, src }) => (
    <label htmlFor='photo-upload' className='custom-file-upload fas'>
        <div className='img-wrap img-upload'>
            <img
                for='photo-upload'
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}

            />
        </div>
        <input accept='image/*' id='photo-upload' type='file' onChange={onChange} />
    </label>
);

var randomnumber = Math.floor(100000 + Math.random() * 900000);


class VendorMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl:
                "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",

            ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
            ImageData: [],
            Status: "Active",

            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            CountryData: [],
            CityData: [],
            StateData: [],
            CountryId: 0,
            StateId: 0,
            CityId: 0,
            Name: "",
            Mobile: "",
            Email: "",
            Website: "",
            Address: "",
            Country: "",
            State: "",
            City: "",
            Pincode: "",

            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex:
                /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            ContactEmail: "",
            Landmark: "",
            Gstnumber: null,
            Pannumber: null,
            Tannumber: null,
            StoreName: "",
            StoreURL: "",
            Designation: "",
            ContactPerson: "",
            AboutCompany: "",
            ManufacturedBy: false,
            MarketingBy: false,
            VendoredBy: false,

            IFSCCode: "",
            AccountNumber: "",
            BankName: "",
            Branch: "",
            BankData: [],
            CompanyId: null,
            originalImage: [],
            originalLink: "",
            clicked: false,
            uploadImage: false,
            companyLogo: null,
            RandomNumber: randomnumber
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        // ========For Update============

        if (localStorage.getItem("companyId") != null) {
            var CompanyID = JSON.parse(localStorage.getItem("companyId"));

            PostApiCall.postRequest(
                {
                    whereClause: `where fld_company_id=${CompanyID}`,

                },
                "GetCompanyList"
            ).then((results) =>
                results.json().then((objcomapny) => {
                    if (results.status == 200 || results.status == 201) {
                        if (objcomapny.data.length != 0) {
                            //console.log(objcomapny.data)
                            this.setState({
                                CompanyData: objcomapny.data,
                            });
                            this.setState({
                                Name: objcomapny.data[0].fld_name,
                                Mobile: objcomapny.data[0].fld_contact_phone,
                                ContactEmail: objcomapny.data[0].fld_email,
                                Website: objcomapny.data[0].fld_website,
                                Address: objcomapny.data[0].fld_address,
                                Country: objcomapny.data[0].fld_country,
                                State: objcomapny.data[0].fld_state,
                                City: objcomapny.data[0].fld_city,
                                Pincode: objcomapny.data[0].fld_pincode,
                                IFSCCode: objcomapny.data[0].fld_pincode,
                                AccountNumber: objcomapny.data[0].fld_accountnumber,
                                BankName: objcomapny.data[0].fld_bankname,
                                Branch: objcomapny.data[0].fld_branch,
                                ManufacturedBy: objcomapny.data[0].fld_Manufacturer == 'True' ? true : false,
                                MarketingBy: objcomapny.data[0].fld_marketer == 'True' ? true : false,
                                VendoredBy: objcomapny.data[0].fld_vendor == 'True' ? true : false,
                                Landmark: objcomapny.data[0].fld_landmark,
                                Tannumber: objcomapny.data[0].fld_tan,
                                Pannumber: objcomapny.data[0].fld_pan,
                                AboutCompany: objcomapny.data[0].fld_about,
                                Status: objcomapny.data[0].fld_status,
                                CompanyId: objcomapny.data[0].fld_company_id

                            })

                            if (objcomapny.data[0].fld_image != null) {
                                this.setState({
                                    imagePreviewUrl: objcomapny.data[0].fld_image,
                                    companyLogo: objcomapny.data[0].fld_image,
                                })

                            }
                            else {
                                this.setState({
                                    imagePreviewUrl:
                                        "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",

                                })
                            }




                            Notiflix.Loading.Dots("Please wait...");

                            GetApiCall.getRequest("GetCountry").then((resultdes) =>
                                resultdes.json().then((obj) => {
                                    this.setState({
                                        CountryData: obj.data,
                                    });

                                    if (obj.data.length != 0) {
                                        this.setState({
                                            CountryId: obj.data.filter(
                                                (value) => value.value == objcomapny.data[0].fld_country_name
                                            )[0].value,
                                        });
                                    }

                                    PostApiCall.postRequest(
                                        {
                                            countryid: obj.data.filter(
                                                (value) => value.label == objcomapny.data[0].fld_country_name
                                            )[0].value,
                                        },
                                        "GetState"
                                    ).then((results) =>
                                        results.json().then((objstate) => {
                                            if (results.status == 200 || results.status == 201) {
                                                if (objstate.data.length != 0) {
                                                    this.setState({
                                                        StateId: objstate.data.filter(
                                                            (value) => value.label == objcomapny.data[0].fld_state_name
                                                        )[0].value,

                                                        StateData: objstate.data,
                                                        State: objstate.data[0].value,
                                                    });
                                                }

                                                PostApiCall.postRequest(
                                                    {
                                                        stateid: objstate.data.filter(
                                                            (value) => value.label == objcomapny.data[0].fld_state_name
                                                        )[0].value,
                                                    },
                                                    "GetCity"
                                                ).then((resultscity) =>
                                                    resultscity.json().then((objcity) => {
                                                        if (resultscity.status == 200 || resultscity.status == 201) {
                                                            if (objcity.data.length != 0) {
                                                                this.setState({
                                                                    CityId: objcity.data.filter(
                                                                        (value) => value.label == objcomapny.data[0].fld_city_name
                                                                    )[0].value,
                                                                    CityData: objcity.data,
                                                                    City: objcity.data[0].value,
                                                                });
                                                            }
                                                        }
                                                    })
                                                );
                                            }
                                        })
                                    );
                                })
                            );
                        }
                        Notiflix.Loading.Remove();
                    }
                }))
        }



        // ============For Insert=============

        Notiflix.Loading.Dots("Please wait...");

        GetApiCall.getRequest("GetCountry").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    CountryData: obj.data,
                });

                if (obj.data.length != 0) {
                    this.setState({
                        CountryId: 101,
                        Country: obj.data[102].value,
                    });
                }

                PostApiCall.postRequest(
                    {
                        countryid: obj.data[102].value,
                    },
                    "GetState"
                ).then((results) =>
                    results.json().then((objstate) => {
                        if (results.status == 200 || results.status == 201) {
                            if (objstate.data.length != 0) {
                                this.setState({
                                    StateId: objstate.data[0].value,

                                    StateData: objstate.data,
                                    State: objstate.data[0].value,
                                });
                            }

                            PostApiCall.postRequest(
                                {
                                    stateid: objstate.data[0].value,
                                },
                                "GetCity"
                            ).then((resultscity) =>
                                resultscity.json().then((objcity) => {
                                    if (resultscity.status == 200 || resultscity.status == 201) {
                                        if (objcity.data.length != 0) {
                                            this.setState({
                                                CityId: objcity.data[0].value,
                                                CityData: objcity.data,
                                                City: objcity.data[0].value,
                                            });
                                        }
                                    }
                                })
                            );
                        }
                    })
                );
            })
        );

        GetApiCall.getRequest("GetBankMaster").then((resultdes) =>
            resultdes.json().then((obj) => {
                // //console.log(obj.data)
                this.setState({
                    BankData: obj.data,
                });
                Notiflix.Loading.Remove();
            })
        );
    }

    photoUpload = (e) => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        this.setState({
            imagePreviewUrl: URL.createObjectURL(imageFile),
            originalImage: imageFile,
            outputFileName: imageFile.name,
            uploadImage: true
        });
    };

    onChangeCountry(country) {
        this.setState({
            CountryId: country.target.value,
            Country: this.state.CountryData[parseInt(country.target.value) + 1].value,
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country:
                    this.state.CountryData[parseInt(country.target.value) + 1].value,
            },
            "GetState"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        StateId: obj.data[0].value,
                        State: obj.data[0].value,
                    });

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value,
                        },
                        "GetCity"
                    ).then((resultscity) =>
                        resultscity.json().then((objcity) => {
                            if (resultscity.status == 200 || resultscity.status == 201) {
                                this.setState({
                                    CityData: objcity.data,
                                    StateData: obj.data,
                                });
                                if (objcity.data.length > 0) {
                                    this.setState({
                                        CityId: objcity.data[0].value,
                                        City: objcity.data[0].value,
                                    });
                                }
                                Notiflix.Loading.Remove();
                            }
                        })
                    );
                }
            })
        );
    }

    onChangeState(state) {
        this.setState({
            StateId: state.target.value,
        });

        Notiflix.Loading.Dots("Please wait...");

        for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
            if (this.state.StateData[i].value == state.target.value) {
                this.setState({
                    State: this.state.StateData[i].value,
                });
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value,
            },
            "GetCity"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {
                        this.setState({
                            CityData: obj.data,
                            CityId: obj.data[0].value,
                            City: obj.data[0].value,
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
    }

    onChangeCity(city) {
        this.setState({
            CityId: city.target.value,
        });

        for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
            if (this.state.CityData[i].value == city.target.value) {
                this.setState({
                    City: this.state.CityData[i].value,
                });
            }
        }
    }

    onSaveData() {
        // console.log(this.state.Country)
        // console.log(this.state.State)
        // console.log(this.state.City)

        if (this.state.Name != "") {
            if (this.state.ContactEmail !== "") {
                if (this.state.EmailRegex.test(this.state.ContactEmail)) {
                    if (this.state.Mobile != "") {
                        if (this.state.Mobile.length == 10) {
                            if (this.state.Address != "") {
                                if (this.state.Country != "") {
                                    if (this.state.State != "") {
                                        if (this.state.City != "") {
                                            if (this.state.Pincode != "") {
                                                if (this.state.ManufacturedBy != false || this.state.MarketingBy != false || this.state.VendoredBy != false) {
                                                    this.onPost();
                                                } else {
                                                    Notiflix.Notify.Failure(
                                                        "Please select atleast one vendor role"
                                                    );
                                                }

                                            } else {
                                                Notiflix.Notify.Failure("Please enter pincode");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter city");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please enter state");
                                    }
                                } else {
                                    Notiflix.Notify.Failure("Please select country");
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter Company's address");
                            }
                        } else {
                            Notiflix.Notify.Failure(
                                "Please enter contact's valid mobile number"
                            );
                        }
                    } else {
                        Notiflix.Notify.Failure(
                            "Please enter contact's mobile number"
                        );
                    }
                } else {
                    Notiflix.Notify.Failure("Please enter valid Contact's email");
                }
            } else {
                Notiflix.Notify.Failure("Please enter Contact's email");
            }
        } else {
            Notiflix.Notify.Failure("Please enter Company's name");
        }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        if (this.state.outputFileName != undefined) {

            PostApiCall.postRequest(
                {
                    id: this.state.CompanyId,
                    name: this.state.Name,
                    image:
                        JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/VendorImages/" +
                        this.state.RandomNumber + "-" + this.state.outputFileName,
                    // image: localStorage.getItem("ClientStoreURL") + "/images/VendorImages/" +
                    //     res.data.Message.split(",")[2].split("=")[1].trim(),
                    status: this.state.Status,
                    gst: this.state.Gstnumber == null ? null : this.state.Gstnumber,
                    pan: this.state.Pannumber == null ? null : this.state.Pannumber,
                    tan: this.state.Tannumber == null ? null : this.state.Tannumber,
                    about: this.state.AboutCompany,
                    email: this.state.ContactEmail,
                    website: this.state.Website,
                    address: this.state.Address,
                    landmark: this.state.Landmark,
                    country: this.state.Country,
                    state: this.state.State,
                    city: this.state.City,
                    pincode: this.state.Pincode,
                    accNumber: this.state.AccountNumber,
                    bankname: this.state.Bankname,
                    branch: this.state.Branch,
                    ifsc: this.state.Ifsc,
                    approved: null,
                    active: null,
                    createdOn: moment().format("YYYY-MM-DD"),
                    updatedOn: moment().format("YYYY-MM-DD"),
                    createdBy: details[0].fld_userid,
                    updatedBy: details[0].fld_userid,
                    manufacture: this.state.ManufacturedBy == true ? 'True' : 'False',
                    marketer: this.state.MarketingBy == true ? 'True' : 'False',
                    vendor: this.state.VendoredBy == true ? 'True' : 'False',
                    contactphone: this.state.Mobile
                },
                "Add_Company_Api"
            ).then((resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {

                        this.onUploadImage()

                    } else {
                        Notiflix.Notify.Failure(obj)
                    }

                })
            );

        } else {


            PostApiCall.postRequest(
                {
                    id: this.state.CompanyId,
                    name: this.state.Name,
                    image: this.state.CompanyId == null ? null : this.state.companyLogo,
                    status: this.state.Status,
                    gst: this.state.Gstnumber == null ? null : this.state.Gstnumber,
                    pan: this.state.Pannumber == null ? null : this.state.Pannumber,
                    tan: this.state.Tannumber == null ? null : this.state.Tannumber,
                    about: this.state.AboutCompany,
                    email: this.state.ContactEmail,
                    website: this.state.Website,
                    address: this.state.Address,
                    landmark: this.state.Landmark,
                    country: this.state.Country,
                    state: this.state.State,
                    city: this.state.City,
                    pincode: this.state.Pincode,
                    accNumber: this.state.AccountNumber,
                    bankname: this.state.Bankname,
                    branch: this.state.Branch,
                    ifsc: this.state.Ifsc,
                    approved: null,
                    active: null,
                    createdOn: moment().format("YYYY-MM-DD"),
                    updatedOn: moment().format("YYYY-MM-DD"),
                    createdBy: details[0].fld_userid,
                    updatedBy: details[0].fld_userid,
                    manufacture: this.state.ManufacturedBy == true ? 'True' : 'False',
                    marketer: this.state.MarketingBy == true ? 'True' : 'False',
                    vendor: this.state.VendoredBy == true ? 'True' : 'False',
                    contactphone: this.state.Mobile,
                },
                "Add_Company_Api"
            ).then((resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        Notiflix.Notify.Success("Company Added Successfully")
                        Notiflix.Loading.Remove();
                        window.location.href = "/vendor_master"
                    } else {
                        Notiflix.Notify.Failure(obj)
                    }

                })
            );

        }


    };


    onUploadImage = () => {
        // console.log('imageUpload')
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);


        if (this.state.outputFileName != undefined) {
            var options = {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(this.state.originalImage, options)
                .then((compressedFile) => {



                    const form = new FormData();

                    form.append("file", compressedFile);
                    form.append("foldername", "VendorImages");
                    form.append(
                        "filename", this.state.RandomNumber + "-" + compressedFile.name

                    );

                    fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    }).then((image) => {
                        image
                            .json()
                            .then((data) => ({
                                data: data,
                                status: image.status,
                            }))
                            .then((res) => {
                                Notiflix.Notify.Success("Company Added Successfully")
                                Notiflix.Loading.Remove();
                                window.location.href = "/vendor_master"
                            })
                    })
                })
        }
    }






    render() {

        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>

                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <ul class="breadcrumbs">
                                        <li><a href={localStorage.getItem("ClientStoreURL")}>store.bechofy.in</a></li>
                                        <li>Master Management</li>
                                        <li><a href='/vendor_master'>Company Master List</a></li>
                                        <li>Add Company Master </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-6">
                                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}
                            </div>
                        </div>
                        <Row>
                            <Col xl='12'>
                                <Card className='overflow-hidden'>
                                    <div className='Bechofy-bg-soft-pink'>
                                        <Row>
                                            <Col xs='3'>
                                                <div className='Bechofy-text  p-3'>
                                                    <h5 className='Bechofy-text'>
                                                        Add Company
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className='pt-0 usercarddata'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className='row'>
                                                        <div className='col col-12'>
                                                            <div className='row'>
                                                                <div className='col-md-3'>
                                                                    <label for='sw-arrows-first-name'>
                                                                        Upload Company Logo (Size &lt; 100kb,
                                                                        500*500)
                                                                        <span className='mandatory'>*</span>
                                                                    </label>
                                                                    <div class='div1'>
                                                                        <ImgUpload
                                                                            onChange={this.photoUpload}
                                                                            src={this.state.imagePreviewUrl}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-9'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Company Name
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Name'
                                                                                    value={this.state.Name}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Name: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Company Website
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='website'
                                                                                    value={this.state.Website}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Website: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for=''>
                                                                                    About Company
                                                                                </label>
                                                                                <textarea
                                                                                    className='form-control'
                                                                                    row='50'
                                                                                    col='30'
                                                                                    value={this.state.AboutCompany}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            AboutCompany: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Email
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='email'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Email'
                                                                                    value={this.state.ContactEmail}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            ContactEmail: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Phone
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    value={this.state.Mobile}
                                                                                    onChange={(mobile) => {
                                                                                        if (
                                                                                            this.state.NumRegex.test(
                                                                                                mobile.target.value
                                                                                            ) &&
                                                                                            mobile.target.value.length <= 10
                                                                                        ) {
                                                                                            this.setState({
                                                                                                Mobile: mobile.target.value,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className='col-4'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            GST Number
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control'
                                                                            name='GSTNUM'
                                                                            value={this.state.Gstnumber}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Gstnumber: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            PAN Number
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control'
                                                                            name='Pannum'
                                                                            value={this.state.Pannumber}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Pannumber: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            TAN Number
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control'
                                                                            name='TanNum'
                                                                            value={this.state.Tannumber}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Tannumber: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label for=''>
                                                                            Address
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <textarea
                                                                            className='form-control'
                                                                            row='50'
                                                                            col='30'
                                                                            value={this.state.Address}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Address: text.target.value,
                                                                                });
                                                                            }}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            Landmark
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control'
                                                                            name='website'
                                                                            value={this.state.Landmark}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Landmark: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            Country
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.CountryId}
                                                                            onChange={this.onChangeCountry.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.CountryData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.label}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            State
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.StateId}
                                                                            onChange={this.onChangeState.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.StateData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.label}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label
                                                                            class='my-1 mr-2'
                                                                            for='inlineFormCustomSelectPref'
                                                                        >
                                                                            City
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <select
                                                                            class='custom-select my-1 mr-sm-2'
                                                                            id='inlineFormCustomSelectPref'
                                                                            name='UserType'
                                                                            value={this.state.CityId}
                                                                            onChange={this.onChangeCity.bind(
                                                                                this
                                                                            )}
                                                                        >
                                                                            {this.state.CityData.map(
                                                                                (schedule) => (
                                                                                    <option
                                                                                        key={schedule.label}
                                                                                        value={schedule.value}
                                                                                    >
                                                                                        {schedule.label}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-3'>
                                                                    <div className='form-group'>
                                                                        <label for='CategoryName'>
                                                                            Pincode
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            id='CategoryName'
                                                                            className='form-control my-1 mr-sm-2'
                                                                            value={this.state.Pincode}
                                                                            onChange={(pincode) => {
                                                                                if (
                                                                                    this.state.NumRegex.test(
                                                                                        pincode.target.value
                                                                                    ) &&
                                                                                    pincode.target.value.length <= 6
                                                                                ) {
                                                                                    this.setState({
                                                                                        Pincode: pincode.target.value,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label for='CategoryName'>
                                                                            Vendor Role
                                                                            <span className='mandatory'>*</span>
                                                                        </label>
                                                                        <br />
                                                                        <div class="row">
                                                                            <div class="col-md-2">
                                                                                <input
                                                                                    checked={this.state.ManufacturedBy == true ? true : false}
                                                                                    type="checkbox"
                                                                                    value={this.state.ManufacturedBy}
                                                                                    onChange={() => {
                                                                                        if (this.state.ManufacturedBy) {
                                                                                            this.setState({
                                                                                                ManufacturedBy: false,



                                                                                            });
                                                                                        } else {
                                                                                            this.setState({
                                                                                                ManufacturedBy: true,

                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                /> <span>Manufacturer</span>
                                                                            </div>

                                                                            <div class="col-md-2">
                                                                                <input
                                                                                    checked={this.state.MarketingBy == true ? true : false}
                                                                                    type="checkbox"
                                                                                    value={this.state.MarketingBy}
                                                                                    onChange={() => {
                                                                                        if (this.state.MarketingBy) {
                                                                                            this.setState({
                                                                                                MarketingBy: false,



                                                                                            });
                                                                                        } else {
                                                                                            this.setState({
                                                                                                MarketingBy: true,

                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                /> <span>Marketer</span>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <input
                                                                                    checked={this.state.VendoredBy == true ? true : false}
                                                                                    type="checkbox"
                                                                                    value={this.state.VendoredBy}
                                                                                    onChange={() => {
                                                                                        if (this.state.VendoredBy) {
                                                                                            this.setState({
                                                                                                VendoredBy: false,



                                                                                            });
                                                                                        } else {
                                                                                            this.setState({
                                                                                                VendoredBy: true,

                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                /> <span>Vendor</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody className="pt-0 usercarddata">
                                        <CardTitle className="usertitle">
                                            Bank Information
                                        </CardTitle>
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row">
                                                        <div className="col col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Bank Name
                                                                                </label>
                                                                                <select
                                                                                    class="custom-select my-1 mr-sm-2"
                                                                                    id="inlineFormCustomSelectPref"
                                                                                    name="UserType"
                                                                                    value={this.state.BankName}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            BankName: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <option selected>
                                                                                        Select Bank
                                                                                    </option>
                                                                                    {this.state.BankData.map(
                                                                                        (bank) => (
                                                                                            <option
                                                                                                key={bank.value}
                                                                                                value={bank.value}
                                                                                            >
                                                                                                {bank.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Branch
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control my-1 mr-sm-2"
                                                                                    value={this.state.Branch}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Branch: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    Account Number
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control"
                                                                                    value={this.state.AccountNumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            AccountNumber:
                                                                                                text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label for="CategoryName">
                                                                                    IFSC Code
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    id="CategoryName"
                                                                                    className="form-control"
                                                                                    value={this.state.IFSCCode}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            IFSCCode: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className='py-1    my-1'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group my-1 mr-sm-2'>
                                                            <label for='CategoryName'>Status</label>
                                                            <br />
                                                            <label class='radio-inline'>
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Active" ? true : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Active",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Active
                                                            </label>
                                                            <label
                                                                class='radio-inline'
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Inactive"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Inactive",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Inactive
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button
                                                            style={{ float: "right", marginTop: "5px" }}
                                                            className='btn align-items-center Bechofy-btn '
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            Save & Add Company
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}

export default VendorMaster;
