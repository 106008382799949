import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";

class AddOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			BankOfferStatus: "No",
			FirstOrder: "Yes",
			AllOrders: "No",
			OneTime: "No",
			FreeShipping: "No",
			RefferalDiscount: "No",
			FreeGifts: "",
			MinimumProducts: "",
			Status: "Active",
			NumRegex: /^0|[0-9]\d*$/,
			MobileRegex: /^[0-9]*$/,
			DecimalRegex: /^(\d*\.?\d{0,9}|\.\d{0,9})$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			SpecialRegex: /[-!$%^&*()_+|~=`'"{}\[\]:\/;<>?,.@#]/,
			EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex: /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			OfferName: "",
			OfferCaption: "",
			OfferPrice: "",
			MaximumDiscountPrice: "",
			MinimumAmountApplicable: "",
			MaximumAmountApplicable: "",
			OfferDescription: "",
			OfferCode: "",
			StartDate: "",
			EndDate: "",
			Terms: "",
			CategoryName: "",
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: "",
			AttributData: [],
			ItemName: "",
			HSNCode: "",
			GSTRate: "",
			GSTData: [],
			BrandName: "",
			BrandData: [],
			VendorName: "",
			VendorData: [],
			MarketedBy: "",
			VariantNamefromDropdown: "",
			FormStatus: "",
			name: "",
			VerticalName: "",
			VerticalData: [],
			MarketedByByData: [],
			ItemData: [],
			ID: null,
			DisableDiscountPercentage: false,
			DisableFixedDiscountAmount: false,
		};
	}


	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		if (this.state.Action == "update") {

			PostApiCall.postRequest({
				whereclause: `where fld_seoid= ${this.state.ID}`
			}, "Get_SEOInfo").then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						// console.log(obj.data)
						this.setState({
							MetaTitle: obj.data[0].fld_meta_tile,
							MetaDescription: obj.data[0].fld_meta_description,
							OGTitle: obj.data[0].fld_og_title,
							OGDescription: obj.data[0].fld_og_description,
							Keywords: obj.data[0].fld_keywords,
							ItemName: obj.data[0].varient_id,
							VerticalName: obj.data[0].verticle_id,
							CategoryName: obj.data[0].category_id,
							SubCategory: obj.data[0].sub_category_id,
							SeoId: obj.data[0].fld_seoid,

						});


						Notiflix.Loading.Dots("Please wait...");

						PostApiCall.postRequest(
							{
								whereClause: `where fld_vertical_id=${obj.data[0].verticle_id}`,
							},
							"Get_categorydropdown"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {

									this.setState({
										CategoryData: obj1.data,
									});

									Notiflix.Loading.Remove();
								}
							})
						);


						Notiflix.Loading.Dots("Please wait...");

						PostApiCall.postRequest(
							{
								categoryid: obj.data[0].category_id,
							},
							"Get_subcategoryDropdown"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									this.setState({
										SubCatgeoryData: obj1.data,
									});

									Notiflix.Loading.Remove();
								}
							})
						);

						PostApiCall.postRequest({
							recordCount: "*",
							whereClause: `where fld_subcategoryid=${obj.data[0].sub_category_id}`,
						}, "Get_ProductDropdown").then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// console.log(obj1.data)
									this.setState({
										ItemData: obj1.data,
									});

									Notiflix.Loading.Remove();
								}
							})
						);



						Notiflix.Loading.Remove();
					}
				}))
		}

		Notiflix.Loading.Dots()
		PostApiCall.postRequest(
			{

				whereClause: `where fld_productid=${JSON.parse(localStorage.getItem("itemDetails"))}`,
				recordCount: '*'
			},
			"GetProductMaster"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// console.log(obj.data)
					this.setState({
						ProductData: obj.data,

					});
					if (obj.data.length != 0) {
					}
					Notiflix.Loading.Remove();
				}
			}))

		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

	}



	onChangeCategory(text) {
		this.setState(
			{
				CategoryName: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryName,
					},
					"Get_subcategoryDropdown"
				).then((results1) =>
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							this.setState({
								SubCatgeoryData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			}, () => {
				Notiflix.Loading.Dots();
				PostApiCall.postRequest({
					recordCount: "*",
					whereClause: `where fld_subcategoryid=${this.state.SubCategory}`,
				}, "Get_ProductDropdown").then((results1) =>
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							// console.log(obj1.data)
							this.setState({
								ItemData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			});
	}

	onChangeVariantName(text) {

		this.setState({
			ItemName: text.target.value
		})
	}

	handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value,
		});
	};

	onChangeDescription = (e) => {
		this.setState({
			OfferDescription: e.editor.getData(),
		});
	};

	onTermsDescription = (e) => {
		this.setState({
			Terms: e.editor.getData(),
		});
	};

	onPost = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				offer_id: this.state.ID,
				type: this.state.VerticalName == "" ? "All" : (this.state.CategoryName != "" && this.state.SubCategory == '') ? "Category" : (this.state.SubCategory != '' && this.state.ItemName == '') ? 'SubCategory' : this.state.ItemName != '' ? 'Variant' : "Vertical",
				typeid: this.state.VerticalName == "" ? 0 : (this.state.CategoryName != "" && this.state.SubCategory == '') ? this.state.CategoryName : (this.state.SubCategory != '' && this.state.ItemName == '') ? this.state.SubCategory : this.state.ItemName != '' ? this.state.ItemName : this.state.VerticalName,
				name: this.state.OfferName,
				caption: this.state.OfferCaption,
				pricepercent: this.state.OfferPrice,
				maximumdiscountprice: this.state.MaximumDiscountPrice,
				minapplyamnt: this.state.MinimumAmountApplicable,
				maxapplyamnt: this.state.MaximumAmountApplicable,
				minimumproduct: this.state.MinimumProducts,
				freegift: this.state.FreeGifts,
				firstorder: this.state.FirstOrder,
				allorder: this.state.AllOrders,
				onetime: this.state.OneTime,
				freeshipping: this.state.FreeShipping,
				refferaldiscount: this.state.RefferalDiscount,
				description: this.state.OfferDescription,
				code: this.state.OfferCode,
				startdate: this.state.StartDate,
				enddate: this.state.EndDate,
				termscondition: this.state.Terms,
				showonwebsite: this.state.Status,
				userid: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_userid
			},
			"AddOffer"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					Notiflix.Loading.Remove();

					Notiflix.Notify.Success("Successfully Added");
					window.location.href = "/offer-list";
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure("Error Occured");
				}
			})
		);
	};



	SaveOffer() {
		// if (this.state.VerticalName != "") {
		if (this.state.OfferName != "") {
			if (this.state.OfferCaption != "") {
				if (this.state.OfferPrice !== "") {
					if (this.state.MaximumDiscountPrice !== "") {
						if (this.state.MinimumAmountApplicable != "") {
							if (this.state.MaximumAmountApplicable != "") {
								if (this.state.MinimumProducts != "") {
									if (this.state.NumRegex.test(this.state.MinimumProducts)) {
										if (this.state.FreeGifts != "") {
											if (this.state.NumRegex.test(this.state.FreeGifts)) {
												if (this.state.OfferCode != "") {
													if (this.state.StartDate != "") {
														if (this.state.EndDate != "") {
															if (this.state.Terms != "") {
																if (
																	this.state.Terms.replace(/(<([^>]+)>)/gi, "").trim()
																		.length < 500
																) {
																	this.onPost();
																} else {
																	Notiflix.Notify.Failure(
																		"Please enter terms & conditions with maximum 500 characters."
																	);
																}
															} else {
																Notiflix.Notify.Failure(
																	"Offer Terms & Conditions cannot be empty."
																);
															}
														} else {
															Notiflix.Notify.Failure(
																"Offer EndDate cannot be empty."
															);
														}
													} else {
														Notiflix.Notify.Failure(
															"Offer StartDate cannot be empty."
														);
													}
												} else {
													Notiflix.Notify.Failure("Offer Code cannot be empty.");
												}
											} else {
												Notiflix.Notify.Failure("Free gifts can only be the number. Please add the number of free gifts you are giving to the customer");
											}
										} else {
											Notiflix.Notify.Failure("Free Gifts cannot be empty. If there is no free gifts, please enter 0");
										}
									} else {
										Notiflix.Notify.Failure(
											"Minimum products can only be the number. Please add the minimum number of products"
										);
									}
								} else {
									Notiflix.Notify.Failure("Please enter the minimum products on which you want to give the offers.");
								}

							} else {
								Notiflix.Notify.Failure(
									"Maximum applicable Discount on the offer cannot be empty."
								);
							}
						} else {
							Notiflix.Notify.Failure(
								"Minimum order amount on the offer cannot be empty."
							);
						}
					} else {
						Notiflix.Notify.Failure(
							"Offer Fixed Discounted Price cannot be empty."
						);
					}
				} else {
					Notiflix.Notify.Failure("Discount Percentage cannot be empty.");
				}
			} else {
				Notiflix.Notify.Failure("Offer Caption cannot be empty.");
			}
		} else {
			Notiflix.Notify.Failure("Offer Name cannot be empty.");
		}
		// } else {
		// 	Notiflix.Notify.Failure("Please select atleast one type (vertical/category/subcategory/variantname) from dropdown")
		// }
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={"Bechofy"}
							breadcrumbItem={"Add New Offer"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">

									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink"
													style={{ background: "#777f80" }}>
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text" style={{ color: "#fff" }}>
																	Add New Offer
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Vertical
																					{/* <span className="mandatory">*</span> */}
																				</label>

																				<select
																					value={this.state.VerticalName}
																					onChange={(text) => {
																						this.setState({
																							VerticalName: text.target.value
																						},
																							() => {
																								Notiflix.Loading.Dots("Please wait...");

																								PostApiCall.postRequest(
																									{
																										whereClause: `where fld_vertical_id=${this.state.VerticalName}`,
																									},
																									"Get_categorydropdown"
																								).then((results1) =>
																									results1.json().then((obj1) => {
																										if (results1.status == 200 || results1.status == 201) {

																											this.setState({
																												CategoryData: obj1.data,
																											});

																											Notiflix.Loading.Remove();
																										}
																									})
																								);
																							}
																						)
																					}}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Vertical
																					</option>
																					{this.state.VerticalData.map(
																						(Verticle) => (
																							<option
																								key={Verticle.value}
																								value={Verticle.value}
																							>
																								{Verticle.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Category
																				</label>
																				<select
																					value={this.state.CategoryName}
																					onChange={this.onChangeCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="Select Category">
																						Select Category
																					</option>

																					{this.state.CategoryData.map(
																						(Category) => (
																							<option
																								key={Category.value}
																								value={Category.value}
																							>
																								{Category.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Sub Category
																				</label>
																				<select
																					value={this.state.SubCategory}
																					onChange={this.onChangesubCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Sub Category
																					</option>
																					{this.state.SubCatgeoryData.map(
																						(subcat) => (
																							<option
																								key={subcat.value}
																								value={subcat.value}
																							>
																								{subcat.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-3">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Variant Name
																				</label>
																				<select
																					value={this.state.ItemName}
																					onChange={this.onChangeVariantName.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Variant Name
																					</option>
																					{this.state.ItemData.map(
																						(variantname) => (
																							<option
																								key={variantname.fld_itemname}
																								value={variantname.fld_variantid}
																							>
																								{variantname.fld_itemname}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col-4 mt-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Offer Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.OfferName}
																						onChange={(job) => {
																							this.setState({
																								OfferName: job.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-4 mt-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Offer Caption
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.OfferCaption}
																						onChange={(job) => {
																							this.setState({
																								OfferCaption: job.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-4 mt-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Discount (%)
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						disabled={this.state.DisableDiscountPercentage == true ? true : false}
																						style={{ backgroundColor: this.state.DisableDiscountPercentage == true ? "rgb(234, 234, 234)" : "" }}
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.OfferPrice}
																						onChange={(text) => {

																							if (
																								this.state.DecimalRegex.test(
																									text.target.value
																								)
																							) {
																								this.setState({
																									OfferPrice: text.target.value,
																								}, () => {
																									if (this.state.OfferPrice.length > 0) {
																										this.setState({
																											DisableFixedDiscountAmount: true,
																											MaximumDiscountPrice: 0
																										})
																									}
																									else {
																										this.setState({
																											DisableFixedDiscountAmount: false,


																										})
																									}
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						Fixed Discount Amount(Rs.)
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						disabled={this.state.DisableFixedDiscountAmount == true ? true : false}
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						style={{ backgroundColor: this.state.DisableFixedDiscountAmount == true ? "rgb(234, 234, 234)" : "" }}
																						value={this.state.MaximumDiscountPrice}
																						onChange={(text) => {
																							if (
																								this.state.DecimalRegex.test(
																									text.target.value
																								)
																							) {
																								this.setState({

																									MaximumDiscountPrice:
																										text.target.value,

																								}, () => {
																									if (this.state.MaximumDiscountPrice.length > 0) {
																										this.setState({
																											DisableDiscountPercentage: true,
																											OfferPrice: 0

																										})
																									}
																									else {
																										this.setState({
																											DisableDiscountPercentage: false,


																										})
																									}
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						Minimum Order Amount(Rs.)
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MinimumAmountApplicable}
																						onChange={(text) => {
																							if (
																								this.state.DecimalRegex.test(
																									text.target.value
																								)
																							) {
																								this.setState({
																									MinimumAmountApplicable:
																										text.target.value,
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						Maximum Applicable Discount (Rs.)
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MaximumAmountApplicable}
																						onChange={(text) => {
																							if (
																								this.state.DecimalRegex.test(
																									text.target.value
																								)
																							) {
																								this.setState({
																									MaximumAmountApplicable:
																										text.target.value,
																								});
																							}
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Minimum Products
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MinimumProducts}
																						onChange={(text) => {

																							this.setState({
																								MinimumProducts:
																									text.target.value,
																							});

																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Free Gifts
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.FreeGifts}
																						onChange={(text) => {

																							this.setState({
																								FreeGifts:
																									text.target.value,
																							});

																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-2">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						First Order
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="firstorderradio"
																							checked={
																								this.state.FirstOrder == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									FirstOrder: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="firstorderradio"

																							checked={
																								this.state.FirstOrder == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									FirstOrder: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>
																			<div className="col-2">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						All Orders
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="allordersradio"
																							checked={
																								this.state.AllOrders == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									AllOrders: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="allordersradio"

																							checked={
																								this.state.AllOrders == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									AllOrders: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>
																			<div className="col-2">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						One Time
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="onetimeradio"
																							checked={
																								this.state.OneTime == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									OneTime: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="onetimeradio"

																							checked={
																								this.state.OneTime == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									OneTime: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						Free Shipping
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="freeshippingradio"
																							checked={
																								this.state.FreeShipping == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									FreeShipping: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="freeshippingradio"

																							checked={
																								this.state.FreeShipping == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									FreeShipping: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						Refferal Discount
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="refferaldiscountradio"
																							checked={
																								this.state.RefferalDiscount == "Yes"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									RefferalDiscount: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="refferaldiscountradio"

																							checked={
																								this.state.RefferalDiscount == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									RefferalDiscount: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>

																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Validity
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">Offer Code
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="text"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			value={this.state.OfferCode}
																			onChange={(job) => {
																				this.setState({
																					OfferCode: job.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			Start Date
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="date"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			min={moment().format("YYYY-MM-DD")}
																			value={this.state.StartDate}
																			onChange={(job) => {
																				this.setState({
																					StartDate: job.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>

																<div className="col-4">
																	<div className="form-group">
																		<label for="CategoryName">
																			End Date
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="date"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			min={moment().format("YYYY-MM-DD")}
																			value={this.state.EndDate}
																			onChange={(job) => {
																				this.setState({
																					EndDate: job.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>

																{/* <div className="col-4">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Bank Offer
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="bankofferradio"
																				checked={
																					this.state.BankOfferStatus == "Yes"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						BankOfferStatus: "Yes",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="bankofferradio"

																				checked={
																					this.state.BankOfferStatus == "No"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						BankOfferStatus: "No",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-4" style={{ display: this.state.BankOfferStatus == "Yes" ? "block" : "none" }}>
																	<div className="form-group">
																		<label for="CategoryName">Bank Name</label>
																		<input
																			type="text"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			value={this.state.BankName}
																			onChange={(text) => {
																				this.setState({
																					BankName: text.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
																<div className="col-4" style={{ display: this.state.BankOfferStatus == "Yes" ? "block" : "none" }}>
																	<div className="form-group">
																		<label for="CategoryName">Bank Discount %</label>
																		<input
																			type="text"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			value={this.state.BankDiscount}
																			onChange={(text) => {
																				this.setState({
																					BankDiscount: text.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>*/}
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Terms & Condition
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-12">
																	<div class="form-group">
																		<label for="sw-arrows-first-name">
																			Terms & Conditions(maximum 500 Character)
																			<span className="mandatory">*</span>
																		</label>

																		<div class="niceeditors">
																			<CKEditor
																				config={{
																					extraPlugins:
																						"justify,font,colorbutton",
																				}}
																				data={this.state.Terms}
																				onChange={(e) =>
																					this.onTermsDescription(e)
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1    my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.Status == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						Status: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.SaveOffer.bind(this)}
																	>
																		Save New Offer
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddOffer;
