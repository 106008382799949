import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import moment from "moment";
import { connect } from "react-redux";
import GetApiCall from "../../GETAPI";

const ImgUpload = ({ onChange, src }) => (
    <label htmlFor='photo-upload' className='custom-file-upload fas'>
        <div className='img-wrap img-upload'>
            <img
                for='photo-upload'
                src={src}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
            />
        </div>
        <input accept='image/*' id='photo-upload' type='file' onChange={onChange} />
    </label>
);

class VendorMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl:
                "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",

            ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
            Status: "Active",
            ImageData: [],
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            CountryData: [],
            CityData: [],
            StateData: [],
            CountryId: 0,
            StateId: 0,
            CityId: 0,
            Name: "",
            Mobile: "",
            Email: "",
            Website: "",
            Address: "",
            Country: "",
            State: "",
            City: "",
            Pincode: "",
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            NumRegex: /^[0-9]*$/,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex:
                /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            ContactEmail: "",
            Landmark: "",
            Gstnumber: "",
            Pannumber: "",
            Tannumber: "",
            StoreName: "",
            StoreURL: "",
            Designation: "",
            ContactPerson: "",
            AboutCompany: "",
            ManufacturedBy: "",
            MarketingBy: "",
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        GetApiCall.getRequest("GetCountry").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    CountryData: obj.data,
                });

                if (obj.data.length != 0) {
                    this.setState({
                        CountryId: 101,
                        Country: obj.data[102].label,
                    });
                }

                PostApiCall.postRequest(
                    {
                        countryid: obj.data[102].value,
                    },
                    "GetState"
                ).then((results) =>
                    results.json().then((objstate) => {
                        if (results.status == 200 || results.status == 201) {
                            if (objstate.data.length != 0) {
                                this.setState({
                                    StateId: objstate.data[0].value,

                                    StateData: objstate.data,
                                    State: objstate.data[0].label,
                                });
                            }

                            PostApiCall.postRequest(
                                {
                                    stateid: objstate.data[0].value,
                                },
                                "GetCity"
                            ).then((resultscity) =>
                                resultscity.json().then((objcity) => {
                                    if (resultscity.status == 200 || resultscity.status == 201) {
                                        if (objcity.data.length != 0) {
                                            this.setState({
                                                CityId: objcity.data[0].value,
                                                CityData: objcity.data,
                                                City: objcity.data[0].label,
                                            });
                                        }
                                    }
                                })
                            );
                        }
                    })
                );
            })
        );
    }

    photoUpload = (e) => {
        e.preventDefault();
        if (e.target.files[0].size < 300000) {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    ImageData: file,
                });
            };
            reader.readAsDataURL(file);
        } else {
            Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
        }
    };

    onChangeCountry(country) {
        this.setState({
            CountryId: country.target.value,
            Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                countryid: country.target.value,
                Country:
                    this.state.CountryData[parseInt(country.target.value) + 1].label,
            },
            "GetState"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        StateId: obj.data[0].value,
                        State: obj.data[0].label,
                    });

                    PostApiCall.postRequest(
                        {
                            stateid: obj.data[0].value,
                        },
                        "GetCity"
                    ).then((resultscity) =>
                        resultscity.json().then((objcity) => {
                            if (resultscity.status == 200 || resultscity.status == 201) {
                                this.setState({
                                    CityData: objcity.data,
                                    StateData: obj.data,
                                });
                                if (objcity.data.length > 0) {
                                    this.setState({
                                        CityId: objcity.data[0].value,
                                        City: objcity.data[0].label,
                                    });
                                }
                                Notiflix.Loading.Remove();
                            }
                        })
                    );
                }
            })
        );
    }

    onChangeState(state) {
        this.setState({
            StateId: state.target.value,
        });

        Notiflix.Loading.Dots("Please wait...");

        for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
            if (this.state.StateData[i].value == state.target.value) {
                this.setState({
                    State: this.state.StateData[i].label,
                });
            }
        }

        PostApiCall.postRequest(
            {
                stateid: state.target.value,
            },
            "GetCity"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {
                        this.setState({
                            CityData: obj.data,
                            CityId: obj.data[0].value,
                            City: obj.data[0].label,
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
    }

    onChangeCity(city) {
        this.setState({
            CityId: city.target.value,
        });

        for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
            if (this.state.CityData[i].value == city.target.value) {
                this.setState({
                    City: this.state.CityData[i].label,
                });
            }
        }
    }

    onSaveData() {
        if (
            this.state.imagePreviewUrl !=
            "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png"
        ) {
            if (this.state.Name != "") {
                if (this.state.Email != "") {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                        if (this.state.ContactEmail !== "") {
                            if (this.state.EmailRegex.test(this.state.ContactEmail)) {
                                if (this.state.Mobile != "") {
                                    if (this.state.Mobile.length == 10) {
                                        if (this.state.Address != "") {
                                            if (this.state.Country != "") {
                                                if (this.state.State != "") {
                                                    if (this.state.City != "") {
                                                        if (this.state.Pincode != "") {
                                                            if (this.state.ManufacturedBy != "") {
                                                                if (this.state.MarketingBy != "") {
                                                                    if (this.state.Pincode.length == 6) {
                                                                        if (this.state.AboutCompany != "") {
                                                                            if (this.state.ContactPerson != "") {
                                                                                if (this.state.StoreName != "") {
                                                                                    this.onPost();
                                                                                } else {
                                                                                    Notiflix.Notify.Failure(
                                                                                        "Please enter Store Name"
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                Notiflix.Notify.Failure(
                                                                                    "Please enter contact person's name"
                                                                                );
                                                                            }
                                                                        } else {
                                                                            Notiflix.Notify.Failure(
                                                                                "Please enter something about your company"
                                                                            );
                                                                        }
                                                                    } else {
                                                                        Notiflix.Notify.Failure(
                                                                            "Please enter valid pincode"
                                                                        );
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.Failure("Marketing By section cannot be empty")
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure("Please enter the Manufacturer")
                                                            }
                                                        } else {
                                                            Notiflix.Notify.Failure("Please enter pincode");
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure("Please enter city");
                                                    }
                                                } else {
                                                    Notiflix.Notify.Failure("Please enter state");
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please select country");
                                            }
                                        } else {
                                            Notiflix.Notify.Failure("Please enter Company's address");
                                        }
                                    } else {
                                        Notiflix.Notify.Failure(
                                            "Please enter contact's valid mobile number"
                                        );
                                    }
                                } else {
                                    Notiflix.Notify.Failure(
                                        "Please enter contact's mobile number"
                                    );
                                }
                            } else {
                                Notiflix.Notify.Failure("Please enter valid Contact's email");
                            }
                        } else {
                            Notiflix.Notify.Failure("Please enter Contact's email");
                        }
                    } else {
                        Notiflix.Notify.Failure("Please enter Company's valid email");
                    }
                } else {
                    Notiflix.Notify.Failure("Please enter Company's email");
                }
            } else {
                Notiflix.Notify.Failure("Please enter Company's name");
            }
        } else {
            Notiflix.Notify.Failure("Please upload Company's Logo");
        }
    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        PostApiCall.postRequest(
            {
                name: this.state.Name,
                gstnumber: this.state.Gstnumber,
                pannumber: this.state.Pannumber,
                tannumber: this.state.Tannumber,
                aboutCompany: this.state.AboutCompany,
                contactPerson: this.state.ContactPerson,
                designation: this.state.Designation,
                contactEmail: this.state.ContactEmail,
                companywebsite: this.state.Website,
                address: this.state.Address,
                landmark: this.state.Landmark,
                country: this.state.Country,
                state: this.state.State,
                city: this.state.City,
                pincode: this.state.Pincode,
                storename: this.state.StoreName,
                storeUrl: this.state.StoreURL,
                email: this.state.Email,
                mobile: this.state.Mobile,
                status: this.state.Status,
                marketingby: this.state.MarketingBy,
                manufacturedby: this.state.ManufacturedBy,
                createdon: moment().format("YYYY-MM-DD"),
                updatedon: moment().format("YYYY-MM-DD"),
                updatedby: details[0].fld_userid,
            },
            "Add_Vendor_Api"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Notify.Success("Vendor Added Successfully")
                } else {
                    Notiflix.Notify.Failure("Something went wrong. Please try again later")
                }
            })
        );
    };

    onUploadImage(obj) {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);
        if (JSON.stringify(this.state.ImageData) != "[]") {
            const form = new FormData();

            form.append("file", this.state.ImageData);
            form.append("foldername", "User");
            form.append(
                "filename",
                this.state.Name.trim().replace(/\s/g, "-") +
                "-" +
                JSON.parse(JSON.stringify(obj.data[0])).UserId
            );

            fetch(this.state.ImageApiUrl, {
                method: "POST",
                body: form,
            }).then((image) => {
                image
                    .json()
                    .then((data) => ({
                        data: data,
                        status: image.status,
                    }))
                    .then((res) => {
                        PostApiCall.postRequest(
                            {
                                id: JSON.parse(JSON.stringify(obj.data[0])).UserId,
                                image:
                                    "http://demo.globaltrendz.online/Bechofy/images/User/" +
                                    res.data.Message.split(",")[2].split("=")[1].trim(),
                                updatedon: moment().format("YYYY-MM-DD"),
                                updatedby: details[0].fld_userid,
                            },
                            "AddUserimage"
                        ).then((results1) =>
                            results1.json().then((obj1) => {
                                if (results1.status == 200 || results1.status == 201) {
                                    // //console.log(obj1)
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Success("Vendor successfully Added.");
                                } else {
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Failure(obj1.data);
                                }
                            })
                        );
                    });
            });
        } else {
            Notiflix.Loading.Remove();
            //console.log("error occured");
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-6">
                                <Breadcrumbs
                                    title={"Bechofy / Vendor Management "}
                                    breadcrumbItem={"Add Vendor"}
                                />

                            </div>
                            <div className="col-md-6">
                                <img src="http://globaltrendz.com/assets/img/gt-logo.jpeg" alt="company logo" className="img-responsive companyLogo"></img>
                            </div>
                        </div>
                        <Row>
                            <Col xl='12'>
                                <Card className='overflow-hidden'>
                                    <div className='Bechofy-bg-soft-pink'>
                                        <Row>
                                            <Col xs='3'>
                                                <div className='Bechofy-text  p-3'>
                                                    <h5 className='Bechofy-text'>
                                                        Add Vendor
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className='pt-0 usercarddata'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className='row'>
                                                        <div className='col col-12'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <label for='sw-arrows-first-name'>
                                                                        Upload Company Logo (Size &lt; 100kb,
                                                                        500*500)
                                                                        <span className='mandatory'>*</span>
                                                                    </label>
                                                                    <div class='div1'>
                                                                        <ImgUpload
                                                                            onChange={this.photoUpload}
                                                                            src={this.state.imagePreviewUrl}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-8'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Company Name
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Name'
                                                                                    value={this.state.Name}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Name: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Email
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='email'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Email'
                                                                                    value={this.state.ContactEmail}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Email: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Company Email
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='email'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Email'
                                                                                    value={this.state.Email}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Email: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Phone
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    value={this.state.Mobile}
                                                                                    onChange={(mobile) => {
                                                                                        if (
                                                                                            this.state.NumRegex.test(
                                                                                                mobile.target.value
                                                                                            ) &&
                                                                                            mobile.target.value.length <= 10
                                                                                        ) {
                                                                                            this.setState({
                                                                                                Mobile: mobile.target.value,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    GST Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='GSTNUM'
                                                                                    value={this.state.Gstnumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Gstnumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    PAN Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='Pannum'
                                                                                    value={this.state.Pannumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Pannumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    TAN Number
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='TanNum'
                                                                                    value={this.state.Tannumber}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Tannumber: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Company Website
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='website'
                                                                                    value={this.state.Website}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Website: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for=''>
                                                                                    About Company
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <textarea
                                                                                    className='form-control'
                                                                                    row='50'
                                                                                    col='30'
                                                                                    value={this.state.AboutCompany}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            AboutCompany: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Contact Person
                                                                                </label>
                                                                                <span className='mandatory'>*</span>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name=' Contact Person'
                                                                                    value={this.state.ContactPerson}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            ContactPerson: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Designation
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name=' Contact Person'
                                                                                    value={this.state.Designation}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Designation: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for=''>
                                                                                    Address
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <textarea
                                                                                    className='form-control'
                                                                                    row='50'
                                                                                    col='30'
                                                                                    value={this.state.Address}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Address: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Landmark
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='website'
                                                                                    value={this.state.Landmark}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            Landmark: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            <div className='form-group'>
                                                                                <label
                                                                                    class='my-1 mr-2'
                                                                                    for='inlineFormCustomSelectPref'
                                                                                >
                                                                                    Country
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <select
                                                                                    class='custom-select my-1 mr-sm-2'
                                                                                    id='inlineFormCustomSelectPref'
                                                                                    name='UserType'
                                                                                    value={this.state.CountryId}
                                                                                    onChange={this.onChangeCountry.bind(
                                                                                        this
                                                                                    )}
                                                                                >
                                                                                    {this.state.CountryData.map(
                                                                                        (schedule) => (
                                                                                            <option
                                                                                                key={schedule.label}
                                                                                                value={schedule.value}
                                                                                            >
                                                                                                {schedule.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-3'>
                                                                            <div className='form-group'>
                                                                                <label
                                                                                    class='my-1 mr-2'
                                                                                    for='inlineFormCustomSelectPref'
                                                                                >
                                                                                    State
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <select
                                                                                    class='custom-select my-1 mr-sm-2'
                                                                                    id='inlineFormCustomSelectPref'
                                                                                    name='UserType'
                                                                                    value={this.state.StateId}
                                                                                    onChange={this.onChangeState.bind(
                                                                                        this
                                                                                    )}
                                                                                >
                                                                                    {this.state.StateData.map(
                                                                                        (schedule) => (
                                                                                            <option
                                                                                                key={schedule.label}
                                                                                                value={schedule.value}
                                                                                            >
                                                                                                {schedule.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            <div className='form-group'>
                                                                                <label
                                                                                    class='my-1 mr-2'
                                                                                    for='inlineFormCustomSelectPref'
                                                                                >
                                                                                    City
                                                                                </label>
                                                                                <select
                                                                                    class='custom-select my-1 mr-sm-2'
                                                                                    id='inlineFormCustomSelectPref'
                                                                                    name='UserType'
                                                                                    value={this.state.CityId}
                                                                                    onChange={this.onChangeCity.bind(
                                                                                        this
                                                                                    )}
                                                                                >
                                                                                    {this.state.CityData.map(
                                                                                        (schedule) => (
                                                                                            <option
                                                                                                key={schedule.label}
                                                                                                value={schedule.value}
                                                                                            >
                                                                                                {schedule.label}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-3'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Pincode
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control my-1 mr-sm-2'
                                                                                    value={this.state.Pincode}
                                                                                    onChange={(pincode) => {
                                                                                        if (
                                                                                            this.state.NumRegex.test(
                                                                                                pincode.target.value
                                                                                            ) &&
                                                                                            pincode.target.value.length <= 6
                                                                                        ) {
                                                                                            this.setState({
                                                                                                Pincode: pincode.target.value,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Manufactured By
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='manufacturedby'
                                                                                    value={this.state.ManufacturedBy}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            ManufacturedBy: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for=''>
                                                                                    Marketing By
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    className='form-control'
                                                                                    value={this.state.MarketingBy}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            MarketingBy: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                ></input>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody className='pt-0 usercarddata'>
                                        <CardTitle className='usertitle'>
                                            Store Information
                                        </CardTitle>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className='row'>
                                                        <div className='col col-12'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <label for='sw-arrows-first-name'>
                                                                        Upload Store Logo (Size &lt; 100kb, 500*500)
                                                                        <span className='mandatory'>*</span>
                                                                    </label>
                                                                    <div class='div1'>
                                                                        <ImgUpload
                                                                            onChange={this.photoUpload}
                                                                            src={this.state.imagePreviewUrl}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-8'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Store Name
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='StoreName'
                                                                                    value={this.state.StoreName}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            StoreName: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Store URL
                                                                                </label>
                                                                                <input
                                                                                    type='text'
                                                                                    id='CategoryName'
                                                                                    className='form-control'
                                                                                    name='StoreURL'
                                                                                    value={this.state.StoreURL}
                                                                                    onChange={(text) => {
                                                                                        this.setState({
                                                                                            StoreURL: text.target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody className='pt-0 usercarddata'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='form my-4'>
                                                    <div className='row'>
                                                        <div className='col col-12'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Approval Status
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label for='CategoryName'>
                                                                                    Enquiry Number
                                                                                    <span className='mandatory'>*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className='py-1    my-1'>
                                        <Row>
                                            <Col xs='12'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group my-1 mr-sm-2'>
                                                            <label for='CategoryName'>Status</label>
                                                            <br />
                                                            <label class='radio-inline'>
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Active" ? true : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Active",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Active
                                                            </label>
                                                            <label
                                                                class='radio-inline'
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <input
                                                                    type='radio'
                                                                    name='optradio'
                                                                    //  disabled={!this.state.IsVisible}
                                                                    checked={
                                                                        this.state.Status == "Inactive"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            Status: "Inactive",
                                                                        });
                                                                    }}
                                                                />{" "}
                                                                Inactive
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button
                                                            style={{ float: "right", marginTop: "5px" }}
                                                            className='btn align-items-center Bechofy-btn '
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            Save & Add Vendor
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default VendorMaster;
