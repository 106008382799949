import React, { useState } from "react";
import { ProSidebar, Menu, SidebarHeader, MenuItem, SubMenu, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {FaAddressCard, FaHistory, FaUserLock} from 'react-icons/fa';
import { CgProfile, CgLogOut } from "react-icons/cg";
import { Link } from 'react-router-dom';

function ResponsiveSideBarr() {
  const [showMenu, setShowMenu] = useState(false);

  let menu;
  let menuOverlay;

  if (showMenu === true) {
    menu = (
      <div class={`mobile-menu ${showMenu ? "slide-in" : "slide-out"}`}>
        <ProSidebar style={{width:'inherit'}}>
          <SidebarHeader>
            <div class="profile-circle">
              <img class="profile-img" src="https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head.png"></img>
            </div>
            <div class="user-name">
              <h5 style={{color:'white'}}>Deepanshi Jain</h5>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem icon={<CgProfile size="20px" />}>
                Profile 
                <Link to="/account_profile" />
              </MenuItem>
              <MenuItem icon={<FaAddressCard size="20px"/>}>
                Address
                <Link to="/add_address" />
              </MenuItem>
              <MenuItem icon={<FaHistory size="20px"/>}>
                Order History
                <Link to="/order_history" />
              </MenuItem>
              <MenuItem icon={<FaUserLock size="20px"/>}>
                Change Password
                <Link to="/change_password" />
              </MenuItem>
              <MenuItem icon={<CgLogOut size="20px"/>}>
                Logout
                <Link to="/" />
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter></SidebarFooter>
        </ProSidebar>
      </div>
    );

    menuOverlay = (
      <div class="menu-overlay" onClick={() => setShowMenu(false)}></div>
    );
  }

  return (
    <div style={{ display: "inline" }}>
      <p onClick={() => setShowMenu(!showMenu)}>
        <div class="mobile-menu-icon">
          <i class="fa fa-bars one" style={{fontSize:'20px'}} aria-hidden="true"></i>
        </div>
      </p>
 
      {menuOverlay}
      {menu}
    </div>
  );
}

export default ResponsiveSideBarr;

