import React, { Component } from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import NoImage from "../../assets/images/NoImage.png"
import GetApiCall from "../../GETAPI";


class TopCities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      BestProduct: []
    };

  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });


    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest("GetFeaturedProduct").then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data);
        this.setState({
          BestProduct: obj.data.filter(data => data.variant_list !== null),
        });
        Notiflix.Loading.Remove();
      })
    );


  }


  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      autoplay: true,
      speed: 800,

      slidesToShow: 4,
      slidesToScroll: 1,
    };
    let progessed = 0

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="mb-4">
              Top Selling Products
            </h4>
            <Slider {...settings}>
              {this.state.BestProduct.map((data, i) => (
                <div class="best-products">
                  <div class="product-item">
                    <div class="img-container">
                      <img src={data.variant_list.split('#')[0].split('$')[1].split(',')[7].split('~')[0] ? data.variant_list.split('#')[0].split('$')[1].split(',')[7].split('~')[0] : NoImage} alt="product image" className="img-fluid"></img>
                    </div>

                    <div class="product-desc">
                      <h4>
                        {data.variant_list.split('#')[0].split('$')[1].split(',')[0]} </h4>

                    </div>
                  </div>
                </div>
              )

              )}







            </Slider>

          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default TopCities;
