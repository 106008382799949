import Notiflix from "notiflix";

// var urlString = "http://localhost:8051/API/";
var urlString;
if (localStorage.getItem("ClientDetails") != null) {
	urlString = JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress;
}

var PostApiCall = {
	postRequest(userData, url) {
		return fetch(urlString + url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
			},
			body: JSON.stringify(userData),
		})
			.then((response) => {

				if (response.status == 401) {
					Notiflix.Notify.failure("You are not authorized to access this page. Please log in and try again.")
					window.location.href = "/"
				} else {
					return response;
				}
			})
			.catch((error) => {
				//console.log("request failed", error);
				return error;
			});
	},
};

export default PostApiCall;
