import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
	Card,
	CardBody, Col, Container,
	Row
} from "reactstrap";
// import Notiflix from "notiflix";
import moment from "moment";
import PostApiCall from "../../Api";
import varianticon from '../../assets/images/dashboardimages/variant.png';
import GetApiCall from "../../GETAPI";
// import {XSquare} from 'react-feather';

var arr = [];
var arr2 = [];

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class AddNewItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enabled: false,

			CategoryName: null,
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: null,
			AttributData: [],
			ItemName: null,
			HSNCode: null,
			GSTRate: null,
			GSTData: [],
			BrandName: null,
			BrandData: [],
			VendorName: null,
			VendorData: [],
			MarketedBy: null,

			ManufacturedBy: null,
			ManufacturedByData: [],
			Importedby: null,
			CountryOrigindata: [],
			CountryOrigin: null,
			UnitofMeasurementData: [],
			UOM: null,
			Status: "Yes",
			SKU: null,


			ItemStatus: "Active",


			FormStatus: null,

			name: null,
			VerticalName: null,
			VerticalData: [],
			MarketedByByData: []

			// BrandData: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});




		if (localStorage.getItem("itemDetails") != null) {

			PostApiCall.postRequest(
				{

					whereClause: `where fld_productid=${JSON.parse(localStorage.getItem("itemDetails"))}`,
					recordCount: '*'
				},
				"GetProductMaster"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						// console.log(obj.data)
						this.setState({
							ProductData: obj.data,

						});
						if (obj.data.length != 0) {
							this.setState({

								CategoryName: obj.data[0].fld_categoryid,
								SubCategory: obj.data[0].fld_subcategoryid,

								ItemName: obj.data[0].fld_itemname,
								HSNCode: obj.data[0].fld_hsncode,
								GSTRate: obj.data[0].fld_Gstrate,
								BrandName: obj.data[0].fld_brandid,
								VendorName: obj.data[0].fld_vendor_id,
								MarketedBy: obj.data[0].fld_marketedbyId,
								ManufacturedBy: obj.data[0].fld_manufacturer_id,
								Status: obj.data[0].fld_imported_product,
								Importedby: obj.data[0].fld_importedbyId,
								CountryOrigin: obj.data[0].fld_origin_country_id,
								UOM: obj.data[0].fld_uom,
								ProductID: obj.data[0].fld_productid,
								VerticalName: obj.data[0].fld_verticleid,
								ItemStatus: obj.data[0].fld_status


							})

							Notiflix.Loading.Dots("Please wait...");

							PostApiCall.postRequest(
								{
									categoryid: obj.data[0].fld_categoryid,
								},
								"Get_subcategoryDropdown"
							).then((results1) =>
								results1.json().then((obj1) => {
									if (results1.status == 200 || results1.status == 201) {
										this.setState({
											SubCatgeoryData: obj1.data,
										});

										Notiflix.Loading.Remove();
									}
								})
							);

							Notiflix.Loading.Dots("Please wait...");

							PostApiCall.postRequest(
								{
									whereClause: `where fld_vertical_id=${obj.data[0].fld_verticleid}`,
								},
								"Get_categorydropdown"
							).then((results1) =>
								results1.json().then((obj1) => {
									if (results1.status == 200 || results1.status == 201) {
										this.setState({
											CategoryData: obj1.data,
										});

										Notiflix.Loading.Remove();
									}
								})
							);



						}
						Notiflix.Loading.Remove();
					}
				}))
		}




		Notiflix.Loading.Dots("Please wait...");


		PostApiCall.postRequest(
			{
				whereClause: "where fld_vendor='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						VendorData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				whereClause: "where fld_Manufacturer='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						ManufacturedByData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				whereClause: "where fld_marketer='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						MarketedByByData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				WhereClause: `where fld_status='Active'`,
				RecordCount: 'fld_uom as label,fld_uomid as value'
			},
			"GetUOM"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						UnitofMeasurementData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))



		Notiflix.Loading.Dots(null);

		PostApiCall.postRequest(
			{
				WhereClause: `where fld_status='Active'`,
				RecordCount: 'fld_gstpercentage as label,fld_gstpercentage as value'
			},
			"GetGST"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						GSTData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))




		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);



		GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					BrandData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryOrigindata: obj.data,
				});
			})
		);
	}

	onChangeCategory(text) {
		this.setState(
			{
				CategoryName: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryName,
					},
					"Get_subcategoryDropdown"
				).then((results1) =>
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							this.setState({
								SubCatgeoryData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			});
	}


	onSaveData() {
		// //console.log(this.state.imageCount);

		if (this.state.ItemName != null) {
			if (this.state.Status == "Yes") {

				this.onPostItem();
			}
			else {
				Notiflix.Notify.Failure("Please select imported by");
			}
		}
		else {
			Notiflix.Notify.Failure("Please enter item name");
		}
		// 	} else {
		// 		Notiflix.Notify.Failure("Please select sub category name");
		// 	}
		// } else {
		// 	Notiflix.Notify.Failure("Please select  category name");
		// }
	}




	onPostItem = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				productid: this.state.ProductID,
				categoryname: this.state.CategoryName,
				subcategoryname: this.state.SubCategory,
				categoryid: this.state.CategoryName,
				subcategoryid: this.state.SubCategory,
				itemname: this.state.ItemName,
				hsncode: this.state.HSNCode,
				Gstrate: this.state.GSTRate,
				vendorname: this.state.VendorName,
				manufactouredby: this.state.ManufacturedBy,
				brandname: this.state.BrandName,
				marketedby: this.state.MarketedBy,
				importedProduct: this.state.Status,
				importedBy: this.state.Importedby,
				originCountryId: this.state.CountryOrigin,
				uom: this.state.UOM,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
				formstatus: "Completed",
				verticleid: this.state.VerticalName

			},
			"AddProductMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success('Item is Successfully Added')
					window.location.reload();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data)

				}
			})
		);
	};



	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	render() {


		let temp = [];

		this.state.AttributData.map((data, i) => {
			temp.push(data.fld_attributename);
		});
		temp = [...new Set(temp)];
		let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");

		let KeysArray = Object.keys(groupTemp);
		let ValuesArray = Object.values(groupTemp);

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>


						<div className="row">
							<div className="col-md-6">
								{/* <Breadcrumbs
									title={"Bechofy / Product Management"}
									breadcrumbItem={"Add New Product"}
								/> */}
								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Product List</a></li>
										<li>Add New Product</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								{/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

							</div>


						</div>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"

									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Add New Product
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<button
													onClick={() => {
														localStorage.removeItem("VariantDetails")
														window.location.href = "/addnewvariant";
													}}

													className="btn align-items-center btn Bechofy-btn AddNewBtn"
												>
													Add New Variant{" "}
													<span ><img src={varianticon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

												</button>
											</Col>
										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">


												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">


																			<div className="col col-12">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.ItemName}
																						onChange={(text) => {
																							this.setState({
																								ItemName: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																					/>
																				</div>
																			</div>




																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		Save
																	</button>

																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>


										</Col>
									</Row>
									{/* -row */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddNewItem;
