import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

const AddStaticPage = () => {
  const [previewUrl, setPreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [originalImage, setOriginalImage] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [name, setName] = useState("");
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [id, setId] = useState(null);
  const [outputFileName, setOutputFileName] = useState(null);
  const [Sequence, setSequence] = useState(null);
  const [SequenceData, setSequenceData]= useState([
    {label: "1", value: "1"},
    {label: "2", value: "2"},
    {label: "3", value: "3"},
    {label: "4", value: "4"},
    {label: "5", value: "5"},
    {label: "6", value: "6"},
    {label: "7", value: "7"},
    {label: "8", value: "8"},
    {label: "9", value: "9"},
    {label: "10", value: "10"},
])

  const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap img-upload">
        <img
          for="photo-upload"
          src={src}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5%",
          }}
        />
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        onChange={onChange}
      />
    </label>
  );

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
    setUploadImage(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validate();
  };
  const validate = () => {
    if (name !== "") {
      if (heading !== "") {
        if (content !== "") {
          if (
            previewUrl !==
            "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
          ) {
            if(Sequence !== null){
            onPost();
            }else{
              Notiflix.Notify.Failure("Please select the sequence in which you want to show it on website");
            }
          } else {
            Notiflix.Notify.Failure("Please add Page Image");
          }
        } else {
          Notiflix.Notify.Failure("Page Content cannot be empty");
        }
      } else {
        Notiflix.Notify.Failure("Page Heading cannot be empty");
      }
    } else {
      Notiflix.Notify.Failure("Page Name cannot be empty");
    }
  };
  const onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);
    PostApiCall.postRequest(
      {
        pageid: id,
        name: name,
        heading: heading,
        content: content,
        sequence: Sequence,
        // image: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
        // ("updatedImage").trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,

        updatedby: details[0].fld_userid,
      },
      "AddStaticPageContent"
    ).then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          onPostAddPageImage(obj);
          Notiflix.Loading.Remove();

          Notiflix.Notify.Success("Successfully Added");
          window.location.href = "/staticPage";
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure("Error Occured");
        }
      });
    });
  };

  const onPostAddPageImage = (obj) => {}
  // function onUploadImage(obj) {}




  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Bechofy"} breadcrumbItem={"Add New Page"} />
          <Row>
            <Col xl="12">
              <Card className="overflow-hidden">
                <Row>
                  <Col xl="12">
                    <Card className="overflow-hidden">
                      <div
                        className="Bechofy-bg-soft-pink"
                        style={{ background: "#777f80" }}
                      >
                        <Row>
                          <Col xs="3">
                            <div className="Bechofy-text p-3">
                              <h5
                                className="Bechofy-text"
                                style={{ color: "#fff" }}
                              >
                                Add New Page
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <article
                        style={{ margin: "1rem 2rem", padding: "1rem 3rem 0" }}
                      >
                        <Row>
                          <Col xs={4}>
                          <ImgUpload src={previewUrl} onChange={photoUpload} />
                          </Col>
                          <Col xs={8}>
                          <div className=" form-main">
                            <div className="form-group form-main">
                              <label for="CategoryName">
                                Page Name
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control"
                                name="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            
                            <div className="form-group form-main">
                              <label for="CategoryName">
                                Page Heading
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control"
                                name="Heading"
                                value={heading}
                                onChange={(e) => setHeading(e.target.value)}
                              />
                            </div>
                            <div className="form-group form-main">
                              <label for="CategoryName">
                                Sequence
                                <span className="mandatory">*</span>
                              </label>
                              <select
                                
                                name="Name"
                                value={Sequence}
                                onChange={(e) => {
                                  setSequence(e.target.value)
                                }}
                                className="custom-select my-1 mr-sm-2"
                              >
                                <option value="">
                                  Select Sequence
                                </option>
                                {
                                  SequenceData.map((data, i)=>{
                                    return(
                                      <option key={data.value} value={data.value}>
                                        {data.label}
                                      </option>
                                    )
                                   
                                  })
                                }
                               
                              </select>
                            </div>
                          </div>
                          </Col>
                        </Row>
                        {/* <div className="form-container">
                        </div> */}
                        <Card className="overflow-hidden">
                          <div className="Bechofy-bg-soft-pink">
                            <Row>
                              <Col xs="3">
                                <div className="Bechofy-text p-3">
                                  <h5 className="Bechofy-text">Content</h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <CardBody className="py-1 my-1">
                            <Row>
                              <Col xs="12">
                                <div className="row">
                                  <div className="col-12">
                                    <div class="form-group">
                                      <label for="sw-arrows-first-name">
                                        Content for the Page
                                        <span className="mandatory">*</span>
                                      </label>
                                      <div className="niceeditors">
                                        <CKEditor
                                          config={{
                                            extraPlugins:
                                              "justify,font,colorbutton",
                                          }}
                                          data={content}
                                          onChange={(e) =>
                                            setContent(e.editor.getData())
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <section>
                          <div className="row">
                            <div className="col-6">
                              {/* <div className="form-group my-1 mr-sm-2">
                                <label for="CategoryName">
                                  Show on Website
                                  <span className="mandatory">*</span>
                                </label>
                                <br />
                                <label class="radio-inline">
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={status == "Active" ? true : false}
                                    onClick={() => setStatus("Active")}
                                  />{" "}
                                  Yes
                                </label>
                                <label
                                  class="radio-inline"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      status == "Inactive" ? true : false
                                    }
                                    onClick={() => setStatus("Inactive")}
                                  />{" "}
                                  No
                                </label>
                              </div> */}
                            </div>
                            <div className="col-6">
                              <button
                                style={{ float: "right" }}
                                className="btn align-items-center Bechofy-btn "
                                onClick={handleSubmit}
                                type="submit"
                              >
                                Save Page Content
                              </button>
                            </div>
                          </div>
                        </section>
                      </article>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default AddStaticPage;
