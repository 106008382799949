import React, { Component } from "react";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";


class SocialSource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Orders: 'All Orders',
			OrderData: [
				{ label: 'All Orders', value: 'All Orders' },
				{ label: 'Todays Orders', value: 'Todays Orders' },
				{ label: 'Orders This week', value: 'Orders This week' },
				{ label: 'Orders This Month', value: 'Orders This Month' },
				{ label: 'Orders This Year', value: 'Orders This Year' },


			],
			OrderSummaryData: []
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Custom("");

		PostApiCall.postRequest(
			{
				WhereClause: 'All Orders'
			},
			"Get_DashboardOrderSummary"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// console.log(obj.data)
					this.setState({
						OrderSummaryData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))

	}

	render() {
		// console.log(this.state.OrderSummaryData.length)
		return (
			<React.Fragment>

				<Card className="mini-stats-wid">
					<CardBody>
						<Media>
							<Media body>
								<Row>
									<Col xl="6">
										<h4 className="mb-0">Order Summary

										</h4>
									</Col>
									{/* <Col xl="6">
										<span>
											<select className="form-control"

												value={this.state.Orders}
												onChange={(text) => {
													this.setState({
														Orders: text.target.value
													}, () => {

														PostApiCall.postRequest(
															{
																WhereClause: this.state.Orders
															},
															"Get_DashboardOrderSummary"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	console.log(obj.data)
																	this.setState({
																		OrderSummaryData: obj.data,

																	});
																	Notiflix.Loading.Remove();
																}
															}))
													})
												}}
											>
												{this.state.OrderData.map((data => (
													<option key={data.value} value={data.value}>
														{data.label}
													</option>
												)))}
											</select>
										</span>
									</Col> */}

								</Row>
								<hr />
								<p className="text-muted font-weight-medium">
									New Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'total_order') {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count}
												</span>
											)
										}

									})}
								</p>
								<hr />
								<p className="text-muted font-weight-medium">
									Accepted Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'accepted_order') {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{i == 0 ? 0 : data.order_count}
												</span>
											)
										}
										else {
											if (i == 0) {
												return (
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
												)

											}
										}
									})}

								</p>
								<hr />
								<p className="text-muted font-weight-medium">
									Dispatched Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'dispatch_order') {
											return (

												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{i == 0 ? 0 : data.order_count}
												</span>

											)
										}
										{/* else {
											if (i == 0) {
												return (

													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
												)
											}
										} */}
									})}
								</p>

								<hr />
								<p className="text-muted font-weight-medium">
									Delivered Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'delivered_order') {
											return (
												data.order_count != 0 ?
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														{data.order_count}
													</span>
													:
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
											)
										}
									})}
								</p>
								<hr />

								<p className="text-muted font-weight-medium">
									Cancelled Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'cancelled_order') {
											return (
												data.order_count || data.order_count != 0 ?
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														{data.order_count}
													</span>
													:
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
											)
										}

									})}
								</p>
								<hr />

								<p className="text-muted font-weight-medium">
									Abandoned Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'abandoned_order') {
											return (
												data.order_count || data.order_count != 0 ?
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														{data.order_count}
													</span>
													:
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
											)
										}

									})}
								</p>
								{/* <p className="text-muted font-weight-medium">
									Returned Orders
									{this.state.OrderSummaryData.map((data, i) => {
										if (data.count_for == 'returned_order') {
											return (
												<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
													{data.order_count}
												</span>
											)
										}
										else {
											if (i == 0) {
												return (
													<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
														0
													</span>
												)

											}
										}
									})}
								</p> */}
							</Media>
						</Media>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default SocialSource;
