import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Media,
} from "reactstrap";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withNamespaces } from "react-i18next";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import SalesSummary from './SalesSummary'
import OrderSummary from './OrderSummary'
import NewOrderList from './NewOrderList'
import ProductsIcon from '../../assets/images/dashboardimages/brand-identity.png'
import OrdersIcon from '../../assets/images/dashboardimages/shopping-cart.png'
import UserIcon from '../../assets/images/dashboardimages/profile.png'
import Refund from './Refund';
import RefundList from './NewRefundList'
import ItemDetails from "./ItemDetails";
import LatestReviews from "./LatestReviews";


class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {

			modal: false,
			TablesRecords: [],
			OrdersCount: [],
			TotalSales: [],

			CustomerData: [],
			OrderData: [],
			ProductData: []
		};
		this.togglemodal.bind(this);
	}

	togglemodal = () => {
		this.setState((prevState) => ({
			modal: !prevState.modal,
		}));
	};
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Custom("");
		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DasboradCustomerCard"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// console.log(obj.data)
					this.setState({
						CustomerData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


		// ====ProductCard====
		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DasboradProductCard"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					// //console.log(obj1.data)
					this.setState({
						ProductData: obj1.data,

					});
					Notiflix.Loading.Remove();
				}
			}))



		// ====OrderCard====
		PostApiCall.postRequest(
			{
				WhereClause: ""
			},
			"Get_DasboradOrderCard"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj2) => {
				if (results.status == 200 || results.status == 201) {
					// //console.log(obj2.data)
					this.setState({
						OrderData: obj2.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


	}
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						<Breadcrumbs breadcrumbItem={"Dashboard"} urlPath={"/dashboard"} />

						<Row>
							<Col xl="4">
								<WelcomeComp />
								<MonthlyEarning />

							</Col>
							<Col xl="8">


								<Row>
									{/* Reports Render */}
									<Col md="4">
										<Card className="mini-stats-wid">
											<CardBody>
												<Media>
													<Media body>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-8" >
																	<h4 className="mb-0 productTitle" >Customers
																	</h4>
																</div>

																<div className="col-md-4">

																	<img src={UserIcon} alt="customer" className="ProductIcons"></img>

																</div>

															</div>
														</div>
														<hr />
														<p className="text-muted font-weight-medium">
															Customers
															{this.state.CustomerData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Total_Customer}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Today's New Customers
															{this.state.CustomerData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Today_Customer}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															New Customers this Month
															{this.state.CustomerData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.This_month_Customer}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Ordered today
															{this.state.CustomerData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Today_Customer_Order}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Customers not Ordered
															{this.state.CustomerData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Customer_Not_Ordered}
																</span>
															))}
														</p>
													</Media>
												</Media>
											</CardBody>
										</Card>
									</Col>

									{/* ======Products========= */}
									<Col md="4">
										<Card className="mini-stats-wid">
											<CardBody>
												<Media>
													<Media body>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-8" >
																	<h4 className="mb-0 productTitle" >Products
																	</h4>
																</div>

																<div className="col-md-4">

																	<img src={ProductsIcon} alt="customer" className="ProductIcons"></img>

																</div>

															</div>
														</div>
														<hr />
														<p className="text-muted font-weight-medium">
															Brands
															<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																{this.state.ProductData.map((data, i) => (
																	<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																		{data.Total_brand}
																	</span>
																))}
															</span>
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Verticals
															{this.state.ProductData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Today_Vertical}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Categories
															{this.state.ProductData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Total_Categories}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Sub Categories
															{this.state.ProductData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Today_sub_Categories}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Items / Variants
															{this.state.ProductData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Total_Variants}
																</span>
															))}
														</p>
													</Media>
												</Media>
											</CardBody>
										</Card>
									</Col>
									{/* =========Orders======== */}
									<Col md="4">
										<Card className="mini-stats-wid">
											<CardBody>
												<Media>
													<Media body>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-8" >
																	<h4 className="mb-0 productTitle" >Orders
																	</h4>
																</div>

																<div className="col-md-4">

																	<img src={OrdersIcon} alt="customer" className="ProductIcons"></img>

																</div>

															</div>
														</div>
														<hr />
														<p className="text-muted font-weight-medium">
															Orders
															{this.state.OrderData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Total_order}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															New Orders Today
															{this.state.OrderData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.Today_Order}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Orders this Week
															{this.state.OrderData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.order_this_week}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Orders this Month
															{this.state.OrderData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.order_this_month}
																</span>
															))}
														</p>
														<hr />
														<p className="text-muted font-weight-medium">
															Orders this Year
															{this.state.OrderData.map((data, i) => (
																<span className="text-muted font-weight-medium float-right Bechofy-count d-flex justify-content-center align-items-center">
																	{data.order_this_year}
																</span>
															))}
														</p>
													</Media>
												</Media>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row>
									<SalesSummary />
								</Row>
							</Col>
						</Row>

						<Row>
							<Col xl="4">
								<OrderSummary />
							</Col>
							<Col xl="8">
								<NewOrderList />
							</Col>
						</Row>



						<Row>
							<Col lg="12">
								<LatestTranaction />
							</Col>
						</Row>
						<Row>
							<Col xl="12">
								<TopCities />
							</Col>
						</Row>

					</Container>
				</div>

			</React.Fragment>
		);
	}
}

export default withNamespaces()(Dashboard);
