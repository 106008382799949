import { MDBCol } from "mdbreact";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-responsive-modal/styles.css";

import Searchicon from '../../assets/images/dashboardimages/search.png';


import Saveicon from '../../assets/images/dashboardimages/filesave.png';
import Editicon from '../../assets/images/dashboardimages/pencil.png';


const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas" style={{ marginBottom: '5px' }}>
    <div className="img-wrap brandimages img-upload">
      <img
        for="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

class StickerDiscountMaster extends Component {
  constructor(props) {
    super(props);
    const { shapeIdProps } = props.match.params;
    this.state = {
      data: [],
      searchInput: "",
      stickerDiscountData: [],
      ViewClick: "true",
      open: false,
      imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',
      ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",
      Status: "Yes",
      SizeName: "",
      originalImage: null,
      outputFileName: null,
      openedit: false,
      quantity: null,
      quantity2: null,
      discount: null,
      stickerDiscountId: null
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
      // ...
    });

    // Fetch Sticker Size data from the API
    PostApiCall.postRequest(
      {
        WhereClause: "",
      },
      "GetStickerDiscountMaster"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          this.setState({
            stickerDiscountData: obj.data,
            shapeName: obj.data[0]?.shape_name
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  // name is mandatory
  onSaveData() {
    if (this.state.quantity !== "") {
      if (this.state.quantity2 !== "") {
        if (this.state.discount !== "") {
          this.onPost();
        } else {
          Notiflix.Notify.Failure("Please enter discount.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter quantity to.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter quantity from.");
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    var login = localStorage.getItem("LoginDetail");
    var details = JSON.parse(login);

    PostApiCall.postRequest(
      {
        id: this.state.stickerDiscountId, // ID with response
        quantity: this.state.quantity,
        quantity2: this.state.quantity2,
        status: this.state.Status,
        discount: this.state.discount
      },
      "AddStickerDiscount"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("New Sticker discount successfully added");
          this.setState(({
            quantity: null,
            discount: null,
            shapeid: null,
            stickerDiscountId: null
          }))
          this.componentDidMount();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(
            "Something went wrong, please try again later"
          );
        }
      })
    );
  };

  handleInputChange = (e) => {
    this.setState({
      fld_Size_name: e.target.value,
    });
  };

  searchBarHandler = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    this.setState({
      imagePreviewUrl: URL.createObjectURL(imageFile),
      originalImage: imageFile,
      outputFileName: imageFile.name,
      uploadImage: true
    });
  };


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            <div className="row">
              <div className="col-md-6">

                <div>
                  <ul class="breadcrumbs">
                    <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
                    <li>Master Management</li>
                    <li>Sticker Discount</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                {/* <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img> */}

              </div>


            </div>
            <Row>
              <Col xl="12">
                <Card className="pagebackground" id="upload-card">


                  <Card >
                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                      <div className="row my-1">
                        <div className="col-4">
                          <div className="form-group">
                            <h4 className="FilterCard" style={{ marginTop: '10px' }}>Sticker Discount</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      <Row>


                        <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                          <div class="input-group mb-3" >


                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                              onChange={(e) => this.seachBarHandler(e)}
                              value={this.state.searchInput}

                            />
                          </div>
                        </MDBCol>

                        <Col md="12">

                          {this.state.stickerDiscountData.length !== 0 ?
                            <Card >

                              <CardBody className="pt-0">
                                <Row>
                                  <Col xs="4">
                                    <CardBody className="categoryCard">
                                      <Row className="card-row">
                                        <Col sm="12">
                                          <h5>Add New Discount</h5>
                                        </Col>
                                      </Row>
                                      <Row className="card-row">
                                        <Col sm="6">
                                          <div className="row">

                                            <div className="col-12">
                                              <label class="my-1 mr-2">Quantity From *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Quantity From"
                                                value={this.state.quantity}
                                                onChange={(text) => {
                                                  this.setState({
                                                    quantity: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">Quantity To *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Quantity To"
                                                value={this.state.quantity2}
                                                onChange={(text) => {
                                                  this.setState({
                                                    quantity2: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">Discount *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Discount"
                                                value={this.state.discount}
                                                onChange={(text) => {
                                                  this.setState({
                                                    discount: text.target.value,
                                                  });
                                                }}
                                              />

                                            </div>

                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                              <div className="form-group">
                                                <label for="CategoryName">
                                                  Status
                                                  <span className="mandatory">*</span>
                                                </label>
                                                <br />
                                                <label class="radio-inline">
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "Yes"
                                                        ? true
                                                        : false
                                                    }

                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "Yes",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Active
                                                </label>
                                                <label
                                                  class="radio-inline"
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "No"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "No",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Inactive
                                                </label>
                                              </div>
                                            </div>

                                          </div>
                                        </Col>
                                        <hr />
                                      </Row>
                                      <Row>
                                        <Col sm="6">
                                        </Col>
                                        <Col sm="6">

                                          <a
                                            onClick={this.onSaveData.bind(this)}
                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                            style={{ marginTop: "5px", color: "white" }}
                                          >
                                            <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                            Save
                                          </a>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Col>


                                  {/* ======Brand List */}
                                  {this.state.stickerDiscountData.filter((data) => {
                                    if (this.state.searchInput == "") {
                                      return data;
                                    }
                                    if (
                                      this.state.searchInput !== "" &&
                                      (data.fld_SizeName
                                        .toLowerCase()
                                        .includes(this.state.searchInput.toLowerCase()) ||

                                        `${data.fld_status
                                          ? data.fld_status.toLowerCase()
                                          : ""
                                          }`.includes(this.state.searchInput.toLowerCase()))
                                    ) {
                                      return data;
                                    }
                                  }).map((data, i) => (
                                    <Col xs="4">
                                      <CardBody className="categoryCard">

                                        <Row className="card-row">
                                          <Col sm="7">
                                            <div className="row">
                                              <div className="col-12" style={{ marginTop: '10px' }}>
                                                <p>
                                                  <b>Quantity From</b> -{" "}
                                                  <span>
                                                    {data.fld_quantity}
                                                  </span>
                                                </p>

                                              </div>
                                              <div className="col-12" style={{ marginTop: '10px' }}>
                                                <p>
                                                  <b>Quantity To</b> -{" "}
                                                  <span>
                                                    {data.fld_quantity_to}
                                                  </span>
                                                </p>

                                              </div>

                                            </div>
                                            <div className="row">
                                              <div className="col-12" style={{ marginTop: '10px' }}>
                                                <p>
                                                  <b>Discount</b> -{" "}
                                                  <span>
                                                    {data.fld_discount} %
                                                  </span>
                                                </p>

                                              </div>
                                            </div>

                                          </Col>
                                          <hr />
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="BrandStatus">
                                            <p >
                                              <b>Status</b> -{" "}
                                              <span
                                                style={{
                                                  color:
                                                    data.fld_status == "Active" || data.fld_status == "Yes"
                                                      ? "green"
                                                      : "red",
                                                }}
                                              >
                                                {data.fld_status}
                                              </span>
                                            </p>
                                          </Col>
                                          <Col sm="8">
                                            <span>
                                              <a
                                                onClick={() => {
                                                  this.setState({
                                                    stickerDiscountId: data.fld_id,
                                                    quantity: data.fld_quantity,
                                                    quantity2: data.fld_quantity_to,
                                                    discount: data.fld_discount,
                                                    Status: data.fld_status
                                                  })
                                                  window.location.href = "#upload-card"

                                                }}
                                                // onClick={this.onSaveData.bind(this)}
                                                className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                style={{ marginTop: "5px", color: "white" }}
                                              >
                                                <span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                Edit
                                              </a>
                                            </span>


                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Col>
                                  ))}
                                </Row>
                              </CardBody>
                            </Card>
                            :
                            <Card >

                              <CardBody className="pt-0">
                                <Row>
                                  <Col xs="4">
                                    <CardBody className="categoryCard">
                                      <Row className="card-row">
                                        <Col sm="12">
                                          <h5>Add New Discount</h5>
                                        </Col>
                                      </Row>
                                      <Row className="card-row">
                                        <Col sm="6">
                                          <div className="row">

                                            <div className="col-12">
                                              <label class="my-1 mr-2">Quantity From *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Quantity From"
                                                value={this.state.quantity}
                                                onChange={(text) => {
                                                  this.setState({
                                                    quantity: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">Quantity To *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Quantity To"
                                                value={this.state.quantity2}
                                                onChange={(text) => {
                                                  this.setState({
                                                    quantity2: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label class="my-1 mr-2">Discount *</label>

                                              <input
                                                className="form-control"
                                                name="name"
                                                type="text"
                                                placeholder="Discount %"
                                                value={this.state.discount}
                                                onChange={(text) => {
                                                  this.setState({
                                                    discount: text.target.value,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-12" style={{ marginTop: '10px' }}>
                                              <div className="form-group">
                                                <label for="CategoryName">
                                                  Status
                                                  <span className="mandatory">*</span>
                                                </label>
                                                <br />
                                                <label class="radio-inline">
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "Yes"
                                                        ? true
                                                        : false
                                                    }

                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "Yes",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Active
                                                </label>
                                                <label
                                                  class="radio-inline"
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  <input
                                                    type="radio"
                                                    name="optradio"
                                                    //  disabled={!this.state.IsVisible}
                                                    checked={
                                                      this.state.Status == "No"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() => {
                                                      this.setState({
                                                        Status: "No",
                                                      });
                                                    }}
                                                  />{" "}
                                                  Inactive
                                                </label>
                                              </div>
                                            </div>

                                          </div>
                                        </Col>
                                        <hr />
                                      </Row>
                                      <Row>
                                        <Col sm="6">
                                        </Col>
                                        <Col sm="6">

                                          <a
                                            onClick={this.onSaveData.bind(this)}
                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                            style={{ marginTop: "5px", color: "white" }}
                                          >
                                            <span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                            Save
                                          </a>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Card>

                {/* <WelcomeComp /> */}
              </Col>
            </Row>
            {/* </Card>

							</Col>
						</Row> */}
          </Container>
        </div >
      </React.Fragment >
    );
  }
}

export default StickerDiscountMaster;
