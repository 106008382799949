import React, { Component } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Media,
    Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";


class SocialSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderListData: [],

        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        PostApiCall.postRequest(
            {
                WhereClause: 'order by fld_order_date desc'
            },
            "Get_DashboardOrderList"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        OrderListData: obj.data.filter(value => value.fld_payment_status == "Successful" || value.fld_payment_status == "success"),

                    });
                    Notiflix.Loading.Remove();
                }
            }))

    }


    render() {
        return (
            <React.Fragment>

                <Card className="mini-stats-wid" style={{ height: '408px' }}>
                    <CardBody>
                        <Media>
                            <Media body>
                                <Row>
                                    <Col xs="6">
                                        <h4 className="Bechofy-text" style={{ color: '#495057' }}>
                                            Latest Order List
                                        </h4>

                                    </Col>
                                    <Col xs="6">
                                        <div className="Bechofy-text">
                                            <Link
                                                to="/order_list"
                                                style={{
                                                    float: "right",

                                                    background: 'black',
                                                    color: 'white',
                                                    marginBottom: '5px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                View All Orders{" "}

                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                <div class="table-wrapper-scroll-y my-custom-scrollbar" style={{ maxHeight: '310px' }}>

                                    <table class="table table-bordered table-striped mb-0">

                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Order Number</th>
                                                <th>Customer Name</th>
                                                <th>Order Date & Time</th>
                                                <th>Total Items</th>
                                                <th>Order Value</th>
                                                <th>Order Status</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.OrderListData.map((data, i) => (
                                                <tr>
                                                    <td className="centered">{i + 1}</td>
                                                    <td className="centered">{data.fld_order_number}</td>
                                                    <td className="centered">{data.customer_name}</td>
                                                    <td className="centered">{moment(data.fld_order_date).format("DD/MM/YYYY")}</td>
                                                    <td className="centered">{data.items_count}</td>
                                                    <td className="rightAlign">{parseFloat(data.fld_order_amount).toFixed(2)}</td>
                                                    <td className="centered">{data.fld_order_status}</td>
                                                    <td className="centered">{data.fld_payment_status}</td>
                                                </tr>
                                            ))}


                                        </tbody>

                                    </table>
                                </div>
                            </Media>
                        </Media>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SocialSource;
